import ReportTemplateGraphs_v1_0 from './Graphs/v1.0.js';
import ReportTemplateGraphs_v1_1 from './Graphs/v1.1.js';

export default function ReportTemplateGraphs(props) {
  const {apiVersion} = props;
  if (apiVersion === '1.0') {
    return ReportTemplateGraphs_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateGraphs_v1_1(props);
  }
  return null;
};
