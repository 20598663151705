import { isValidElement, cloneElement } from "react";
import { withTranslation } from "react-i18next";

const ReportTemplateBiometryTable = ({t: __, defaultKey='', children, props, fetus = 1 }) => {

  const {"column-labels": columnLabels} = (props ?? {});

  let defaultColumnLabels = [
    __("report.biometryTable.biometry"),
    __("report.biometryTable.value"),
    __("report.biometryTable.ga"),
    __("report.biometryTable.percentile"),
    ""
  ];

  const columnLabelsArray = columnLabels?.split("|") || [];
  for(let i = 0; columnLabelsArray[i]; i++) {
    defaultColumnLabels[i] = columnLabelsArray[i];
  }

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props, fetus, defaultKey: `${defaultKey}_${i}_biometrytable_${fetus}` });
    }
    return child;
  }) : children;

  return (
    <div className="biometry-table">
      <div className="biometry-item column-heading">
        {defaultColumnLabels.slice(0, 4).map((label, index) => <div key={index}>{label.trim() || <>&nbsp;</>}</div>)}
      </div>
      {childrenWithFetusNumber}
    </div>
  );
}

export default withTranslation()(ReportTemplateBiometryTable);
