import ActionTypes from './actions/actionTypes';

export const defaultExamSharingState = {
    data: {
        sharingDocuments: [],
        generalDocuments: [],
        uploadedDocuments: [],
        preferenceGeneralDocuments: [],
        sharingImages: [],
        selectedSharingImages: [],
        selectedSharingVideos: [],
        sharingVideos: [],
        totalActiveUploadedDocuments: 0,
    },
}

const updateStatus = (list, newStatus) => list.map(
    item => item.id === newStatus.id ? newStatus : item
);
const updateStatusWithoutAssociation = (list, newStatus) => list.map(
    item => !newStatus.id && item.attachment_id === newStatus.attachment_id ? newStatus : item
);
const updateStatusPreferenceSharingDoc = (list, newStatus) => list.map(
    item => item.title === newStatus.title ? newStatus : item
);


export default (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case ActionTypes.SET_SHARING_DOC: {
            return {...state, data: {
                ...state.data,
                generalDocuments: payload.list
                .filter(item => item.attachment.scope === 'site')
                .map(item => ({
                    ...(state.data.generalDocuments
                        .find(
                            oldDoc => oldDoc.attachment_id === item.attachment_id && item.id === null && item.examination_id === oldDoc.examination_id
                        ) || item
                    ),
                })),
                uploadedDocuments: payload.list.filter(item => item.attachment.scope === 'examination'),
                sharingDocuments: payload.list,
                totalActiveUploadedDocuments: payload.list.filter(item => item.active).length,
            }};
        }

        case ActionTypes.SET_PREFERENCE_GENERAL_DOC: {
            return {...state, data: {
                ...state.data,
                preferenceGeneralDocuments: payload.list
                .filter(item => item.attachment?.scope || item.scope === 'site')
                .map(item => ({
                    ...(state.data.generalDocuments
                        .find(
                            oldDoc => (oldDoc.title === item.title) && item.id === null
                        ) || item
                    ),
                })),
            }};
        }

        case ActionTypes.SET_SHARING_IMAGES: {
            return {...state, data: {
                ...state.data,
                sharingImages: payload.list,
                selectedSharingImages: payload.list.filter(item =>item.shared_with_patient),
            }};
        }

        case ActionTypes.SET_SHARING_VIDEOS: {
            return {...state, data: {
                ...state.data,
                sharingVideos: payload.list,
                selectedSharingVideos: payload.list.filter(item =>item.shared_with_patient),
            }};
        }

        case ActionTypes.CHANGE_ACTIVE_STATUS_SHARE_DOC: {
            const { newStatus } = payload; 

            return {...state, data: {
                ...state.data,
                sharingDocuments: updateStatus(state.data.sharingDocuments, newStatus),
                uploadedDocuments: updateStatus(state.data.uploadedDocuments, newStatus),
                totalActiveUploadedDocuments: updateStatus(state.data.sharingDocuments, newStatus)
                  .filter(item => item.active).length,
            }};
        }

        case ActionTypes.CHANGE_ACTIVE_STATUS_GENERAL_DOC: {
            const { newStatus } = payload;

            return {...state, data: {
                ...state.data,
                sharingDocuments: updateStatus(state.data.sharingDocuments, newStatus),
                generalDocuments: updateStatus(state.data.generalDocuments, newStatus),
                uploadedDocuments: updateStatus(state.data.uploadedDocuments, newStatus),
                totalActiveUploadedDocuments:  updateStatus(state.data.sharingDocuments, newStatus)
                  .filter(item => item.active).length,
            }}
        }

        case ActionTypes.CHANGE_ACTIVE_STATUS_GENERAL_DOC_WITHOUT_ASSOC: {
            const { newStatus } = payload;

            return {...state, data: {
                ...state.data,
                generalDocuments: updateStatusWithoutAssociation(state.data.generalDocuments, newStatus),
                uploadedDocuments: updateStatusWithoutAssociation(state.data.uploadedDocuments, newStatus),
                sharingDocuments: updateStatusWithoutAssociation(state.data.sharingDocuments, newStatus),
                totalActiveUploadedDocuments: updateStatusWithoutAssociation(state.data.sharingDocuments, newStatus)
                  .filter(item => item.active).length,
            }};
        }

        case ActionTypes.CHANGE_STATUS_PREFERENCE_GENERAL_DOC: {
            const { newStatus } = payload;

            return {...state, data: {
                ...state.data,
                preferenceGeneralDocuments: updateStatusPreferenceSharingDoc(state.data.preferenceGeneralDocuments, newStatus),
            }};
        }

        default : {
            return state;
        }
    }
}
