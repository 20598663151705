import React from "react";
import SimulatorDisclaimerView from "./SimulatorDisclaimerView";

const Simulator = () => {
  return (
    <SimulatorDisclaimerView />
  )
};

export default Simulator;
