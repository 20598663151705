import React, {useContext} from "react";
import PropTypes from "prop-types";
import useAuth from "../context-providers/Auth";
import LeftNavbarRoutine from "../components/LeftNavbar";
import LeftNavbarDetect from "../components-dt/LeftNavbar";
import Loader from "../atoms/Loader/Loader";
import { AppContext } from "../context-providers/App";
import { WindowContext } from "../context-providers/Window";
import "./BaseLayout.css";

const BaseLayout = ({ children }) => {
  const appContext = useContext(AppContext);
  const windowContext = useContext(WindowContext);
  const { isFeatureFlagEnabled } = useAuth();

  return (
    <>
      <Loader isLoading={appContext.isLoading} />
      {!windowContext.isDetached && (
        isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine") ? <LeftNavbarDetect /> : <LeftNavbarRoutine />
      )}
      <div className="page-container">
        {children}
      </div>
    </>
  );
};

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BaseLayout;
