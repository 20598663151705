import { default as React } from 'react';
import { withTranslation } from "react-i18next";
import Button from '../../atoms/Button/Button';
import { useHistory } from 'react-router-dom';

import "./index.css";
import Icon from '../../atoms/Icon/Icon';

function Display({icon_name, callback, messages}) {
  return (
    <div className="success-pages-page">
      <div className="left-logo"><img src="/logo-full.svg" alt="Sonio"/></div>
      <div className="success-pages-check-icon"><Icon name={icon_name} /></div>
      <div className="success-pages-exclamation">{messages.exclamation}</div>
      {callback?<div className="success-pages-button"><Button label={messages.action} onClick={callback} /></div>:null}
    </div>
  )
}

function RegistrationUserSuccess({t: __}) {
  const history = useHistory();
  const props = {
    icon_type: 'done-circled',
    callback: () => history.push('/login'),
    messages: {
      exclamation: __('registered.exclamation'),
      action: __('registered.action'),
    }
  }
  return <Display {...props}/>
}


function ResetPasswordSuccess({t: __}) {
  const history = useHistory();
  const props = {
    icon_name: 'done-circled',
    callback: () => history.push('/login'),
    messages: {
      exclamation: __('reseted_password.exclamation'),
      action: __('reseted_password.action'),
    }
  }
  return <Display {...props}/>
}

function ActivateSessionSuccess({t: __}) {
  const props = {
    icon_name: 'done-circled',
    messages: {
      exclamation: __('activated_session.exclamation'),
      action: __('activated_session.action'),
    }
  }
  return <Display {...props}/>
}

function ActivateSessionError({t: __}) {
  const props = {
    icon_name: 'info',
    messages: {
      exclamation: __('activated_session.error'),
      action: __('activated_session.action'),
    }
  }
  return <Display {...props}/>
}

const TranslatedResetPasswordSuccess = withTranslation()(ResetPasswordSuccess)
const TranslatedRegistrationUserSuccess = withTranslation()(RegistrationUserSuccess)
const TranslatedActivateSessionSuccess = withTranslation()(ActivateSessionSuccess)
const TranslatedActivateSessionError = withTranslation()(ActivateSessionError)

export { TranslatedResetPasswordSuccess as ResetPasswordSuccess }
export { TranslatedRegistrationUserSuccess as RegistrationUserSuccess }
export { TranslatedActivateSessionSuccess as ActivateSessionSuccess }
export { TranslatedActivateSessionError as ActivateSessionError }
export { Display }
