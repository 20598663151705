import React from "react";
import Icon from "../Icon/Icon";
import "./Toggle.css";


function stateClassName(className, {state, disabled}) {
  return [
    className,
    state ? "on" : "",
    disabled ? "disabled" : "",
  ].join(" ")
}

export default function Toggle({state, onChangeStateFun, disabled}) {
  const onChangeState = (...params) => {
    if(typeof(onChangeStateFun) === "function")
      return onChangeStateFun.apply(null, params)
  }
  return (
    <div className={stateClassName("toggle-wrapper", {state, disabled})} onClick={() => !disabled && onChangeState(!state)}>
      <div className={stateClassName("toggle-dot", {state, disabled})}><Icon name="done" /></div>
    </div>
  )
}
