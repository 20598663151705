import React from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import LookupApi from "../../services/lookup";
import "./GlobalSearchBarInput.css";
import TextInput from "../../atoms/TextInput/TextInput";

/**
 * <GlobalSearchBarInput
 *  searchKey = string
 *  label = string (the placeholder)
 *  onChange = ([results]) => {} trigger once results are loaded
 *  onChangeSearchKey = ("newSearchKey") => {} trigger on every search key changes
 * />
 */

const GlobalSearchBarInput = ({
  t: __,
  searchKey = '',
  label = '',
  setFocus,
  examinationId,
  onChange = () => { },
  onChangeSearchKey = () => { }
}) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

  const getResults = (newSearchKey) => {
    onChange([]);
    onChangeSearchKey(newSearchKey);

    if (!newSearchKey || newSearchKey.length < 2) {
      return false;
    }

    LookupApi.getChecklistItemBySearchKey(newSearchKey, currentLanguage, examinationId)
      .then(results => {
        const searchResults = results.data.data
          .sort((a, b) => {
            const aMatching = (a.canonical_name?.[currentLanguage] || a.canonical_name)?.toLowerCase().substr(0, newSearchKey.length) === newSearchKey?.toLowerCase();
            const bMatching = (b.canonical_name?.[currentLanguage] || b.canonical_name)?.toLowerCase().substr(0, newSearchKey.length) === newSearchKey?.toLowerCase();
            if (aMatching && !bMatching) return -1;
            if (!aMatching && bMatching) return 1;
            return 0;
          });
        onChange(searchResults);
        return true;
      });
  }

  return (
    <div className="search-bar">
      <TextInput
        icon="search"
        value={searchKey}
        placeholder={label}
        onClick={(e) => e.stopPropagation()}
        onFocus={() => setFocus(true)}
        onChange={(value) => getResults(value)}
        changeOnBlur={false}
        fullwidth={true}
        clearButton={true}
      />
      {searchKey.length > 0 && searchKey.length < 2 && (
        <span className="hint">{__("signSearchBar.typeAtLeastNChars", {amount: 2})}</span>
      )}
    </div>
  );
};

export default withTranslation()(GlobalSearchBarInput);
