import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from '../../context-providers/Examination';
import { convertCmToInch, convertInchToCm, convertKgToLbs, convertLbsToKg } from '../../utils';
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup";
import DateInput from "../../atoms/DateInput/DateInput";
import NumericInput from "../../atoms/NumericInput/NumericInput";
import NumericInputOptional from "../../atoms/NumericInput/NumericInputOptional";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import SelectInputOptional from "../../atoms/SelectInput/SelectInputOptional";
import TextInput from "../../atoms/TextInput/TextInput";
import MedicationsSearchBar from "../MedicationsSearchBar/MedicationsSearchBar";
import SyndromeSearchBar from "../SyndromeSearchBar/SyndromeSearchBar";

/*
 * Print a medical history field using the right component
 * 
 * <MedicalHistoryInput
 *   field={}
 *   customCallbacks={onBlur: () => {}, onFocus: () => {}, onChange: () => {}}
 *   disableOptionalFields={boolean} // use normal input fields instead of optional ones, eg: NumericInput instead of NumericInputOptional
 * />
 */

const MedicalHistoryInput = ({ t:__, field, customCallbacks = {}, disableOptionalFields = false }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const preferedDateFormat = appContext.preferences.date_format;
  const preferedUnit = appContext.preferences.units;

  /**
   * Get the value of the given risk factor text id
   * @param {string} text_id 
   * @returns 
   */
  const getMedicalHistoryValue = ( text_id ) => {
    return {
      value: examinationContext.examination?.medical_history?.[text_id]?.value,
      raw_value: examinationContext.examination?.medical_history?.[text_id]?.raw_value,
      tmp_value: examinationContext.examination?.medical_history?.[text_id]?.tmp_value
    };
  }

  /**
   * Print the given field using the right component
   * @param {*} field 
   * @param {*} customCallbacks 
   * @returns 
   */
  const printField = (field, customCallbacks = {}) => {
    if(!field.type) return false;

    const onBlur = customCallbacks.onBlur ? customCallbacks.onBlur : () => {};
    const onChange = customCallbacks.onChange ? customCallbacks.onChange : examinationContext.updateMedicalHistory;
    const onDisable = customCallbacks.onDisable ? customCallbacks.onDisable : examinationContext.removeFromMedicalHistory;

    return {
      text: () => {
        return <TextInput
          value={getMedicalHistoryValue(field.text_id).raw_value}
          label={field.label[currentLanguage]}
          onChange={(value) => examinationContext.updateMedicalHistory(field, value)}
        />
      },
      number: () => {
        if( !field.options_metadata.optional || disableOptionalFields ) {
          return <NumericInput
            label={field.label[currentLanguage]}
            min={field.options_metadata.lower_limit || 0}
            max={field.options_metadata.upper_limit || false}
            step={field.options_metadata.step || 1}
            prefix=""
            suffix=""
            value={getMedicalHistoryValue(field.text_id).raw_value}
            tmpValue={field.options.find(option => option.value === getMedicalHistoryValue(field.text_id).tmp_value)?.label[currentLanguage] || false}
            defaultValue={field.options_metadata.default_value || ""}
            options={field.options}
            onChange={(value) => !!value ? onChange(field, value) : false}
          />
        }
        return <NumericInputOptional
          label={field.label[currentLanguage]}
          min={field.options_metadata.lower_limit || 0}
          max={field.options_metadata.upper_limit || false}
          step={field.options_metadata.step || 1}
          prefix=""
          suffix=""
          value={getMedicalHistoryValue(field.text_id).raw_value || null}
          defaultValue={field.options_metadata.default_value || ""}
          tmpValue={field.options.find(option => option.value === getMedicalHistoryValue(field.text_id).tmp_value)?.label[currentLanguage] || false}
          options={field.options}
          onChange={(value) => onChange(field, value)}
          onDisable={() => examinationContext.removeFromMedicalHistory(field.text_id)}
          />
      },  
      denominator: () => {
        if( !field.options_metadata.optional || disableOptionalFields ) {
          return <NumericInput
            label={field.label[currentLanguage]}
            min={field.options_metadata.lower_limit || 0}
            max={field.options_metadata.upper_limit || false}
            step={field.options_metadata.step || 1}
            prefix="1/"
            suffix=""
            value={getMedicalHistoryValue(field.text_id).raw_value}
            defaultValue={field.options_metadata.default_value || false}
            options={field.options}
            onChange={(value) => onChange(field, value)}
          />
        }
        return <NumericInputOptional
          label={field.label[currentLanguage]}
          min={field.options_metadata.lower_limit || 0}
          max={field.options_metadata.upper_limit || false}
          step={field.options_metadata.step || 1}
          prefix="1/"
          suffix=""
          value={getMedicalHistoryValue(field.text_id).raw_value || null}
          defaultValue={field.options_metadata.default_value || false}
          options={field.options}
          onChange={(value) => onChange(field, value)}
          onDisable={() => onDisable(field.text_id)}
        />
      },
      size: () => {
        const value = getMedicalHistoryValue(field.text_id).raw_value
        return <NumericInput
            label={field.label[currentLanguage]}
            min={(preferedUnit ==="imperial"
            ? convertCmToInch(field.options_metadata.lower_limit).toFixed(0)
            : field.options_metadata.lower_limit) || 0}
            max={
              (preferedUnit ==="imperial"
            ? convertCmToInch(field.options_metadata.upper_limit ).toFixed(0)
            : field.options_metadata.upper_limit ) || false}
            step={field.options_metadata.step || 1}
            prefix=""
            suffix={' ' + (preferedUnit ==="imperial"
            ? __('medicalhistory.units.inches')
            : __('medicalhistory.units.cm'))}
            value={
              !value
              ? ''
              : preferedUnit ==="imperial"
                ? convertCmToInch(value).toFixed(0)
                : Math.ceil(value * 10) / 10}
            defaultValue={field.options_metadata.default_value || false}
            options={field.options}
            onChange={(value) =>
              onChange(field, preferedUnit ==="imperial"
              ? convertInchToCm(value)
              : value)
            }
          />
        },
      weight: () => {
        const value = getMedicalHistoryValue(field.text_id).raw_value
        return <NumericInput
          label={field.label[currentLanguage]}
          min={(preferedUnit ==="imperial"
          ? convertKgToLbs(field.options_metadata.lower_limit)
          : field.options_metadata.lower_limit) || 0}
          max={(preferedUnit ==="imperial"
          ? convertKgToLbs(field.options_metadata.upper_limit)
          : field.options_metadata.upper_limit) || false}
          step={field.options_metadata.step || 1}
          prefix=""
          suffix={' ' + (preferedUnit ==="imperial"
          ? __('medicalhistory.units.lbs')
          : __('medicalhistory.units.kg'))}
          value={
            !value
            ? ''
            : preferedUnit ==="imperial"
              ? convertKgToLbs(value).toFixed(0)
              : Math.ceil(value * 10) / 10}
          defaultValue={field.options_metadata.default_value || false}
          options={field.options}
          onChange={(value) =>
            onChange(field, preferedUnit ==="imperial"
            ? convertLbsToKg(value)
            : value)
          }
        />
        },
      buttongroup: () => {
        return <ButtonGroup
          label={field.label[currentLanguage]}
          value={getMedicalHistoryValue(field.text_id).value || field.options_metadata.default_value}
          options={field.options.map(option => { return {value: option.value, label:option.label[currentLanguage] } })}
          onChange={(value) => onChange(field,false,value)}
        />;
      },
      select: () => {
        if( !field.options_metadata.optional || disableOptionalFields ) {
          return <SelectInput
            label={field.label[currentLanguage]}
            options={field.options.map(option => { return {value: option.value, label:option.label[currentLanguage] } })}
            value={getMedicalHistoryValue(field.text_id).value || field.options_metadata.default_value}
            onChange={(value) => onChange(field,false,value)}
          />
        }
        return <SelectInputOptional
          label={field.label[currentLanguage]}
          options={field.options.map(option => { return {value: option.value, label:option.label[currentLanguage] } })}
          value={getMedicalHistoryValue(field.text_id).value || null}
          defaultValue={field.options_metadata.default_value}
          onChange={(value) => onChange(field,false,value)}
          onDisable={() => onDisable(field.text_id)}
        />
        },
      date: () => {
        return <DateInput
          value={getMedicalHistoryValue(field.text_id).raw_value}
          localeOrFormat={preferedDateFormat}
          onChange={(value) => onChange(field, value)}
          onBlur={(value) => onBlur(field, value)}
          />
        },
      medication: () => {
        return <MedicationsSearchBar
          value={getMedicalHistoryValue(field.text_id).value}
          onChange={(raw_value, value) => onChange(field, raw_value, value)}
        />
        },
      syndrome: () => {
        return <SyndromeSearchBar
          label={field.label[currentLanguage]}
          value={getMedicalHistoryValue(field.text_id).value}
          SYNDROMES={examinationContext.SYNDROMES}
          onChange={(raw_value, value) => onChange(field, raw_value, value)}
        />
      }
    }[field.type]();
  }

  return printField(field, customCallbacks);
};

export default withTranslation()(MedicalHistoryInput);
