import "./FetusControllerView.css";

const FetusControllerView = ({
  getClassName,
  getMarkerClassName,
  getUnusualClassName,
  goToZone,
}) => {
  const mouseOver = (event) => {
    const clickZone = event.currentTarget;
    if (!clickZone) return;
    const zoneName = clickZone.getAttribute("class").replace("click ", "");
    const zone = document.querySelector(".fetus-controller .zone." + zoneName);
    if (zone) zone.classList.add("hover");
  };

  const mouseOut = (event) => {
    const clickZone = event.currentTarget;
    if (!clickZone) return;
    const zoneName = clickZone.getAttribute("class").replace("click ", "");
    const zone = document.querySelector(".fetus-controller .zone." + zoneName);
    if (zone) zone.classList.remove("hover");
  };

  return (
    <div className="fetus-controller">
      <div>
        <svg
          version="1.2"
          x="0px"
          y="0px"
          width="380px"
          height="511.3px"
          viewBox="60 0 330 511.3"
          overflow="visible"
        >
          <circle className="shape" cx="222.3" cy="255.6" r="250.2" />
          <circle
            className={"zone appendages " + getClassName(7)}
            cx="222.3"
            cy="255.6"
            r="250.2"
          />
          <path
            className="shape"
            d="M286.5,254.4c4.7-2.1,10.4-7,13.9-6.9c3.4,0,4.7,3.1,4.4,5.1c-0.2,2-4.3,4.4-6.2,6.3c-2,1.9-0.9,3,0,3c1,0,6.7-0.8,10.2-0.8
              s5,3.7,4.1,5.7c-0.9,2-4,2.7-6.2,3.2s-4.5,2.3-2.5,4c2,1.8,6.9,0.9,8.5,2.1c1.6,1.1,1,4.1-1.3,4.9c-2.3,0.8-6.7,1.1-7,2.7
              c-0.3,1.6,0.8,2.4,2.4,3c1.7,0.6,4.6,1.6,3.4,4.4c-1.2,2.9-5.5,3.6-14.8,3.9c-9.3,0.3-14.1-2.5-20.8-3.8c-6.7-1.2-10.9-0.2-16.4,1.9
              c-5.5,2-10,7.4-11.6,6.7c-1.2-0.5,6-18.4,6.5-19.3c0.5-0.9,1.3-1,2.8-2.5s4.6-6.5,6.2-9.8c1.7-3.4,3.5-13.8,6.6-18
              c3.1-4.2,6.8-2.1,7.9,0.1c1.1,2.1-1.3,6.5,0.4,7.5S281.8,256.5,286.5,254.4z M375.3,400.8c-2.4,0-29.2-0.7-41.7-4.6
              c-12.4-3.8-14.7-6.4-18-15.4c-3.3-8.9-2.7-12-5.8-17.2c-3.2-5.3-10.9-6.4-11.7-5.3c-1.2,1.5-1,7.8-1,21.5s-1.2,29.4,1,36.9
              s5.2,15.7,12.6,15.6c7.4-0.1,24.5-6.4,30.9-7.5c6.4-1.1,26.3-5.7,34.1-10.7c7.8-4.9,6.5-9.3,4.7-11.1
              C378.6,401.2,377.7,400.8,375.3,400.8z M402.1,314c-3.1,0.2-20.8,11.4-28.9,15.6c-8.1,4.2-23.5,8.2-27.5,8.3s-22.2-15.5-37.2-26.4
              c-15-10.9-29-15.4-37.7-15.4s-16,5.3-21.9,12.7c-6,7.4-16.3,27.1-22.4,49c-6,21.9-7.6,41.7-9.3,46.2c0,0.1-0.1,0.2-0.1,0.3
              c-0.9-1.7-1.6-14-0.5-24.3c1.3-11.1,8.5-35,7-36s-5.4,2.1-23.6-0.5l-0.1,0.1c-1-0.1-2.2-0.3-2.8-0.5c-10.2-1.6-23.6-6.9-34.7-11.9
              c-11.3-5.1-20.2-9.9-21.3-10.4c0,0-5.2-2.8-3.6-4.6h0c1.4,0.4,14.7,6.2,23.9,10.1c9.2,3.9,33.3,11.2,43.2,10.8
              c9.8-0.4,19.2-2.6,26.2-17c7.1-14.4,24.3-62.2,30.3-70.6c6-8.4,13.6-16.1,21.7-19c8-2.8,6.8-6.6,6.5-8c-0.3-1.4-3.6-1.7-6-1.2
              c-2.4,0.5-5.1,0.6-5.2-0.3c-0.1-0.9,1-3.4,3.5-4.5c2.5-1,5.6-2.7,5.7-4.6c0.1-1.9-2-5.3-4.5-4.7c-2.5,0.6-8.6,5.8-10.2,4
              c-1.6-1.7,6.7-6.7,7.2-10.8c0.5-4.1-2.5-5.1-4.5-4.3c-2,0.7-9.4,8-12,6.2c-2.6-1.8,2.1-7.4,2.4-10.7c0.3-3.2-2.5-4.8-5.3-3.8
              s-6.5,6.2-8.5,8.9c-2,2.7-8.3,13.5-9.9,13.1c-1.6-0.4-2.4-7.8-6.2-8.6c-3.8-0.8-5.8,6-5.6,9.1c0.3,3.1,3.6,13.1,3.8,15.9
              c0.2,2.8-0.3,9.2-8.7,23.7c-8.5,14.5-21.4,37.7-22.7,37.5c-1.3-0.2-33.3-21.4-49.8-26.7c-16.5-5.2-27.7-6.8-32.9-6.8
              c-4.6,0-15.4,2.4-18.3,1.6l-0.5-0.3c1.2-0.9,7.9-5.5,22.6-5.9c16.3-0.3,24.9,5,30.8,7.3s18.1,6.1,23.7,2.6s6-8.5,8.7-10.5
              c2.7-2,5.7-0.7,8.5-3.2c2.8-2.5,0.8-4.4,1.6-5.4s4.3,0.3,6.5-2.2c2.2-2.6,0.2-8.7,1.2-9.9c1-1.2,10.4-4.7,10.7-10.4
              c0.3-5.7-9.1-11.1-8.8-21.7c0.3-10.6,8.2-19,8.2-48.1s-27.4-80.5-82.9-80.5s-96.6,46-96.6,90c0,24,9.1,46.6,15.4,62.1
              c1.9,4.7,3.6,9.3,5.5,13.7c0.1,1.1,0.3,2.2,0.7,3.3c3.1,9.1,5.2,18,5.2,27.2c0,32.5-11.8,50,4.3,85.8c7,15.3,18.3,33.9,31.6,51
              c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c1.3,1.7,2.6,3.4,4,5c0.3,0.3,0.5,0.5,0.7,0.8c0.4,0.8,38.3,40.1,60.2,51.8
              c43.7,23.4,70.9,24.3,86.2,17.1c0.5-0.2,1-0.5,1.4-0.7c3.3-1.7,6.2-3.8,8.8-6.1c12.2-9.2,23.7-25.5,28.4-51.4
              c6-33.1,6-59.8-0.3-64.2s-16.4-9.4-15.5-10.5c0.9-1.1,13.5,5.9,27.7,10.1s22.5,5.9,27.5,8.1s16.2,19.4,25.3,20.5
              c9.1,1.1,11-2.4,15-7.6c4.1-5.2,18.6-17.4,26.7-27.5c8.1-10.1,14.8-21.8,15.1-25C409.6,317.8,405.2,313.8,402.1,314z"
          />
          <path
            className={"zone thorax " + getClassName(2)}
            d="M201.1,343.7l-52.8,55.6c-4.9,5.2-11.5,7.8-18.2,7.8c-5.5,0-11-1.8-15.6-5.5 c-4.8-3.8-11.4-4.1-16.5-1.1c-13.3-17.1-24.6-35.7-31.6-51c-16.1-35.8-4.3-53.3-4.3-85.8c0-9.2-2.1-18.1-5.2-27.2 c-4.9-14.7,14.9-24.6,23.5-11.7l20.2,30.2l9.6,5c-10.8-5.1-15.5,15.9-8.9,28.9c5.1,10,23.2,20.8,36.2,27.3c-1.6,1.8,3.6,4.6,3.6,4.6 c1.1,0.5,10,5.3,21.3,10.4c11.1,5,24.5,10.3,34.7,11.9C198,343.3,200.2,343.6,201.1,343.7z"
          />
          <path
            className={"zone abdomen " + getClassName(3)}
            d="M240.2,401.4c-10.9-2.1-20.1-2.1-22.9,3.1l-0.1,0.1c0-0.1,0-0.1,0-0.1c-1-0.9-1.8-13.8-0.6-24.5 c1.3-11.1,8.5-35,7-36s-5.4,2.1-23.6-0.5l-52.3,55c-4.9,5.2-11.5,7.8-18.2,7.8c-1.8,0-3.6-0.2-5.4-0.6c-3.5-0.8-6.6-2.9-8.8-5.7 c-8.1-10.5-31.8-42-37.9-61c-5.9-18.5-5-27.8-4.3-51.6c0.7-23.8-1.2-32.1-1.9-37.6c-0.7-5.5-8.3-33.7-14.3-21.9 c-0.9,2.6-1,5.5,0.1,8.7c3.1,9.1,5.2,18,5.2,27.2c0,6.7-0.5,12.8-1.2,18.6l0,0l0,0c-2.5,21.9-7.3,38.8,5.5,67.2 c7,15.3,18.3,33.9,31.6,51l0,0c1.3,1.7,2.6,3.4,4,5c0.3,0.3,0.5,0.5,0.7,0.8c0.4,0.8,38.3,40.1,60.2,51.8 c43.7,23.4,70.9,24.3,86.2,17.1c0.5-0.2,1-0.5,1.4-0.7C283.4,457.8,276.2,408.5,240.2,401.4z"
          />
          <path
            className={"zone limbs " + getClassName(6)}
            d="M246.6,299.8c1.6,0.7,6.1-4.7,11.6-6.7c5.5-2.1,9.7-3.1,16.4-1.9c6.7,1.3,11.5,4.1,20.8,3.8 c9.3-0.3,13.6-1,14.8-3.9c1.2-2.8-1.7-3.8-3.4-4.4c-1.6-0.6-2.7-1.4-2.4-3c0.3-1.6,4.7-1.9,7-2.7c2.3-0.8,2.9-3.8,1.3-4.9 c-1.6-1.2-6.5-0.3-8.5-2.1c-2-1.7,0.3-3.5,2.5-4s5.3-1.2,6.2-3.2c0.9-2-0.6-5.7-4.1-5.7s-9.2,0.8-10.2,0.8c-0.9,0-2-1.1,0-3 c1.9-1.9,6-4.3,6.2-6.3c0.3-2-1-5.1-4.4-5.1c-3.5-0.1-9.2,4.8-13.9,6.9c-4.7,2.1-7.8,4.4-9.5,3.4s0.7-5.4-0.4-7.5 c-1.1-2.2-4.8-4.3-7.9-0.1s-4.9,14.6-6.6,18c-1.6,3.3-4.7,8.3-6.2,9.8c-1.5,1.5-2.3,1.6-2.8,2.5S245.4,299.3,246.6,299.8z M298.1,358.3c-1.2,1.5-1,7.8-1,21.5s-1.2,29.4,1,36.9s5.2,15.7,12.6,15.6s24.5-6.4,30.9-7.5s26.3-5.7,34.1-10.7 c7.8-4.9,6.5-9.3,4.7-11.1s-2.7-2.2-5.1-2.2s-29.2-0.7-41.7-4.6c-12.4-3.8-14.7-6.4-18-15.4c-3.3-8.9-2.7-12-5.8-17.2 C306.6,358.3,298.9,357.2,298.1,358.3z M100.9,255c-11.2-6-16.1,15.5-9.4,28.6s35,29.3,46,32.6c1.4,0.4,14.7,6.2,23.9,10.1 s33.3,11.2,43.2,10.8c9.8-0.4,19.2-2.6,26.2-17c7.1-14.4,24.3-62.2,30.3-70.6c6-8.4,13.6-16.1,21.7-19c8-2.8,6.8-6.6,6.5-8 s-3.6-1.7-6-1.2s-5.1,0.6-5.2-0.3c-0.1-0.9,1-3.4,3.5-4.5c2.5-1,5.6-2.7,5.7-4.6s-2-5.3-4.5-4.7s-8.6,5.8-10.2,4 c-1.6-1.7,6.7-6.7,7.2-10.8s-2.5-5.1-4.5-4.3c-2,0.7-9.4,8-12,6.2s2.1-7.4,2.4-10.7c0.3-3.2-2.5-4.8-5.3-3.8s-6.5,6.2-8.5,8.9 s-8.3,13.5-9.9,13.1c-1.6-0.4-2.4-7.8-6.2-8.6s-5.8,6-5.6,9.1c0.3,3.1,3.6,13.1,3.8,15.9c0.2,2.8-0.3,9.2-8.7,23.7 c-8.5,14.5-21.4,37.7-22.7,37.5s-33.3-21.4-49.8-26.7c-16.5-5.2-27.7-6.8-32.9-6.8C114.7,253.9,101.3,257,100.9,255 M227,413.9 c18.7,8.6,28.6,15,27.5,38.8c-0.6,13.6-14.6,26.1-5.3,21.9c15.4-6.9,32.6-24.4,38.6-57.5s6-59.8-0.3-64.2 c-6.3-4.4-16.4-9.4-15.5-10.5s13.5,5.9,27.7,10.1s22.5,5.9,27.5,8.1s16.2,19.4,25.3,20.5s11-2.4,15-7.6c4.1-5.2,18.6-17.4,26.7-27.5 s14.8-21.8,15.1-25c0.3-3.2-4.1-7.2-7.2-7s-20.8,11.4-28.9,15.6s-23.5,8.2-27.5,8.3s-22.2-15.5-37.2-26.4s-29-15.4-37.7-15.4 s-16,5.3-21.9,12.7c-6,7.4-16.3,27.1-22.4,49c-6,21.9-7.6,41.7-9.3,46.2C215.4,408.4,220.1,410.2,227,413.9z"
          />
          <path
            className={"zone head " + getClassName(1)}
            d="M100.9,255.4c0,0,6.5-5.6,22.8-6c16.3-0.3,24.9,5,30.8,7.3s18.1,6.1,23.7,2.6s6-8.5,8.7-10.5 s5.7-0.7,8.5-3.2s0.8-4.4,1.6-5.4s4.3,0.3,6.5-2.2c2.2-2.6,0.2-8.7,1.2-9.9s10.4-4.7,10.7-10.4s-9.1-11.1-8.8-21.7s8.2-19,8.2-48.1 s-27.4-80.5-82.9-80.5s-96.6,46-96.6,90c0,24,9.1,46.6,15.4,62.1S61.5,250,74.2,255S97.8,257.4,100.9,255.4z"
          />
          <circle
            className={"marker head " + getMarkerClassName(1)}
            cx="125.3"
            cy="159.5"
            r="26"
          />
          <ellipse
            className={"marker limbs " + getMarkerClassName(6)}
            cx="246.5"
            cy="299.9"
            rx="26"
            ry="26"
          />
          <circle
            className={"marker thorax " + getMarkerClassName(2)}
            cx="110.6"
            cy="338.3"
            r="26"
          />
          <circle
            className={"marker abdomen " + getMarkerClassName(3)}
            cx="186.8"
            cy="406.3"
            r="26"
          />
          <circle
            className={"marker appendages " + getMarkerClassName(7)}
            cx="335.5"
            cy="187.5"
            r="26"
          />
          <circle
            className={"unusual head " + getUnusualClassName(1)}
            cx="125.4"
            cy="159.5"
            r="12.4"
          />
          <circle
            className={"unusual thorax " + getUnusualClassName(2)}
            cx="110.6"
            cy="338.3"
            r="12.4"
          />
          <circle
            className={"unusual limbs " + getUnusualClassName(6)}
            cx="246.5"
            cy="299.9"
            r="12.4"
          />
          <circle
            className={"unusual appendages " + getUnusualClassName(7)}
            cx="335.5"
            cy="187.5"
            r="12.4"
          />
          <circle
            className={"unusual abdomen " + getUnusualClassName(3)}
            cx="186.8"
            cy="406.3"
            r="12.4"
          />
          <circle
            className="click appendages"
            onClick={() => goToZone(7)}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            cx="222.3"
            cy="255.6"
            r="250.2"
          />
          <circle
            className="click head"
            onClick={() => goToZone(1)}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            cx="130.4"
            cy="167.2"
            r="103.7"
          />
          <polygon
            className="click limbs"
            onClick={() => goToZone(6)}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            points="137.8,270.9 274.7,470.9 381.8,409.7 408,317.3 327.4,325.9 296.8,227.8 249.4,189.8 195.4,245.6 "
          />
          <polygon
            className="click thorax"
            onClick={() => goToZone(2)}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            points="49.9,244.5 137.8,270.9 193.2,351.3 79.1,425.6 23.5,337.1 "
          />
          <polygon
            className="click abdomen"
            onClick={() => goToZone(3)}
            onMouseOver={mouseOver}
            onMouseOut={mouseOut}
            points="79.1,425.6 193.2,351.3 274.7,470.9 222.3,496.1 141.6,479.3 "
          />
        </svg>
      </div>
    </div>
  );
};
export default FetusControllerView;
