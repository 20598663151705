import PropTypes from "prop-types";
import React from "react";
import Icon from "../Icon/Icon";
import "./ButtonGroup.css";

/**
 * <ButtonGroup
 *  label (displayed above the field)
 *  value
 *  theme = "default" | "dark" | "light" | "grey"
 *  color = "blue" | "ocra"
 *  options = [ {value, label, icon} ]
 *  size = "normal" | "compact" --> "compact" is intended to match the <select> and <input> height
 *  variant = "normale" | "outline"
 *  wrap = (boolean)
 *  testId = string | null
 *  onChange
 * />
 */

const ButtonGroup = ({ label = "", value, options = [], disabled = false, size = "normal", theme = "default", color = "blue", variant = "normal", wrap = true, onChange = () => { }, testId = null }) => {
  const onChangeHandler = (option) => {
    !disabled && onChange(option.value);
  } 
  return (
    <>
      <div className={`button-group ${disabled ? 'disabled' : ''} size-${size} variant-${variant} theme-${theme} color-${color} ${wrap ? 'wrap' : ''}`}>
        {!!label && (<label>{label}</label>)}
        <div className="button-group-options">
          {options.map((option, index) => {
            const dataProps = {
              ...(testId != null ? {'data-test-id': `${testId}-${option?.label}`} : {})
            }
            return (
              <div
                className={
                  'option ' +
                  (value && value.toString() === (option.value ?? "").toString() ? 'selected' : '')
                }
                onClick={() => onChangeHandler(option)}
                {...dataProps}
                data-value={option.value}
                key={index}
              >
                <span>
                  {option.icon && <Icon name={option.icon} />}
                  {!!option.label && <span className="button-group_label">{option.label}</span>}
                  {option.iconAfter && <Icon name={option.iconAfter} />}
                </span>
              </div>
            )
          })}
        </div>
      </div>
    </>
  );
};

export default ButtonGroup;

ButtonGroup.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
