import Icon from "../Icon/Icon";
import "./ButtonBack.css";

const ButtonBack = ({icon = "arrow-left", onClick}) => {
    return <div className={`button-back_wrapper type-${icon}`} onClick={onClick}>
        <Icon name={icon} />
    </div>;
}

export default ButtonBack;
