import { withTranslation } from "react-i18next";

const ReportTemplatePrivate = ({ t: __, children, reportMode }) => {

  if (reportMode === 'edit')
    return (
      <div className="private">
        <span className="private-label"><span>{__("report.private")}</span></span>
        {children}
      </div>
    );
  return false;
}

export default withTranslation()(ReportTemplatePrivate);
