import { useEffect, useState } from "react";
import "./GaInput.css";
import NumericInput from "../NumericInput/NumericInput";

/**
 * <GaInput
 *  label
 *  icon
 *  value
 *  fullwidth
 *  variant = "outline" | "underline"
 *  onChange
 *  ...props />
 */

const GaInput = ({ label = "", icon = "", value, variant = "outline", fullwidth = false, onChange = () => { }, changeOnBlur = true, clearButton = false, ...props }) => {

  const [bounceTimeout, setBounceTimeout] = useState(false);
  const [displayedWeeks, setDisplayedWeeks] = useState(Math.floor(value / 7));
  const [displayedDays, setDisplayedDays] = useState(value % 7);
  const [gaValue, setGaValue] = useState(value);

  useEffect(() => {
    setAll(value);
  }, [value]);

  const setAll = (value) => {
    setDisplayedWeeks(Math.floor(value / 7));
    setDisplayedDays(value % 7);
    setGaValue(value);
  };

  const onChangeHandler = (newWeeks, newDays) => {
    if (bounceTimeout) {
      clearTimeout(bounceTimeout);
      setBounceTimeout(false);
    }
    const newValue = (newWeeks * 7) + newDays;
    setBounceTimeout(setTimeout(() => onChange(newValue), 300));
    setAll(newValue);
  };

  const onChangeWeekHandler = (newWeeks) => {
    onChangeHandler(Number(newWeeks), displayedDays || 0);
  };

  const onChangeDayHandler = (newDays) => {
    onChangeHandler(displayedWeeks || 0, Number(newDays));
  };

  return (
    <div className="ga-input">
      <NumericInput min="0" max="50" value={displayedWeeks} onChange={onChangeWeekHandler} suffix=" WK" />
      <NumericInput min="0" max="6" value={displayedDays} onChange={onChangeDayHandler} suffix=" d" />
    </div >
  );
};

export default GaInput;
