import { useEffect, useState } from 'react';
import { fromStrToDate, formatDateSwapDdMm, formatDate } from '../../utils';
import PropTypes from "prop-types";
import "./DateInput.css";

/**
 * <DateInput
 *  value
 *  localeOrFormat
 *  onChange
 *  onFocus
 *  onBlur
 * />
 */

const DateInput = ({ value, localeOrFormat = 'fr', onChange = () => {}, onBlur = () => {}, onFocus = () => {} }) => {
  if (localeOrFormat === "en-US") localeOrFormat = "mm/dd/yyyy"
  if (localeOrFormat === "fr") localeOrFormat = "dd/mm/yyyy"

  const [tmpDate, setTmpDate] = useState(formatDate(value, localeOrFormat));

  useEffect(() => setTmpDate(formatDate(value, localeOrFormat)), [value]);

  const onChangeHandler = (event) => {
    const date = formatDateAsYouType(event.target.value);
    setTmpDate(date);
    onChange(date);
  }

  const onBlurHandler = (event) => {
    const date = fromStrToDate(event.target.value, localeOrFormat);
    const validDate = new Date(date);
    if( !isNaN(validDate) ) {
      validDate.setTime( validDate.getTime() + validDate.getTimezoneOffset() * (-60*1000) );
      return onBlur( validDate.toISOString().slice(0,10) );
    }
    return onBlur();
  }

  const onFocusHandler = (event) => {
    const date = event.target.value;
    onFocus( date );
  }

  const formatDateAsYouType = (dateStr) => {
    const dateStr_normalized = dateStr.trim().replace(/[^\d]/g, '/')
    const dateStr_numbers = dateStr.trim().replace(/[^\d]/g, '')
  
    let [day, month, year] =
      dateStr_normalized.indexOf('/') >= 1
        ? dateStr_normalized.split('/')
        : [
            dateStr_numbers.substr(0, 2),
            dateStr_numbers.substr(2, 2),
            dateStr_normalized.substr(4, 4),
          ]
  
    if (month.length > 2) {
      year = month.substr(2)
      month = month.substr(0, 2)
    }
  
    if ((day && dateStr_numbers.length > 1) || (day.length === 1 && day > 3))
      // if the first number of the month is greater than 3, pad with 0. Why 3? Because it includes the day in case of mm/dd/yyyy date format
      day = parseInt(day).toString().padStart(2, '0')
  
    if (
      (dateStr_normalized[1] && isNaN(dateStr_normalized[1])) ||
      (dateStr_normalized[2] && isNaN(dateStr_normalized[2]))
    )
      day += '/'
  
    if ((month && dateStr_numbers.length > 3) || (month.length === 1 && month > 3))
      // if the first number of the month is greater than 3, pad with 0. Why 3? Because it includes the day in case of mm/dd/yyyy date format
      month = parseInt(month).toString().padStart(2, '0')
  
    if (
      (dateStr_normalized[4] && isNaN(dateStr_normalized[4])) ||
      (dateStr_normalized[5] && isNaN(dateStr_normalized[5]))
    )
      month += '/'
  
    if (year != null && year[1] != null && year[0] > 2 && year.length <= 2) {
      year = (year[0] > 4 ? '19' : '20') + year
    }
  
    let dateStr_final = (
      day +
      (month ? '/' + month : '') +
      (year ? '/' + year : '')
    ).replace(/\/+/g, '/')
    const res = dateStr_final.substr(0, 10)
    return res

  }
  
  return (
    <div className="date-input">
      <input
        type="text"
        value={tmpDate}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
      />
    </div>
  );
};

export default DateInput;

DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
