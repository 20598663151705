import React from "react";
import "./ProgressRing.css";

/**
 * <ProgressRing
 *  radius=(dec)
 *  stroke=(dec)
 *  progress=(dec) 0-100
 *  label=string
 *  color="ocra" | "blue"
 * />
 */
const ProgressRing = ({ radius=3, stroke=0.5, progress=0, label=false, color="blue" }) => {

  const startAngle = 40;
  const endAngle = 320;
  const normalizedRadius = radius - stroke / 2;
  const circumference = ((endAngle - startAngle) * Math.PI / 180) * normalizedRadius;
  const strokeDashoffset = (circumference / 100 * progress);

  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = (angleInDegrees-90) * Math.PI / 180;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }
  
  const end = polarToCartesian(radius, radius, -normalizedRadius, startAngle);
  const start = polarToCartesian(radius, radius, -normalizedRadius, endAngle);
  const d = [
    "M", start.x, start.y, 
    "A", normalizedRadius, normalizedRadius, 0, 1, 0, end.x, end.y
  ].join(" ");

  return (
    <div className={`progress-ring-container color-${color}`}>
      {label !== false && (<label>{label}</label>)}
      <svg
        className="circular-meter"
        height={start.y + stroke/2}
        width={radius * 2}
        viewBox={'0 0 ' + radius * 2 + ' ' + (start.y + stroke/2)}
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d={d}
          fill="transparent"
          strokeWidth={stroke}
          strokeLinecap="round"
          id="bar"
        />
        {progress > 0 && (
          <path
            d={d}
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={strokeDashoffset + ' ' + circumference}
            style={{ strokeDashoffset: strokeDashoffset - circumference }}
            strokeLinecap="round"
            id="progress"
          />
        )}
      </svg>
    </div>
  )
};

export default ProgressRing;
