import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import { ExaminationContext } from "../../context-providers/Examination";
import { getRegExpValue } from "../../utils";
import "./AnomalyLine.css";

/**
 * <AnomalyLine
 *  anomaly = {}
 *  syndromes = []
 *  onClick = () => {}
 *  disableOnClick = (boolean) // hide the anomaly after clicking on it
 *  showStatus = (boolean) // display the status along with the name
 * />
 */

const AnomalyLine = ({ t: __, anomaly, cta = "", syndromes = [], searchKey = "", isHidden = false, isolated = false, onClick = () => {}, disableOnClick=true, showStatus=false }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const examinationContext = useContext(ExaminationContext);

  const [morePanelOpen, setMorePanelOpen] = useState(false);
  const [disabled, setDisabled] = useState(isHidden);
  let anomalyName = examinationContext.MALFORMATIONS?.[anomaly.id]?.canonical_name?.[currentLanguage] || "";
  if(isolated) anomalyName = __("dx.summary.isolatedAnomaly").replace("{anomaly}", anomalyName);

  syndromes = syndromes.filter((syndrome, index) => syndromes.indexOf(syndrome) === index);

  useEffect(() => {
    setDisabled(isHidden);
  }, [isHidden])

  const formatSyndromePreview = (syndromes) => {
    let output = "";
    if (syndromes[0]) output += examinationContext.getSyndromeById(syndromes[0])?.canonical_name?.[currentLanguage];
    if (syndromes[1]) output += ", …";
    return output;
  };

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if(!string) return { __html: "" };
    if(!searchKey) return { __html: string };
    const expression = getRegExpValue(searchKey);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  }

  return (
    <li
      className={`anomaly-line ${anomaly.status || ""} ${
        morePanelOpen ? "open" : ""
      } ${disabled ? "disabled" : ""}`}
    >
      <div onClick={() => {
        onClick(anomaly, anomaly.status === "yes" ? "no" : "yes");
        return disableOnClick ? setDisabled(true) : true;
      }}>
        <span className="anomaly-name" data-id={anomaly?.id}>
          <span dangerouslySetInnerHTML={highlight(anomalyName)} />
          {anomalyName != null && !anomalyName.match(getRegExpValue(searchKey)) && anomaly.synonyms && anomaly.synonyms[currentLanguage] && !!anomaly.synonyms[currentLanguage].length && (
            <div className="synonym" dangerouslySetInnerHTML={highlight(anomaly.synonyms[currentLanguage].find(anomalyName => anomalyName.match(getRegExpValue(searchKey))))}></div>
            )}
        </span>
        {syndromes?.length > 0 && (
          <span className="disease-name">
            {formatSyndromePreview(syndromes)}
          </span>
        )}
        {showStatus && (
          <span className={`anomaly-status ${anomaly.status}`}>{__('dx.checklist.buttons.' + anomaly.status)}</span>
        )}
        {!!cta && (
          <span className="anomaly-cta">
            {cta}{" "}
          </span>
        )}
        <span
          className="more"
          onClick={(e) => {
            e.stopPropagation();
            return setMorePanelOpen((prev) => !prev);
          }}
        >
          <Icon name="down" />
        </span>
      </div>
      <div className="anomaly-details">
        <ul>
          {syndromes?.map((syndromeId) => (
            <li key={syndromeId} data-id={syndromeId}>{examinationContext.getSyndromeById(syndromeId)?.canonical_name?.[currentLanguage]}</li>
          ))}
        </ul>
        <div className="anomaly-details-change-status">
          <Button
            label={__("dx.checklist.buttons.no")}
            variant={anomaly.status === "no" ? "" : "outline"}
            onClick={() => onClick(anomaly, "no")}
            />
          <Button
            label={__("dx.checklist.buttons.maybe")}
            variant={anomaly.status === "maybe" ? "" : "outline"}
            onClick={() => onClick(anomaly, "maybe")}
            />
          <Button
            label={__("dx.checklist.buttons.yes")}
            variant={anomaly.status === "yes" ? "" : "outline"}
            onClick={() => onClick(anomaly, "yes")}
          />
        </div>
      </div>
    </li>
  );
};

export default withTranslation()(AnomalyLine);
