import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import DragAndDropItem from "../../../components/DragAndDropItem/DragAndDropItem";
import ExamPresetButtons from "../../../components/ExamPresetButtons/ExamPresetButtons";
import ScrollBar from "../../../components/ScrollBar/ScrollBar";
import config from "../../../config";
import useAuth from "../../../context-providers/Auth";
import { AppContext } from "../../../context-providers/App";
import { ExaminationContext } from "../../../context-providers/Examination";
import { LiveExaminationContext } from "../../../context-providers/LiveExamination";
import { useElementOnScreen } from "../../../useElementOnScreen";
import { isNullOrUndefined } from "../../../utils";
import Slide from "../../Slide/Slide";
import "./SlideGrid.css";

let dragReassociating = false;

const SlideGrid = ({
  t: __,
  slides,
  selectedPlane,
  atRiskSlides,
  unusualMedias,
  medias,
  currentTrimester,
  zoomLevel = false,
  fetusSex,
  nextMatchingSlide,
  onItemClick = () => { },
  onItemDoubleClick = () => { },
  setShowSlideBrowser = () => { },
  setOpenPanel = () => { },
  setSelectedPlane = () => { },
  imageChapterRef,
  matchingChapterRef,
  videoChapterRef,
  setManualUserInteraction,
  highlightNextMatching,
}) => {
  const appContext = useContext(AppContext)
  const examinationContext = useContext(ExaminationContext)
  const liveExaminationContext = useContext(LiveExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();

  const allSlides = Object.values(slides).flat();
  const options = useMemo(() => ({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  }), [])
  const [targetsRef, targetsVisibility] = useElementOnScreen(allSlides, options);
  
  const containerRef = useRef(null);
  const [clickedSlide, setClickedSlide] = useState(null);
  const [preventScrollingAfterReassociation, setPreventScrollingAfterReassociation] = useState(null);
  const [needToSetClickedSlide, setNeedToSetClickedSlide] = useState(true);

  const lastAddedSlideKey = useMemo(() => allSlides.find(slide => slide.mediaId === liveExaminationContext.incomingDicomInstance?.id)?.key, [slides, liveExaminationContext.incomingDicomInstance?.id]);
  
  useEffect(() => {
    return () => liveExaminationContext.cleanIncomingDicomInstance();
  }, []);

  const goToSlide = useCallback((slideKey, smooth = true, preventExtractedFromVideo = false, preventUnmatched = false) => {
    const target = Object.values(targetsRef.current).find(elm => elm.current?.dataset.id === slideKey);
    if (!target?.current) return false;
    const current = target.current;
    
    if (preventExtractedFromVideo && current?.dataset.isExtracted === "true") return false;
    if (preventUnmatched && current?.dataset.isMatched === "false") return false;

    containerRef?.current?.scrollTo({
      top: current.offsetTop - (containerRef.current.offsetHeight / 2) + (current.offsetHeight / 2),
      behavior: smooth ? 'smooth' : 'auto',
    });
  }, []);

  useEffect(() => {
    containerRef?.current?.scrollTo({ top: 0 });
    return () => liveExaminationContext.cleanIncomingDicomInstance();
  }, [examinationContext.examination?.id]);

  useEffect(() => {
    if (scrollIsAllowed()) {
      if (selectedPlane?.slide?.key) goToSlide(selectedPlane.slide.key);
      if (selectedPlane?.media?.id) goToSlide(selectedPlane.media.id);
    }
  }, [selectedPlane?.slide?.key, selectedPlane?.media?.id]);

  const onItemClickHandler = async (e, slide, media) => {
    e.preventDefault();
    if (dragReassociating) return false;

    onItemClick(slide, { slide, media }, clickedSlide);
    if (slide) setClickedSlide(slide);
  }

  const scrollIsAllowed = useCallback(() => {
    return window.localStorage.getItem('zoomUponReceive') !== "noscroll"
      && !preventScrollingAfterReassociation
      && (
        // prevent scrolling to a slide when trying to scroll down to unmatched ones
        !liveExaminationContext.incomingDicomInstance
        || liveExaminationContext.incomingDicomInstance.modality !== "SR"
      )
  }, [window.localStorage.getItem('zoomUponReceive'), preventScrollingAfterReassociation, liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    clickedSlide && scrollIsAllowed() && goToSlide(clickedSlide.key, true, true, true);
    setPreventScrollingAfterReassociation(false);
  }, [goToSlide, clickedSlide?.key]);

  useEffect(() => {
    lastAddedSlideKey && scrollIsAllowed() && goToSlide(lastAddedSlideKey, true, true, true);
    setPreventScrollingAfterReassociation(false);
  }, [goToSlide, lastAddedSlideKey, scrollIsAllowed]);

  useEffect(() => {
    needToSetClickedSlide && setClickedSlide(nextMatchingSlide);
    setNeedToSetClickedSlide(true);
    // do not include needToSetClickedSlide in dependency array
  }, [nextMatchingSlide]);

  useEffect(() => {
    setClickedSlide(nextMatchingSlide);
  }, [nextMatchingSlide]);

  useEffect(() => {
    if (liveExaminationContext.incomingDicomInstance?.modality === "SR" && examinationContext.isCompleted()) {
      showEndExamPanelOrScrollToMatching();
    }
  }, [examinationContext.examination.status, liveExaminationContext.incomingDicomInstance]);
  
  useEffect(() => {
    if (examinationContext.isCompleted()) {
      showEndExamPanelOrScrollToMatching();
    }
  }, [examinationContext.examination.status]);

  const showEndExamPanelOrScrollToMatching = () => {
    if (liveExaminationContext.incomingDicomInstance) {
      if (examinationContext.examination.trimester !== "ND" && !!slides.unmatched.length) {
        containerRef?.current?.scrollTo({
          top: matchingChapterRef.current.offsetTop,
          behavior: 'smooth',
        });
      } else {
        if (!isFeatureFlagEnabled("sonio.detect") || isFeatureFlagEnabled("sonio.routine")) {
          setOpenPanel("stats");
        }
        setSelectedPlane(false);
      }
    }
  }

  const gridProps = {
    allSlides,
    zoomedSlide: selectedPlane?.slide,
    medias,
    unusualMedias,
    atRiskSlides,
    currentTrimester,
    fetusSex,
    zoomLevel,
    setShowSlideBrowser,
    setManualUserInteraction,
    config,
    liveExaminationContext,
    appContext,
    examinationContext,
    isNullOrUndefined,
    containerRef,
    targetsRef,
    clickedSlide,
    onItemClick,
    onItemDoubleClick,
    isFeatureFlagEnabled,
    highlightNextMatching,
  };

  return (
    <>
      <div className={`examination-live-grid-dt-container trimester-${currentTrimester} zoom-level-${zoomLevel}`} ref={containerRef}>
        {currentTrimester === 'ND'
          ? <div className="examination-live-grid-dt" ref={imageChapterRef}>
            <GridNd {...{
              allSlides,
              zoomedMedia: selectedPlane?.media,
              medias,
              currentTrimester,
              fetusSex,
              zoomLevel,
              onItemClickHandler,
              setShowSlideBrowser,
              setManualUserInteraction,
            }} />
          </div>
          : <>
            <div className="examination-live-grid-dt" ref={imageChapterRef}>
              <Grid {...{
                slides: slides.protocol,
                ...gridProps,
              }} />
            </div>
            {!!slides.optional.length && (
              <div className="examination-live-grid-dt">
                <div className="slide-grid-heading">
                  <h2>{__("examination.inAdditionToProtocol")}</h2>
                </div>
                <Grid {...{
                  slides: slides.optional,
                  section: "optional",
                  ...gridProps,
                }} />
              </div>
            )}
            {!!slides.unmatched.length && (
              <div className="examination-live-grid-dt" ref={matchingChapterRef}>
                <div className="slide-grid-heading">
                  <h2>{__(`examination.matching.${slides.unmatched.length === 1 ? 'singular' : 'multiple'}`, { count: slides.unmatched.filter(s => s.mediaId).length })}</h2>
                </div>
                <Grid {...{
                  slides: slides.unmatched,
                  section: "unmatched",
                  ...gridProps,
                }} />
              </div>
            )}
            {!!slides.videos.length && (
              <div className="examination-live-grid-dt videos" ref={videoChapterRef}>
                <div className="slide-grid-heading">
                  <h2>{__("examination.videos")}</h2>
                </div>
                <Grid {...{
                  slides: slides.videos,
                  section: "video",
                  ...gridProps,
                }} />
              </div>
            )}
          </>
        }
      </div>
      {currentTrimester !== "ND" && (
        <ScrollBar
          targets={allSlides.map(slide => ({
            key: slide.key,
            completed: slide.completed,
            unusual: slide.unusual,
            visible: targetsVisibility[slide.key] || false,
          }))}
          goToTarget={goToSlide}
        />
      )}
    </>
  )
}

export default withTranslation()(SlideGrid);



const Grid = withTranslation()(({
  t: __,
  slides,
  section,
  zoomLevel,
  config,
  ...gridProps
}) => {
  return (
    <div className="slide-grid-container" style={{ "--number-of-columns": zoomLevel || config.examinationLive.numberOfColumns }}>
      {slides?.map(slide =>
        <GridSlide
          key={slide.key}
          {...{
            slide,
            section,
            zoomLevel,
            config,
            ...gridProps
          }}
        />
      )}
    </div>
  )
});

const GridNd = withTranslation()(({
  t: __,
  allSlides,
  medias,
  zoomedMedia,
  currentTrimester,
  fetusSex,
  zoomLevel,
  setShowSlideBrowser,
  setManualUserInteraction,
  onItemClickHandler,
}) => {
  return (
    !!allSlides.length
    ? <div className="slide-grid-dt-container" style={{ "--number-of-columns": zoomLevel || config.examinationLive.numberOfColumns }}>
      {medias.map(media => {
        const slide = allSlides.find(s => s.mediaId === media.id);

        return <div
          key={media.id}
          className="examination-live-slide"
          data-id={media.id}
          data-is-zoomed={zoomedMedia?.id === media.id}
          onClick={(e) => onItemClickHandler(e, slide, media)}
        >
          <Slide
            slide={slide}
            trimester={currentTrimester}
            sex={fetusSex}
            media={media}
            zoomLevel={zoomLevel}
            showSlideBrowser={() => setShowSlideBrowser({ slide, media })}
            setManualUserInteraction={setManualUserInteraction}
          />
        </div>
      })}</div>
    : <div className="row examination-empty-grid vertical-center">
      <div className="w7">
        <p>
          {__("examination.takeYourFirstMedia")}<br />
          {__("examination.changeTrimester")}
        </p>
          <ExamPresetButtons
            action="change-preset"
            size="compact"
            variant="outline"
            label=""
          />
      </div>
      <div className="w5">
        <img src={`${process.env.PUBLIC_URL}/images/obgyn.svg`} alt="OBGYN" />
      </div>
    </div>
  );
});

const GridSlide = withTranslation()(({
  slide,
  section,
  zoomedSlide,
  allSlides,
  medias,
  unusualMedias,
  atRiskSlides,
  currentTrimester,
  fetusSex,
  zoomLevel,
  setShowSlideBrowser,
  setManualUserInteraction,
  config,
  liveExaminationContext,
  appContext,
  examinationContext,
  isNullOrUndefined,
  containerRef,
  targetsRef,
  clickedSlide,
  onItemClick,
  onItemDoubleClick,
  isFeatureFlagEnabled,
  highlightNextMatching,
}) => {
  const media = medias.find(media => media.id === slide.mediaId);
  const isLastInstanceReceived = !isNullOrUndefined(liveExaminationContext.incomingDicomInstance?.id) && liveExaminationContext.incomingDicomInstance?.id === media?.id;
  const dictionaryTerms = appContext.viewsDictionary?.filter(d => d.instance_view_id === slide?.id).map(d => d.text_on_img);

  const viewEvaluationIsOverriden = !isNullOrUndefined(media?.view_evaluation_override);
  const warningFromOverride = viewEvaluationIsOverriden && media?.view_evaluation_override === true
  const warningFromScore = media?.ve_prediction && (media?.ve_prediction?.data?.[0]?.conf.normalized_score < config.evaluationScoreThreshold)

  const viewEvaluation = {
    warning: viewEvaluationIsOverriden ? warningFromOverride : warningFromScore,
    source: viewEvaluationIsOverriden ? "user" : "vision-ai"
  }

  return <DragAndDropItem
    isDraggable={examinationContext.canEdit && !!media && currentTrimester !== 'ND' && media?.dicom_media_type !== "video"}
    container={containerRef?.current}
    targetSelector='.examination-live-slide'
    createGhost={(draggedElement) => {
      let slideMedia = draggedElement.querySelector('.slide-live-media');
      if (!slideMedia) slideMedia = draggedElement.querySelector('.slide-live-dt-media');
      const dragPlaceholder = document.createElement('DIV');
      if (slideMedia) dragPlaceholder.appendChild(slideMedia.cloneNode(true));
      return dragPlaceholder;
    }}
    onDragStart={(e, attr) => { /* here for reference */ }}
    onDragHover={(e, attr) => {
      if (examinationContext.canEdit && attr?.dragContainer) {
        for (const Element of attr.dragContainer?.querySelectorAll('.examination-live-slide')) {
          const isHovered = Element.dataset?.id?.split("_").slice(0, 2).join("_") === attr.hoveredElement?.dataset.id.split("_").slice(0, 2).join("_");
          Element.classList?.toggle('drag-overing', isHovered);
        }
      }
    }}
    onDrop={async (e, attr) => {
      const targetElementId = Number(attr.hoveredElement?.dataset?.id.split("_")[1]);
      const targetElementIdxInTemplate = Number(attr.hoveredElement?.dataset?.id.split("_")[0]);
      const targetElement = allSlides.find(slide => slide.id === targetElementId && slide.idx_in_template === targetElementIdxInTemplate);
      const sourceElementKey = attr.draggedElement?.dataset?.id;
      const sourceElement = allSlides.find(slide => slide.key === sourceElementKey);
      if (examinationContext.canEdit && !isNullOrUndefined(sourceElement?.mediaId) && !isNullOrUndefined(targetElement)) {
        dragReassociating = true;
        await examinationContext.setInstanceAssociation({...targetElement, idx_in_template: targetElement.idx_in_template === 1000 ? null : targetElement.idx_in_template}, sourceElement.mediaId, true);
        dragReassociating = false;
      }
      return true;
    }}
    onClick={(e, attr) => {
      const sourceElementKey = attr.draggedElement?.dataset?.id;
      const sourceElement = allSlides.find(slide => slide.key === sourceElementKey);
      const media = sourceElement?.mediaId ? medias.find(m => m.id === sourceElement.mediaId) : false;
      onItemClick(sourceElement, { slide: sourceElement, media: media }, sourceElement);
    }}
    onDblClick={(e, attr) => {
      const sourceElementKey = attr.draggedElement?.dataset?.id;
      const sourceElement = allSlides.find(slide => slide.key === sourceElementKey);
      const media = sourceElement?.mediaId ? medias.find(m => m.id === sourceElement.mediaId) : false;
      onItemDoubleClick(sourceElement, { slide: sourceElement, media: media }, sourceElement);
    }}
  >
    <div
      data-id={slide.key}
      data-is-extracted={!!media?.dicom_origin_id}
      data-is-verified={!viewEvaluation?.warning && viewEvaluation?.source === "user"}
      data-is-matched={section !== "unmatched"}
      data-is-zoomed={zoomedSlide?.key === slide.key}
      ref={targetsRef.current[slide.key]}
      className={`examination-live-slide ${isLastInstanceReceived ? 'last-received' : ''} ${(clickedSlide?.key === slide.key && highlightNextMatching) ? "next-matching" : ""} ${isFeatureFlagEnabled("sonio.risk") && atRiskSlides.includes(slide.id) ? "at-risk" : ""} ${unusualMedias.includes(slide.mediaId) ? "unusual" : ""}`}
    >
      <Slide
        slide={slide}
        trimester={currentTrimester}
        sex={fetusSex}
        media={media}
        atRisk={atRiskSlides.includes(slide.id)}
        zoomLevel={zoomLevel}
        showSlideBrowser={() => setShowSlideBrowser({ slide, media })}
        setManualUserInteraction={setManualUserInteraction}
        dictionaryTerms={dictionaryTerms}
      />
    </div>
  </DragAndDropItem>;
})

