import { useRef } from "react";
import { isNullOrUndefined } from "../../utils";
import "./ScrollBar.css";

/**
 * <ScrollBar
 *  targets = [ {
 *    key: integer,
 *    completed: boolean,
 *    unusual: boolean,
 *    visible: boolean,
 *   } ]
 *  goToTarget = () => {}
 * />
 */

const mousePosition = { x: 0, y: 0 };

const Scrollbar = ({ targets, goToTarget }) => {
  const startIndex = targets.findIndex(target => target.visible);
  const endIndex = targets.length - [...targets].reverse().findIndex(target => target.visible);

  const containerRef = useRef();

  const onDragStart = (e) => {
    e.preventDefault();
    document.body.addEventListener('mousemove', onDragMove);
    document.body.addEventListener('touchmove', onDragMove);
    document.body.addEventListener('mouseup', onDragStop);
    document.body.addEventListener('touchend', onDragStop);
    onDragMove(e, true);
  }
  
  const onDragMove = (e, smoothScroll=false) => {
    e.preventDefault();
    const event = e.targetTouches?.[0] || e;
    mousePosition.x = event.clientX;
    mousePosition.y = event.clientY;
    const containerBox = containerRef.current.getBoundingClientRect();
    const targetIndex = Math.floor(Math.max(event.clientY - containerBox.top, 0) / (containerBox.height / targets.length)) || 0;
    if (!isNullOrUndefined(targetIndex)) {
      goToTarget(targets[targetIndex].key, smoothScroll);
    }
  }

  const onDragStop = (e) => {
    e.preventDefault();
    document.body.removeEventListener('mousemove', onDragMove);
    document.body.removeEventListener('touchmove', onDragMove);
    document.body.removeEventListener('mouseup', onDragStop);
    document.body.removeEventListener('touchend', onDragStop);
  }

  return (
    <nav className="examination-live-scrollbar-container" ref={containerRef}>
      {targets.some(target => target.visible) && (
        <ol className="examination-live-scrollbar-highlight">
          <li style={{flexGrow: startIndex}} />
          <li style={{flexGrow: endIndex - startIndex}} className="highlight" />
          <li style={{flexGrow: targets.length - endIndex}} />
        </ol>
      )}
      <ol>
        {targets.map(target =>
          <li
            key={target.key}
            className={`${!!target.completed ? 'completed' : ''} ${!!target.unusual ? 'unusual' : ''}`}
            onMouseDown={onDragStart}
            onTouchStart={onDragStart}
          ><span /></li>)}
      </ol>
    </nav>
  )
}

export default Scrollbar;