import ResourceApi from "../services/resource";
import changeStatusSharingDoc from "../providers/examSharing/actions/changeStatusSharingDoc";

const changeStatusSharingDocument = async (shareDoc) => {
  const { status } = await ResourceApi.updateStatusSharingDocument(shareDoc.examination_id, shareDoc.id, !shareDoc.active);
    
  if (status === 200) {
    changeStatusSharingDoc({...shareDoc, active: !shareDoc.active });
  }
}

export default changeStatusSharingDocument;
