import { default as React, useEffect, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Button from '../../atoms/Button/Button';
import InputPassword from '../../atoms/InputPassword';
import IsNotPractitionerWarn from '../../components/IsNotPractitionerWarn';
import PasswordChecker from '../../components/PasswordChecker';
import CountryFlag from '../../atoms/CountryFlag';
import UserApi from '../../services/user';
import LookupApi from '../../services/lookup';
import i18next from 'i18next';
import { useHistory } from 'react-router-dom';
import {useUnauth} from '../../context-providers/Unauthenticated';
import {CertificationIdInput, CertificationIdErrorMessage, MultipleCertificationIdInput, certificationIdTypeName}  from '../../components/CertificationId';
import "./RegisterInvitedUser.css";
import Icon from '../../atoms/Icon/Icon';

function upCaseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function setDefaultIdentity(doSetIdentity, {country_id, email, lang, is_professional, certification_id_type}) {
    return doSetIdentity({
        country_id,
        email,
        lang,
        is_professional,
        certification_id_type,
        first_name: "",
        last_name: "",
        rpps: "",
        password: "",
        confirm_password: "",
    })
}

function ToolTipMessage({children}) {
    return children
}

function WithToolTip({children, ...props}) {
    const formatted_children = Array.isArray(children) ? children : [children]
    const displayed = formatted_children.filter((child) => child.type !== ToolTipMessage)
    const message = formatted_children.filter((child) => child.type === ToolTipMessage)
    const [showHint, setShowHint] = useState(false)

    return (
        <div className="register-user-tooltip-wrapper" {...props}>
            {displayed}
            <div className="register-user-tooltip-icon"
                onMouseEnter={() => setShowHint(true)}
                onMouseLeave={() => setShowHint(false)}
            >
              <div className="register-user-tooltip-message" style={{display: (showHint && message.length !== 0) ? "block" : "none"}}>{message}</div>
              <Icon name="info" />
            </div>
        </div>
    )
}

function OneCertificationIdTypeField(props) {
    const {identity, doSetIdentity, errors, t: __, certificationIdTypes} = props

    const setCertificationId = (newCertificationId) => {
        return doSetIdentity({...identity, rpps: newCertificationId})
    }

    const certificationIdProps = {certificationId: identity.rpps, setCertificationId, certificationIdTypes, certificationIdType: identity.certification_id_type, certificationIdError: errors.rpps}

    return (
        <div>
            <div className="register-user-country-number-wrapper">
                { identity.country_id === "other" ? null :
                    <div className="register-user-field-wrapper" style={{flex: (identity.is_professional ? "none" : "1")}}>
                        <div><label>{__('register.country')}</label></div>
                        <CountryFlag country={identity.country_id}/>
                    </div>
                }
                <div className="register-user-field-wrapper" style={{flex: "1", display: (identity.is_professional ? "block" : "none")}}>
                    <div><label htmlFor="rpps">{upCaseFirstLetter(certificationIdTypeName({...certificationIdProps, t: __}))}</label></div>
                    <div>
                        <CertificationIdInput {...certificationIdProps}/>
                    </div>
                    <CertificationIdErrorMessage {...certificationIdProps} />
                </div>
            </div>
            {!identity.is_professional ? <IsNotPractitionerWarn {...props}/> : null}
        </div>
    )
}

function MultipleCertificationIdTypeField(props) {
    const {identity, doSetIdentity, errors, t: __, certificationIdTypes} = props

    const setCertificationId = (newCertificationId) => {
        return doSetIdentity({...identity, rpps: newCertificationId})
    }
    const setCertificationIdType = (newCertificationIdType) => {
        return doSetIdentity({...identity, certification_id_type: newCertificationIdType})
    }

    const certificationIdProps = {certificationId: identity.rpps, setCertificationId, certificationIdTypes, certificationIdType: identity.certification_id_type, setCertificationIdType, certificationIdError: errors.rpps}
    return (
        <div>
            <div className="register-user-field-wrapper">
                <div><label>{__('register.country')}</label></div>
                <CountryFlag country={identity.country_id} t={__}/>
            </div>
            <div className="register-user-field-wrapper" style={{display: (identity.is_professional ? "block" : "none")}}>
                <div><label htmlFor="id_number">{upCaseFirstLetter(certificationIdTypeName({...certificationIdProps, t: __}))}</label></div>
                <div>
                    <MultipleCertificationIdInput {...certificationIdProps} />
                </div>
                <CertificationIdErrorMessage {...certificationIdProps} />
            </div>
            {!identity.is_professional ? <IsNotPractitionerWarn {...props}/> : null}
        </div>
    )
}

function NoCertificationIdFields(props) {
    const {identity, t: __} = props

    return (
        <div>
            <div className="register-user-country-number-wrapper">
                <div className="register-user-field-wrapper" style={{flex: 1}}>
                    <div><label>{__('register.country')}</label></div>
                    <CountryFlag country={identity.country_id} t={__}/>
                </div>
            </div>
            {!identity.is_professional ? <IsNotPractitionerWarn {...props}/> : null}
        </div>
    )
}

function CountryFields(props) {
    if(props.certificationIdTypes.length === 0)
        return NoCertificationIdFields(props)
    if(props.certificationIdTypes.length === 1)
        return OneCertificationIdTypeField(props)
    return MultipleCertificationIdTypeField(props)
}

function NotExistingInvitationCode({t: __}) {
    return (
        <div className="register-user-page">
            <div>{__('register.invitationCodeInvalid')}</div>
        </div>
    )
}

const RegisterInvitedUser = ({ t: __ }) => {
    const { inviteCode: invite_code } = useParams();
    const history = useHistory();

    const [loading, setLoading] = useState(true)
    const [submitting, setSubmitting] = useState(false)
    const [identity, setIdentity] = useState({})
    const [certificationIdTypes, setCertificationIdTypes] = useState([])
    const [errors, setErrors] = useState({})

    const { lang } = useUnauth();
    useEffect(() => {
        setIdentity((identity) => ({...identity, lang}))
    }, [lang])

    const doSetIdentity = (new_identity) => {
        const modified_keys = Object.keys(identity).filter((k) => (new_identity[k] !== identity[k]) && errors[k])
        if(modified_keys.lenght > 0) {
            const deleted_errors = modified_keys.reduce((acc, x) => acc[x] = null, {})
            const new_errors = {...errors, ...deleted_errors}
            setErrors(new_errors)
        }
        return setIdentity(new_identity)
    }

    useEffect(() => {
        const fetchApis = async () => {
            const identity = (await UserApi.GetDetailsFromInvited(invite_code)).data.data
            i18next.changeLanguage(identity.lang)
            const certificationIdTypes = (await LookupApi.getCertificationIdTypes(identity.country_id)).data.data
            const defaultCertificationIdType = certificationIdTypes.length === 0 ? null : certificationIdTypes[0].id
            setDefaultIdentity(doSetIdentity, {...identity, certification_id_type: defaultCertificationIdType})
            setCertificationIdTypes(certificationIdTypes)
            return
        }

        fetchApis()
            .catch(() => doSetIdentity(null))
            .finally(() => setLoading(false))
    }, [invite_code])

    const registerUser = (event) => {
        event.preventDefault()
        setSubmitting(true)
        UserApi.registerInvited({...identity, invite_code})
            .then(resp => history.push('/register/success'))
            .catch((resp) => {
                const { response: { data , status} } = resp
                if (status === 400) {
                    setErrors(data.errors)
                } else {
                    /* What to do if the server crashed? Do we have a snack */
                }
            })
            .finally(() => setSubmitting(false))
    }

    if(loading)
        return null /* TODO make a loading screen? */
    if(identity == null) {
        return <NotExistingInvitationCode t={__}/>
    }

    const little_identity = {
        first_name: identity.first_name,
        last_name: identity.last_name,
        rpps: identity.rpps,
        email: identity.email,
        email_part1: identity.email ? identity.email.split("@")[0] : "",
    }

    return (
        <div className="register-user-page">
            <div>
                <div className="sonio-header-logo"><img src="/logo-full.svg" alt="Sonio"/></div>
                <form className="register-user-form" onSubmit={registerUser}>
                    <div className="register-user-form-title">{__('register.title')}</div>
                    <div className="section-title register-user-form-section-title">
                        <WithToolTip>
                            <span>{__('register.section.information')}</span>
                            <ToolTipMessage>
                                <div>
                                    {__("register.tip.information")}
                                </div>
                            </ToolTipMessage>
                        </WithToolTip>
                    </div>
                    <div className="register-user-field-wrapper">
                        <div><label htmlFor="email">{__('login.email')}</label></div>
                        <input className="" id="email" autoComplete="username" disabled value={identity.email || ""}/>
                    </div>
                    <div className="register-user-field-splitter">
                        <div className="register-user-field-wrapper half">
                            <div><label htmlFor="first_name">{__('register.firstName')}</label></div>
                            <input className={errors.first_name ? "input-error" : ""} id="first_name" type="text" autoComplete="given-name" style={{width: "100%"}} onChange={(e) => doSetIdentity({ ...identity, first_name: e.target.value })} value={identity.first_name || ""}/>
                            {errors.first_name && <div className="error-message">{__(errors.first_name)}</div>}
                        </div>
                        <div className="register-user-field-wrapper half" style={{marginLeft: "1rem"}}>
                            <div><label htmlFor="last_name">{__('register.lastName')}</label></div>
                            <input className={errors.last_name ? "input-error" : ""} id="last_name" type="text" autoComplete="family-name" style={{width: "100%"}} onChange={(e) => doSetIdentity({ ...identity, last_name: e.target.value })} value={identity.last_name || ""}/>
                            {errors.last_name && <div className="error-message">{__(errors.last_name)}</div>}
                        </div>
                    </div>
                    <CountryFields doSetIdentity={doSetIdentity} identity={identity} t={__} errors={errors} certificationIdTypes={certificationIdTypes}/>
                    <div className="section-title register-user-form-section-title">
                      <span>{__('register.section.password')}</span>
                    </div>
                    <PasswordChecker password={identity.password} opts={little_identity} t={__}/>
                    <div className="register-user-field-wrapper">
                        <div><label htmlFor="password">{__('login.password')}</label></div>
                        <InputPassword className={errors.password ? "input-error" : ""} id="password" autoComplete="new-password" placeholder={__("register.password.placeholder")} style={{width: "100%"}} onChange={(e) => doSetIdentity({ ...identity, password: e.target.value })} value={identity.password}/>
                        {errors.password && <div className="error-message">{__(errors.password)}</div>}
                    </div>
                    <div className="register-user-field-wrapper">
                        <div><label htmlFor="confirm-password">{__('login.confirmPassword')}</label></div>
                        <InputPassword className={errors.confirm_password ? "input-error" : ""} id="confirm-password" type="password" autoComplete="new-password" style={{width: "100%"}} onChange={(e) => doSetIdentity({ ...identity, confirm_password: e.target.value })} value={identity.confirm_password}/>
                        {errors.confirm_password && <div className="error-message">{__(errors.confirm_password)}</div>}
                    </div>
                    <div className="register-user-button-wrapper"><Button label={submitting ? __('common.loading') : __('register.action')} disabled={submitting} onClick={registerUser} /></div>
                    <div className="register-user-contact">{__('register.contactUs')} <a className="register-mail-link" href={"mailto:help@sonio.ai"}>help@sonio.ai</a> {__('register.orCallHelp')}</div>
            </form>
            </div>
        </div>)
}

export default withTranslation()(RegisterInvitedUser);
