import { isValidElement, cloneElement } from "react";

export default function ReportTemplateHeading({ defaultKey = '', children, props, fetus = 1, reportMode }) {

  const { size = "medium", align = null, color = null, background = null } = (props ?? {});

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };
  const HeaderTag = "h" + (sizes[size] || 2);

  const style = {};
  if (align) style.textAlign = align;
  if (color && reportMode === 'print') style.color = color;
  if (background && reportMode === 'print') style.backgroundColor = background;

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_heading_${fetus}` });
    }
    return child;
  }) : children;

  return (
    <HeaderTag style={style}>{childrenWithFetusNumber}</HeaderTag>
  );
}
