import React, { useEffect, useState, useRef } from "react";
import Icon from "../Icon/Icon";
import "./Switch.css";

/**
 * <Switch
 * options=[value:"", icon:"", count:0]
 * selected=""
 * onChange=()=>{}
 * />
 */

const Switch = ({options, selected, onChange=()=>{}}) => {
  const [currentOptions, setCurrentOptions] = useState([]);
  const [previousOptions, setPreviousOptions] = useState([]);

  useEffect(() => {
    setPreviousOptions(currentOptions);
    setCurrentOptions(options);
  }, [JSON.stringify(options)]);

  return (
    <div className="switch">
      <ul>
      {currentOptions.map( (option, i) => (
        <li
          key={option.value+option.count}
          className={`switch-${option.value} ${selected===option.value?'selected':'not-selected'}`}
          onClick={() => onChange(option.value)}
        >
          {!!option.icon && (
            <Icon name={option.icon} />
          )}
          {!!option.count && (
            <>
            <span className="switch-count">
              {option.count}
            </span>
            {option.count > parseInt(previousOptions[i]?.count) && (
              <span className="switch-count-changed">{option.count}</span>
            )}
            </>
          )}
        </li>
      ))}
      </ul>
    </div>
  );
};

export default Switch;
