import { faker } from '@faker-js/faker';
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import TextInput from "../../atoms/TextInput/TextInput";
import FetusControllerView from '../../components-dx/FetusController/FetusControllerView';
import Popup from "../../components/Popup/Popup";
import ResourceApi from "../../services/resource";
import { isNullOrUndefined } from "../../utils";
import "./assets/fontello.css";
import "./SimulatorView.css";

const FPS = 25
const FRAMETIME = 1 / FPS;
let lastClickedButtonTimeout = false;
let endExamTimeout = false;
const PADDING = 20;
const MARGIN = 30;
const SIZE = 60;
const FONT = `${SIZE}px Arial`;
const TECHNO = [
  { value: "US", labelTraslationSource: "simulator.techno.us" },
  { value: "DOPPLER", labelTraslationSource: "simulator.techno.doppler" },
  { value: "3D", labelTraslationSource: "simulator.techno.threeD" }
]

const SimulatorView = ({ t: __,
  performingPhysicianName,
  seriesInstanceUID,
  studyInstanceUID,
  patientName,
  accessionNumber,
  endExam = () => { }
}) => {
  const canvasRef = useRef();
  const canvasContextRef = useRef();
  const videoRef = useRef();
  const progressBarRef = useRef();
  const requestRef = useRef();
  const textInputRef = useRef();

  const [typing, setTyping] = useState(false);
  const [popup, setPopup] = useState(false);
  const [endExamcounter, setEndExamCounter] = useState(null);
  const [selectedText, setSelectedText] = useState();
  const [currentTechnoIndex, setCurrentTechnoIndex] = useState(0);
  const [currentZone, setCurrentZone] = useState(1);
  const [annotations, setAnnotations] = useState([])
  const [annotationToBeAdded, setAnnotationToBeAdded] = useState()
  const [lastClickedButton, setLastClickedButton] = useState();
  const [videoSrc, setVideoSrc] = useState(null);
  const [videoProgress, setVideoProgress] = useState();

  const canvasZoomRatio = useMemo(() => canvasRef.current?.offsetWidth / canvasRef.current?.width, [canvasRef.current?.offsetWidth, canvasRef.current?.width]);

  const getVideo = useCallback(() => {
    let videoSrc;
    switch (currentZone) {
      case 1:
        switch (TECHNO[currentTechnoIndex].value) {
          case "US":
            videoSrc = "https://s3.eu-west-3.amazonaws.com/sonio-data.eu-west-3.live-simulator-data/videos/T2/4.mp4"
            break;

          default:
            videoSrc = `${process.env.PUBLIC_URL}/videos/sonio.mp4`
            break;
        }
        break;

      default:
        videoSrc = `${process.env.PUBLIC_URL}/videos/sonio.mp4`
        break;
    }
    setVideoSrc(videoSrc);
  }, [currentZone, currentTechnoIndex])

  const addAnnotation = useCallback((value) => {
    setTyping(false);
    setAnnotations(prev => {
      if (!!value?.length) {
        // to avoid overlay of annotations
        var y = prev.length * SIZE + PADDING + MARGIN;

        var text = {
          text: value,
          x: PADDING + MARGIN,
          y: y
        };

        canvasContextRef.current.font = FONT;
        text.width = canvasContextRef.current.measureText(text.text).width;
        text.height = SIZE;
        return [...prev, text]
      }
    });
    setAnnotationToBeAdded(null);
    textInputRef.current?.blur();
  }, []);

  const onInputChangeHandler = useCallback((value) => {
    setTyping(true);
    const nextValue = value
    setAnnotationToBeAdded(nextValue);
  }, [textInputRef.current])

  const onInputKeyDown = useCallback(async (e) => {
    if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      await onKeydownAction(e, () => addAnnotation(annotationToBeAdded));
    } else if (e.key === 'Escape' || e.code === 'Escape' || e.keyCode === 27) {
      await onKeydownAction(e, () => {
        setAnnotationToBeAdded(false);
        setTyping(false);
      });
    }
  });

  const deleteAnnotation = useCallback((index) => {
    setAnnotations(prev => {
      const newAnnotations = prev;
      newAnnotations.splice(index, 1);
      return [...newAnnotations]
    })
  }, [])

  const capture = useCallback(() => {
    if (!videoRef.current || !canvasRef.current || !canvasContextRef.current) {
      return;
    }

    canvasContextRef.current.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height);

    canvasContextRef.current.font = FONT;
    canvasContextRef.current.fillStyle = "rgb(230,230,60)"; // #e5e53b

    for (let i = 0; i < annotations?.length; i++) {
      const text = annotations[i];
      canvasContextRef.current.fillText(text.text, text.x, text.y);
    }

    setVideoProgress(Math.round(10000 / videoRef.current?.duration * videoRef.current?.currentTime) / 100);
    requestRef.current = requestAnimationFrame(() => {
      capture();
    });
  }, [annotations]);
  
  // test if x,y is inside the bounding box of annotations[textIndex]
  const textHittest = useCallback((x, y, textIndex) => {
    var text = annotations[textIndex];
    const valid = (x >= text.x - PADDING && x <= text.x + PADDING + text.width && y >= text.y - PADDING - text.height && y <= text.y + PADDING);
    return {
      valid,
      offsetX: valid ? x - text.x : false,
      offsetY: valid ? y - text.y : false,
    };
  }, [annotations]);

  useEffect(() => {
    if (canvasRef.current.getContext) {
      canvasRef.current.width = videoRef.current.videoWidth;
      canvasRef.current.height = videoRef.current.videoHeight;
      canvasContextRef.current = canvasRef.current.getContext('2d');
      canvasContextRef.current.clearRect(0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
    }
  }, [videoRef.current?.videoWidth, videoRef.current?.videoHeight, canvasRef.current?.getContext]);

  useEffect(() => {
    requestAnimationFrame( capture );

    return () => {
      cancelAnimationFrame(requestRef.current);
    }
  }, [capture]);


  const handleMouseDown = useCallback((e) => {
    const canvasOffset = canvasRef.current.getBoundingClientRect();
    const offsetX = canvasOffset.left;
    const offsetY = canvasOffset.top;
    e.preventDefault();
    const startX = parseInt(e.clientX - offsetX) / canvasZoomRatio;
    const startY = parseInt(e.clientY - offsetY) / canvasZoomRatio;

    for (var i = 0; i < annotations?.length; i++) {
      const isValid = textHittest(startX, startY, i);
      if (isValid.valid) {
        setSelectedText({index: i, offsetX: isValid.offsetX, offsetY: isValid.offsetY});
        return;
      }
    }

    setSelectedText(null);
    pauseVideo();
  }, [textHittest, annotations])

  const handleMouseUp = useCallback((e) => {
    e.preventDefault();
    setSelectedText(null);
  }, [])

  const handleMouseMove = useCallback((e) => {
    if (isNullOrUndefined(selectedText?.index)) {
      return;
    }
    const canvasOffset = canvasRef.current.getBoundingClientRect();
    const offsetX = canvasOffset.left;
    const offsetY = canvasOffset.top;
    e.preventDefault();
    const mouseX = (parseInt(e.clientX - offsetX) / canvasZoomRatio) - selectedText.offsetX;
    const mouseY = (parseInt(e.clientY - offsetY) / canvasZoomRatio) - selectedText.offsetY;

    setAnnotations(prev => {
      const newAnnotations = [...prev]
      newAnnotations[selectedText.index] = {
        ...newAnnotations[selectedText.index],
        x: mouseX,
        y: mouseY
      }
      return newAnnotations
    });
  }, [selectedText?.index, isNullOrUndefined])

  const showAction = useCallback((code) => {
    if (lastClickedButtonTimeout) {
      clearTimeout(lastClickedButtonTimeout);
      lastClickedButtonTimeout = false;
    }
    setLastClickedButton(code);
    lastClickedButtonTimeout =
      setTimeout(() => {
        setLastClickedButton(false);
      }, 500);
  }, [])

  const sendDicomWithImage = useCallback(async () => {
    showAction("Enter");
    const imgThumbnail = canvasRef.current.toDataURL().split(',')[1];
    const SOPInstanceUID = faker.datatype.uuid();

    await ResourceApi.ingestDicomWithImage({
      "instanceUuid": SOPInstanceUID,
      "metadata": {
        "DeviceSerialNumber": "1234567",
        "SOPInstanceUID": SOPInstanceUID,
        "Modality": "US",
        "PerformingPhysicianName": performingPhysicianName,
        "OperatorsName": "",
        "NumberOfFrames": "1",
        "SeriesInstanceUID": seriesInstanceUID,
        "StudyInstanceUID": studyInstanceUID,
        "PatientName": patientName,
        "PatientBirthDate": "19950805",
        "PatientSex": "F",
        "AccessionNumber": accessionNumber,
        "PatientID": "",
        "instanceUuid": SOPInstanceUID
      },
      "thumbnailBase64": imgThumbnail,
      "metaHeader": ""
    })
  }, [faker,
    ResourceApi.ingestDicomWithImage,
    performingPhysicianName,
    seriesInstanceUID,
    studyInstanceUID,
    patientName,
    accessionNumber,
    showAction
  ])

  const previousFrame = useCallback(() => {
    showAction("ArrowLeft")
    videoRef.current.pause();
    videoRef.current.currentTime = Math.max(0, videoRef.current.currentTime - FRAMETIME);
  }, [showAction])

  const nextFrame = useCallback(() => {
    showAction("ArrowRight")
    videoRef.current.pause();
    videoRef.current.currentTime = Math.min(videoRef.current?.duration, videoRef.current.currentTime + FRAMETIME);
  }, [showAction])

  const pauseVideo = useCallback(() => {
    if (videoRef.current.paused) {
      videoRef.current.play();
    } else {
      showAction("Space");
      videoRef.current.pause();
    }
  }, [showAction, videoRef.current?.paused]);

  const seekVideoTo = useCallback((e) => {
    const progressBarBox = progressBarRef.current.getBoundingClientRect();
    const targetPercent = 100 / progressBarBox.width * (e.clientX - progressBarBox.x);
    videoRef.current.currentTime = videoRef.current.duration / 100 * targetPercent;
  }, []);
  
  const onKeydownAction = useCallback(async (e, action = async () => { }) => {
    e?.preventDefault();
    e?.stopPropagation();
    await action();
  }, [])

  const endExamHandle = useCallback(() => {
    showAction("Escape")
    endExam()
  }, [endExam, showAction])

  const exitHandler = useCallback(async (e) => {
    // This is happening on the exit from fullscreen mode by clicking on escape
    // we are just using the same code "Escape" to catch that the escape button was pressed.
    // https://github.com/private-face/jquery.fullscreen/issues/5#issuecomment-14964565
    if (!window.document.webkitIsFullScreen
      && !window.document.mozFullScreen
      && !window.document.msFullscreenElement) {
      setEndExamCounter(10);
    }
  }, [window.document.webkitIsFullScreen,
  window.document.mozFullScreen,
  window.document.msFullscreenElement])

  const onKeyDown = useCallback(async (e) => {
    if (!videoRef.current || typing) return;
    if (e.key == " " || e.code == "Space" || e.keyCode == 32) {
      await onKeydownAction(e, pauseVideo);
    } else if (e.key === 'ArrowLeft' || e.code === 'ArrowLeft' || e.keyCode === 37) {
      await onKeydownAction(e, previousFrame);
    } else if (e.key === 'ArrowRight' || e.code === 'ArrowRight' || e.keyCode === 39) {
      await onKeydownAction(e, nextFrame);
    } else if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
      await onKeydownAction(e, sendDicomWithImage)
    } else if (e.key === 'Escape' || e.code === 'Escape' || e.keyCode === 27) {
      await onKeydownAction(e, endExamHandle);
    } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90)) {
      await onKeydownAction(e, () => {
        setAnnotationToBeAdded(e.key);
        setTyping(true);
        textInputRef.current?.focus();
      });
    }
  }, [typing, onKeydownAction, pauseVideo, previousFrame, nextFrame, sendDicomWithImage, endExamHandle])

  const endExamPopupHandler = useCallback(async () => {
    if (!isNullOrUndefined(endExamcounter)) {
      if (endExamcounter >= 0) {
        endExamTimeout = setTimeout(() => setEndExamCounter(prev => {
          setPopup(() => {
            const simulatorPopupCancelButtonLabel = __("simulator.popup.cancel")
            return {
              message: "",
              icon: false,
              cta: (
                <>
                  <h2>{__("simulator.popup.title")}</h2>
                  <p>{__("simulator.popup.message", { simulatorPopupCancelButtonLabel: simulatorPopupCancelButtonLabel })}</p>
                  <br />
                  <Button
                    label={simulatorPopupCancelButtonLabel}
                    variant="outline"
                    onClick={() => {
                      clearTimeout(endExamTimeout);
                      endExamTimeout = false;
                      setEndExamCounter(null)
                    }}
                  />
                  &nbsp;
                  <Button
                    label={__("simulator.popup.end", { counter: prev })}
                    onClick={() => {
                      clearTimeout(endExamTimeout);
                      endExamTimeout = false;
                      setEndExamCounter(0)
                    }}
                  />
                </>)
            }
          })
          return (prev - 1);
        }), 1000);
      } else {
        await onKeydownAction(null, endExamHandle)
        setEndExamCounter(null)
      }
    }
    else {
      setPopup(false)
    }
  }, [endExamcounter, onKeydownAction, endExamHandle])

  useEffect(() => {
    getVideo()
  }, [getVideo])

  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    // TODO: Add full screen button
    // the escape button will be used to exist full screen and endExam
    window.addEventListener('webkitfullscreenchange', exitHandler);
    window.addEventListener('mozfullscreenchange', exitHandler);
    window.addEventListener('fullscreenchange', exitHandler);
    window.addEventListener('MSFullscreenChange', exitHandler);

    return () => {
      window.removeEventListener('webkitfullscreenchange', exitHandler);
      window.removeEventListener('mozfullscreenchange', exitHandler);
      window.removeEventListener('fullscreenchange', exitHandler);
      window.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, [exitHandler])

  useEffect(() => {
    if (videoSrc) {
      videoRef.current.play();
      setAnnotations([]);
    }
  }, [videoSrc])

  useEffect(() => {
    canvasRef.current.addEventListener('mousedown', handleMouseDown);
    canvasRef.current.addEventListener('mousemove', handleMouseMove);
    canvasRef.current.addEventListener('mouseup', handleMouseUp);
    canvasRef.current.addEventListener('mouseout', handleMouseUp);

    return () => {
      canvasRef.current?.removeEventListener('mousedown', handleMouseDown);
      canvasRef.current?.removeEventListener('mousemove', handleMouseMove);
      canvasRef.current?.removeEventListener('mouseup', handleMouseUp);
      canvasRef.current?.removeEventListener('mouseout', handleMouseUp);
    };
  }, [
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
  ]);

  useEffect(() => {
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  useEffect(() => {
    endExamPopupHandler()
  }, [endExamPopupHandler]);

  return (
    <div className="simulator-page-container">
      <div className="video">
        <canvas id="canvas" ref={canvasRef} className={`capture-canvas ${!isNullOrUndefined(selectedText?.index) ? "grabbing" : ''}`} />
        <video id="video" ref={videoRef} className='video-container' type="video/mp4" src={videoSrc} muted loop crossOrigin="*" />
        <div className="progress" style={{ "--percent": videoProgress }} onClick={seekVideoTo} ref={progressBarRef}>
          <div className="progress-bar" />
        </div>
      </div>
      <div className="footer">
        <div className="instructions">
          <div className="item-group">
            <div className={`item ${lastClickedButton === "ArrowLeft" ? 'selected' : ''}`} onClick={previousFrame}>
              <span className="icon-simulator icon-keyboard-left" />
              <span className="label">{__("simulator.key.backword")}</span>
            </div>
            <div className={`item ${lastClickedButton === "ArrowRight" ? 'selected' : ''}`} onClick={nextFrame}>
              <span className="icon-simulator icon-keyboard-right" />
              <span className="label">{__("simulator.key.forward")}</span>
            </div>
          </div>
          <div className="item-group">
            <div className={`item ${videoRef.current?.paused ? 'selected' : ''}`} onClick={pauseVideo}>
              <span className="icon-simulator icon-keyboard-space" />
              <span className="label">{__("simulator.key.freeze")}</span>
            </div>
            <div className={`item ${lastClickedButton === "Enter" ? 'selected' : ''}`} onClick={sendDicomWithImage}>
              <span className="icon-simulator icon-keyboard-enter" />
              <span className="label">{__("simulator.key.send")}</span>
            </div>
          </div>
          <div className="item-group">
            <div className={`item ${lastClickedButton === "Escape" ? 'selected' : ''}`} onClick={endExamHandle}>
              <span className="icon-simulator icon-keyboard-esc" />
              <span className="label">{__("simulator.key.end")}</span>
            </div>
          </div>
        </div>
        { /*
        <div className='fetus'>
          <div className='fetus-controller-container'>
            <FetusControllerView
              getClassName={(zoneId) => { if (currentZone === zoneId) return " current"; }}
              getMarkerClassName={() => { }}
              getUnusualClassName={() => { }}
              goToZone={(id) => { setCurrentZone(id); }}
            />
          </div>
          <div className='techno-list'>
            {TECHNO.map((techno, index) =>
              < div
                key={techno.value}
                className={`techno ${TECHNO[currentTechnoIndex].value === techno.value ? 'selected' : ""}`}
                onClick={() => { setCurrentTechnoIndex(index) }}
              >
                {__(techno.labelTraslationSource)}
              </div>
            )}
          </div>
        </div>
        */ }
        <div className="annotations">
          <TextInput
            placeholder={__("simulator.annotations.placeholder")}
            fullwidth={true}
            value={annotationToBeAdded}
            onChange={onInputChangeHandler}
            onKeyDown={onInputKeyDown}
            ref={textInputRef}
          />
          <div className="annotations-list">
            {!!annotations?.length && annotations.map((annotation, index) =>
              <div key={annotation.text} className="annotations-item" >
                {annotation.text}
                <div className='close-icon'>
                  <Icon name="close" onClick={() => { deleteAnnotation(index) }} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!!popup && <Popup message={popup.message} icon={popup.icon} cta={popup.cta} />}
    </div >
  );
};

export default withTranslation()(SimulatorView);
