import {useCallback, useState} from "react";
import {withTranslation} from "react-i18next";
import config from "../../config";
import Slide from "../../components/Slide/Slide";
import Icon from "../../atoms/Icon/Icon";
import ButtonAdd from "../../atoms/ButtonAdd/ButtonAdd";
import Button from "../../atoms/Button/Button";
import SlideBrowser from "../../components/Examination/SlideBrowser/SlideBrowser";

const zoomLevel = Number(window.localStorage.getItem('defaultZoomLevel')) || config.examinationLive.numberOfColumns;

const PreselectSlideGrid = ({t: __, slides: initialSlides, protocolSlides, trimester, onCancel, onUpdate}) => {
  const [slides, setSlides] = useState(initialSlides);
  const [selectedSlide, setSelectedSlide] = useState(false);
  const [slideListOpen, setSlideListOpen] = useState(false);

  const addNewSlides = useCallback((slide, options = {}) => {
    const insertAfter = options.insertAfter ?? slides.length;
    const newSlides = [...slides.slice(0, insertAfter), slide, ...slides.slice(insertAfter)];
    setSlides(newSlides);
    setSelectedSlide(insertAfter);
  }, [slides])

  const deleteSlide = useCallback((index) => {
    const newSlides = [...slides]
    newSlides.splice(index, 1)
    setSlides(newSlides);
    setTemplateHasBeenModified(true);
  }, [slides])

  const handleCancel = () => onCancel();
  const handleUpdate = () => {
    const slideIds = slides.map(slide => slide.id)
    return onUpdate(slideIds);
  }

  const handleBack = () => {
    if (slideListOpen) {
      setSlideListOpen(false)
    } else {
      handleCancel()
    }
  }

  return <>
    <div className="left-navbar">
      <div className="toggle-menu go-back" onClick={handleBack}>
        <Icon name="left" />
      </div>

    </div>
    <div
      className={`preferences-per-protocol-selection page-container manage-template-container`}>
      <div className="manage-template-create">
        {<div className="manage-template-slide-grid-container">
          <div className="manage-template-slide-grid" style={{"--number-of-columns": zoomLevel}}>
            {slides.map((slide, index) => (
              <div
                key={`${slide.id}_${index}`}
                className={`manage-template-slide-grid-cell ${selectedSlide === index ? 'selected' : ''}`}
                onClick={() => setSelectedSlide(prev => prev !== index ? index : false)}
              >
                <div className="manage-template-examination-live-slide">
                  <Slide
                    slide={slide}
                    trimester={trimester}
                    zoomLevel={zoomLevel}
                  />
                </div>
                {selectedSlide === index && (
                  <>
                    <div className="manage-template-examination-live-delete" onClick={(e) => e.stopPropagation()}>
                      <Icon name="close" onClick={() => deleteSlide(index)}/>
                    </div>
                  </>
                )}
                <div
                  className="manage-template-slide-grid-cell-add"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSlideListOpen({insertAfter: index});
                  }}
                >
                  <ButtonAdd
                    align="left"
                  />
                </div>
              </div>
            ))}
            <div className="manage-template-slide-grid-cell add-new">
              <div className="manage-template-examination-live-slide" onClick={() => setSlideListOpen(true)}>
                <ButtonAdd
                  label={__("manageTemplate.addPlane")}
                  align="left"
                />
              </div>
            </div>
          </div>
        </div>
        }

        <div className="manage-template-create-footer">
          <div className="manage-template-grid-warning">
          </div>
          <div className="manage-template-create-cta">
            <Button label={__("manageTemplate.cancel")}
                    variant="outline"
                    onClick={handleCancel}/>
            &nbsp;
            <Button
                    label={__("manageTemplate.save")}
                    onClick={handleUpdate}/>
          </div>
        </div>
      </div>
    </div>
    {!!slideListOpen && (
      <SlideBrowser
        slides={protocolSlides}
        trimester={trimester}
        title={__("manageTemplate.selectSlide")}
        onChange={(slide) => {
          addNewSlides({...slide, techno: slide.default_techno}, {insertAfter: slideListOpen.insertAfter});
          setSlideListOpen(false);
        }}
        onClose={() => setSlideListOpen(false)}
      />
    )}
  </>;
}

export default withTranslation()(PreselectSlideGrid);
