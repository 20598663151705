import { withTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import "./PaginationControls.css"
const PaginationControls = ({ t: __, page, pageSize, total, onPageChange }) => {
  const prevPage = Math.max(page - 1, 1)
  const lastPage = Math.ceil(total/pageSize)
  const nextPage = Math.min(page + 1, lastPage)

  return <div className="pagination-bar">
    <div>
      {__("pagination.page")} {page} / {lastPage}
    </div>
    <div>
      ( {total} {__("pagination.results")}, {pageSize} {__("pagination.perPage")} )
    </div>
    <div>
      <Button
        icon="arrow-left"
        variant="outline"
        disabled={page === 1}
        onClick={() => {onPageChange(prevPage)}}
      />
      <Button
        icon="arrow-right"
        variant="outline"
        disabled={page >= lastPage}
        onClick={() => {onPageChange(nextPage)}}
      />
    </div>
  </div>
}

export default withTranslation()(PaginationControls);
