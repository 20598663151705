import React from "react";
import { setDragAndDropOptions, handleMouseDown } from "../../dragAndDrop";
import "./DragAndDropItem.css";

const DragAndDropItem = ({
  children,
  container = null,
  targetSelector = '',
  createGhost = () => {},
  isDraggable = true,
  direction = "",
  onDragStart = (e, attr) => {},
  onDragHover = (e, attr) => {},
  onDrop = (e, attr) => {},
  onClick = (e, attr) => {},
  onDblClick = (e, attr) => {},
}) => {

  const handleDragMouseDown = (e) => {
    setDragAndDropOptions({
      isDraggable,
      direction,
      container,
      targetSelector,
      createGhost,
      onDragStart,
      onDragHover,
      onDrop,
      onClick,
      onDblClick,
    });
    handleMouseDown(e);
  }

  return (
    React.Children.map(children, child => {
      return React.cloneElement(child, {
        className: child.props.className + (isDraggable ? ' is-draggable' : ''),
        onMouseDown: handleDragMouseDown,
        onTouchStart: handleDragMouseDown,
      });
    })
  );
};

export default DragAndDropItem;
