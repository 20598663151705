import { useState, useEffect } from 'react'
import ResourceApi from '../services/resource';
import { useQuery } from '../utils';

const AuthUplink = () => {
    const query = useQuery()

    const [success, setSuccess] = useState(null);
    const [sites, setSites] = useState([]);
    const [siteId, setSiteId] = useState("");

    useEffect(() => {
        ResourceApi.getSite().then(resp => {
            setSites(resp.data.data);
            if (resp.data.data.length > 0) setSiteId(resp.data.data[0].id);
        })
    }, [])

    const registerUplink = () => {
        ResourceApi.createUplink({ auth_request_token: query.get("auth_request_token"), site_id: siteId })
            .then(resp => setSuccess("Success! You can now close the window."))
            .catch(err => setSuccess("Uplink registration failed."))
    }

    return <div className="container" style={{width: "100vw"}}>
        <div style={{width: "15rem"}}>
            <select style={{width: "100%", marginBottom: "0.5rem"}} value={siteId} onChange={(e) => setSiteId(parseInt(e.target.value))}>
                {sites.map(site => <option key={site.id} value={site.id}>{site.name}</option>)}
            </select>
            {success ? <p>{success}</p> : <button style={{width: "100%"}} onClick={registerUplink}>Register uplink</button>}
        </div>
    </div>
}

export default AuthUplink;
