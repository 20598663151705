import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";

/* Atoms */
import Icon from "../../atoms/Icon/Icon";
import Button from "../../atoms/Button/Button";
import SelectInput from '../../atoms/SelectInput/SelectInput';
import InlineDropdown from "../../atoms/InlineEditing/InlineDropdown/InlineDropdown";
import InlineInput from "../../atoms/InlineEditing/InlineInput/InlineInput";

/* Components */
import SimplePopup from '../Popup/SimplePopup';

/* Services */
import ResourceApi from '../../services/resource';

/* CSS */
import "../../atoms/SimpleTable/SimpleTable.css";
import "./FetusesTable.css"


const FetusesTable = ({ t: __, episode, examination, fetusSexVisibility, updateExaminationFetus, createEpisodeFetus, updateEpisodeFetus, deleteEpisodeFetus, canEdit = true}) => {

  const [popedUpFetus, setPopedUpFetus] = useState(null);
  const [editingFetus, setEditingFetus] = useState(null);
  useEffect(() => {
    if(popedUpFetus) {
      const fetus = episode.fetuses?.find((f) => f.id == popedUpFetus?.id)
      const examination_fetus = examination.fetuses?.find((f) => f.id == popedUpFetus.examination_fetus?.id)
      setPopedUpFetus({ ...fetus, examination_fetus })
    }
  }, [examination, episode])

  if(!episode || !episode.fetuses)
    return null

  const onAddFetus = () => {
    const max_internal_id = Math.max.apply(null, episode.fetuses.map(({ internal_id}) => internal_id).concat(0))
    const max_non_deleted_internal_id = Math.max.apply(null, episode.fetuses.filter((f) => f.state != "deleted").map(({ internal_id}) => internal_id))
    const max_non_deleted_fetus = episode.fetuses.find(( {internal_id} ) => internal_id == max_non_deleted_internal_id)

    const internal_id = max_internal_id + 1;
    const label = max_non_deleted_fetus ? String.fromCharCode(max_non_deleted_fetus.label[0].charCodeAt() + 1) : "A"

    
    createEpisodeFetus({
      internal_id,
      label,
    })
  }

  const fetuses = episode.fetuses
    .map((f) => ({
      ...f,
      examination_fetus: examination.fetuses.find((ef) => ef.fetus_id == f.id)
    }))
    .filter(({ state, examination_fetus }) => ! (state == "deleted"  && !examination_fetus) )
    .sort((f1, f2) => f1.label.localeCompare(f2.label))

  const displayFullInformation = (f) => {
    setPopedUpFetus(f)
  }

  const onChangeExaminationFetus = (f, params) => {
    if(!f)
      return
    return updateExaminationFetus(f, params)
  }

  const onChangeEpisodeFetus = (f, params) => {
    if(!f)
      return
    return updateEpisodeFetus(f, params)
  }

  const fetusSexOptions = [
    {label: __("fetusesTable.sex."), value: null},
    {label: __("fetusesTable.sex.unknown"), value: "unknown"},
    {label: __("fetusesTable.sex.male"), value: "male"},
    {label: __("fetusesTable.sex.female"), value: "female"},
  ]

  const onStartEditingDemisedDate = (fetus) => {
    setEditingFetus(fetus)
  }

  const onChangeDemisedDate = (f) => {
    return (demised_date) => {
      setEditingFetus(null)
      updateEpisodeFetus(f, { demised_date })
    }
  }

  const onChangeFetusSex = (fetus) => {
    return (_, option) => {
      updateEpisodeFetus(fetus, { sex: option.value })
    }
  }

  return (
    <>
    <div>
    {
      popedUpFetus ? (
        <SimplePopup
          onBackgroundClick={() => setPopedUpFetus(null)}
      >
          <h1>{ __("fetusesTable.popup", { fetusName: popedUpFetus.label }) }</h1>
          {
            fetusSexVisibility != "hidden" && !!popedUpFetus.examination_fetus ? (
              <>
              <div className="flex vertical-center justify-center">
                <div className="mr-small">
                  {__("fetusesTable.popup.sexSection", { fetusName: popedUpFetus.label })}
                </div>
                <SelectInput
                  value={popedUpFetus?.sex}
                  options={fetusSexOptions}
                  onChange={onChangeFetusSex(popedUpFetus)}
                  fullwidth={true}
                />
              </div>
              <hr className="small no-line"/>
              { fetusSexVisibility == "masked" ? (
                  <div className="disclaimer">
                    <Icon name="warning" /> {__("medicalhistory.fetusSex.disclaimer")}
                  </div>
              ) : null
              }
              <hr/>
              </>
            ) : null
          }
          {
            popedUpFetus.state == "active" ? (
            <>
              <Button variant="outline" icon="warning" label={__("fetusesTable.popup.declareAsDemised")} onClick={() => updateEpisodeFetus(popedUpFetus, { state: "demised" })}/>
            </>
            ) : null
          }
          {
            popedUpFetus.state == "demised" ? (
            <>
              <Button variant="outline" icon="error" label={__("fetusesTable.popup.declareAsNonDemised")} onClick={() => updateEpisodeFetus(popedUpFetus, { state: "active" })}/>
            </>
            ) : null
          }
        </SimplePopup>
      ) : null
    }
    </div>
    <table className="simple-table fetus-table">
      <thead>
        <tr>
          <th>{__("fetusesTable.label")}</th>
          {
            fetusSexVisibility == "visible" ? (
              <th>{__("fetusesTable.sex")}</th>
            ) :
              null
          }
          <th>{__("fetusesTable.dicomId")}</th>
          <th className="no-right-border">{__("fetusesTable.information")}</th>
          <th className="no-right-border no-left-border"></th>
          <th className="no-right-border no-left-border"></th>
        </tr>
      </thead>
      <tbody>
        {
          fetuses.map((f) => (
            <tr key={f.id} className={( f.state != "demised" ) ? "" : "fetus-table-disabled-row"}>
              <td>
                <InlineInput
                  value={f.label}
                  format={"string"}
                  active={canEdit}
                  onChange={(label) => onChangeEpisodeFetus(f, { label })}
                />
              </td>
              {
                fetusSexVisibility == "visible" ? (
                  <td>
                    <InlineDropdown
                      value={examination.frozen ? f?.examination_fetus?.sex : f?.sex}
                      options={fetusSexOptions}
                      active={canEdit}
                      onChange={onChangeFetusSex(f)}
                    />
                  </td>
                ) : (
                  null
                )
              }
              <td>
                {f.examination_fetus ? (
                  <InlineInput
                    value={f?.examination_fetus?.dicom_id}
                    format={"number"}
                    active={canEdit}
                    onChange={(dicom_id) => updateExaminationFetus(f?.examination_fetus, { dicom_id }) }
                  />
                ) : "-"}
              </td>
              <td>
                {f.state == "demised" ? (
                  <>
                  {__("fetusesTable.demised_date_title")}
                  <InlineInput
                    value={f.demised_date || ""}
                    format={"date"}
                    active={canEdit}
                    onStartEditing={() => onStartEditingDemisedDate(f)}
                    onChange={onChangeDemisedDate(f)}
                  />
                  </>
                ) : (
                  " - "
                )
                }
              </td>
              <td className="actionable">
                {canEdit &&
                    <Icon className="clickable" name="edit" onClick={() => displayFullInformation(f)} />
                }
              </td>
              <td className="actionable">
                {canEdit && f.deletable &&
                    <Icon className="clickable" name="trash" onClick={() => deleteEpisodeFetus(f)}/>
                }
              </td>
            </tr>
          ))
        }

        {canEdit && (
          <tr>
            <td colSpan={6}>
              <div className="row cursor">
                <div className="w12 flex justify-center add-fetus">
                  <Button
                    icon="add"
                    onClick={onAddFetus}
                    label={__("fetusesTable.addFetus")}
                    variant="outline"
                    size="small"
                  />
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
    </>
  )
}

export default withTranslation()(FetusesTable);
