import { getInstanceThumbnailUri } from "../../../../utils";
import { placeholderIdFromProps } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateImagesBody({ props, placeholder = {}, base64Images = [], setBase64Images, reportMode }) {
  let { columns } = (props || {});
  let { mediaIds } = (placeholder || {});

  if (!mediaIds) mediaIds = [];

  if (!columns) columns = mediaIds.length;
  columns = Number(columns);

  const mediaIdOnColumns = mediaIds.filter(m => m).reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / columns);
    resultArray[chunkIndex] = resultArray[chunkIndex] || new Array(columns).fill(false);
    resultArray[chunkIndex][index % columns] = item;
    return resultArray;
  }, []);

  const getBase64Image = async (mediaId) => {
    const img = document.createElement("img");
    img.addEventListener("load", () => getBase64(img, mediaId));
    img.src = getInstanceThumbnailUri(mediaId);
  }

  const getBase64 = (img, mediaId) => {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    setBase64Images(imgs => {
      imgs[mediaId] = canvas.toDataURL("image/png");
      return imgs;
    });
  }

  /* Only load if we are not printing */
  if (reportMode === 'edit') {
    mediaIds.map(mediaId => {
      if (mediaId && !base64Images[mediaId]) getBase64Image(mediaId);
    });
  }

  return (<div className="images">
    {mediaIdOnColumns.map((ids, index) => (
      <div key={index} className="block" data-layout="columns">
        {ids.map(mediaId => (
          <div key={mediaId} className="block">
            {!mediaId ? false : <img key={mediaId} src={getInstanceThumbnailUri(mediaId)} />}
          </div>
        ))}
      </div>
    ))}
  </div>);
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateImages({
  props,
  placeholders,
  ...otherProps
}) {
  const fieldId = placeholderIdFromProps(props);

  return (
    <PlaceholderLoader
      Component={ReportTemplateImagesBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, "fetus.number"]}
      fetusNumberPlaceholder={placeholders["fetus.number"]}
      placeholder={placeholders[fieldId]}
      props={props}
      {...otherProps}
    />
  );
};
