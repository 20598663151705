import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { convertValueToSelectedUnit } from "../../../../unitConverter";
import BiometryGraph from "../../../BiometryGraph";
import Icon from "../../../../atoms/Icon/Icon";
import NotVisibleOverlay from "../../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";

import {
  measurementCurvePlaceholderId,
  isMeasurementCurveVisible,
  measurementCurve,
  measurementCruveSlug,
  measurementCurveRequiredPlaceholders,
  isMeasurementCurveEmpty,
  measurementCurveData,
} from "../../utils";

import PlaceholderLoader from "../../PlaceholderLoader";

const ReportTemplateMeasurementCurveBody = ({
  t: __,
  props,
  placeholders,
  editingFieldId,
  startEditingField,
  reportMode,
  onEndEditing,
  reportDataOptions,
  showOptions = null,
}) => {
  const fieldId = measurementCurvePlaceholderId(props);
  const curveSlug = measurementCruveSlug(props, placeholders);
  const curve = measurementCurve(props, placeholders);
  const originalPlaceholder = placeholders[fieldId]
  const { storedUnit, displayUnit, yAxis, xAxis, isAtRisk, allMeasurements, fetus, placeholder } = measurementCurveData(props, placeholders, reportDataOptions);

  const [isEditing, setIsEditing] = useState(false);
  // TODO understand this line as originalPlaceholder should be an array
  showOptions = reportMode === "edit" && (showOptions ?? originalPlaceholder?.showOptions ?? true);

  const { data, percentile = "%", decimals = "2", units: userUnits } = props;
  const visible_graph = isMeasurementCurveVisible(props, placeholders);

  useEffect(() => {
    if (editingFieldId !== data) setIsEditing(false);
  }, [editingFieldId, data]);


  if (!allMeasurements?.length || !allMeasurements[0]?.length) return false;
  const toggleEditing = () => {
    if (!!placeholder?.editor && editingFieldId !== data) {
      startEditingField(data, props);
      setIsEditing(true);
    }
  };

  const toggleVisible = (e) => {
    e.stopPropagation();
    onEndEditing(`measurement.${props.data}`, { visible_graph: !visible_graph, fetus });
  };


  return isMeasurementCurveEmpty(props, placeholders) ? <div className="measurement-curve empty" /> : (
    /* don't change "measurement-curve not-visible" order because it impacts on visibility inside the <graphs> component */
    <div className={`
      exam-report-editing-field measurement-curve ${visible_graph ? 'is-visible' : 'not-visible'}
      ${visible_graph ? 'has-printable-value' : ''}
      ${!!placeholder?.editor ? 'is-editable' : ''}
      ${isEditing && editingFieldId === data ? 'editing' : ''}
    `} onClick={toggleEditing}>
      <div className="measurement-curve-label">{placeholder.editedLabel || placeholder.label}</div>
      <div className={`measurement-curve-values ${isAtRisk ? 'highlight' : ''}`}>
        <div className="measurement-curve-value">
          {placeholder.value && (
            <>
              {convertValueToSelectedUnit(Number(placeholder.value), storedUnit, displayUnit).toFixed(Number(decimals))} {displayUnit}
            </>
          )}
        </div>
        <div className="measurement-curve-percentile">
          {placeholder.sonio_percentile && (
            <>
              {percentile === "%" && (
                <>
                  <span className="biometry-item-percentile-value percent">{placeholder.sonio_percentile || '< 1'}</span>
                  {placeholder.abbreviation}
                </>
              )}
              {percentile === "zscore" && (
                <>
                  <span className="biometry-item-percentile-value zscore">{placeholder.sonio_zscore?.toFixed(2)}</span>
                  &nbsp;{__("report.zscore")}
                </>
              )}
            </>
          )}
          <span className="measurement-curve-growth-standard">{curveSlug.slice(curveSlug.lastIndexOf(".") + 1).toUpperCase()}</span>
        </div>
      </div>
      <div className="measurement-curve-graph">
        <BiometryGraph
          xAxis={xAxis}
          yAxis={yAxis}
          equations={curve}
          measurements={allMeasurements}
          atRisk={isAtRisk}
        />
      </div>
      {editingFieldId === data && isEditing && placeholder.editor({ ...placeholder, editingGraph: true, decimals, userUnits, defaultUnit: storedUnit, curveSlug, availableCurveSlugs: placeholder.availableCurveSlugs })}
      {visible_graph && showOptions && (
        <div className="content-options exam-report-editing-options" >
          <div onClick={toggleVisible}>
            <Icon name={visible_graph ? "eye" : "eye-off"} />
          </div>
        </div>
      )}
      {!visible_graph && reportMode === "edit" && <NotVisibleOverlay onClick={toggleVisible} />}
    </div>
  );
};

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateMeasurementCurve({
  props,
  placeholders,
  ...otherProps
}) {
  const requiredPlaceholders = measurementCurveRequiredPlaceholders(props, placeholders);

  return (
    <PlaceholderLoader
      Component={withTranslation()(ReportTemplateMeasurementCurveBody)}
      placeholders={placeholders}
      props={props}
      requiredPlaceholders={requiredPlaceholders}
      {...otherProps}
    />
  );
};
