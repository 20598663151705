import { useContext, useEffect, useState } from "react";
import { DxContext } from "../../../context-providers/Dx";
import { ExaminationContext } from "../../../context-providers/Examination";
import DiagnosticPanelView from "./DiagnosticPanelView";

const DiagnosticPanel = ({}) => {
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);

  return (
    <DiagnosticPanelView
      // Filter the malformations by zone if we are not in the semiology workflow.
      // Otherwise some malformations do not show up in semiology worflow (ex: Spina bifida, when selecting Arnold chiari)
      malformations={dxContext.recommendation?.malformations?.filter(
        (m) =>
          !!dxContext.semiologyWorkflowStack.length ||
          (!examinationContext.examination?.zones?.find(zone => zone.id === dxContext.currentZoneId && zone.status === "validated") && m.exam_zone_id === dxContext.currentZoneId)
      ).map(m => ({...m, status: m.status || examinationContext.examination.malformations?.find(item => item.id === m.id)?.status || "no"})) || []}
      signs={
        dxContext.recommendation?.signs ? dxContext.recommendation.signs : []
      }
    />
  );
};

export default DiagnosticPanel;
