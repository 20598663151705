import { withTranslation } from "react-i18next";
/* Atoms */
import Icon from "../../../atoms/Icon/Icon";
import InlineMultiselect from '../../../atoms/InlineEditing/InlineMultiSelect/InlineMultiSelect';
/* Context */
import NotVisibleOverlay from "../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay";
import SelectInput from "../../../atoms/SelectInput/SelectInput";

const STATUSES_OPTIONS = [
  { id: "usual", value: "usual", label: "Normal" },
  { id: "unusual", value: "unusual", label: "Abnormal" },
  { id: "non_visualized", value: "non_visualized", label: "Non visualized" },
  { id: "suboptimal", value: "suboptimal", label: "Suboptimal" },
  { id: "previously_documented", value: "previously_documented", label: "Previously documented" },
]

const ReportTemplateChecklist = ({ t: __, props, placeholders, getPlaceholderWithProps, checklistItems = [], zones = [], onEndEditing, onEndEditingChecklist, fetus = 0, reportMode = "edit", showOptions = true }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationFetusIds = getPlaceholderWithProps({ data: 'fetus.examination_id' }).value || [];
  const examination_fetus_id = examinationFetusIds[fetus]?.id

  const checklistItemsOfFetus = checklistItems.map((items) => items[fetus])

  const saveChange = (id, updates) => {
    let fetusUpdates = [];
    fetusUpdates[fetus] = updates;
    onEndEditing(`checklist.item.${id}`, fetusUpdates);
  }

  const alternativeStatuses = {
    "unusual": ["not-normal", "not_normal"],
    "usual": ["normal"],
    "non_visualized": ["not-viewed", "not_viewed", "non-viewed", "non_viewed", "not-visualized", "not_visualized"],
    "previously_documented": ["previously-documented", "documented"],
    "suboptimal": [],
  }

  const normalizeStatus = (status) => {
    if (alternativeStatuses[status]) return status;
    return Object.entries(alternativeStatuses).find(([key, value]) => value.includes(status))?.[0];
  }

  const filterByStatus = props.status ? normalizeStatus(props.status) : false;

  const matchesStatus = (item, status) => {
    return item?.status && (item.status === status || alternativeStatuses[item.status]?.includes(status));
  }

  const matchesZone = item => {
    const currentZone = zones.find(z => z.label[currentLanguage].toLowerCase() === props.zone?.toLowerCase())
    return currentZone?.id === item.exam_zone_id
  }

  const isInRequiredItems = item => props.items?.split("|").includes(item.slug)

  const filteredItems = checklistItemsOfFetus.filter(item => (
    !!item
    && (!props.status || matchesStatus(item, filterByStatus))
    && (!props.items || isInRequiredItems(item, filterByStatus))
    && (!props.zone || matchesZone(item, filterByStatus))
    && (item.examination_fetus_id == examination_fetus_id)
  )).map(item => ({
    ...item,
    visible: placeholders[`checklist.item.${item.slug}`]?.[fetus]?.visible ?? true,
    placeholder: placeholders[`checklist.item.${item.slug}`],
  }));

  const itemsToDisplay = props.items
    ? props.items?.split("|")
      .map(itemSlug => filteredItems.find(item => item.slug === itemSlug))
      .filter(item => item && (reportMode !== "print" || (reportMode === "print" && item.visible)))
    : filteredItems;

  const markAllAs = async (newStatus) => {
    onEndEditingChecklist(newStatus, itemsToDisplay);
  }

  return (!!itemsToDisplay.length &&
    <div className="checklist checklist_v1">
      {itemsToDisplay.map(item => (
        <span
          key={item.id}
          className={`${filterByStatus ? '' : item.status} ${item.visible ? 'has-printable-value' : 'not-visible'} exam-report-editing-field checklist-item`}
        >
          {
            <InlineMultiselect
              compactMode
              printable
              compactModeLabel={item.label?.[currentLanguage]}
              options={STATUSES_OPTIONS}
              onChange={(value) => onEndEditingChecklist(value, item)}
              value={filterByStatus}
              disabled={reportMode === "print"}
            />
          }
          {item.children?.length && (
            <span className="children">
              {item.children.map(child =>
                <span key={child.id}>
                  <Icon name="right" />
                  {child.label?.[currentLanguage]}
                </span>
              )}
            </span>
          )}
          {item.visible && reportMode === "edit" && showOptions && (
            <div className="checklist-options exam-report-editing-options">
              <div onClick={() => saveChange(item.slug, { visible: !item.visible })}>
                <Icon name={item.visible ? "eye" : "eye-off"} />
              </div>
            </div>
          )}
          {!item.visible && reportMode === "edit" && <NotVisibleOverlay onClick={() => saveChange(item.slug, { visible: !item.visible })} />}
        </span>
      )).reduce((prev, curr) => [prev, prev.length > 0 ? ', ' : '', curr], [])}
      {!!props.status && (
        <div className="exam-report-editing-options">
          <div>
            <span>{__("report.markAllAs")}</span>
            <SelectInput
              options={STATUSES_OPTIONS}
              value={normalizeStatus(props.status)}
              onChange={(value) => markAllAs(value)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default withTranslation()(ReportTemplateChecklist);
