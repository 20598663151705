import ReportTemplateSearch_v1_0 from './Search/v1.0.js';
import ReportTemplateSearch_v1_1 from './Search/v1.1.js';

export default function ReportTemplateSearch(props) {
  const {apiVersion} = props;
  if (apiVersion === '1.0') {
    return ReportTemplateSearch_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateSearch_v1_1(props);
  }
  return null;
};
