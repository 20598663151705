import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ExaminationContext } from "./Examination";
import { XMLTemplateContext } from "./XMLTemplate";
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import ResourceApi from "../services/resource";
import { getUniqueId } from "../utils";
import useAuth from "./Auth";
export const AmbientListeningContext = createContext({});

const SILENCE_THRESHOLD_DB = -60;
const CHUNK_TIMESLICE = 2000; // send an audio chunk every x ms
let chunkHasSound = false;
let audioContext = null;
let analyserIsCapturing = false;

export const AmbientListeningContextProvider = ({ children }) => {
  const examinationContext = useContext(ExaminationContext);
  const XMLTemplate = useContext(XMLTemplateContext);
  const { isFeatureFlagEnabled, user } = useAuth();

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [mediaRecorderStream, setMediaRecorderStream] = useState(null);

  const [isRecording, setIsRecording] = useState(false);
  const [transcript, setTranscript] = useState("");
  const [reportChanges, setReportChanges] = useState("");
  const [sessionId, setSessionId] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [highlightSupportedFields, setHighlightSupportedFields] = useState(null);
  
  const canRecord = useMemo(() => isFeatureFlagEnabled('sonio.ambient_listening') && examinationContext.examination.nb_fetus, [isFeatureFlagEnabled('sonio.ambient_listening'), examinationContext.examination.nb_fetus]);
  const [supportedFields, setSupportedFields] = useState([]);

  // TODO: find a dynamic way to get the list of macros
  const macroFields = [
    "examination.method",
    "examination.consultation_macros_san_jose",
    "examination.consultation_macro_san_jose",
    "examination.follow_up_san_jose"
  ];

  const [chunks, setChunks] = useState();

  useEffect(() => {
    connect().then(r => register(r));
  }, []);

  useEffect(() => {
    if (canRecord) {
      ResourceApi.getListenSupportedFields().then((response) => {
        setSupportedFields(response?.data?.supported_fields_id || []);
      });
    } else {
      setSupportedFields([]);
    }
  }, [canRecord]);

  const startNewTranscriptionSession = async () => {
    if (sessionId) {
      setTimeout(() => {
        ResourceApi.closeListenSession(sessionId);
      }, CHUNK_TIMESLICE);
    }
    const sId = `${user.id}_${examinationContext.examination.id}_${Date.now()}_${getUniqueId()}`;
    setSessionId(sId);
    setChunks([]);
    await ResourceApi.openListenSession(sId);
    return sId;
  }

  const endCurrentTranscriptionSession = (sId) => {
    if (sessionId || sId) {
      setTimeout(() => {
        ResourceApi.closeListenSession(sessionId || sId).then(response => {
          storeAndProcessTranscription(response, sessionId || sId);
        });
      }, CHUNK_TIMESLICE);
      setSessionId(false);
    }
  }

  const detectSound = useCallback((analyser) => {
    if (!analyser) return;
    const domainData = new Uint8Array(analyser.frequencyBinCount);
    analyser.getByteFrequencyData(domainData);
    const maxVolume = Math.max( ...domainData );
    setIsSpeaking(maxVolume);
    if (maxVolume > 0) chunkHasSound = true;
    if (analyserIsCapturing) window.requestAnimationFrame(() => detectSound(analyser));
  }, []);

  const onDataAvailable = useCallback(async (e) => {
    if (chunkHasSound) {
      let sId = sessionId || await startNewTranscriptionSession();
      setChunks(c => [...c, e.data]);

      // send chunk to the BE
      const blob = e.data;
      blobToBase64(blob).then(b64chunk => {
        ResourceApi.pushListenAudioChunk(sId, b64chunk.substr(b64chunk.indexOf('base64,') + 7)).then(response => {
          storeAndProcessTranscription(response, sId);
        });
      });

      chunkHasSound = false;
    } else {
      if (mediaRecorder) endCurrentTranscriptionSession();
    }
  }, [sessionId, endCurrentTranscriptionSession]);

  const processChunks = async (wavFiles = []) => {
    const sId = !!wavFiles.length ? await startNewTranscriptionSession() : false;
    if (sId && wavFiles?.length) {
      setChunks(wavFiles);
      for (var wavFile of wavFiles) {
        const b64wav = await blobToBase64(wavFile);
        const response = await ResourceApi.pushListenAudioChunk(sId, b64wav.substr(b64wav.indexOf('base64,') + 7));
        storeAndProcessTranscription(response, sId);
      }
      endCurrentTranscriptionSession(sId);
    }
  }

  const blobToBase64 = async (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  const storeAndProcessTranscription = (response, sessionId) => {
    const transcript = response?.data;
    if (transcript.type === "final_transcript") {
      setTranscript(transcript.transcript);
      if (transcript.transcript) applyTranscriptToReport(transcript.transcript, sessionId);
      endCurrentTranscriptionSession();
    }
  }

  /** manage start and stop recording */
  useEffect(() => {
    if (!isFeatureFlagEnabled("sonio.ambient_listening")) return;

    if (mediaRecorder) {
      analyserIsCapturing = false;
      mediaRecorderStream?.getTracks().forEach(track => {
        track.stop();
      });
      mediaRecorder.stop();
      setMediaRecorder(null);
    }

    if (isRecording) {
      if (!mediaRecorder && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
        .getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            volume: 1,
            channelCount: 1, // also defined in listen.ex
          }
        })
        .then((stream) => {
          startNewTranscriptionSession();
          setMediaRecorderStream(stream);
          const newMediaRecorder = new MediaRecorder(stream, { mimeType: "audio/wav" });
          setMediaRecorder(newMediaRecorder);
          audioContext = new AudioContext()
          const analyser = audioContext.createAnalyser();
          const audioStreamSource = audioContext.createMediaStreamSource(stream);
          analyser.minDecibels = SILENCE_THRESHOLD_DB;
          audioStreamSource.connect(analyser);
          
          newMediaRecorder.start(CHUNK_TIMESLICE);
          analyserIsCapturing = true;
          window.requestAnimationFrame(() => detectSound(analyser));
        })
        .catch((err) => {
          console.error(`The following getUserMedia error occurred: ${err}`);
        });
      }
    } else {
      endCurrentTranscriptionSession();
    }
  }, [isRecording]);
  
  useEffect(() => {
    if (mediaRecorder) mediaRecorder.ondataavailable = onDataAvailable;
  }, [mediaRecorder, sessionId])

  const startRecording = () => {
    setTranscript("");
    setReportChanges({});
    setIsRecording(true);
  }

  const stopRecording = () => {
    setIsRecording(false);
    return transcript;
  }

  const toggleRecording = () => {
    isRecording ? stopRecording() : startRecording();
  };

  const flatDropdownTree = (node) => {
    let options = [];
    if (!node) return options;
    for(const child of node) {
      options.push({...child, tree: false});
      if (!!child.tree?.length) {
        options = [...options, ...flatDropdownTree(child.tree)];
      }
    }
    return options;
  }

  const getAvailableFields = async() => {
    let fields = {};
    let allFields = Object.entries({...XMLTemplate.placeholders, ...XMLTemplate.customPlaceholders});
    let shouldResetAllFields = false;
    
    allFields.forEach(async ([slug, field]) => {
      if (!supportedFields.includes(slug)) return fields;
      if (field?.isDynamic) {
        await XMLTemplate.loadDynamicDropdownFullTree(slug);
        shouldResetAllFields = true;
      }  
    });  

    if (shouldResetAllFields) {
      allFields = Object.entries({...XMLTemplate.placeholders, ...XMLTemplate.customPlaceholders});
    }  

    fields = allFields.reduce((fields, [slug, field]) => {
      if (!supportedFields.includes(slug)) return fields;
      
      if (field) {
        fields[slug] = {
          label: field.label ?? field[0]?.label ?? "",
          synonyms: field.synonyms ?? field[0]?.synonyms ?? [],
          value_type: macroFields.includes(slug) ? "exact" : "semantic",
          options: flatDropdownTree(field.tree ?? field[0]?.tree)?.map(option => [option.label || "None", `${option.id}` || "none"]) ?? [],
          multiple: field.format === "multiple",
        };
      }
      return fields;
    }, {});

    return fields;
  }

  const getAvailableQuickReports = () => {
    if (isFeatureFlagEnabled("sonio.quick_reports")) {
      return XMLTemplate.reportDataOptions?.automation_templates?.map(t => t.name) || [];
    }
    return [];
  }

  const applyTranscriptToReport = async (optimisticTranscript, sessionId) => {
    if (examinationContext.examination.id && (optimisticTranscript || transcript)) {
      const params = {
        detected_text: optimisticTranscript || transcript,
        report_fields: await getAvailableFields(),
        quick_reports: getAvailableQuickReports(),
        request_id: sessionId,
      }

      ResourceApi.analyseTranscript(params).then(async (response) => {
        setReportChanges(response.data);
        applyChangesToReport(response.data);
      });
    }
  }

  const applyChangesToReport = async (responseData) => {
    const { data: values, quick_reports, request_id: session_id } = responseData || reportChanges || {};
    
    const availableFields = await getAvailableFields();

    const changes = Object.entries(values).map(([slug, value]) => {
      if (!supportedFields.includes(slug)) return false;
      
      if (!macroFields.includes(slug)) {
        let realValue = value?.[0] && availableFields[slug]?.options?.find(option => option.includes(value[0]))?.[1];

        if (realValue) {
          if (realValue === "none") realValue = "";
          return [
            slug,
            {
              value: [`${realValue}`,`${realValue}`],
              source: "ambientListening",
            }
          ];
        }
      }
      
      return false;
    }).filter(change => change);

    if (changes.length) {
      XMLTemplate.applyChanges(Object.fromEntries(changes), {});
    }

    // macros
    Object.entries(values).forEach(async ([slug, value]) => {
      if (!supportedFields.includes(slug)) return false;

      if (macroFields.includes(slug)) {
        const availableFields = await getAvailableFields();
        let optionSlug = availableFields[slug]?.options?.find(option => option.includes(value[0]))?.[1];
        const placeholder = XMLTemplate.getPlaceholderWithProps({data: slug});
        const previousValue = placeholder?.value || {};
        if (placeholder.isDynamic) {
          return [
            slug,
            XMLTemplate.onEndEditingDynamicDropdown(slug, {
              fetus: null,
              value: {
                ...previousValue,
                [optionSlug]: {
                  value: true,
                  label: value[0],
                  description: false,
                  order: Object.keys(previousValue).length,
                }
              },
              source: "ambientListening",
            })
          ];

        } else {
          const placeholderOptions = flatDropdownTree(placeholder.tree);
          const placeholderOption = placeholderOptions.find(option => option.id === optionSlug);
          
          return [
            slug,
            XMLTemplate.onEndEditing(slug, {
              fetus: null,
              value: {
                ...previousValue,
                [optionSlug]: {
                  ...placeholderOption,
                  order: Object.keys(previousValue).length,
                }
              },
              source: "ambientListening",
            })
          ];

        }
      }
    });


    // flash reports
    if (isFeatureFlagEnabled("sonio.quick_reports") && quick_reports?.length) {
      for (const quick_report of quick_reports) {
        const slug = XMLTemplate.reportDataOptions?.automation_templates?.find(qr => qr.name === quick_report)?.slug;
        if (slug) {
          XMLTemplate.applyAutomationTemplate(slug);
        }
      }
    }
  }

  const editedByAmbientListening = useMemo(() => {
    return canRecord
      ? Object.fromEntries(Object.entries({...XMLTemplate.placeholders, ...XMLTemplate.customPlaceholders}).filter(([slug, placeholder]) => placeholder?.source === "ambientListening"))
      : {};
  }, [XMLTemplate.placeholders, XMLTemplate.customPlaceholders, canRecord]);

  useEffect(() => {
    const supportedLocalhost = `${localStorage.getItem("ambient_documentation.highlight_supported")}` === "true";
    setHighlightSupportedFields(supportedLocalhost);
  }, [supportedFields]);

  useEffect(() => {
    if (highlightSupportedFields !== null) localStorage.setItem("ambient_documentation.highlight_supported", highlightSupportedFields);
    XMLTemplate.setHighlightedFields && XMLTemplate.setHighlightedFields(fields => highlightSupportedFields
      ? supportedFields.map(field => ({
        slug: field,
        icon: "microphone",
        iconClass: Object.keys(editedByAmbientListening).some(slug => slug === field) ? 'selected' : '',
        source: 'ambientListening'
      }))
      : fields.filter(field => field.source !== 'ambientListening')
    )
  }, [highlightSupportedFields, supportedFields]);
  
  
  return (
    <AmbientListeningContext.Provider
      value={{
        canRecord,
        startRecording,
        stopRecording,
        toggleRecording,
        isRecording,
        isSpeaking,
        supportedFields,
        transcript,
        setTranscript,
        getAvailableFields,
        applyTranscriptToReport,
        reportChanges,
        setReportChanges,
        applyChangesToReport,
        editedByAmbientListening,
        highlightSupportedFields,
        setHighlightSupportedFields,
        chunks,
        processChunks,
        sessionId,
      }}
    >
      {children}
    </AmbientListeningContext.Provider>
  );
};
