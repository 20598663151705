import React from "react";
import "./index.css";

function flagSrc(country) {
    return `/images/flags/${country?.toUpperCase()}.svg`
}

export default function CountryFlag({country}) {
    return (
        <div className="country-container">
            <div className="country-flag-wrapper"><img src={flagSrc(country)} alt={country}/></div>
            <div>{country?.toUpperCase()}</div>
        </div>
    )
}
