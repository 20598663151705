import { useContext, useMemo } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../../atoms/Button/Button";
import ProgressRing from "../../../atoms/ProgressRing/ProgressRing";
import { AppContext } from "../../../context-providers/App";
import { ExamStatus } from "../../../config";
import { compareDates } from "../../../utils";
import { ExaminationContext } from "../../../context-providers/Examination";
import useAuth from "../../../context-providers/Auth";
import Icon from "../../../atoms/Icon/Icon";
import "./ExamCompletedBanner.css";

const ExamCompletedBanner = ({ t: __, medias, unusualMedias, trimester, instanceViews, setOpenPanel, setShownPlanes, setIsPrintPreviewOpen, setIsSharingPreviewOpen }) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();

  const featuredSlides = useMemo(() => instanceViews.filter(instance => instance.type === "picture" && appContext.isSlideInDefaultCategory(instance, trimester))
    .reduce((sum, instance) => {
      // multi-instance support
      if (!sum[instance.id]) sum[instance.id] = { ...instance, medias: 0 };
      sum[instance.id].medias += Number(instance.medias[trimester]);
      return sum;
    }, []).flat(), [instanceViews, appContext.preferences.default_category], trimester);

  const completedFeaturedSlides = featuredSlides.filter(slide => slide.mediaIds.length >= slide.medias);

  return (
    <div className="exam-completed-banner">
      <div className="exam-completed-banner-stats">
        {trimester !== "ND" ? (
          <>
            {!!featuredSlides.length && (
              <>
                <div className={`exam-completed-banner-stat ${completedFeaturedSlides.length < featuredSlides.length ? "ko" : "ok"}`}>
                  <div className="exam-completed-banner-stat-graph">
                    <ProgressRing
                      progress={Math.min(100, 100 / featuredSlides.length * completedFeaturedSlides.length)}
                      stroke="1"
                      color={completedFeaturedSlides.length < featuredSlides.length ? "ocra" : "blue"}
                    />
                  </div>
                  <label>
                    <b>{`${completedFeaturedSlides.length} / ${featuredSlides.length}`}</b><br />
                    {__("examination.stats.takenFeaturedViewsVsExpected", { category: __(`examination-plane.category.${appContext.preferences.default_category}`) })}
                  </label>
                </div>
                <div className={`exam-completed-banner-stat unusual ${unusualMedias.length > 0 ? "ko" : "ok"}`} onClick={() => setShownPlanes("unusual")}>
                  <label>
                    <b>{unusualMedias.length}</b><br />
                    {__(unusualMedias.length === 1 ? "examination.stats.unusualMedia" : "examination.stats.unusualMedias", { category: __(`examination-plane.category.${appContext.preferences.default_category}`) })}
                  </label>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="exam-completed-banner-text">
            {medias.length} {__("examination.stats.takenMedias")}
          </div>
        )}
      </div>
      {!examinationContext.canEdit && 
        <div className="exam-completed-banner-editexam">
          <Icon name="lock_closed" /> {__("examinationReview.examIsLocked")}
          {examinationContext.canUnfreeze && <>
            {__(":")} 
            <Button variant="link" size="small" label={__("examinationReview.editExam")} onClick={() => examinationContext.reopenExamination()}/>
          </>}
        </div>
      }
      <div className="exam-completed-banner-cta">

        <Button
          label={__("examinationReview.readTheReport")}
          icon="stats"
          onClick={() => setOpenPanel("stats")}
        />
        {isFeatureFlagEnabled("sonio.share") && trimester !== "ND" && <>
          <Button
            label={__("examinationReview.print")}
            icon="print"
            variant="outline"
            onClick={() => setIsPrintPreviewOpen(true)}
          />
          <Button
            label={__("examinationReview.share")}
            icon="share"
            variant="outline"
            onClick={() => setIsSharingPreviewOpen(true)}
          />
        </>}
      </div>
    </div>
  )
}

export default withTranslation()(ExamCompletedBanner);