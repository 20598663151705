import { useContext, useMemo } from "react";
import { withTranslation } from "react-i18next";

/* Contexts */
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from "../../context-providers/Examination";

import SelectInput from "../../atoms/SelectInput/SelectInput";

const ExamPresetSelector = ({t: __}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);

  const onSelectPreset = (id) => {
    examinationContext.setPreset(id);
  }

  const options = useMemo(() => {
    return (appContext.displayedPresets || [])
      .map((preset_id) => (appContext.allPresets || []).find(({id}) => preset_id == id))
      .filter((p) => p)
      .sort((a,b) => {
        if (a.category === "OB" && b.category !== "OB") return -1;
        if (a.category === "GYN" && b.category !== "GYN") return 1;
        return a.category.localeCompare(b.category)
      })
      .reduce((options, preset) => {
        const acc = [...options];
        const categoryExists = acc.some(p => p.type === "heading" && p.category === preset.category);
        if (!categoryExists) {
          if (acc.length) {
            acc.push({type: "separator"});
          }
          acc.push({
            type: "heading",
            label: __("examPresetSelector.presetCategory." + preset.category),
            category: preset.category,
          });
        };
        acc.push({
          label: preset.name,
          value: preset.id,
          images: !!preset.imaging_expected,
          icon: !!preset.imaging_expected ? "ultrasound" : "paste",
        })
        return acc;
      }, []);
  }, [appContext.allPresets]);

  return (
    <div className="exam-preset-select-container" data-test-id="exam-preset-container">
      <SelectInput
        padding="medium"
        value={examinationContext?.examination?.preset?.id}
        options={options}
        onChange={onSelectPreset}
        theme="blue"
      />
    </div>
  )
}

export default withTranslation()(ExamPresetSelector);
