import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateLogoBody({
  placeholder,
}) {
  const url = placeholder?.value;

  const Image = () => {
    if (!url) return false;
    else return <img src={url} />;
  }

  return <div className="logo"><Image /></div>;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateLogo({
  props,
  placeholders,
  ...otherProps
}) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateLogoBody}
      placeholders={placeholders}
      requiredPlaceholders={["logo.url"]}
      placeholder={placeholders["logo.url"]}
      props={props}
      {...otherProps}
    />
  );
}
