import React from "react";
import LoginView from "./LoginView";

const Login = () => {
  return (
    <div className={`theme-dark`}>
      <LoginView />
    </div>)
};

export default Login;
