import React, {useContext, useEffect, useMemo, useState} from 'react';
import { withTranslation } from "react-i18next";
import {ExaminationContext} from "../../../context-providers/Examination";
import useAuth from "../../../context-providers/Auth";
import Icon from "../../../atoms/Icon/Icon";
import "./SharingIndicator.css";

const SharingIndicator = ({ t: __ , slim}) => {
  const examinationContext = useContext(ExaminationContext);
  const {isFeatureFlagEnabled} = useAuth();
  const isAlreadyShared = useMemo(() => {
    return !!examinationContext?.share?.share_url;
  }, [examinationContext?.share?.share_url]);
  const [isHistoryPopupOpen, setIsHistoryPopupOpen] = useState(false)
  const [shares, setShares] = useState([])

  useEffect(() => {
    setShares(
    (examinationContext?.allShares && examinationContext?.share?.share_url)
      ? examinationContext?.allShares?.filter(share => share?.share_url || share?.instant_share_url)
      : []
    )
  }, [examinationContext?.allShares, examinationContext?.share])

  return isFeatureFlagEnabled("sonio.share") &&
    <>
      <div className={`examination-sharing-indicator ${isAlreadyShared ? "active" : ""} ${slim ? "slim" : "" }`}
           onClick={isAlreadyShared ? () => setIsHistoryPopupOpen(true) : null}
      >
        <Icon name="sharing-indicator"/>
      </div>
      {isHistoryPopupOpen && (
        <>
          <div className="examination-sharing-history-popup-container">
            <div className="history">
              <h3>{__("examinationSharingFlow.sharing_history.active_sharing")}</h3>
              <div className="headers row">
                <div className="w3">{__("examinationSharingFlow.sharing_history.share_id")}</div>
                <div className="w4">{__("examinationSharingFlow.sharing_history.recipient")}</div>
                <div className="w4">{__("examinationSharingFlow.sharing_history.last_access")}</div>
              </div>
              {shares.map((share) => (
                <div key={share?.id} className="row data">
                  <div className="w3">{share?.id}</div>
                  <div className="w4">{share?.parameters?.email ?? __("examinationSharingFlow.sharing_history.shared_by_qr")}</div>
                  <div className="w4">{share?.parameters?.last_accessed_at ? new Date(share.parameters.last_accessed_at).toLocaleString(): "---"}</div>
                  <div className="w1 last">
                    <div className="delete" onClick={examinationContext.deleteShareForExamination}>
                      <Icon name="trash"/>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="popup-close" onClick={() => setIsHistoryPopupOpen(false)}>
              <Icon name="close"/>
            </div>
          </div>
          <div className="modal-background" onClick={() => setIsHistoryPopupOpen(false)}/>
        </>
      )}
    </>
};

export default withTranslation()(SharingIndicator);
