import SignSearchBar from "../../components/SignSearchBar";
import "./AppointmentsSearchBar.css";

const AppointmentsSearchBar = () => {
  return (
    <div className="appointments-search-bar">
      <SignSearchBar label="Search/filter for exams, patients, scan rooms" />
    </div>
  );
};

export default AppointmentsSearchBar;
