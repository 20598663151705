import {useState, useContext} from "react";
import Icon from "../../atoms/Icon/Icon";
import SharingPreviewPopup from "./SharingPreviewPopup";
import {ExaminationContext} from "../../context-providers/Examination";
import './InstantQrCode.css'
import ResourceApi from "../../services/resource";

const InstantQrCode = ({variant}) => {
  const examinationContext = useContext(ExaminationContext);
  const [qrCodePopupOpen, setQrCodePopupOpen] = useState(false);

  const openQrCodePopup = async () => {
    if (!examinationContext.share?.instant_share_link) {
      await examinationContext.shareExamination();
    }

    const sharedInstances = await ResourceApi.sharingList(examinationContext.examination.id).then(resp => resp.data.data);
    if (sharedInstances?.length) {
      setQrCodePopupOpen(true);
    }
  };

  return <>
    <div className={`instant-qr-code ${variant}`} onClick={openQrCodePopup}>
      <Icon name="qrcode" />
    </div>
    {qrCodePopupOpen && (
      <SharingPreviewPopup onClose={() => setQrCodePopupOpen(false)} />
    )}
  </>
}

export default InstantQrCode;