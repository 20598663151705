import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import UserApi from "../../services/user";
import ToggleCheckbox from "../../atoms/Checkbox/Checkbox";
import "./PrintingPageDefaultOptions.css";

const PrintingPageDefaultOptions = ({ t: __ }) => {
  const [preselectConfiguration, setPreselectConfiguration] = useState(null);

  useEffect(() => {
    UserApi.getUserSharingPreselection().then((resp) => setPreselectConfiguration(resp.data.preselection));
  }, []);

  console.log(preselectConfiguration)
  const qrCodeAutoEnable = preselectConfiguration?.qr_code_auto_enabled ?? false;

  const updatePreselectField = (fields) => {
    UserApi.setUserSharingPreselection(fields)
      .then((resp) => setPreselectConfiguration(resp.data.preselection));
  };

  return <>
    <div className="row">
      <h4>{__("sharingPreferences.preselect.printedLayout.title")}</h4>
    </div>
    <div className="row">
      <div className="w8">
        <div>{__(`sharingPreferences.printingPageDefaultOptions.qrCodeAction`)}</div>
        <small className="sub-title">
          <p>{__("sharingPreferences.printingPageDefaultOptions.qrCodeExplanation")}</p>
        </small>
      </div>
      <div className="w4 printing-qr-code-toggle">
        <ToggleCheckbox
          checked={qrCodeAutoEnable}
          onChange={(value) => updatePreselectField({ "qr_code_auto_enabled": value })}
        />
      </div>
    </div>
    <div className="row spacer"></div>
  </>
};
export default withTranslation()(PrintingPageDefaultOptions);