import { useContext, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { AppContext } from "../../context-providers/App"
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup"
import "./Disclaimer.css"
import Button from "../../atoms/Button/Button"
import useAuth from "../../context-providers/Auth"

/**
 *
 * product can be one of the following :
 *  - soniopedia
 *
 */
function LegalTermsAgreement({ t: __, product, onAccept }) {
  const appContext = useContext(AppContext)
  const { user } = useAuth()
  const [acceptToS, setAcceptToS] = useState(false)
  const [acceptToU, setAcceptToU] = useState(false)
  const [acceptedAt, setAcceptedAt] = useState()

  useEffect(() => {
    setAcceptedAt(new Date())
  }, [])

  return (
    <div className="disclaimer-container">
      <div className="disclaimer-set-language">
        <ButtonGroup
          value={appContext?.preferences?.lang ?? ""}
          options={[
            { value: "en", label: "EN" },
            { value: "fr", label: "FR" },
            { value: "de", label: "DE" },
            { value: "ptbr", label: "PTBR" },
          ]}
          onChange={appContext.changeLanguage}
        />
      </div>
      <div className="disclaimer">
        <div dangerouslySetInnerHTML={{ __html: __(`legal_terms_agreement.${product}.text`) }}></div>
        <hr />
        <h2>{__("legal_terms_agreement.user_information.title")}</h2>
        <ul>
          <li>{__("legal_terms_agreement.user_information.name", { name: user.title })}</li>
          <li>{__("legal_terms_agreement.user_information.email", { email: user.contact_email })}</li>
          <li>
            {__("legal_terms_agreement.user_information.date", {
              date: acceptedAt && acceptedAt.toLocaleDateString(appContext?.preferences?.lang?.slice(0, 2)),
            })}
          </li>
        </ul>
        <hr />
        <div>
          <input type="checkbox" id="terms_of_sales" value={acceptToS} onChange={() => setAcceptToS(!acceptToS)} />
          <label
            htmlFor="terms_of_sales"
            dangerouslySetInnerHTML={{
              __html: __("legal_terms_agreement.terms_of_sales.label", {
                termsOfSalesLink: __("navbar.termsOfSales.soniopedia.href"),
              }),
            }}
          />
        </div>
        <div>
          <input type="checkbox" id="terms_of_use" value={acceptToU} onChange={() => setAcceptToU(!acceptToU)} />
          <label
            htmlFor="terms_of_use"
            dangerouslySetInnerHTML={{
              __html: __("legal_terms_agreement.terms_of_use.label", {
                termsOfUseLink: __("navbar.termsOfUse.soniopedia.href"),
              }),
            }}
          />
        </div>
        <div className="submit">
          <Button
            label={__("legal_terms_agreement.submit")}
            disabled={!acceptToS || !acceptToU}
            onClick={() =>
              onAccept(product, ["terms_of_sales", "terms_of_use"], appContext?.preferences?.lang, acceptedAt)
            }
          />
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(LegalTermsAgreement)
