import { isValidElement, cloneElement } from "react";
import { conditionRequiredPlaceholders, checkCondition } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateIfBody({ defaultKey = '', children, placeholders, props, fetus }) {
  if (props.fetus) fetus = props.fetus;

  const matchesConditions = checkCondition({ ...props }, fetus, placeholders);

  const childrenWithFetusNumber = !matchesConditions ? false : (Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_if_${fetus}` });
    }
    return child;
  }) : children);

  return childrenWithFetusNumber || false;
};



/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateIf({
  props,
  ...otherProps
}) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateIfBody}
      requiredPlaceholders={conditionRequiredPlaceholders(props)}
      props={props}
      {...otherProps}
    />
  );
};

