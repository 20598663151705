import { useContext } from "react";
import { withTranslation } from "react-i18next";
import "./SharingTopBar.css";
import PatientName from "../../components/PatientName/PatientName";
import SharingIndicator from "../ExaminationLive/SharingIndicator/SharingIndicator";
import { ExaminationContext } from "../../context-providers/Examination";
import { AppContext } from "../../context-providers/App";
import InstantQrCode from "../ExaminationReview/InstantQrCode";
import ExamPresetButtons from "../../components/ExamPresetButtons/ExamPresetButtons";
import ExamPresetSelector from "../../components/ExamPresetSelector";
import Button from "../../atoms/Button/Button";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import useAuth from "../../context-providers/Auth";

const SharingTopBar = ({
  t: __,
  mode,
  onReport,
  onPrint,
  onShare,
  filterOptions,
  onFilterChange,
  searchBar,
  filterFeedback,
  dimButtons,
  showReport = true,
}) => {
  const { isFeatureFlagEnabled } = useAuth();
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);
  const isSharing = mode === "share";
  const isPrinting = mode === "print";

  const showSearchBar =
    isFeatureFlagEnabled("sonio.checklist") ||
    isFeatureFlagEnabled("sonio.dx_v2");
  const showExamSelector = appContext.protocolsEnabled;

  return (
    <>
      <div className="examination-sharing-flow-top-bar">
        <div className="group params">
          {examinationContext.canEdit ? (
            showExamSelector ? (
              <ExamPresetSelector
                action="change-preset"
                size="compact"
                wrap={false}
                collapse={true}
              />
            ) : (
              <ExamPresetButtons
                action="change-preset"
                size="compact"
                wrap={false}
                collapse={true}
                theme="grey"
                stayExpanded={false}
              />
            )
          ) : (
            <Button
                    label={appContext.allPresets?.find(preset => preset.id === examinationContext.examination.preset_id)?.name}/>
          )}
          <SelectInput
            value={undefined}
            placeholder={{
              icon: "settings-alt",
              label: "",
              value: "",
            }}
            options={filterOptions || []}
            onChange={onFilterChange || (() => {})}
            theme="grey"
          />
          {filterFeedback}
        </div>
        {showSearchBar && <div className="group search">{searchBar}</div>}
        <div className="group buttons">
          {showReport && (
            <div>
              <Button
                label={__("examinationSharingFlow.actions.report")}
                icon="stats"
                variant={dimButtons ? "dim" : null}
                onClick={onReport}
              />
            </div>
          )}
          <div>
            <Button
              label={__("examinationReview.print")}
              icon="print"
              variant={
                (isPrinting && "contained-shadow") ||
                (dimButtons && "dim") ||
                null
              }
              onClick={onPrint}
            />
          </div>
          <div>
            <Button
              label={__("examinationReview.share")}
              icon="sharing-indicator"
              variant={
                (isSharing && "contained-shadow") ||
                (dimButtons && "dim") ||
                null
              }
              onClick={onShare}
            />
          </div>
          <InstantQrCode variant={(dimButtons && "dim") || null} />
        </div>
        <div className="group">
          <PatientName
            patient={examinationContext.patient}
            pregnancy={examinationContext.pregnancy}
            examinationDate={examinationContext.examination.examination_date}
            variant="transparent"
          />
          <SharingIndicator slim={true} />
        </div>
      </div>
    </>
  );
};

export default withTranslation()(SharingTopBar);
