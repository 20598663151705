import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';

/* Atoms */
import Button from "../../../atoms/Button/Button";
import ButtonGroup from "../../../atoms/ButtonGroup/ButtonGroup";
import Icon from "../../../atoms/Icon/Icon";
import TextArea from "../../../atoms/TextArea/TextArea";

/* Components */
import SlideDrawing from "../../../components/Slide/SlideDrawing/SlideDrawing";
import FetusesTable from '../../../components/FetusesTable/FetusesTable';
import ContactPointCard from "../../../components/ContactPointCard/ContactPointCard";
import FetusSexHandler from '../../../components/MedicalHistoryInput/FetusSexHandler';

/* Contexts */
import { AppContext } from "../../../context-providers/App";
import useAuth from "../../../context-providers/Auth";
import { ExaminationContext } from "../../../context-providers/Examination";
import { useXMLTemplate } from "../../../context-providers/XMLTemplate";

/* Services & Utils */
import { computeBMI, formatName, getNiceGestionalAgeFromDays } from '../../../services/examination';
import { formatDate, formatYYYYMMDDDate, convertTimeZone } from '../../../utils';
import ResourceApi from "../../../services/resource";
import { stakeholderRoles } from "../../../config";

/* CSS */
import "./PhenotypePanel.css";
import ViewExamStakeholdersDialog from "../../ExaminationReport/ViewExamStakeholdersDialog";

const examinationSorter = (examA, examB) => {
  return new Date(examB.examination_date) - new Date(examA.examination_date);
}

const PatientNotes = ({ t: __, ...props }) => {
  const [initialNotes, setInitialNotes] = useState(props.notes);
  const [notes, setNotes] = useState(props.notes);
  const [focused, setFocused] = useState(false);
  const [initialNotesModified, setInitialNotesModified] = useState(false);
  const [solvingConflict, setSolvingConflict] = useState(false);

  useEffect(() => {
    if (solvingConflict)
      return;
    if (focused && initialNotes != props.notes) {
      setInitialNotesModified(true);
      setInitialNotes(props.notes);
      return;
    }
    else {
      setInitialNotes(props.notes);
      setNotes(props.notes);
    }
  }, [props.notes, initialNotes, setNotes, focused]);

  const onChange = (value) => {
    setNotes(value);
  }

  const onBlur = () => {
    setFocused(false);
    if (!initialNotesModified) {
      props.onChange(notes);
    } else {
      console.warn("Conflict between initial notes and modified notes. TODO handle this case.")
      /* For the moment we override with the new notes */
      setSolvingConflict(true);
      setInitialNotesModified(false);
    }
  }

  const onSolveConflict = (value) => {
    setSolvingConflict(false);
    if (value == "override") {
      props.onChange(notes);
    } else {
      setNotes(props.notes);
      setInitialNotes(props.notes);
    }
  }

  return (
    <>
      <h2 className="section-title">{__("patient.notes")}</h2>
      <div>
        <TextArea
          fullwidth={true}
          value={notes || ""}
          onChange={onChange}
          onFocus={() => setFocused(true)}
          onBlur={onBlur}
          error={initialNotesModified || solvingConflict}
          icon={initialNotesModified || solvingConflict ? "error" : "paste"}
        />
        {solvingConflict && (<div class="text-xsmall italic">
          <div class="error-message"><span class="mr-small"><Icon name="error" /></span>{__("patient.notes.conflict")}</div>
          <ButtonGroup
            options={
              [
                { label: __("patient.notes.override"), value: "override" },
                { label: __("patient.notes.discard"), value: "discard" }
              ]
            }
            value={"override"}
            onChange={onSolveConflict}
          />
        </div>)}
      </div>
    </>
  )
}

const ExaminationViewPhenotypePanel = ({
  t: __,
  patient,
  episode,
  trimester,
  checklistItems,
  medicalHistory,
  slides,
  slidesRiskFactors,
  checklistItemsRiskFactors
}) => {
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const history = useHistory();
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();

  const [filterByRiskFactorId, setFilterByRiskFactorId] = useState(false);
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = useState(false);
  const [medicalHistoryWithBMI, setMedicalHistoryWithBMI] = useState(medicalHistory);
  const [possibleEntities, setPossibleEntities] = useState([]);
  const [allEntities, setAllEntities] = useState([]);
  const [episodes, setEpisodes] = useState([]);

  const { reportData = {}, setReportData = () => { }, loadDynamicReportData = () => { } } = useXMLTemplate();

  const handleGoToMedicalHistory = () => {
    history.push(`/exam-anamnesis/${examinationContext.examination.id}`)
    appContext.activateGoBackMenuButton(true)
  }

  useEffect(() => {
    const f = async () => {
      const res = await ResourceApi.listEpisodes(examinationContext.examination.patient_id)
      setEpisodes(res.data.data)
    }
    f()
  }, [examinationContext.examination.episode_id, examinationContext.examination.patient_id])


  useEffect(() => {
    setMedicalHistoryWithBMI(mh => {
      mh = structuredClone(medicalHistory);
      const weight = examinationContext.examination.medical_history?.["medicalexam.mother.weight"]?.value;
      const height = examinationContext.examination.medical_history?.["medicalexam.mother.height"]?.value;
      if (weight && height) {
        const bmiValue = computeBMI(weight, height).toFixed(2);
        if (mh.some(m => m.text_id === "medicalexam.mother.bmi")) {
          mh = mh.map(m => ({ ...m, raw_value: (m.text_id === "medicalexam.mother.bmi" && !m.raw_value) ? bmiValue : m.raw_value }));
        } else {
          mh = [
            ...mh.slice(0, mh.findIndex(m => m.text_id === "medicalexam.mother.weight") + 1),
            {
              label: examinationContext.medicalHistoryItems?.["medicalexam.mother.bmi"]?.label[currentLanguage],
              text_id: "medicalexam.mother.bmi",
              raw_value: computeBMI(weight, height).toFixed(2),
              // TODO: add is_risky value
            },
            ...mh.slice(mh.findIndex(m => m.text_id === "medicalexam.mother.height") + 2),
          ];
        }
      }
      return mh;
    });
  }, [medicalHistory, examinationContext.medicalHistoryItems?.["medicalexam.mother.bmi"]?.label, examinationContext.examination?.medical_history?.["medicalexam.mother.weight"]?.value, examinationContext.examination?.medical_history?.["medicalexam.mother.height"]?.value, examinationContext.examination?.medical_history?.["medicalexam.mother.bmi"]]);

  /* load stakeholders */
  useEffect(() => {
    ResourceApi.listPossibleEntityToExamination(examinationContext.examination.id)
      .then((r) => {
        setPossibleEntities(r.data.data)
      })
  }, [])

  useEffect(() => {
    let entities = [
      { id: examinationContext.examination.practitioner_id, entity_id: examinationContext.examination.practitioner_id, contact_point: { id: examinationContext.examination.practitioner_id, name: possibleEntities.find(e => e.id === examinationContext.examination.practitioner_id)?.title }, role: "main_performing_clinician" },
      { id: examinationContext.examination.reader_id, entity_id: examinationContext.examination.reader_id, contact_point: { id: examinationContext.examination.reader_id, name: possibleEntities.find(e => e.id === examinationContext.examination.reader_id)?.title }, role: "main_reading_provider" },
      ...(examinationContext.examination.entities || []).map(entity => ({ ...entity, contact_point: { id: entity?.entity_id, name: entity?.entity?.title } })),
      ...(reportData?.examination_data?.associated_contact_points || [])
    ].filter(entity => entity.entity_id);

    setAllEntities(entities);
  }, [examinationContext.examination.reader_id, examinationContext.examination.practitioner_id, possibleEntities, examinationContext.examination.entities, reportData?.examination_data?.associated_contact_points]);

  useEffect(() => {
    loadDynamicReportData();
  }, [examinationContext.examination.reader_id, examinationContext.examination.practitioner_id, JSON.stringify(examinationContext.examination.entities), JSON.stringify(reportData?.examination_data?.associated_contact_points)]);


  const printRiskRawValue = (risk) => {
    if (risk.raw_value
      && !risk.tmp_value
      && (
        !risk.value
        || (Array.isArray(risk.value) && !risk.value.includes(risk.raw_value))
      )
    ) return <span className="raw-value">{getNiceRawValue(risk.raw_value)}</span>;
    return false;
  }

  const getNiceRawValue = (value) => {
    if (typeof value === 'string' || !isNaN(value)) return value;
    if (value.canonical_name) return value.canonical_name[currentLanguage];
    return false;
  }

  const afterAssociatingContactPoint = (contactPoint) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    newReportData.examination_data?.associated_contact_points.push(contactPoint);
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const afterDeassociatingContactPoint = (contactPointId) => {
    /* optimistic update */
    const newReportData = structuredClone(reportData);
    if (newReportData?.examination_data?.associated_contact_points) {
      newReportData.examination_data.associated_contact_points = newReportData.examination_data?.associated_contact_points?.filter(cp => cp.id !== contactPointId);
    }
    setReportData(newReportData);
    loadDynamicReportData();
  }

  const updatePatientNotes = (notes) => {
    ResourceApi.createBIEvent({
      event_type: "patient_note_updated",
      examination_id: examinationContext?.examination?.id,
      page: "prep_exam_page",
      notes: notes
    });
    examinationContext.updatePatient(patient.id, { notes });
  }

  /* Do not display anything if depeding on the FF */
  if (!isFeatureFlagsLoaded)
    return null

  return (
    <>
      <div className="examination-live-phenotype-panel-container">
        <div className="examination-live-phenotype-panel-risk-phenotype">
          <div>
            <h2 className="section-title">{__("examinationReview.patient")}</h2>
            <div className="entry">
              <label>{__("examinationReview.patient.name")}</label>
              <span className="ph-no-capture">{patient?.name ? formatName(patient?.name).fullName : __("patients.anonymous")}</span>
            </div>
            {!!episode?.dob && (
              <div className="entry">
                <label>{__("examinationReview.patient.dob")}</label>
                <span className="ph-no-capture">{patient?.dob ? formatDate(patient?.dob, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
              </div>
            )}
            {!!episode?.conception_date && (
              <div className="entry">
                <label>{__("examinationReview.episode.conceptionDate")}</label>
                <span className="ph-no-capture">{episode?.conception_date ? formatDate(episode?.conception_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
              </div>
            )}
            {!!episode?.lmp_date && (
              <>
                <div className="entry">
                  <label>{__("examinationReview.episode.lmpDate")}</label>
                  <span className="ph-no-capture">{episode?.lmp_date ? formatDate(episode?.lmp_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
                </div>
                <div className="entry">
                  <label>{__("examinationReview.episode.gestationalAge")}</label>
                  <span className="ph-no-capture">{getNiceGestionalAgeFromDays(__, examinationContext?.dating?.value)}</span>
                </div>
              </>
            )}
            <br />
            <PatientNotes
              t={__}
              notes={patient?.notes}
              onChange={updatePatientNotes}
            />
          </div>

          {!!examinationContext.episode &&
            <div>
              <h2 className="section-title">{__("examination.episode")}</h2>
              <div className="outlined">
                <FetusSexHandler showFetusSexButton={false} />
              </div>
              {!isFeatureFlagEnabled("sonio.dx_v2") && (
                <>
                  <h6>{__("fetusesTable.fetuses")}</h6>
                  <FetusesTable
                    episode={examinationContext.episode}
                    examination={examinationContext.examination}
                    fetusSexVisibility={examinationContext.fetusSexVisibility}
                    updateExaminationFetus={examinationContext.updateExaminationFetus}
                    createEpisodeFetus={examinationContext.createEpisodeFetus}
                    updateEpisodeFetus={examinationContext.updateEpisodeFetus}
                    deleteEpisodeFetus={examinationContext.deleteEpisodeFetus}
                    canEdit={examinationContext.canEdit}
                  />
                </>
              )}
            </div>
          }

          <div>
            <h2 className="section-title">{__("examination.medicalHistory")}</h2>
            <ul className="risk-factors">
              {medicalHistoryWithBMI?.filter(mh => mh.label).map((risk, index) => {
                return <li key={index} className={!!risk.is_risky ? 'is-risky' : ''} onClick={() => risk.is_risky && setFilterByRiskFactorId(risk.risk_factor_id === filterByRiskFactorId ? false : risk.risk_factor_id)}>
                  <label>{risk.label}</label>
                  <span>{printRiskRawValue(risk)} {risk.value}</span>
                </li>
              })}
            </ul>
            {!medicalHistory.length && (
              <div className="risk-factors-empty">{__('medicalhistory.noRiskFactors')}</div>
            )}
            <div className="examination-live-phenotype-panel-cta">
              <Button
                label={examinationContext.canEdit ? __('examination.goToMedicalHistory') : __('examination.consultMedicalHistory')}
                variant={(!medicalHistory.length) ? "" : "outline"}
                onClick={handleGoToMedicalHistory}
              />
            </div>
          </div>
        </div>
        <div className="examination-live-phenotype-panel-risk-factors">
          <div className="examination-live-phenotype-panel-stakeholders">
            {!!allEntities.length && (
              <div>
                <h2 className="section-title"><Icon name="user" /> {__("examinationStakeholder.role.internal")}</h2>
                <div className="exam-live-phenotype-stakeholder-section">
                  <div className="exam-live-phenotype-stakeholder-section-list">
                    {
                      stakeholderRoles.filter(r => !r.external).map(r => (
                        <React.Fragment key={r.id}>
                          {allEntities.filter(e => e.role === r.id).map(entity => (
                            <StakeholderLine
                              key={r.id + '_' + entity.id}
                              entity={entity}
                              __={__}
                            />
                          ))
                          }
                        </React.Fragment>
                      ))
                    }
                  </div>
                </div>
              </div>
            )}
            {!!reportData?.examination_data?.associated_contact_points?.length && (
              <div>
                <h2 className="section-title"><Icon name="hospital" /> {__("examinationStakeholder.role.external")}</h2>
                <div className="exam-live-phenotype-stakeholder-section">
                  <div className="exam-live-phenotype-stakeholder-section-list">
                    {reportData?.examination_data?.associated_contact_points.map(stakeholder =>
                      <ContactPointLine
                        key={stakeholder.id}
                        stakeholder={stakeholder}
                        __={__}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="examination-live-phenotype-panel-cta">
              {examinationContext.canEdit && <Button variant="outline" onClick={() => setSubmitDialogIsOpen(true)} icon="user" label={__("examinationReview.dialog.editStakeholders")} />}
            </div>
          </div>

          {isFeatureFlagEnabled("sonio.episode_details") && <div className="examination-live-phenotype-panel-episodes">
            <h2 className="section-title">{__("episode.episodes")}</h2>
            <table className="simple-table">
              <tbody>
                {
                  episodes.map((episode) => <React.Fragment key={episode.id}>
                    <tr className={"examination-live-phenotype-episode-name"}>
                      <th colSpan="4">
                        <span>{episode.name || __("episode.defaultName", { inserted_at: formatDate(episode.inserted_at, appContext.preferences.date_format) })}</span>
                      </th>
                    </tr>
                    {
                      episode.examinations.sort(examinationSorter).map((examination) => <React.Fragment key={`examination-${examination.id}`}>
                        <tr data-is-current={examination.id === examinationContext.examination.id}>
                          <td>{examination.id === examinationContext.examination.id && <span className="examination-live-phenotype-episode-current-exam"><Icon name="arrow-right" /></span>}</td>
                          <td>{examination.preset.name}</td>
                          <td>{examination.preset.category === "other" ? "-" : examination.preset.category}</td>
                          <td>
                            {formatYYYYMMDDDate(convertTimeZone(examination.examination_date, examinationContext.examination?.site?.timezone), appContext.preferences.date_format)} &nbsp;
                            {convertTimeZone(examination.examination_date, examinationContext.examination?.site?.timezone).substr(11, 5)}
                          </td>
                        </tr>
                      </React.Fragment>)
                    }
                  </React.Fragment>)
                }
              </tbody>
              {examinationContext.examination?.site?.timezone && (
                <tfoot>
                  <tr>
                    <td colSpan="4">
                      <Icon name="info" /> {__("episode.timezone", { timezone: examinationContext.examination?.site?.timezone || "UTC" })}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
            {
              examinationContext.canEdit &&
              <div className="examination-live-phenotype-panel-cta">
                <Button variant="outline" onClick={() => { window.location.href = `/patient/${examinationContext.patient.id}` }} icon="edit" label={__("episode.editEpisodes")} />
              </div>
            }
          </div>}

          {isFeatureFlagEnabled("sonio.risk") && <div>
            <h2 className="section-title">
              {__("examination.notToForget")}
              {!!filterByRiskFactorId && (
                <span className="selected-risk-factor">
                  {medicalHistory.find(item => item.risk_factor_id === filterByRiskFactorId)?.label} {__(":") + " "}
                  {medicalHistory.find(item => item.risk_factor_id === filterByRiskFactorId)?.value}
                  <Icon name="close" onClick={() => setFilterByRiskFactorId(false)} />
                </span>
              )}
            </h2>
            <div className="examination-live-phenotype-panel-slide-risk-factors">
              {!Object.values(slidesRiskFactors).filter(slide => !!slide.risk_factors?.length).length ? (
                <div className="examination-live-phenotype-panel-norisks">{__("examination.noRiskFactors")}</div>
              ) : (
                Object.keys(slidesRiskFactors).map(slideId => {
                  if (!!slidesRiskFactors[slideId]?.risk_factors?.length) {
                    const slide = slides.find(s => s.id === Number(slideId));
                    if (!slide) return false;
                    return <div className={(!filterByRiskFactorId || slidesRiskFactors[slideId].risk_factors?.some(r => r.id === filterByRiskFactorId)) ? 'visible-risk' : 'hidden-risk'} key={slide.id}>
                      <div className="examination-live-phenotype-panel-slide">
                        <SlideDrawing
                          version="2"
                          trimester={trimester}
                          slide={slide}
                          type={slide?.type}
                        />
                        <div className="examination-live-phenotype-panel-checklist">
                          <div className="examination-live-phenotype-panel-name">
                            {slide.label[currentLanguage]}
                          </div>
                          <ul>
                            {checklistItems.filter(item => item.instance_view_id[trimester].includes(slide.id) && !!checklistItemsRiskFactors[item.id]?.risk_factors.length).map(item => (
                              <li key={item.id} className={(!filterByRiskFactorId || checklistItemsRiskFactors[item.id].risk_factors?.some(r => r.id === filterByRiskFactorId)) ? 'visible-risk' : 'hidden-risk'}>
                                {item.label[currentLanguage]}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  }
                })
              )}
            </div>
          </div>}
        </div>
      </div>
      {submitDialogIsOpen &&
        <ViewExamStakeholdersDialog
          submitDialogIsOpen={submitDialogIsOpen}
          setSubmitDialogIsOpen={setSubmitDialogIsOpen}
          allowClosing={true}
          examination={examinationContext.examination}
          reportData={reportData}
          associateEntity={examinationContext.associateEntity}
          deassociateEntity={examinationContext.deassociateEntity}
          associateContactPoint={(params) => examinationContext.associateContactPoint(params, afterAssociatingContactPoint)}
          deassociateContactPoint={(association_cp_id) => examinationContext.deassociateContactPoint(association_cp_id, afterDeassociatingContactPoint)}
        />
      }
    </>
  )
}

const StakeholderLine = ({ entity, onRemove, __ }) => {
  return <div className="exam-report-dialog-stakehoder">
    <ContactPointCard
      contact={{ name: entity.contact_point?.name, role: __(`examinationStakeholder.role.${entity.role}`) }}
    />
  </div>
}

const ContactPointLine = ({ stakeholder, onRemove, __ }) => {
  if (!stakeholder.contact_point) return false;

  return <div className="exam-report-dialog-stakehoder">
    <ContactPointCard
      contact={{ ...stakeholder, ...stakeholder.contact_point, role: __(`examinationStakeholder.role.external.${stakeholder.role}`), association_id: stakeholder.id }}
      icon="hospital"
    />
  </div>
}


export default withTranslation()(ExaminationViewPhenotypePanel);
