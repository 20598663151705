import {
  createContext,
  useContext,
  useEffect,
  useState,
  useReducer,
} from "react";
import { SocketContext } from "./Socket";

export const LiveExaminationContext = createContext({});

const resourceStateReducer = (state, { resource_type: resourceType, data }) => {
  switch (resourceType) {
    case "examination":
      return { ...state, examination: data };
    case "episode":
        return {...state, episode: data }
    case "patient":
      return { ...state, patient: data };
    case "examination_data":
      return { ...state, examinationData: data}
    default:
      return state;
  }
};

export const LiveExaminationContextProvider = ({ children }) => {
  const { socket } = useContext(SocketContext);

  const [examinationIdToTrack, setExaminationIdToTrack] = useState(null);
  const [examinationChannel, setExaminationChannel] = useState(null);
  const [resourceStates, dispatchResourceStates] = useReducer(
    resourceStateReducer,
    {}
  );

  const [incomingDicomInstance, setIncomingDicomInstance] = useState(null);
  const [incomingAssociation, setIncomingAssociation] = useState(null);
  const [incomingQCPrediction, setIncomingQCPrediction] = useState(null);
  const [removedDicomInstance, setRemovedDicomInstance] = useState(null);

  const cleanIncomingDicomInstance = () => {
    setIncomingDicomInstance(null);
    setRemovedDicomInstance(null);
  }

  useEffect(() => {
    if (examinationIdToTrack && examinationChannel) examinationChannel.leave();

    if (socket && examinationIdToTrack) {
      const channelTopic = `examination:${examinationIdToTrack}`;

      const channel = socket.channel(channelTopic);

      channel.on("update", (payload) => {
        dispatchResourceStates(payload);
      });

      channel.on("new_dicom_instance", ({ data }) => {
        setIncomingDicomInstance(data);
      });

      channel.on("update_dicom_instance", ({ data }) => {
        if (data?.state === "deleted") {
          setRemovedDicomInstance(data);
        } else {
          setIncomingDicomInstance(data);
        }
      });

      channel.on("dicom_instance_association", ({ data }) => {
        setIncomingAssociation(data);
        const qc_prediction = data?.[0]?.qc_prediction?.data?.[0]?.quality_criterias;
        if (!!qc_prediction?.length) {
          setIncomingQCPrediction(data);
        }
      });

      channel
        .join()
        .receive("ok", (resp) => {
          setExaminationChannel(channel);
        })
        .receive("error", () => {
          console.error(
            `Join resource channel with topic ${channelTopic} failed`
          );
        });
    }

    return () => {
      if (examinationChannel) {
        examinationChannel.leave();
        setExaminationChannel(null);
      }
    };
  }, [socket, examinationIdToTrack]);

  const track = (examinationId) => {
    setExaminationIdToTrack(parseInt(examinationId));
  };

  return (
    <LiveExaminationContext.Provider
      value={{
        track,
        examination: resourceStates.examination,
        patient: resourceStates.patient,
        episode: resourceStates.episode,
        examinationData: resourceStates.examinationData,
        incomingDicomInstance,
        removedDicomInstance,
        cleanIncomingDicomInstance,
        incomingAssociation,
        incomingQCPrediction,
        dispatchResourceStates,
      }}
    >
      {children}
    </LiveExaminationContext.Provider>
  );
};
