import { createContext, useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import semver from "semver";
import Button from "../atoms/Button/Button";
import Popup from "../components/Popup/Popup";
import LookupApi from "../services/lookup";
import { NotificationContext } from "./Notification";

const LatestVersionContext = createContext({});

export const LatestVersionProvider = withTranslation()(
  ({ t: __, i18n, children }) => {
    const notificationContext = useContext(NotificationContext);

    const [currApiVersion, setCurrApiVersion] = useState(null);
    const [latestApiVersion, setLatestApiVersion] = useState(null);
    const [currUiVersion, setCurrUiVersion] = useState(null);
    // const [currApiMinUiVersion, setCurrApiMinUiVersion] = useState(null);
    const [updateAvailable, setUpdateAvailable] = useState(false);
    const [isForcedUpdate, setIsForcedUpdate] = useState(false);
    const [appMeta, setAppMeta] = useState(null);
    const [soniopediaBaseUrl, setSoniopediaUrl] = useState(null);

    useEffect(() => {
      LookupApi.version().then((resp) => {
        setCurrApiVersion(resp.data.api.version);
        setCurrUiVersion(resp.data.ui.version);
        setAppMeta(resp.data.meta);
        setSoniopediaUrl(resp.data.soniopedia_base_url)
        // setCurrApiMinUiVersion(resp.data.api.min_ui_version);
        console.log(`Current API version ${resp.data.api.version}`);
        console.log(`Curr UI version ${resp.data.ui.version}`);
        // console.log(`Current API min UI version ${resp.data.api.min_ui_version}`);
      });
    }, []);

    const checkForUpdate = (newLatestApiVersion, latestApiMinUiVersion) => {
      if (currApiVersion && semver.lt(currApiVersion, newLatestApiVersion)) {
        if (!updateAvailable) {
          setUpdateAvailable(true);
          setLatestApiVersion(newLatestApiVersion);
          if (
            currUiVersion &&
            semver.lt(currUiVersion, latestApiMinUiVersion)
          ) {
            setIsForcedUpdate(true);
          }
        }
      }
    };

    useEffect(() => {
      if (updateAvailable && latestApiVersion) {
        console.log(__("navbar.newVersionAvailable", { version: latestApiVersion }));
        notificationContext.showNotification(__("navbar.newVersionAvailable", { version: latestApiVersion }), 10000);
      }
    }, [updateAvailable, latestApiVersion]);

    useEffect(() => {
      if (isSoniopediaURL()) {
        i18n.setDefaultNamespace("soniopedia");
        console.log("User accessed soniopedia.");
      }
    }, [soniopediaBaseUrl])

    const update = () => {
      window.location.reload();
    };

    const isSoniopediaURL = () => {
      return window.location.origin === soniopediaBaseUrl;
    }

    return (
      <LatestVersionContext.Provider
        value={{
          currApiVersion,
          latestApiVersion,
          checkForUpdate,
          updateAvailable,
          update,
          appMeta,
          isSoniopediaURL,
          soniopediaBaseUrl,
        }}
      >
        {isForcedUpdate && (
          <Popup
            message={__("navbar.forcedUpdateMessage")}
            cta={
              <Button
                label={__("navbar.forcedUpdateRefresh")}
                onClick={() => update()}
              />
            }
          />
        )}
        {children}
      </LatestVersionContext.Provider>
    );
  }
);

export default () => {
  return useContext(LatestVersionContext);
};
