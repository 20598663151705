import Button from "../../atoms/Button/Button";
import Appointment from "./Appointment";
import AppointmentsSearchBar from "./AppointmentsSearchBar";
import "./AppointmentsView.css";

const AppointmentsView = ({ appointments, syncWithEmr }) => {
  return (
    <div className="appointments-container">
      <div className="appointments-container-header">
        <div className="sync-btn">
          <Button size="small" label="EMR Sync" onClick={syncWithEmr} />
        </div>
        <AppointmentsSearchBar />
      </div>
      {appointments
        .sort((a, b) => b.id - a.id)
        .map((appointment) => (
          <Appointment appointment={appointment} />
        ))}
    </div>
  );
};

export default AppointmentsView;
