import DxSidebarView from "./DxSidebarView";

const DxSidebar = ({medications, instances}) => {
    return <DxSidebarView
      medications={medications}
      instances={instances}
    /> 
}

export default DxSidebar;
