import "./LoaderInline.css";

const LoaderInline = () => {

  return (
    <span className="loader-inline-container"></span>
  );
};

export default LoaderInline;
