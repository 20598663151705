import { useContext } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { ExaminationContext } from "../../../context-providers/Examination";
import "./NdExaminationToolbar.css";
import InstantQrCode from "../../ExaminationReview/InstantQrCode";

const NdExaminationToolbar = ({ t: __ }) => {
  const history = useHistory();
  const examinationContext = useContext(ExaminationContext);

  let numberOfInstancesForPrint = examinationContext.instances.filter(
    (inst) => inst.selected_for_print
  ).length;
  let numberOfInstancesForShare = examinationContext.instances.filter(
    (inst) => inst.shared_with_patient
  ).length;

  return (
    examinationContext.examination?.trimester === "ND" && (
      <>
        <div className="nd-examination-toolbar-container">
          <div className="buttons">
            <div className="action-btn-group">
              <div
                className="action-settings"
                onClick={() =>
                  history.push(
                    `/exam/${examinationContext.examination.id}/report/printing-list`
                  )
                }
              >
                <span>
                  {__("examinationReview.print") +
                    (numberOfInstancesForPrint === 0
                      ? ""
                      : ` (${numberOfInstancesForPrint})`)}
                </span>
              </div>
            </div>
            <div className="action-btn-group">
              <div
                className="action-settings"
                onClick={() =>
                  history.push(
                    `/exam/${examinationContext.examination.id}/report/sharing-list`
                  )
                }
              >
                <span>
                  {__("examinationReview.share") +
                    (numberOfInstancesForShare === 0
                      ? ""
                      : ` (${numberOfInstancesForShare})`)}
                </span>
              </div>
            </div>
            <InstantQrCode />
          </div>
        </div>
      </>
    )
  );
};

export default withTranslation()(NdExaminationToolbar);
