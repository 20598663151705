import React from "react";
import Icon from "../Icon/Icon";
import "./Checkbox.css";

/**
 * <Checkbox
 * label=""
 * checked={(boolean)}
 * onChange=(checked)=>{}
 * />
 */

const Checkbox = ({ label, checked = false, onChange = () => { }, disabled = false, beforeLabel = '' }) => {
  return (
    <div 
      className={`checkbox ${checked ? 'checked' : 'not-checked'} ${disabled ? 'disabled' : ''}`} 
      onClick={disabled ? () => {} : () => onChange(!checked)}
    >
       {!!beforeLabel && (
        <span className="checkbox-label before-input">{beforeLabel}</span>
      )}
      <span className="checkbox-field"><span className="checkbox-toggle"><Icon name="done" /></span></span>
      {!!label && (
        <span className="checkbox-label">{label}</span>
      )}
    </div>
  );
};

export default Checkbox;
