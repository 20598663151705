import ResourceApi from "./resource";
import setPreferenceGeneralDoc from "../providers/examSharing/actions/setPreferenceGeneralDoc";

const loadGeneralExamAssocAttachments = async () => {
    const { data } = await ResourceApi.getAttachments();

    if (data && data.data) {
        setPreferenceGeneralDoc(data.data);
    }
  }

export default loadGeneralExamAssocAttachments;
