import { useState, useContext, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { DxContext } from "../../../context-providers/Dx";
import { ExaminationContext } from "../../../context-providers/Examination";
import AnomalyLine from "../../../components-dx/AnomalyLine";
import MedicationLine from "../../../components/MedicationLine";
import { nicePrevalence, formatProbability, getSyndromeName } from "../../../services/examination";
import "./SyndromeDetails.css";
import Publications from "../../../components-dx/Publications";
import Icon from "../../../atoms/Icon/Icon";
import useAuth from '../../../context-providers/Auth';
import LookupApi from "../../../services/lookup";

const SyndromeDetails = ({ t: __, currentSyndrome, allMedications, onClose = () => {}, onPinClick = () => {}, onHideClick = () => {} }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const dxContext = useContext(DxContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();

  const [genes, setGenes] = useState([])
  const [syndromeTabs, setSyndromeTabs] = useState([
    {
      id: "phenotypeMatching",
      label: __("dx.summary.tab.phenotypeMatching"),
      status: "active"
    },
    {
      id: "riskFactors",
      label: __("dx.summary.tab.riskFactors"),
      status: !!currentSyndrome.riskFactors?.length || !!allMedications.length ? "active" : "inactive"
    },
    {
      id: "publications",
      label: __("dx.summary.tab.publications"),
      status: !!currentSyndrome.publications?.length ? "active" : "inactive"
    },
    {
      id: "toConfirm",
      label: __("dx.summary.tab.toConfirm"),
      status: Object.values((currentSyndrome.diagnosticable_by || {})).includes(true) || !!currentSyndrome.future_malformations?.length ? "active" : "inactive"
    },
  ]);

  const isGeneticEnabled = isFeatureFlagEnabled('sonio.dx_syndrome_summary');
  
  useEffect(() => {
    if (isGeneticEnabled) {
      LookupApi.getGenesForSyndrome(currentSyndrome.id).then((genes) => {
        setGenes(genes)
        syndromeTabs.unshift({
          id: "genetics",
          label: __("dx.summary.tab.genetics"),
          status: genes.length > 0 ? "active" : "inactive",
        })
        setSyndromeTabs(syndromeTabs);
      });
    }
  }, []);


  const [currentSyndromeTab, setCurrentSyndromeTab] = useState(syndromeTabs[0].id);

  const isPinned = dxContext.userOverrides.some(rule => rule.type === "syndrome" && rule.id === currentSyndrome.id && rule.action === "add");
  const isHidden = dxContext.userOverrides.some(rule => rule.type === "syndrome" && rule.id === currentSyndrome.id && rule.action === "remove");

  useEffect(() => {
    if(!dxContext.checklistSummaryOpen)
      setCurrentSyndromeTab(syndromeTabs[0]);
  }, [dxContext.checklistSummaryOpen]);

  const fullSyndrome = examinationContext.getSyndromeById(currentSyndrome.id)
  const sName = getSyndromeName(fullSyndrome, examinationContext.MALFORMATIONS, currentSyndrome.malformations, __)

  return (
    <div className="dx-checklist-syndrome-details-container">
      <div className="dx-checklist-summary-syndrome-header">
        <div
          className="close-syndrome"
          onClick={() => onClose(false)}
        >
          <Icon name="left" />
        </div>
        <div className="dx-checklist-summary-syndrome-name">
          {sName}{isGeneticEnabled && (<>
            {fullSyndrome?.main_biological_test?.name[currentLanguage] && (<> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>)}
            {fullSyndrome?.abstract[currentLanguage] && (<><br /><small>{fullSyndrome?.abstract[currentLanguage]}</small></>)}
          </>)}
        </div>
        <div className="dx-checklist-summary-syndrome-cta">
          {!isHidden && (
            <span className={`syndrome-line-is-pinned ${isPinned ? 'active' : ''}`} onClick={() => onPinClick(currentSyndrome.id, !isPinned)}>
              <Icon name="pin" />
              {isPinned && (__("dx.summary.userDefined.syndrome.added"))}
            </span>
          )}
          {!isPinned && (
            <span className={`syndrome-line-is-hidden ${isHidden ? 'active' : ''}`} onClick={() => onHideClick(currentSyndrome.id, !isHidden)}>
              <Icon name="eye-off" />
              {isHidden && (__("dx.summary.userDefined.syndrome.removed"))}
            </span>
          )}
        </div>
      </div>
      <div className="dx-checklist-summary-syndrome-tabs">
        <ul>
          {syndromeTabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => setCurrentSyndromeTab(tab.id)}
              className={`${
                currentSyndromeTab === tab.id ? "selected" : ""
              } ${tab.status}`}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <div className="dx-checklist-summary-syndrome-body">
        {syndromeTabs.map((tab) => (
          <div
            key={tab.id}
            className={`dx-checklist-summary-syndrome-tab ${
              currentSyndromeTab === tab.id ? "visible" : "hidden"
            }`}
          >
            {tab.id === "phenotypeMatching" && (
              <>
                <div className="row">
                  <div className="w6">
                    <div className="proximity-score">
                      <Icon name="matching" />{" "}
                      {__("dx.summary.matchingScore")}
                      <span className="score-value"><b>{formatProbability(currentSyndrome.probability || 0)}</b><small> / 10</small></span>
                    </div>
                  </div>
                  <div className="w6">
                    <div className="syndrome-prevalence">
                      {nicePrevalence(currentSyndrome.prevalence || 0, __)}
                    </div>
                  </div>
                </div>

                {!!currentSyndrome.malformations?.length && (
                  <>
                  <div className="row">
                    <div className="w3">
                      <h3 className="sticky">{__("dx.summary.typicalSigns")}</h3>
                    </div>
                    <div className="w9">
                      {currentSyndrome.malformations.map((malformation) => (
                          <AnomalyLine
                            key={malformation.id}
                            anomaly={malformation}
                            onClick={(m, status) => dxContext.updateMalformation(m, status)}
                            disableOnClick={false}
                            showStatus={true}
                          />                            
                      ))}
                    </div>
                  </div>
                  </>
                )}
                {!!currentSyndrome.atypicalMalformations?.length && (
                  <>
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__("dx.summary.discrepancies")}
                        <small>{__("dx.summary.discrepancies.notes")}</small></h3>
                      </div>
                      <div className="w9">
                        {currentSyndrome.atypicalMalformations.map((malformation) => (
                            <AnomalyLine
                              key={malformation.id}
                              anomaly={malformation}
                              onClick={(m, status) => dxContext.updateMalformation(m, status)}
                              disableOnClick={false}
                              showStatus={true}
                            />
                        ))}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {tab.id === "riskFactors" && (
              (!currentSyndrome.riskFactors?.length && !allMedications.length) ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noRiskFactorsAssociatedWithThisSyndrome')}
                </div>
              ) : (
                <div className="dx-checklist-summary-syndrome-risk-factors">
                  {!!currentSyndrome.riskFactors?.length && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__("dx.summary.tab.riskFactors")}</h3>
                      </div>
                      <div className="w9">
                        {currentSyndrome.riskFactors?.map((risk) => (
                          <div className={`risk-factor ${!!risk.medicalHistoryItem ? "yes" : "no"}`}>
                            <span className="label">{risk.label[currentLanguage]}</span>
                            <span className="option">{risk.options[0].label[currentLanguage]}</span>
                            {!!risk.medicalHistoryItem?.raw_value && !risk.medicalHistoryItem?.tmp_value && (
                              <span className="value">{risk.medicalHistoryItem.raw_value}</span>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {!!allMedications.length && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__("dx.summary.drugs")}</h3>
                      </div>
                      <div className="w9">
                        <div className="medications-list">
                          {allMedications.map( (medication) => (
                            <MedicationLine
                              key={medication.id}
                              medication={medication}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
            {tab.id === "publications" && (
              !currentSyndrome.publications?.length ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noPublicationsAvailableForThisSyndrome')}
                </div>
              ) : (
                <div className="row">
                  <div className="w3">
                    <h3 className="sticky">{__("dx.summary.publications")}</h3>
                  </div>
                  <div className="w9">
                    <Publications publications={currentSyndrome.publications}/>
                  </div>
                </div>
              )
            )}
            {tab.id === "toConfirm" && (
              (!Object.values((currentSyndrome.diagnosticable_by || {})).includes(true) && !currentSyndrome.future_malformations?.length) ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.noFurtherActionsForThisSyndrome')}
                </div>
              ) : (
                <div>
                  {Object.values((currentSyndrome.diagnosticable_by || {})).includes(true) && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__("dx.summary.diagnosticableBy")}</h3>
                      </div>
                      <div className="w9">
                        <ul className="diagnosticable-by">
                          {currentSyndrome.diagnosticable_by.cgh && (<li>{__("dx.summary.cgh")}</li>)}
                          {currentSyndrome.diagnosticable_by.caryotype && (<li>{__("dx.summary.karyotype")}</li>)}
                          {currentSyndrome.diagnosticable_by.dpni && (<li>{__("dx.summary.dpni")}</li>)}
                        </ul>
                      </div>
                    </div>
                  )}
                  {!!currentSyndrome.future_malformations?.length && (
                    <div className="row">
                      <div className="w3">
                        <h3 className="sticky">{__("dx.summary.toCheckInTheFuture")}</h3>
                      </div>
                      <div className="w9">
                        <ul className="to-check-in-the-future">
                        {currentSyndrome.future_malformations
                          .sort((a, b) => b.probability - a.probability)
                          .slice(0, 5)
                          .map((m) => (
                            <li key={m.id}>
                              {
                                examinationContext.MALFORMATIONS[m.id]?.canonical_name[
                                  currentLanguage
                                ]
                              }
                            </li>
                        ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              )
            )}
            {tab.id === "genetics" && (
              genes.length == 0 ? (
                <div className="dx-checklist-summary-syndrome-empty-tab">
                  {__('dx.summary.genetics.noGeneAvailableForThisSyndrome')}
                </div>
              ) : (
                <div className="row">
                  <div className="w3">
                    <h3 className="sticky">{__("dx.summary.genetics.genes")}</h3>
                  </div>
                  <div className="w9">
                    <ul>
                      {genes.map(g => (
                        <li key={g.id}>
                          {__("dx.summary.genetics.geneMapping", g)}
                          <a target="_blank" href={`https://www.ncbi.nlm.nih.gov/gene/?term=${g.ncbi_id}`}>
                            <Icon name="detach" />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withTranslation()(SyndromeDetails);
