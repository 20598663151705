import React from 'react';
import {useApp} from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import Icon from '../../atoms/Icon/Icon';

const EnabledFF = ({ff}) => {
  const FFlag = ({name}) => <span className="ff-item">{name}</span>
  return(
    <div>
      <label>Enabled FF</label>
      {ff.map(ff => <FFlag key={ff} name={ff} />)}
    </div>
  )
}
const LiveConfig = ({liveConfig}) => {
  if(liveConfig == null) return <div>Empty</div>;
  const physician = liveConfig.filters?.operands?.[0].operator === "ALL" ? "ALL" : liveConfig.filters?.operands?.[0].operands
  const machines = liveConfig.filters?.operands?.[1].operator === "ALL" ? "ALL" : liveConfig.filters?.operands?.[1].operands
  return(
    <>
      <div><label>LiveFilter</label> {liveConfig.live_enabled === true ? 'ON' : 'OFF'}</div>
      <div><label>Physician</label> {JSON.stringify(physician)}</div>
      <div><label>Machines</label> {JSON.stringify(machines)}</div>
    </>
  )
}
export default function SessionContent({examination, openChapter, setOpenChapter}) {
  const {site} = useApp();
  const {user, isManager, featureFlags, config} = useAuth();

  if(site == null) return null;

  const enabledFF = [];
  (featureFlags ?? []).forEach( ff => {
    enabledFF.push(ff)
  })

  return(
    <>
    <div className={`debug-section ${openChapter === "session" ? "open" : "closed"}`}>
      <h2 onClick={() => setOpenChapter(chapter => chapter === "session" ? false : "session")}>User and Session info <Icon name="down" /></h2>
      {openChapter === "session" && (
        <div className="debug-subsection">
          <div>
            <div><label>Site</label> {site.name} (ID: {site.id})</div>
            <div><label>User</label> {user.title} ~~ {user.contact_email} (id {user.id})</div>
            <div><label>Is Manager?</label> {String(isManager)}</div>
            <EnabledFF ff={enabledFF} />
          </div>
        </div>
      )}
    </div>
    <div className={`debug-section ${openChapter === "live" ? "open" : "closed"}`}>
      <h2 onClick={() => setOpenChapter(chapter => chapter === "live" ? false : "live")}>LiveConfig <Icon name="down" /></h2>
      {openChapter === "live" && (<LiveConfig liveConfig={config?.live_configuration} />)}
    </div>
    </>
  )
}
