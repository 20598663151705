import React from "react";
import Icon from "../../atoms/Icon/Icon";
import Toggle from '../../atoms/Toggle';
import "./IsNotPractitionerWarn.css";

export default function IsNotPractitionerWarn({isPractitioner, t: __}) {
    if(isPractitioner)
        return null
    return (
        <div>
            <div className="is-not-prac-warn-section">
                <Toggle state={!isPractitioner} disabled={true}/>
                <label>{__("register.isProfessional")}</label>
            </div>
            <div className="is-not-prac-warn-message-wrapper">
                <div className="is-not-prac-warn-icon"><Icon name="warning" /></div>
                <div>{__("register.notProfessionalWarn")}</div>
            </div>
        </div>
    )
}
