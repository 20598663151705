import { useEffect, useState, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';


import { useXMLTemplate } from "../context-providers/XMLTemplate";
import { ExaminationContext } from "../context-providers/Examination";

import { useXMLDocumentPrinter } from '../components/XMLDocument';

export default function ReportPrint() {
  const { examId } = useParams();
  const examinationContext = useContext(ExaminationContext);
  const XMLPrinter = useXMLDocumentPrinter();
  const { reportDataOptions, placeholders } = useXMLTemplate();
  const [placeholderStabilityTimeout, setPlaceholderStabilityTimeout] = useState(1);
  const [htmlContent, setHtmlContent] = useState("");

  const templateBlueprint = examinationContext.debugTemplate || reportDataOptions?.report_template?.blueprint;
  const renderReportHtml = useCallback(async () => await XMLPrinter({
    base64Images: [],
    templateBlueprint
  }), [templateBlueprint, XMLPrinter]);

  useEffect(() => {
    examinationContext.loadExamination(examId);
  }, [examId]);

  useEffect(() => {
    renderReportHtml()
    setPlaceholderStabilityTimeout(setTimeout(() => setPlaceholderStabilityTimeout(null), 1000));
    return () => clearTimeout(placeholderStabilityTimeout);
  }, [placeholders, setPlaceholderStabilityTimeout])

  /* We only load the content once as it should not be updated here */
  useEffect(() => {
    if (!templateBlueprint) return;
    if (!!placeholderStabilityTimeout) return;
    renderReportHtml().then(htmlContent => setHtmlContent(htmlContent));
  }, [templateBlueprint, !placeholderStabilityTimeout]);

  useEffect(() => {
    if(!!placeholderStabilityTimeout) return;
    if(!htmlContent) return;
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    document.head.innerHTML = doc.getElementsByTagName("head")[0].innerHTML;
    document.body.innerHTML = doc.getElementsByTagName("body")[0].innerHTML;
    document.body.setAttribute("ready-to-print", "true");
  }, [htmlContent, !!placeholderStabilityTimeout]);

  const finallyReadyToPrint = htmlContent !== "" && !placeholderStabilityTimeout;

  return <div>
    {finallyReadyToPrint ? null : <div>Loading...</div>}
  </div>
}
