import ReportTemplateChecklist_v2_v1_0 from './Checklist_v2/v1.0.js';
import ReportTemplateChecklist_v2_v1_1 from './Checklist_v2/v1.1.js';

export default function ReportTemplateChecklist_v2(props) {
  const {apiVersion} = props;
  if (apiVersion === '1.0') {
    return ReportTemplateChecklist_v2_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateChecklist_v2_v1_1(props);
  }
  return null;
};
