import React, { useEffect, useRef, useState } from "react";
import UserApi from "../../services/user";
import Button from "../../atoms/Button/Button";
import { withTranslation } from "react-i18next";
import { useQuery } from "../../utils";
import "./index.css";
import { useHistory } from "react-router-dom";
import useAuth from "../../context-providers/Auth";
import Icon from '../../atoms/Icon/Icon';
import { Display } from '../StatusPages';

const SimpleCode = ({code, setCode, onSubmit}) => {

  const onKeyUp = (event) => {
    event.stopPropagation()
    event.preventDefault()

    const form = event.target.form
    const index = [...form].indexOf(event.target)
    if(event.key.toLowerCase() === "backspace") {

      let codeArray = new Array(5).fill().map((_, i) => code[i] || " ")
      if(codeArray[index] == " ") { /* We focus an already empty cell so we empty the previous case and go on it */
        codeArray.splice(Math.max(index - 1, 0), 1, " ")
        setCode(codeArray.join(""))

        if(index-1 >= 0)
          form.elements[index-1].focus()
      } else { /* We focus a filled cell so we just want to empty this cell */
        codeArray.splice(index, 1, " ")
        setCode(codeArray.join(""))
      }
    }
    if("qwertyuiopasdfghjklzxcvbnm".indexOf(event.key.toLowerCase()) !== -1) {

      let codeArray = new Array(5).fill().map((_, i) => code[i] || " ")
      codeArray.splice(index, 1, event.key.toUpperCase())
      const newCode = codeArray.join("")
      setCode(newCode)

      if(index+1 < form.elements.length)
        form.elements[index+1].focus()
      else
        onSubmit(newCode)
    }
  }

  const inputClassName = (char) => {
    if(char && char !== " ")
      return "code-authenticate-code-char-input filled"
    else
      return "code-authenticate-code-char-input"
  }

  const codeArray = new Array(5).fill().map((_, i) => code[i] || " ")
  return (
    <form className="code-authenticate-code-container">
      {
        codeArray.map((char, index) => <input className={inputClassName(char)} value={char} maxLength={1} onKeyUp={onKeyUp} key={index} onChange={() => null}/>)
      }
    </form>
  )
}

const CodeAuthenticate = ({t: __}) => {
  const [code, setCode] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [redirecting, setRedirecting] = useState(false)
  const history = useHistory()
  const query = useQuery()
  const { finalize_login } = useAuth()

  const submit = (code) => {
    setSubmitting(true)
    setSuccess("")
    setError("")

    UserApi.validateMFACode(code)
      .then(() => {
        setRedirecting(true)
        setTimeout(() => {
          const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/"
          finalize_login(afterLoginRedirectTo)
        }, 2000)
      })
      .catch(({response}) => {
        setSubmitting(false) /* Not put in finally to avoid the user submitting again if the API call succeeded */
        if(response.status == 400) {
          return setError(response.data.errors)
        }
        if(response.status == 401) { /* Session is either expired or already validated. Redirecting to the next location to redo the auth workflow if necessary */
          const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/"
          return history.push(afterLoginRedirectTo)
        }
        throw new Error("Error") /* TODO make the oops page appear */
        return
      })
  }

  const resendCode = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setSuccess("")
    setError("")

    UserApi.regenerateMFACode()
      .then(() => {
        setSuccess("codeAuthenticate.codeResent")
      })
      .catch(({response}) =>  {
        if(response.status == 400) {
          return setError(response.data.errors)
        }
        if([401, 404].indexOf(response.status) !== -1) { /* Session is either expired or already validated. Redirecting to the next location to redo the auth workflow if necessary */
          const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/"
          return history.push(afterLoginRedirectTo)
        }
        throw new Error("Error") /* TODO make the oops page appear */
      })
  }

  const back = () => {
    history.push("/login"+window.location.search)
  }

  const checkSessionActivated = () => {
    UserApi.isSessionActivated()
      .then(() => {
        setRedirecting(true)
        const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/"
        finalize_login(afterLoginRedirectTo)
      })
      .catch((response) => {
        if(response.status == 401) {
          const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/"
          return history.push(afterLoginRedirectTo)
        }
        checkSessionActivated()
      })
  }

  useEffect(() => {
    checkSessionActivated()
  }, [])

  if(redirecting) {
    const props = {
      icon_name: 'done-circled',
      messages: {
        exclamation: __('codeAuthenticate.redirecting'),
      }
    }
    return <Display {...props}/>
  }

  return (
    <div className="code-authenticate-page">
      <div className="code-authenticate-wrapper">
        <div className="sonio-header-logo"><img src="/logo-full.svg" alt="Sonio"/></div>
        <div className="code-authenticate-simple-sentence clickable" onClick={() => back()}>
          <Icon name="left-big"/> <span>{__("codeAuthenticate.back")}</span>
        </div>
        <div className="code-authenticate-center-wrapper">
          <h1 className="code-authenticate-title">{__("codeAuthenticate.title")}</h1>
          <div className="code-authenticate-simple-sentence">{__("codeAuthenticate.enterTheCodeSentence")}</div>
          <div className="code-authenticate-code-wrapper">
            <SimpleCode code={code} setCode={submitting?(() => null):setCode} onSubmit={submit}/>
          </div>
          {error ? <div className="code-authenticate-section error-message">{__(error)}</div> : null }
          {success ? <div className="code-authenticate-section success-message">{__(success)}</div> : null }
          <div>
            <Button label={submitting?"...":__("codeAuthenticate.submit")} onClick={() => submit(code)} disabled={submitting}/>
          </div>
          <div className="code-authenticate-section code-authenticate-simple-sentence">
            <span>{__("codeAuthenticate.mailNotReceived")}</span> <a className="resend-link" onClick={resendCode}>{__("codeAuthenticate.resendCode")}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(CodeAuthenticate);
