import React from 'react'
import { getSyndromeName, formatProbability, nicePrevalence } from '../../../../services/examination';
import { withTranslation } from "react-i18next";
import Publications from '../../../../components-dx/Publications';
import MedicationLine from '../../../../components/MedicationLine';
import Icon from '../../../../atoms/Icon/Icon';
import useAuth from '../../../../context-providers/Auth';

const ExaminationReviewResults = ({ t: __, syndromes, syndromeDetails=[], examinationContext }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const { isFeatureFlagEnabled } = useAuth();
  const sortedSyndromes = syndromes.sort((a, b) => b.probability - a.probability) || [];
  const relevantSyndromes = sortedSyndromes.filter((syndrome, index) => index <= 7 || syndrome.probability > 0.1 || syndrome.pinned);
  const notRelevantSyndromes = sortedSyndromes.filter((syndrome, index) => index > 7 && syndrome.probability <= 0.1);

  const malformationIcons = {
    unknown: "",
    no: "close",
    maybe: "question",
    yes: "done",
  }

  if( syndromeDetails.length === 0 ) return false;

  return (
    <div className="row">
      <div className="w12">
        <h2>{__("examinationReview.results")}</h2>
        {!!relevantSyndromes.length && (
          <ul className="examination-review-syndrome-list relevant">
            {relevantSyndromes.map(syndrome => {
              syndrome = {...syndrome, ...syndromeDetails.find(s => s.id === syndrome.id)};
              if (syndrome.id === 0) {
                return(
                  <li key={syndrome.id} id={`syndrome-details-${syndrome.id}`}>
                    <h3>{getSyndromeName(examinationContext.getSyndromeById(syndrome.id), examinationContext.MALFORMATIONS, syndrome.malformations.map(s => s.id), __)}</h3>
                    <div className="row">
                      <div className="w3">
                        <div className="probability"><Icon name="matching" /> <span className="label">{__('examinationReview.matchingScore')}</span> {formatProbability(syndrome.probability)}</div>
                      </div>
                      <div className="w8">
                        {syndrome?.malformations?.map(malformation => malformation.id && (
                          <div key={syndrome.id + '-' + malformation.id}>
                            <h4>{examinationContext.getMalformationById(malformation.id).canonical_name[currentLanguage]}</h4>
                            <div className="prevalence">{nicePrevalence(malformation.prevalence, __)}</div>
                            <ul className="phenotype malformations">
                              {malformation.signs?.map(sign => (
                                <li key={syndrome.id + '-' + malformation.id + '-' + sign.id}>
                                  {sign?.canonical_name?.[currentLanguage]}
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  </li>
                )
              }
              const fullSyndrome = examinationContext.getSyndromeById(syndrome.id)
              const sName = getSyndromeName(fullSyndrome, examinationContext.MALFORMATIONS, syndrome.malformations, __)
              return (
                <li key={syndrome.id} id={`syndrome-details-${syndrome.id}`}>
                  <h3>
                    {sName}
                    {isFeatureFlagEnabled('sonio.dx_syndrome_summary') && fullSyndrome?.main_biological_test?.name[currentLanguage] && (<> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>)}
                  </h3>
                  {isFeatureFlagEnabled('sonio.dx_syndrome_summary') && fullSyndrome?.abstract[currentLanguage] && (<h5>{fullSyndrome?.abstract[currentLanguage]}</h5>)}
                  <div className="row">
                    <div className="w3">
                      <div className="probability"><Icon name="matching" /> <span className="label">{__('examinationReview.matchingScore')}</span> {formatProbability(syndrome.probability)}</div>
                      <div className="prevalence">{nicePrevalence(syndrome.prevalence, __)}</div>
                    </div>
                    <div className="w4">
                      <h4>{__("examinationReview.phenotype")}</h4>
                      <ul className="phenotype malformations">
                      {syndrome?.malformations?.map(malformation => (
                        <li key={syndrome.id + '-' + malformation.id} className={malformation.status}>
                          <Icon name={malformationIcons[malformation.status]} />
                          {malformation?.canonical_name?.[currentLanguage]}
                        </li>
                      ))}
                      </ul>
                    </div>
                    <div className="w4 o1">
                      <h4>{__("examinationReview.discrepancies")}</h4>
                      {!syndrome?.atypicalMalformations?.length ? (
                        <div className="empty">{__("examinationReview.discrepancies.empty")}</div>
                      ) : (
                        <ul className="phenotype malformations">
                        {syndrome?.atypicalMalformations?.map(malformation => (
                          <li key={syndrome.id + '-' + malformation.id} className={malformation.status}>
                            <Icon name={malformationIcons[malformation.status]} />
                            {examinationContext?.MALFORMATIONS?.[malformation.id]?.canonical_name?.[currentLanguage]}
                          </li>
                        ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  {(!!syndrome.riskFactors?.length) && (
                    <div className="examination-review-syndrome-risk-factors">
                      <div className="row">
                        <div className="w3">&nbsp;</div>
                        <div className="w9">
                          <h4>{__("dx.summary.tab.riskFactors")}</h4>
                          {syndrome.riskFactors?.map((risk) => (
                            <div className={`risk-factor ${!!risk.medicalHistoryItem ? "yes" : "no"}`}>
                              <span className="label">{risk.label[currentLanguage]}</span>
                              <span className="option">{risk.options[0].label[currentLanguage]}</span>
                              {!!risk.medicalHistoryItem?.raw_value && !risk.medicalHistoryItem?.tmp_value && (
                                <span className="value">{risk.medicalHistoryItem.raw_value}</span>
                                )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                  {(!!syndrome.teratogens?.length) && (
                  <div className="examination-review-syndrome-risk-factors">
                    <div className="row">
                      <div className="w3">&nbsp;</div>
                        <div className="w9">
                          <h4>{__("dx.summary.drugs")}</h4>
                          <div className="medications-list">
                            {syndrome.teratogens.map( (medication) => (
                              <MedicationLine
                                key={medication.id}
                                medication={medication}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(syndrome?.diagnosticable_by || syndrome?.future_malformations) && (
                    <div className="row">
                      <div className="w3"></div>
                      {Object.values(syndrome.diagnosticable_by).includes(true) && (
                        <div className="w3">
                          <h4>{__("dx.summary.diagnosticableBy")}</h4>
                          <ul className="diagnosticable-by">
                            {syndrome.diagnosticable_by.cgh && (<li key="cgh">{__("dx.summary.cgh")}</li>)}
                            {syndrome.diagnosticable_by.caryotype && (<li key="karyotype">{__("dx.summary.karyotype")}</li>)}
                            {syndrome.diagnosticable_by.dpni && (<li key="dpni">{__("dx.summary.dpni")}</li>)}
                          </ul>
                        </div>
                      )}
                      {!!syndrome.future_malformations.length && (
                        <div className="w6">
                          <h4>{__("dx.summary.toCheckInTheFuture")}</h4>
                          <ul className="to-check-in-the-future">
                          {syndrome.future_malformations
                            .sort((a, b) => b.probability - a.probability)
                            .slice(0, 5)
                            .map((m) => (
                              <li key={m.id}>
                                {
                                  examinationContext.MALFORMATIONS[m.id]?.canonical_name[
                                    currentLanguage
                                  ]
                                }
                              </li>
                          ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  )}
                  {!!syndrome?.publications?.length && (
                    <div className="row">
                      <div className="w9 o3">
                        <h4 className="sticky">{__("dx.summary.publications")}</h4>
                          <Publications publications={syndrome.publications}/>
                      </div>
                    </div>
                  )}
                </li>
              )
            })}
          </ul>
        )}
        {!!notRelevantSyndromes.length && (
          <ul className="examination-review-syndrome-list not-relevant">
            {notRelevantSyndromes.map(syndrome => {
              const fullSyndrome = examinationContext.getSyndromeById(syndrome.id)
              const sName = getSyndromeName(fullSyndrome, examinationContext.MALFORMATIONS, syndrome.malformations, __)
              return (
              <li key={syndrome.id}>
                {sName}{isFeatureFlagEnabled('sonio.dx_syndrome_summary') && (<>
                  {fullSyndrome?.main_biological_test?.name[currentLanguage] && (<> - {fullSyndrome?.main_biological_test?.name[currentLanguage]}</>)}
                  {fullSyndrome?.abstract[currentLanguage] && (<><br /><small>{fullSyndrome?.abstract[currentLanguage]}</small></>)}
                </>)}
                <div className="probability"><Icon name="matching" /> <span className="label">{__('examinationReview.matchingScore')}</span> {formatProbability(syndrome.probability)}</div>
              </li>
            )})}
          </ul>
        )}
      </div>
    </div>
  );
}

export default withTranslation()(ExaminationReviewResults);