import { default as React, useEffect, useState } from 'react';
import Icon from '../../atoms/Icon/Icon';
import LoaderInline from '../../atoms/LoaderInline/LoaderInline';
import UserApi from '../../services/user';

import "./PasswordChecker.css"

function criteriaState(value, FSMState) {
    if (FSMState != "api-result-received")
        return "loading"
    if (value)
        return "checked"
    return "failed"
}

function CriteriaState(value, FSMState, type) {
    switch(criteriaState(value, FSMState)) {
        case "loading":
            return <LoaderInline/>
        case "checked":
            return <Icon name="done" />
        case "failed":
            if (type == "mandatory") {
                return <Icon name="close" />
            } else {
                return <Icon name="subtract" />
            }
    }
}

function criteriaClassName(value, FSMState) {
    const base = "password-checker-criteria"
    const state = criteriaState(value, FSMState)
    if(state == "checked")
        return base + " checked"
    return base
}

/*
 * The function follow a simple FSM for its behavior
 * ┌────────────┐    ┌──────┐    ┌───────────┐    ┌──────────┐
 * │Password not│    │After │    │Waiting for│    │API result│
 * │  checked   ├───►│500 ms├───►│API result ├───►│ received │
 * └────────────┘    └──────┘    └───────────┘    └──────────┘
 *
 * When the user type a letter in the password input the state is reseted to `Password not checked`
 */

export default function PasswordChecker({password, opts, t: __}) {
    const initialCriteria = {
        mandatory: {
            length: {value: false, meta: {}},
            not_full_digit: {value: false, meta: {}},
            not_number_sequence: {value: false, meta: {}},
            not_public_info: {value: false, meta: {}},
        },
        optional: {
            dict_proof: {value: false, meta: {graphemes: "\"password\", \"admin\", \"test\", ..."}} ,
        }
    }
    const [FSMState, setFSMState] = useState("password-not-checked")
    const [criterias, setCriterias] = useState(initialCriteria)
    const [currentTimeout, setCurrentTimeout] = useState(null)

    useEffect(() => {
        if (password.length <= 4) {
            setFSMState("api-result-received")
            setCriterias(initialCriteria)
            return
        }
        clearTimeout(currentTimeout) /* If opts or password has changed we cancel the test */
        setFSMState("password-not-checked")
        setCurrentTimeout(
            setTimeout(() => {
                setFSMState("waiting-for-api-result")
                UserApi.validatePassword(password, opts).then(({data}) => {
                    setFSMState("api-result-received")
                    setCriterias(data)
                })
            }, 500)
        )
    }, [password, JSON.stringify(opts)])

    return (
        <div className="password-checker-wrapper">
            {
                Object.entries(criterias.mandatory).map(([key, {value, meta}]) => (
                    <div className={criteriaClassName(value, FSMState)} key={key}>
                        <div className="password-checker-criteria-state">{CriteriaState(value, FSMState, "mandatory")}</div>
                        <div className="password-checker-criteria-name">{__("register.tip.password."+key, meta)}</div>
                    </div>
                ))
            }
            {
                Object.entries(criterias.optional).map(([key, {value, meta}]) => (
                    <div className={criteriaClassName(value, FSMState)} key={key}>
                        <div className="password-checker-criteria-state">{CriteriaState(value, FSMState, "optional")}</div>
                        <div className="password-checker-criteria-name">{__("register.tip.password."+key, meta)}</div>
                    </div>
                ))
            }
        </div>
    )
}
