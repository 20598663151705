import { useEffect, useState, useMemo, useCallback } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../../../atoms/Icon/Icon";
import GaItem from "./v1.1/GaItem";
import AssignedGaItem from "./v1.1/AssignedGaItem";
import NonEditableGaItem from "./v1.1/NonEditableGaItem";
import PlaceholderLoader from "../../PlaceholderLoader";

const NONE = "none";
const nonEditableGaSlugs = ["lmp", "conception_date", "embryo_transfer", "edd", "prev_ultrasound"];


const splitSlug = (method) => {
  if (!method) return false;
  const [_, m, s] = method.split('.');
  return { method: m, standard: s || NONE };
};

const makeSlug = (method, standard) => `ga.${method}` + (standard !== NONE ? `.${standard}` : "");

const ReportTemplateGaTableBody = ({
  t: __,
  props,
  placeholders,
  reportDataOptions,
  onEndEditingDating,
  setAssignedGa,
  appPreferences,
  revertAssignedGa,
  setRequiredAndInvalid,
  showErrors,
  reportMode,
  onEndEditing,
  templateLabels,
  templateMethods,
  availableSlugs,
}) => {
  const print = reportMode === "print";
  const numberOfFetuses = placeholders["patient.nb_fetuses"]?.value || 1;
  const availableStandards = reportDataOptions?.dating_standards;
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const methodsWithStandards = useMemo(() => {
    const value = availableSlugs
      .reduce((acc, slug) => {
        const { method, standard } = splitSlug(slug);
        if (!Object.hasOwn(acc, method)) acc[method] = [];
        acc[method].push({ value: standard, label: availableStandards?.[standard]?.[currentLanguage] });
        return acc;
      }, {})

    value[NONE] = [{ value: NONE, label: availableStandards?.[NONE]?.[currentLanguage] }];
    return value;
  },
    [availableSlugs, availableStandards]);

  const datingMethod = useCallback((slug) => {
    return reportDataOptions?.dating_methods?.[slug];
  }, [reportDataOptions?.dating_methods])

  // TODO: feels a bit hacky, we need better labels in the DB
  const methodsDropdownOptions = useMemo(() => Object.entries(methodsWithStandards)
    .sort((a, b) => a[0] >= b[0] ? 1 : -1)
    .map(([method, standards]) => {
      const slug = makeSlug(method, standards[0]?.value);
      const option = { value: method, label: datingMethod(slug)?.label?.[currentLanguage] };
      if (!option.label) option.invisible = true;
      return option;
    })
    .filter(({ value }) => !nonEditableGaSlugs.includes(value)),
    [methodsWithStandards, placeholders, currentLanguage]);

  const { "column-labels": columnLabels, methods, labels, "assigned-label": assignedLabel } = props;
  const [updatingAssignedGa, setUpdatingAssignedGa] = useState(false);

  const defaultFetusFromSlug = (slug, currFetus = false) => {
    if (datingMethod(slug)?.patient_value) return 0;
    return currFetus || 1;
  };


  const makeDatingRow = (fetus, slug, label = false, rowId = false) => {
    rowId = rowId || Math.random().toString(16).slice(2);
    const visible = true;
    return { fetus, slug, rowId, visible, label };
  };


  const makeDefaultLmpAndAssignedRows = () => {
    const rows = nonEditableGaSlugs.map(gaSlug => makeDatingRow(0, `ga.${gaSlug}`, false, gaSlug));
    const assignedRow = { label: (assignedLabel || __("report.gatable.assignedDating")), rowId: "assigned", visible: true };
    return [...rows, assignedRow];
  };

  const getDataToDisplay = () => {
    if (placeholders["dating.table"]?.value) {
      return placeholders["dating.table"].value;
    }
    else {
      // Initial GAs to display based on report template, or default to LMP
      const rows = templateMethods
        .flatMap((method, idx) => {
          const slug = `ga.${method}`;
          const label = templateLabels[idx] || false;
          if (defaultFetusFromSlug(slug) === 0) return [makeDatingRow(0, slug, label)];
          return [...Array(numberOfFetuses).keys()].map(fetus => makeDatingRow(fetus + 1, slug, label));
        });

      return [...rows, ...makeDefaultLmpAndAssignedRows()];
    }
  };

  // TODO initialize this state inside a useEffect once the rendering of reportData is fixed
  const displayData = getDataToDisplay();
  const [currentData, setCurrentData] = useState(displayData);


  let defaultColumnLabels = [
    __("report.gatable.label"),
    __("report.gatable.scanDate"),
    __("report.gatable.method"),
    __("report.gatable.standard"),
    __("report.gatable.currentValue"),
    __("report.gatable.EDD"),
    ""
  ];

  const columnLabelsArray = columnLabels?.split("|") || [];
  for (let i = 0; columnLabelsArray[i]; i++) {
    defaultColumnLabels[i] = columnLabelsArray[i];
  }

  const fetusDropdownOptions = [...Array(numberOfFetuses + 1).keys()].map(fetus => ({ value: fetus, label: placeholders["fetus.name"]?.value?.[fetus] }));
  const showFetusDropDown = numberOfFetuses > 1;

  const doOptimisticUpdate = async (newData) => {
    const oldData = JSON.parse(JSON.stringify(currentData));
    setCurrentData(newData);
    const ok = await onEndEditingDating(newData);
    if (!ok) {
      setCurrentData(oldData);
    }
  };

  const updateRow = (updatedValues, rowId) => {
    const idxToUpdate = currentData.findIndex(row => row.rowId === rowId);
    if (idxToUpdate === -1) return;
    const copy = JSON.parse(JSON.stringify(currentData));
    copy[idxToUpdate] = { ...copy[idxToUpdate], ...updatedValues };
    doOptimisticUpdate(copy);
  };

  const addRow = () => {
    const slug = "ga.none";
    const fetus = defaultFetusFromSlug(slug);
    const newData = [...currentData, makeDatingRow(fetus, slug)];
    doOptimisticUpdate(newData);
  };

  const removeRow = (rowId) => {
    const idxToRemove = currentData.findIndex(row => row.rowId === rowId);
    if (idxToRemove === -1) return;
    const copy = JSON.parse(JSON.stringify(currentData));
    copy.splice(idxToRemove, 1);
    doOptimisticUpdate(copy);
  };

  /* TODO make this as a useMemo and only have static data in it */
  const gaItemProps = {
    datingMethod,
    currentExamDate: placeholders["examination.date"]?.value,
    methodsWithStandards,
    methodsDropdownOptions,
    fetusDropdownOptions,
    showFetusDropDown,
    updateRow,
    removeRow,
    setAssignedGa,
    updatingAssignedGa,
    setUpdatingAssignedGa,
    splitSlug,
    makeSlug,
    defaultFetusFromSlug,
    appPreferences,
    currentLanguage,
    print,
    reportMode,
    onEndEditing,
    revertAssignedGa
  };

  const invalidValue = () => !placeholders?.["ga.assigned.value"]?.value;

  useEffect(() => {
    if (setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ?
          prevState.add("ga_table") : prevState.delete("ga_table");
        return prevState;
      });
    }
  }, [placeholders?.["ga.assigned.value"]?.value, setRequiredAndInvalid]);

  const examId = placeholders["examination.id"]?.value;
  const assignedExam = placeholders["ga.assigned.exam"]?.value;
  const assignedFetus = placeholders["ga.assigned.fetus"]?.value;
  const assignedMethod = placeholders["ga.assigned.method"]?.value;
  const assignedValue = placeholders["ga.assigned.value"]?.value;
  const dateObtained = assignedExam == examId
    ? placeholders["examination.date"]?.raw_value
    : placeholders["previous_exams"]?.value?.[assignedExam]?.examination_date;

  const gaData = { slug: assignedMethod, assignedOnDifferentExam: assignedExam !== examId, examId: assignedExam, fetus: assignedFetus, value: assignedValue, dateObtained, isAssigned: true };

  return (
    <>
      <div className={`ga-table ${numberOfFetuses > 1 ? 'multi-fetal' : ''} ${invalidValue() && showErrors ? 'required-error' : ''}`}>
        <div className={`ga-item column-heading ${showFetusDropDown ? "show-fetus" : ""}`}>
          {defaultColumnLabels.map((label, index) => {
            return <div key={index} className={index === 0 && showFetusDropDown ? "double-col" : ""}>{label || <>&nbsp;</>}</div>;
          })}
        </div>

        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "lmp")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={placeholders["site.timezone"]?.value}
          pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
        />
        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "conception_date")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          gaItemProps={gaItemProps}
          value={placeholders["episode.conception_date"]?.value}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={placeholders["site.timezone"]?.value}
          pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
        />

        {["ivf", "gift_zift"].includes(placeholders["episode.conception_method"]?.value) &&
          <NonEditableGaItem
            data={currentData.find(row => row.rowId === "embryo_transfer")}
            reportDataOptions={reportDataOptions}
            placeholders={placeholders}
            gaItemProps={gaItemProps}
            reportMode={reportMode}
            onEndEditing={onEndEditing}
            timezone={placeholders["site.timezone"]?.value}
            pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
          />
        }

        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "edd")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={placeholders["site.timezone"]?.value}
          pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
        />
        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "prev_ultrasound")}
          reportDataOptions={reportDataOptions}
          placeholders={placeholders}
          gaItemProps={gaItemProps}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={placeholders["site.timezone"]?.value}
          pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
        />

        {currentData.filter(d => ![...nonEditableGaSlugs, "assigned"].includes(d.rowId)).map((data, index) => {
          return <GaItem
            key={index}
            assignedGa={gaData}
            data={data}
            placeholders={placeholders}
            gaItemProps={gaItemProps}
            reportMode={reportMode}
            onEndEditing={onEndEditing}
            timezone={placeholders["site.timezone"]?.value}
            pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
          />;
        })}

        <AssignedGaItem
          data={currentData.find(d => d.rowId === "assigned")}
          placeholders={placeholders}
          gaData={gaData}
          selectedAt={placeholders["ga.assigned.selected_at"]?.raw_value}
          gaItemProps={gaItemProps}
          prevUsMeasurement={placeholders["episode.prev_ultrasound_option"]?.value}
          conceptionMethod={placeholders["episode.conception_method"]?.value}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          timezone={placeholders["site.timezone"]?.value}
          pregnancyLengthInDays={placeholders["examination.pregnancy_length_in_days"]?.value}
        />
        {reportMode === "edit" &&
          <div className="ga-item add-row" onClick={addRow}>
            <div className="plus-icon">
              <Icon name="add" />
            </div>
          </div>
        }
      </div>
    </>
  );
};

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateGaTable({
  props,
  placeholders,
  ...otherProps
}) {


  const availableSlugs = (placeholders["ga.estimations"]?.value ?? []);

  const templateLabels = props.labels?.split("|") || [];
  const templateMethods = props.methods?.split("|") || [];

  const requiredEstimationPlaceholders = placeholders["ga.estimations"]?.value || [];

  // No need to make them uniq as PlaceholderLoader will take care of it
  const requiredPlaceholders = [
    "dating.table",
    "patient.nb_fetuses",
    "episode.lmp_date",
    "episode.cycle_length",
    "episode.conception_date",
    "episode.conception_method",
    "episode.embryo_transfer_day",
    "episode.prev_ultrasound_option",
    "episode.prev_ultrasound_ga",
    "episode.prev_ultrasound_exam_date",
    "episode.prev_ultrasound_biometry_value",
    "episode.edd_methods",
    "ga.estimations",
    "ga.assigned.exam",
    "ga.assigned.fetus",
    "ga.assigned.method",
    "ga.assigned.value",
    "ga.assigned.selected_at",
    "examination.id",
    "examination.date",
    "examination.pregnancy_length_in_days",
    "previous_exams",
    "fetus.name",
    ...requiredEstimationPlaceholders
  ];

  return (
    <PlaceholderLoader
      Component={withTranslation()(ReportTemplateGaTableBody)}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      templateLabels={templateLabels}
      templateMethods={templateMethods}
      availableSlugs={availableSlugs}
      props={props}
      {...otherProps}
    />
  );
};
