import { useEffect, useState } from "react";
import ResourceApi from "../../services/resource";
import AppointmentsView from "./AppointmentsView";

const Appointments = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    // const appointmentLoader = setInterval(() => loadAppointments(), 2000);

    // return () => clearInterval(appointmentLoader);
    loadAppointments();
  }, []);

  const loadAppointments = () => {
    ResourceApi.appointment().then(({ data: { data } }) =>
      setAppointments(data)
    );
  };

  return (
    <AppointmentsView
      appointments={appointments}
      syncWithEmr={() =>
        ResourceApi.syncWithEmr().then((resp) => loadAppointments())
      }
    />
  );
};

export default Appointments;
