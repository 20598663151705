import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context-providers/App";
import { withTranslation } from "react-i18next";
import LookupApi from "../../services/lookup";
import "./SignSearchBar.css";
import Icon from "../../atoms/Icon/Icon";

/**
 * <SignSearchBar
 *  searchKey = string
 *  label = string (the placeholder)
 *  excludeIds = array of anomaly ids to exclude
 *  onChange = ([results]) => {} trigger once results are loaded
 *  onChangeSearchKey = ("newSearchKey") => {} trigger on every search key changes
 * />
 */

let searchBarTimeout = false;

const SignSearchBar = ({ t: __, searchKey = '', notification = '', label = '', excludeIds = [], onChange = () => {}, onChangeSearchKey = () => {}, isLoading = () => {} }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const appContext = useContext(AppContext);

  const [actualNotification, setActualNotification] = useState("");

  useEffect(() => {
    setActualNotification(notification);
    setTimeout(() => setActualNotification(""), 3000);
  }, [notification]);

  const getResults = (e) => {
    if( searchBarTimeout ) clearTimeout(searchBarTimeout);
    
    const newSearchKey = e.currentTarget.value;
    
    onChange([]);
    onChangeSearchKey(newSearchKey);
    
    if(!newSearchKey || newSearchKey.length < 3) {
      isLoading(false);
      appContext.setIsLoading(false);
      return false;
    }
    
    appContext.setIsLoading(true);
    isLoading(true);
    
    searchBarTimeout = setTimeout( () => LookupApi.getMalformationBySearchKey(newSearchKey, excludeIds, currentLanguage).then(results => {
      const searchResults = results.data
        .sort((a,b) => {
          const aMatching = (a.canonical_name?.[currentLanguage] || a.canonical_name)?.toLowerCase().substr(0, newSearchKey.length) === newSearchKey?.toLowerCase();
          const bMatching = (b.canonical_name?.[currentLanguage] || b.canonical_name)?.toLowerCase().substr(0, newSearchKey.length) === newSearchKey?.toLowerCase();
          if(aMatching && !bMatching) return -1;
          if(!aMatching && bMatching) return 1;
          return 0;
        });
      onChange(searchResults);
      isLoading(false);
      appContext.setIsLoading(false);
      return true;
    }), 500);
  }

  return (
        <div className="sign-search-bar">
          {!!actualNotification && (
            <div className="sign-search-bar-notification">{actualNotification}</div>
          )}
          <Icon name="search" />
          {!!searchKey && (<Icon name="close" className="cancel" onClick={() => { onChange([]); return onChangeSearchKey(''); }} />)}
          <input type="text" value={searchKey} placeholder={label} onChange={(e) => getResults(e)}/>
          {searchKey.length > 0 && searchKey.length < 3 && (
            <span className="hint">{__("signSearchBar.typeAtLeastNChars", {amount: 3})}</span>
          )}
        </div>
    );
};

export default withTranslation()(SignSearchBar);
