import { useCallback, useContext, useEffect } from 'react';
import { withTranslation } from "react-i18next";
import { useParams, useHistory } from 'react-router-dom';
import { ExaminationContext } from '../../context-providers/Examination';
import ExaminationLive from '../ExaminationLive';
import ExaminationMedicalHistory from '../ExaminationMedicalHistory';
import { LiveExaminationContext } from '../../context-providers/LiveExamination';
import "./WindowView.css";
import ImageManipulation from '../ImageManipulation';

const WindowView = ({t: __}) => {
  let { view, examId } = useParams();
  const examinationContext = useContext(ExaminationContext);
  const liveExaminationContext = useContext(LiveExaminationContext);
  const history = useHistory();
  const currentView = view;
  const currentExamId = Number(examId);

  const loadExaminationAccordingUrl = useCallback(async () => {
    if (currentExamId) {
      await examinationContext.loadExamination(currentExamId);
      liveExaminationContext.track(currentExamId);
    }
  }, [currentExamId])

  const changeWindowVariables = (event) => {
    if (event.origin !== window.location.origin) return;
    const targetUrl = event?.data?.url ? new URL(event.data.url) : false;
    if (targetUrl?.pathname) history.push(targetUrl.pathname);
  }

  useEffect(() => {
    window.addEventListener("message", changeWindowVariables, false);

    return () => {
      window.removeEventListener("message", changeWindowVariables);
    }
  }, []);
  
  useEffect(() => {
    loadExaminationAccordingUrl();
  }, [loadExaminationAccordingUrl]);


  if (examinationContext.examination && examId) {

    if (currentView === "report") {
      return <ExaminationLive naked="report" />
      
    } else if (currentView === "slide") {
      const [slideKey] = window.location.pathname.replace(/^\//, "").split("/").slice(3);
      if (slideKey) return <ExaminationLive naked="slide" nakedProps={{view: currentView, examId: currentExamId, slideKey}} />
      
    } else if (currentView === "anamnesis") {
      return <ExaminationMedicalHistory />
      
    } else if (currentView === "dicom-editor") {
      const [instanceId] = window.location.pathname.replace(/^\//, "").split("/").slice(3);
      return <ImageManipulation instanceId={instanceId} />
    }

  }

  return <div className="windowview-empty-page-message">{__(`windowView.blank.${view}`)}</div>;
}

export default withTranslation()(WindowView);