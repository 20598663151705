import { withTranslation } from "react-i18next";
import "./Publications.css";

const Publications = ({ t: __, publications = [] }) => {
  return (
    <div className="publications-container">
      <ul className="publications">
        {publications?.map((publication) => (
          <li key={publication.url}>
            <div className="publication-title">{publication.title}</div>
            <div className="publication-meta">
              <span>{publication.authors}, {publication.year}{" "}</span>
              {publication.url && (
                <span className="publication-link">
                  <a href={publication.url} target="_blank">
                    {__("dx.summary.readMore")}
                  </a>
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default withTranslation()(Publications);
