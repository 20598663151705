import { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import SlideStarButton from "../../components/Slide/SlideStarButton";
import config from "../../config";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";
import { getInstanceThumbnailUri, isNullOrUndefined, isGaInTrimester } from "../../utils";
import DetectionScore from "../DetectionScore/DetectionScore";
import "./Slide.css";
import SlideDrawing from "./SlideDrawing/SlideDrawing";

const NOT_STARTED = "not_started";
const IN_PROGRESS = "in_progress";
const DONE = "done";
const ERROR = "error";
const UNKOWN = "error";
let analyzingTimeout = false;

const LiveSlide = ({ t: __, trimester, sex, slide, type = null, media, atRisk = false, zoomLevel = 3, setManualUserInteraction, dictionaryTerms }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useContext(ExaminationContext)
  const { isFeatureFlagEnabled } = useAuth();
  const isVideo = media?.dicom_media_type === "video";
  const isDetectable = slide?.detectable && isGaInTrimester(slide.min_ga, slide.max_ga, trimester) && slide.techno === "us";

  if (!type && !!slide?.type) type = slide?.type;
  const isExtractedFromVideo = !!media?.dicom_origin_id;
  const isVerified = isVideo || media?.verified;
  const isOptional = !!slide?.optional;
  const isOther = type === "other";
  const toRetake = media?.to_retake;

  const [viewEvaluation, setViewEvaluation] = useState(null);
  const [isError, setIsError] = useState(false);

  const instancePrediction = (media
    ?.predictions
    ?.sort((a, b) => b.id - a.id)
    .find(prediction => prediction.type === 'view' && prediction.status === 'done')?.data || [])
    ?.[0];

  const external_to_routine_detected = instancePrediction
    ?.external_to_routine_detected?.[0];

  const annotation_detected = instancePrediction?.annotation_detected || ((type === "other" && !!external_to_routine_detected?.view_id) ? examinationContext.instanceViews[external_to_routine_detected?.view_id]?.label[currentLanguage] : "");

  const qcAmount = useMemo(() => {
    const mandatoryQC = examinationContext.getQualityCriteriaByInstanceViewId(slide, media, trimester, "mandatory");
    const unusualChecklistItems = (examinationContext.examination.checklist_items?.filter(checklist_item => checklist_item.instance_view_id.includes(slide?.id)) || []);
    return mandatoryQC.filter(qc => qc.is_valid === false).length + unusualChecklistItems.length;
  }, [examinationContext.getQualityCriteriaByInstanceViewId, JSON.stringify(examinationContext.examination.checklist_items), slide?.id, media, trimester]);

  const isVideoProcessingPending = media?.video_processing_state === NOT_STARTED

  const isVideoProcessingDone = media?.video_processing_state === DONE;

  const isVideoProcessingInProgress = media?.video_processing_state === IN_PROGRESS;

  const isVideoProcessingDiscarded = media?.video_processing_state === ERROR;

  const videoProcessingFinalState = isVideoProcessingDone ? DONE : isVideoProcessingInProgress ? IN_PROGRESS : isVideoProcessingDiscarded ? ERROR : isVideoProcessingPending ? NOT_STARTED : UNKOWN;

  const isVideoExtractionPending = media?.video_extraction_state === NOT_STARTED || media?.video_extraction_state === undefined

  const isVideoExtractionDone = media?.video_extraction_state === DONE;

  const isVideoExtractionInProgress = media?.video_extraction_state === IN_PROGRESS;

  const isVideoExtractionDiscarded = media?.video_extraction_state === ERROR;

  const videoExtractionFinalState = isVideoExtractionDone ? DONE : isVideoExtractionInProgress ? IN_PROGRESS : isVideoExtractionDiscarded ? ERROR : isVideoExtractionPending ? NOT_STARTED : UNKOWN;

  const detectedAnnotationShouldBeDisplayed = () => {
    const annotation = annotation_detected?.trim();
    if (!dictionaryTerms?.includes(annotation)) return false;
    return !!annotation && annotation?.toLowerCase() !== slide?.label?.[currentLanguage]?.toLowerCase();
  }

  useEffect(() => {
    if (media && isDetectable) {
      if (analyzingTimeout) clearTimeout(analyzingTimeout);
      analyzingTimeout = setTimeout(() => setIsError(true), 6000);
    }
  }, []);

  useEffect(() => {
    if (qcAmount !== false && analyzingTimeout) clearTimeout(analyzingTimeout);
  }, [qcAmount]);

  useEffect(() => {
    setViewEvaluation(() => {
      const score = media?.ve_prediction?.data?.[0]?.conf
      const viewEvaluationIsOverriden = !isNullOrUndefined(media?.view_evaluation_override)
      const warningFromOverride = viewEvaluationIsOverriden && media?.view_evaluation_override === true
      const warningFromScore = media?.ve_prediction?.data?.[0]?.conf.normalized_score < config.evaluationScoreThreshold

      return {
        score: score,
        warning: viewEvaluationIsOverriden ? warningFromOverride : warningFromScore,
        source: viewEvaluationIsOverriden ? "user" : "vision-ai"
      }
    });
  }, [JSON.stringify(media)]);

  useEffect(() => {
    setIsError(false);
  }, [slide?.id]);

  return (
    <div className={`slide-live-dt-container trimester-${trimester} ${isOther ? "is-other" : ""} ${!!media ? "has-media" : ""} ${isVerified ? "verified" : "not-verified"} ${isOptional ? "optional" : "mandatory"} ${!!toRetake ? "to-retake" : ""} ${isFeatureFlagEnabled("sonio.risk") && !!atRisk ? "at-risk" : ""} zoom-level-${zoomLevel}`} >
      <div className="slide-live-dt-protect-click" />
      {!!media && (
        <div className="slide-live-dt-thumbnail slide-live-dt-media">
          <img
            src={getInstanceThumbnailUri(media.id)}
            alt="media thumbnail"
            data-media-id={media.id}
          />
        </div>
      )}
      {!!slide && (
        <div className="slide-live-dt-thumbnail slide-live-dt-slide">
          <SlideDrawing
            version="2"
            trimester={trimester}
            slide={slide}
            type={slide.type}
            sex={sex}
            rotation={slide?.transformation?.find(transformationDef => transformationDef?.fetus_position_id === examinationContext.examination.fetus_position_id)}
          />
        </div>
      )}
      {toRetake && (
        <div className="slide-live-dt-icon">
          <Icon name="reload" />
        </div>
      )}
      {!toRetake && isVideo && (
        <div className="slide-live-dt-icon">
          <Icon name="play-circled" />
        </div>
      )}
      {!toRetake && !isVideo && isExtractedFromVideo && (
        <div className="slide-live-dt-icon">
          <Icon name="video" />
        </div>
      )}

      {isVideo && (<div className="slide-live-dt-video-status">
        {videoProcessingFinalState !== DONE && (
          <div className="video-status-item">
            {videoProcessingFinalState === DONE ?
              __("examination-plane.video.processed") :
              (videoProcessingFinalState === NOT_STARTED || videoProcessingFinalState === IN_PROGRESS) ?
                __("examination-plane.video.processing")
                :
                __("examination-plane.video.process.failed")
            }
          </div>
        )}
        {videoProcessingFinalState === DONE && videoExtractionFinalState !== DONE && videoExtractionFinalState !== NOT_STARTED && (
          <div className="video-status-item">
            {__(`examination-plane.video-extraction.${videoExtractionFinalState}`)}
          </div>
        )}
      </div>)}

      <div className="slide-header">
        <div className="slide-title">
          {isOther && media && !isVerified && (
            <div className="slide-label">
              <Icon name="score-low" />
            </div>
          )}
          {!isOther ? (
            <>
              {!!slide && <div className="slide-label">
                {!!slide.techno && slide.techno !== "us" && (
                  <div className="slide-techno">
                    {__("examination-plane.techno." + slide.techno)}
                  </div>
                )}
                {!!isDetectable && (
                  <><Icon name="ai-detection" />&nbsp;</>
                )}

                {detectedAnnotationShouldBeDisplayed() ? (
                  <span className="original-annotation">{annotation_detected}</span>
                ) : (
                  <span className="original-annotation">{slide?.label?.[currentLanguage]}</span>
                )}
                {slide?.view_count > 1 && (
                  <span className="slide-counter">
                    {slide.idx_in_view + 1}/{slide.view_count}
                  </span>
                )}
                {detectedAnnotationShouldBeDisplayed() && (
                  <span className="template-annotation">{slide?.label?.[currentLanguage]}</span>
                )}
              </div>}
            </>
          ) : (
            !!slide && (<>
              <div className="slide-label">
                {detectedAnnotationShouldBeDisplayed() && annotation_detected}
              </div>
              <div className="slide-image-evaluation">
                {!!external_to_routine_detected?.conf?.normalized_score && (
                  <DetectionScore
                    score={external_to_routine_detected?.conf?.normalized_score}
                    source={external_to_routine_detected?.conf?.source}
                    showIcon={false}
                    showBaloon={false}
                    variant="transparent"
                    instancePrediction={instancePrediction}
                    isDetectable={isDetectable}
                    dictionaryTerms={dictionaryTerms}
                  />
                )}
              </div>
            </>)
          )}
        </div>
        {isFeatureFlagEnabled("sonio.share") && isFeatureFlagEnabled('sonio.routine') && (
          <div className="slide-icons">
            <SlideStarButton
              media={media}
              onClickCallback={() => setManualUserInteraction(true)}
            />
          </div>
        )}
      </div>

      {isError ? (
        <span className="slide-qc-evaluation pending">{__("examination.error.short")}</span>
      ) : (
        <>
          {!isOther && !!qcAmount && (
            <span className="slide-qc-evaluation">{qcAmount}</span>
          )}
          {!!media && isDetectable && qcAmount === false && (
            <span className="slide-qc-evaluation pending">{__("examination-plane.pending")}</span>
          )}
        </>
      )}

    </div>
  )
}

export default withTranslation()(LiveSlide);