import React from 'react';
import useVersion from "../../context-providers/LatestVersion";
import Icon from '../../atoms/Icon/Icon';

export default function AppMetaContent({examination, openChapter, setOpenChapter}) {
  const {appMeta} = useVersion();
  if(appMeta == null) return null;
  
  return(
    <div className={`debug-section ${openChapter === "appmeta" ? "open" : "closed"}`}>
      <h2 onClick={() => setOpenChapter(chapter => chapter === "appmeta" ? false : "appmeta")}>Release info <Icon name="down" /></h2>
      {openChapter === "appmeta" && (
        <div className="debug-subsection">
          <div>
            <div><label>git Branch</label> {appMeta?.git_branchname}</div>
            <div><label>git HEAD</label> {appMeta?.git_head}</div>
          </div>
        </div>
      )}
    </div>
  )
}