import { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import { ExaminationContext } from "../../context-providers/Examination";
import { getRegExpValue } from "../../utils";
import "./SignLine.css";

const SignLine = ({ t: __, sign, cta = "", isHidden = false, disableOnClick = true, onClick = () => {}, searchKey = false }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const examinationContext = useContext(ExaminationContext);
  const [disabled, setDisabled] = useState(false);
  const signName = examinationContext.SEMIOLOGYSIGNS?.[sign.id]?.canonical_name?.[currentLanguage] || sign.id;

  useEffect(() => {
    setDisabled(isHidden);
  }, [isHidden])

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if(!string) return { __html: "" };
    if(!searchKey) return { __html: string };
    const expression = getRegExpValue(searchKey);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  }

  return (
    <li
      className={`sign-line ${
        disabled ? "disabled" : ""
      } ${disableOnClick ? "interactive" : "static"}`}
    >
      <div onClick={() => {
        onClick(sign);
        if(disableOnClick) setDisabled(true);
      }}>
        <span className="sign-name">
          <span dangerouslySetInnerHTML={highlight(signName)} />
        </span>
        <span className="sign-cta">
          {cta}{" "}
          <Icon name="flowchart" />
        </span>
      </div>
    </li>
  );
};

export default withTranslation()(SignLine);
