import { isValidElement, cloneElement } from "react";
import { conditionRequiredPlaceholders, checkCondition } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateConditionBody({ defaultKey = '', props, children, fetus, placeholders }) {
  if (props.fetus) fetus = props.fetus;
  if (!Array.isArray(children)) children = [children];

  /* Find the first matching if */
  for (const child of children) {
    if (isValidElement(child)
      && child.props.type === "if"
      && checkCondition({ ...child.props?.props }, fetus, placeholders)
    ) {
      return Array.isArray(child.props?.children) ? child.props?.children.map((child, i) => {
        if (isValidElement(child)) {
          return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
        } else return false;
      }).filter(child => child) : cloneElement(child.props?.children, { ...child.props?.children.props, fetus, defaultKey: `${defaultKey}_0_block_${fetus}` });
    }
  }

  /* If no if has ever matched we match the first matching else */
  for (const child of children) {
    if (isValidElement(child)
      && child.props.type === "else"
    ) {
      return Array.isArray(child.props?.children) ? child.props?.children.map((child, i) => {
        if (isValidElement(child)) {
          return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
        } else return false;
      }).filter(child => child) : cloneElement(child.props?.children, { ...child.props?.children.props, fetus, defaultKey: `${defaultKey}_0_block_${fetus}` });
    }
  }

  return false;
}

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateCondition({
  children,
  ...otherProps
}) {
  const requiredPlaceholders = children
    .filter(child => isValidElement(child) && child.props?.type === "if")
    .map(child => conditionRequiredPlaceholders(child.props?.props))
    .flat();

  return (
    <PlaceholderLoader
      Component={ReportTemplateConditionBody}
      requiredPlaceholders={requiredPlaceholders}
      {...otherProps}
    >
      {children}
    </PlaceholderLoader>
  );
}
