import { withTranslation } from "react-i18next";
import Icon from "../../../atoms/Icon/Icon";
import { getRegExpValue, formatCheckListItemLabel } from "../../../utils";
import "./ChecklistItemLine.css";

const ChecklistItemLine = ({ t: __, checklistItem, cta = "", onClick = () => { }, searchKey = false, fetuses }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const checklistItemLabel = formatCheckListItemLabel(checklistItem, currentLanguage, __, fetuses)

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if (!string) return { __html: "" };
    if (!searchKey) return { __html: string };
    const expression = getRegExpValue(searchKey);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  }

  return (
    <li className="checklist-item-line-search">
      <div onClick={() => onClick(checklistItem)}>
        <span className="checklist-item-name">
          <span dangerouslySetInnerHTML={highlight(checklistItemLabel)} />
        </span>
        <span className="checklist-item-cta">
          {cta}{" "}
          <Icon name="right" />
        </span>
      </div>
    </li>
  );
};

export default withTranslation()(ChecklistItemLine);
