import { useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import { getRegExpValue } from "../../utils";
import { ExaminationContext } from "../../context-providers/Examination";
import { AppContext } from "../../context-providers/App";
import "./RiskFactorLine.css";
import Icon from "../../atoms/Icon/Icon";

const RiskFactorLine = ({ t: __, riskFactor, isHidden = false, cta="", onClick = () => {}, searchKey = false }) => {
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();
  const [disabled, setDisabled] = useState(false);
  const riskFactorName = riskFactor.canonical_name?.[currentLanguage] || riskFactor.canonical_name || riskFactor.id;
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);

  useEffect(() => {
    setDisabled(isHidden);
  }, [isHidden])

  /**
   * highlight the search key inside the given string (case insensitive, ignore accents)
   */
  const highlight = (string) => {
    if(!string) return { __html: "" };
    if(!searchKey) return { __html: string };
    const expression = getRegExpValue(searchKey);
    const __html = string.replace(expression, '<span class="highlight">$1</span>');
    return { __html };
  }

  const medicalHistoryItem = riskFactor.type === "teratogen" ? false : Object.values(examinationContext.medicalHistoryItems).find(item => item.id === riskFactor.id);

  if (medicalHistoryItem?.text_id === "medicalexam.fetus.sex" && appContext.fetusSexVisibility === "hidden") return false;

  return (
    medicalHistoryItem?.options?.map(option => 
      !!option.risk_factor_id
        ? <li
          className={`risk-factor-line ${
            disabled ? "disabled" : ""
          }`}
        >
          <div onClick={() => {
            onClick(riskFactor, option.value);
            return setDisabled(true);
          }}>
            <span className="risk-factor-name">
              <span dangerouslySetInnerHTML={highlight(riskFactorName + ' – ' + option.label[currentLanguage])} />
              {!riskFactorName.match(getRegExpValue(searchKey)) && !!riskFactor.synonyms?.[currentLanguage]?.length && (
                <div className="synonym" dangerouslySetInnerHTML={highlight(riskFactor.synonyms[currentLanguage].find(riskFactorName => riskFactorName.match(getRegExpValue(searchKey))))}></div>
              )}
            </span>
            <span className="risk-factor-cta">
              {cta}{" "}
              <Icon name="paste" />
            </span>
          </div>
        </li>
        : false
    ) || (
      <li
        className={`risk-factor-line ${
          disabled ? "disabled" : ""
        }`}
      >
        <div onClick={() => {
          onClick(riskFactor);
          return setDisabled(true);
        }}>
          <span className="risk-factor-name">
            <span dangerouslySetInnerHTML={highlight(riskFactorName)} />
            {!riskFactorName.match(getRegExpValue(searchKey)) && !!riskFactor.synonyms?.[currentLanguage]?.length && (
              <div className="synonym" dangerouslySetInnerHTML={highlight(riskFactor.synonyms[currentLanguage].find(riskFactorName => riskFactorName.match(getRegExpValue(searchKey))))}></div>
            )}
          </span>
          <span className="risk-factor-cta">
            {cta}{" "}
            <Icon name="paste" />
          </span>
        </div>
      </li>
    )
  );
};

export default withTranslation()(RiskFactorLine);
