import { isNullOrUndefined } from "../utils";
/*
 * @param {number} percentile
 * @param {function} __ - translation function
 *
 * @returns {string} - formatted human readable percentile value
 */
export function getPercentileValueString(percentile, __) {
  if (isNullOrUndefined(percentile)) return "-";
  const p = __("report.percentile.abbreviation");
  if (Number(percentile) <= 1) return `<1${p}`;
  if (Number(percentile) >= 99) return `>99${p}`;
  return `${percentile}${p}`;
};
