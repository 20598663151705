import { createRef, useEffect, useRef, useState } from 'react';

export const useElementOnScreen = (targets, options) => {
  const targetsRef = useRef({})
  targets.forEach(target => targetsRef.current[target.key] = createRef());

  let [targetsVisibility, setTargetsVisibility] = useState({})

  const callbackFunction = (entries) => {
    const newVisibilities = {}
    for (const entry of entries) {
      newVisibilities[entry.target.dataset.id] = entry.isIntersecting;
    }
    setTargetsVisibility(prev => ({ ...prev, ...newVisibilities }));
  }

  useEffect(() => {
    setTargetsVisibility(() => {
      const newVisibilities = {}
      for(const target of targets) {
        const key = targetsRef.current[target.key]?.dataset?.id;
        if (key) newVisibilities[key] = false;
      }
      return newVisibilities;
    });

    const observer = new IntersectionObserver(callbackFunction, options);
    for (const targetRef of Object.values(targetsRef.current)) {
      !!targetRef.current && observer.observe(targetRef.current);
    }
    return () => {
      for (const targetRef of Object.values(targetsRef.current)) {
        if (targetRef.current) observer.unobserve(targetRef.current);
      }
      // get rid of memory leak error as the setState is triggered in the observer callback
      setTargetsVisibility = (() => undefined);
    }
  }, [JSON.stringify(targets), options]);

  return [targetsRef, targetsVisibility]
}