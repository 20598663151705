import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";

/* Contexts */
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import useAuth from "../../context-providers/Auth";

/* Components */
import Popup from '../Popup/Popup';

/* Atoms */
import Button from '../../atoms/Button/Button';
import ButtonGroup from '../../atoms/ButtonGroup/ButtonGroup';
import { formatName } from '../../services/examination';
import Icon from '../../atoms/Icon/Icon';

/* CSS */
import './FetusSexHandler.css';

const PatientChoiceSelector = withTranslation()(({t : __}) => {
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);

  /* Hidden or masked by the sonographer or the country: patient can not ask */
  if(appContext.fetusSexVisibility === "hidden")
    return null;
  if(examinationContext.fetusSexVisibility === "hidden")
    return null;
  if(!examinationContext.updatePatient)
    return null;
  if(!examinationContext.patient)
    return null;

  const updatePatient = (newValue) => {
    const config = examinationContext.patient.config || {}
    examinationContext.updatePatient(examinationContext.patient.id, {config: {...config, fetus_sex_visibility: newValue}})
  }

  const patientName = formatName(examinationContext.patient.name)

  return (
    <div className="patient-fetus-sex-visibility-choice">
    {examinationContext.canEdit &&
      <ButtonGroup
          label={__("fetusSexVisibility.PatientChoiceSelector", { ...patientName })}
          value={examinationContext.fetusSexVisibility}
          options={[
            {value: "visible", label: __("fetusSexVisibility.state.visible")},
            {value: "masked", label: __("fetusSexVisibility.state.masked")},
          ]}
          onChange={updatePatient}
      />
    }
    {!examinationContext.canEdit &&
      <ButtonGroup
          label={__("fetusSexVisibility.PatientChoiceSelector", { ...patientName })}
          value={examinationContext.fetusSexVisibility}
          disabled={true}
          options={[
            {value: "visible", label: __("fetusSexVisibility.state.visible")},
            {value: "masked", label: __("fetusSexVisibility.state.masked")},
          ].filter(({value}) => value == examinationContext.fetusSexVisibility)}
      />
    }
    </div>
  )
})

const FetusSexHandler = ({ children, wrapper, showPatientChoice = true, showFetusSexButton = true, t: __ }) => {
  const [showFetusSex, setShowFetusSex] = useState(false);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();

  const Wrapper = wrapper || (({children}) => <>{children}</>);

  if (examinationContext.fetusSexVisibility === "hidden")
    return false;
  /* Waiting for the FF to be loaded before displaying the UI */
  if(!isFeatureFlagsLoaded)
    return null;

  if (isFeatureFlagEnabled("sonio.multi_fetal_beta")) {
    return (
      <Wrapper>
        <PatientChoiceSelector />
        {children}
      </Wrapper>
    )
  } else {
    /* Visible with consent of the patient */
    if (examinationContext.fetusSexVisibility === "visible")
      return (
        <Wrapper>
          {showPatientChoice && <PatientChoiceSelector />}
          {children}
        </Wrapper>
      )

    const fetusSexValue = examinationContext.examination?.medical_history?.["medicalexam.fetus.sex"]?.value;

    /* In case of masked status */
    return (
      <Wrapper>
        {showPatientChoice && <PatientChoiceSelector />}
        {showFetusSexButton && (
          <>
          <Button
            label={fetusSexValue ? __("medicalhistory.fetusSex.button.update") : __("medicalhistory.fetusSex.button.set")} 
            onClick={() => setShowFetusSex(true)}
            variant={fetusSexValue ? "outline" : ""}
          />
          {showFetusSex &&
            <Popup
              cta={<div className="fetus-sex-popup" onClick={() => setShowFetusSex(false)}>
                {children}
                <div className="disclaimer"><Icon name="warning" /> {__("medicalhistory.fetusSex.disclaimer")}</div>
              </div>}
              onBackgroundClick={() => { setShowFetusSex(false) }}
            />
          }
          </>
        )}
      </Wrapper>
    )
  }
}

FetusSexHandler.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
}

export default withTranslation()(FetusSexHandler)
