import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";
import "./SelectInputOptional.css";

/**
 * <SelectInputOptional
 *  className
 *  inactiveValue
 *  defaultValue
 *  label
 *  options = [ { label, icon, value } ]
 *  value = value
 *  onChange = () => {}
 * />
 */

const SelectInputOptional = ({ className, defaultValue, label, value, options, onChange, onDisable }) => {

  const [open, setOpen] = useState();
  const [inputHeight, setInputHeight] = useState(0);

  useEffect( () => {
    setOpen(value !== null);
    setInputHeight(value !== null ? '2.4em' : '0');
  }, [value]);

  const toggleOpen = (e) => {
    setOpen(value !== null);
    
    // set inactive value when the element is closing
    if (open) {
      setInputHeight(0);
      if(onDisable) onDisable()
      else if(onChange) onChange(null)
    } else {
      const container = e.currentTarget.parentNode;
      const input = container.querySelector('.input');
      setInputHeight(input.scrollHeight + 'px');
      if(onChange) onChange(defaultValue)
    }
  }

  return (
    <div
      className={
        'select-input-optional ' +
        (className || '') +
        ' ' +
        (open ? 'open' : 'closed')
      }
    >
      <label onClick={toggleOpen}>{label}</label>
      <div className={'input ' + (open ? 'visible' : 'hidden')} style={{height: (open ? inputHeight : 0)}}>
        <SelectInput
          options={options}
          value={value || defaultValue}
          onChange={onChange}
        />
      </div>
    </div>
  )

};

export default SelectInputOptional;

SelectInputOptional.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
};
