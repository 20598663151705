import ResourceApi from "../services/resource";
import setShareDoc  from "../providers/examSharing/actions/setShareDoc";

const loadExamAssocAttachments = async (examId) => {
  const { data } = await ResourceApi.getExamAssocAttachments(examId);

  if (data && data.data) {
    setShareDoc(data.data);
  } 
}

export default loadExamAssocAttachments;
