import React, { useEffect, useRef } from "react";
import Icon from "../../atoms/Icon/Icon";
import "./SectionsNavBar.css";

/**
 * <SectionsNavBar
 *  options = [{icon, title, description, target}, ...]
 *  actions = [{icon, title, description, onClick}, ...]
 *  actionsAfter = [{icon, title, description, onClick}, ...]
 * />
 */

let observer = null;
let navbarTimer = null;
let observerEntries = [];

const SectionsNavBar = ({ onChangeCurrentOption = () => { }, options = [], actions = [], actionsAfter = [], disableOptions = false, disableActions = false }) => {
  const navContainer = useRef(null);
  const marker = useRef(null);

  options = options.map(option => ({
    ...option,
    target: option.target || (option.targetClass ? document.querySelector(option.targetClass) : false)
  }))

  useEffect(() => {
    if (!options.length) return false;

    observer = null;
    const intersectionObserverCallback = (entries, observer) => {
      for (const entry of entries) {
        const index = observerEntries.findIndex( e => e.target === entry.target );
        if (index >= 0) observerEntries[index] = entry;
        else observerEntries.push(entry);
      };

      const lastIntersecting = ([...observerEntries].reverse()).find(e => e.isIntersecting);
      if (lastIntersecting) {
        const boundingRect = lastIntersecting.boundingClientRect
        const intersectionRect = lastIntersecting.intersectionRect
        if (boundingRect.top <= intersectionRect.top) {
          const optionIndex = options.findIndex(option => option.target === lastIntersecting.target);
          if (optionIndex === false) return false;
          if(navContainer.current) setChapterAsCurrent(navContainer.current.querySelector(`li.option-${optionIndex}`));
        }
      }
    }

    observer = new IntersectionObserver(intersectionObserverCallback, {
      rootMargin: '0px',
      threshold: 0.2,
    })

    for (const option of options) {
      if (option.target) observer.observe(option.target);
    }

    return () => {
      observer = null;
    }
  }, [options]);

  /**
   * navigation menu
   */
  const expandNavMenu = (e) => {
    if (disableOptions || actions.some(action => action.selected) || actionsAfter.some(action => action.selected)) return false;
    
    let nav = navContainer.current?.querySelector('ul');
    nav.classList.add('expanded');
    nav.style.width = `${nav.scrollWidth}px`;

    // autohide navbar after 2 seconds
    if (navbarTimer) clearTimeout(navbarTimer);
    navbarTimer = setTimeout(shrinkNavMenu, 2000);
  }

  const shrinkNavMenu = (e) => {
    if (navbarTimer) clearTimeout(navbarTimer)
    let nav = navContainer.current?.querySelector('ul')
    nav.classList.remove('expanded')
    nav.style.width = ''
  }

  const goToChapter = (navElement, target) => {
    if (actions.some(action => action.selected)) {
      actions.find(action => action.selected)?.onClick();
    } else if (actionsAfter.some(action => action.selected)) {
      actionsAfter.find(action => action.selected)?.onClick();
    }

    // scroll to the target item
    let scrollableParent = false;
    for (let parent=target.parentNode; parent && !scrollableParent; parent = parent.parentNode) {
      if (parent.scrollHeight > parent.clientHeight) {
        scrollableParent = parent;
        break;
      }
    }

    if (scrollableParent) {
      scrollableParent.scrollTo({
        top: target.offsetTop,
        behavior: 'smooth',
      });

      setChapterAsCurrent(navElement);
    }
  }

  const setChapterAsCurrent = (navElement) => {
    if (!navElement || navElement === navContainer.current?.querySelector('ul li.current')) return false;
    const nav_ul = navContainer.current?.querySelector('ul')
    const navElements = nav_ul.querySelectorAll('ul li');
    for (const elm of navElements) {
      elm.classList.remove('current');
    }

    navElement.classList.add('current');

    onChangeCurrentOption(options[navElement.dataset.id]);

    if (marker.current) marker.current.style.top = navElement.offsetTop + 'px';
  }

  return (
    <div className={`sections-nav-bar-container ${(actions.some(action => action.selected) || actionsAfter.some(action => action.selected) || disableOptions) ? 'action-selected' : ''} ${disableActions ? 'disable-actions' : ''}`}>
      {!!actions.length && (
        <div
          className="sections-nav-bar sections-nav-bar-actions"
        >
          <ul>
            {actions.map((action, i) => {
              return <li key={i} className={`action-${i} ${action.selected ? 'selected' : ''} ${action.unusual ? 'unusual' : ''} ${action.disabled ? 'disabled' : ''}`} onClick={(e) => action.onClick()}>
                <div className="icon-wrapper">
                  <Icon name={action.icon} title={action.title} />
                </div>
                {!!action.counter && (<div className="counter">{action.counter}</div>)}
              </li>
            })}
          </ul>
        </div>
      )}
      {!!options.length && (
        <div
          className="sections-nav-bar sections-nav-bar-options"
          onMouseOver={expandNavMenu}
          onMouseOut={shrinkNavMenu}
          ref={navContainer}
        >
          <div className="sections-nav-bar-options-corner-top"></div>
          <div className="sections-nav-bar-options-corner-bottom"></div>
          <ul>
            <li className="marker" ref={marker}></li>
            {options.map((option, i) => {
              return <li key={i} className={`option-${i} ${option.disabled ? 'disabled' : ''}`} data-id={i} onClick={(e) => goToChapter(e.currentTarget, option.target)}>
                <div className="icon-wrapper">
                  <Icon name={option.icon} />
                </div>
                <div>
                  <b>{option.title}</b><br />
                  {!!option.description && (option.description)}
                </div>
                {!!option.counter && (<div className="counter">{option.counter}</div>)}
                {!!option.alert && (
                  <span className="risk-factor">
                    <Icon name={option.alert} />
                  </span>
                )}
              </li>
            })}
          </ul>
        </div>
      )}
      {!!actionsAfter.length && (
        <div
          className="sections-nav-bar sections-nav-bar-actions"
        >
          <ul>
            {actionsAfter.map((action, i) => {
              return <li key={i} className={`action-${i} ${action.selected ? 'selected' : ''} ${action.unusual ? 'unusual' : ''} ${action.disabled ? 'disabled' : ''}`} onClick={(e) => action.onClick()}>
                <div className="icon-wrapper">
                  <Icon name={action.icon} title={action.title} />
                </div>
                {!!action.counter && (<div className="counter">{action.counter}</div>)}
              </li>
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SectionsNavBar;
