import React, { useContext, useEffect, useMemo, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SelectInput from "../../atoms/SelectInput/SelectInput";
import Tabs from "../../atoms/Tabs/Tabs";
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import ResourceApi from '../../services/resource';
import { formatDateWithTZ, getInstanceThumbnailUri } from "../../utils";
import './SharingPreview.css';
import SharingPreviewPopup from './SharingPreviewPopup';

const SharingPreview = ({ t: __, close = null }) => {
  const history = useHistory();
  const location = useLocation();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const currentTrimester = examinationContext.examination?.trimester;

  const [shownMediaType, setShownMediaType] = useState("image")
  const [tabsOptions, setTabsOptions] = useState([])

  const [closeAction, setCloseAction] = useState(null)
  const [sharingOptionsPopupOpen, setSharingOptionsPopupOpen] = useState(false);
  const [showShareActionButton, setShowShareActionButton] = useState(true);

  const notSharedInstances = useMemo(() => examinationContext.instances?.filter(instance => !instance.shared_with_patient), [JSON.stringify(examinationContext.instances)]);
  const [sharedInstances, setSharedInstances] = useState([]);
  const [printingListDicomInstanceIds, setPrintingListDicomInstanceIds] = useState([]);

  useEffect(() => {
    const closeActionProvider = () => (!close) ? history.goBack : close
    appContext.activateGoBackMenuButton(true, closeActionProvider);
    setCloseAction(closeActionProvider)
    return () => appContext.activateGoBackMenuButton(false);
  }, [])

  useEffect(() => {
    if (examinationContext.examination.id) {
      ResourceApi.sharingList(examinationContext.examination.id).then(resp => setSharedInstances(resp.data.data));
    }
    setPrintingListDicomInstanceIds(examinationContext.instances.filter(inst => inst.selected_for_print).map(inst => inst.id));
  }, [examinationContext.examination.id, examinationContext.instances])

  const sharedItemTitle = `${examinationContext.patient?.name.replaceAll("^", "").replaceAll(" ", "")}_${formatDateWithTZ(examinationContext.share?.inserted_at, appContext.preferences.date_format).replaceAll("/", "")}_${sharedInstances.length}`

  useEffect(() => {
    // If we came from printing page, and there does not exist a share link then create one
    if (location.state?.source === "printing-list") {
      setShowShareActionButton(false);
    }
  }, [location.state])

  useEffect(() => {
    const mediaTypes = new Set(examinationContext.instances.map(i => i.dicom_media_type))
    setTabsOptions(Array
      .from(mediaTypes).filter(m => ["image", "video"].includes(m)).sort()
      .map(m => ({ value: m, label: __(`examinationReview.tabs.${m}sLabel`), icon: m }))
    )
  }, [examinationContext.instances])

  useEffect(() => {
    (!tabsOptions.map(m => m.value).includes('image')) ? setShownMediaType('video') : setShownMediaType('image')
  }, [JSON.stringify(tabsOptions)])

  const onChangeShareCheckbox = async (instanceId) => {
    await examinationContext.toggleInstanceSelectedForSharing(instanceId, !examinationContext.instances.find(instance => instance.id === instanceId)?.shared_with_patient);
  }

  const changeAllShareStatusTo = async (shared) => {
    for (const instance of examinationContext.instances) {
      await examinationContext.toggleInstanceSelectedForSharing(instance.id, shared);
    }
  }

  const createNewShare = async () => {
    await examinationContext.shareExamination();
    setSharingOptionsPopupOpen(true);
  }

  const createNewShareAndGoBackToPrinting = () => {
    if (!examinationContext.share?.share_link) examinationContext.shareExamination();
    history.goBack();
  }

  const DicomInstanceImagePreview = ({ instanceId, includePrintIcon = false, isVideo = false }) => {
    return <div className="sharing-list-dicom-instance-image-preview">
      <img src={getInstanceThumbnailUri(instanceId)} alt="instance" />
      {isVideo
        ? <div className="printing-list-icon"><Icon name="video" /></div>
        : includePrintIcon && <div className="printing-list-icon"><Icon name="print" /></div>
      }
    </div>
  }

  return (
    <>
      <div className="examination-review-sharing-preview-container">
        <div className="examination-review-sharing-preview-leftbar">
          <h2>{__("examinationReview.sharingImageSelection")}</h2>
          <div className="examination-review-sharing-buttons">
            <SelectInput
              placeholder={{ icon: "", label: __("examinationReview.sharing.dropdownLabel") }}
              showDropDown={true}
              optionsFontSize={"small"}
              options={[{ label: __("examinationReview.sharing.selectAll"), icon: "done-all", value: "all", },
              { label: __("examinationReview.sharing.unselectAll"), icon: "close", value: "none", },
              { label: __("examinationReview.sharing.selectSameAsPrint"), icon: "print", value: "from_print", }]}
              onChange={async (value) => {
                switch (value) {
                  case 'all':
                    changeAllShareStatusTo(true)
                    break;
                  case 'none':
                    changeAllShareStatusTo(false)
                    break;
                  case 'from_print':
                    ResourceApi.importSharingListFromPrinting(examinationContext.examination.id)
                    break;
                  default:
                    break;
                }
              }}
            />
          </div>
          {tabsOptions.map(m => m.value).includes('video') && <Tabs value={shownMediaType} options={tabsOptions} fullwidth={true} onChange={setShownMediaType} />}
          {(!!notSharedInstances.length) && (
            <div className="examination-review-sharing-preview-images">
              {notSharedInstances
                .filter(instance => {
                  // We isolate videos in another tab, the rest is going in the "Images" tab
                  if (shownMediaType === "video" && instance.dicom_media_type === "video") return true
                  else if (shownMediaType !== "video" && instance.dicom_media_type !== "video") return true
                  return false
                })
                .map(instance => {
                  const view = examinationContext.instanceViews.find(view => view?.id === instance.slideId)
                  const categoryLabel = appContext.getDefaultCategoryLabelForSlide(view, currentTrimester, __)
                  return <div className="media not-selected" key={instance.id} onClick={() => onChangeShareCheckbox(instance.id)}>
                    <div className="slide-header">
                      <div className="slide-check"><Icon name="add" /></div>
                      <div className="slide-title">
                        <div className="slide-label">{view?.label[currentLanguage]}</div>
                        {!!categoryLabel && <div className="slide-category">{categoryLabel}</div>}
                      </div>
                    </div>
                    <DicomInstanceImagePreview instanceId={instance.id} includePrintIcon={printingListDicomInstanceIds.includes(instance.id)} isVideo={instance.dicom_media_type === "video"} />
                  </div>;
                })}
            </div>
          )}
        </div>
        <div className="examination-review-sharing-preview-preview">
          <h2>{__("examinationReview.sharingPreview")}</h2>
          <div className="examination-review-sharing-preview-page-container">
            <div className="examination-review-sharing-preview-page">
              {sharedInstances.map(instance => {
                return <div className="media" key={instance.id}>
                  <div className="slide-header">
                    <div className="slide-check" onClick={() => onChangeShareCheckbox(instance.dicom_instance_id)}><Icon name="close" /></div>
                  </div>
                  <DicomInstanceImagePreview instanceId={instance.dicom_instance_id} includePrintIcon={printingListDicomInstanceIds.includes(instance.dicom_instance_id)} isVideo={instance.dicom_media_type === "video"} />
                </div>;
              })}
            </div>
          </div>
        </div>
        <div className="examination-review-sharing-preview-rightbar">
          <h2>{__("examinationReview.sharingHistory")}</h2>
          <div className="examination-review-sharing-preview-settings">
            {examinationContext.share?.share_link && <div className="shared-link-item">
              <div onClick={() => setSharingOptionsPopupOpen(true)}>{sharedItemTitle}</div>
              <Icon name="trash" onClick={examinationContext.deleteShareForExamination} />
            </div>}
          </div>
          <div className="examination-review-sharing-preview-cta">
            {showShareActionButton ?
              examinationContext.share?.share_link ? <Button
                label={__('examinationReview.share')}
                icon="image"
                size="full-width"
                onClick={() => setSharingOptionsPopupOpen(true)}
                disabled={!sharedInstances.length}
              /> :
                <Button
                  label={__("examinationReview.share.create")}
                  icon="image"
                  size="full-width"
                  onClick={createNewShare}
                  disabled={!sharedInstances.length}
                  fontSize="14"
                /> :
              <Button
                label={__("common.submit")}
                onClick={createNewShareAndGoBackToPrinting}
              />}
          </div>
        </div>
      </div >
      <div className="modal-background" onClick={closeAction} />
      {sharingOptionsPopupOpen &&
        <SharingPreviewPopup onClose={() => setSharingOptionsPopupOpen(false)} />
      }
    </>
  )
}

export default withTranslation()(SharingPreview)
