import ReportTemplateHeading from "../Heading";
import ReportTemplateBlock from "../Block";
import ReportTemplateSpacer from "../Spacer";

import {
  isMeasurementCurveVisible,
  isMeasurementCurveEmpty,
  measurementCurveRequiredPlaceholders
} from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";

import { isNullOrUndefined } from "../../../../utils";

function ReportTemplateGraphsBody({ props, placeholders, children, reportMode = "print" }) {

  const columnNumber = props.columns || 1;

  const groupedChildren = children
    .filter(child => {
      // Only measurement curves accepted as children
      if (child?.props?.props?.type !== "measurement-curve") return false;
      // Note here we use placeholders loaded by PlaceholderLoader for Graphs 
      // as MeasurementCurve are not loaded yet as they are not yet rendered
    
      // Only non empty curves accepted
      if (isMeasurementCurveEmpty(child.props.props, placeholders)) return false;
      // Only visible curves accepted except in edit mode where we display all the curves
      if (reportMode === "edit") return true;
      if (!isMeasurementCurveVisible(child.props.props, child.props.placeholders)) return false;
      return true
    })
    .reduce((grouped, child, index) => {
      const groupedIndex = Math.floor(index / columnNumber);
      grouped[groupedIndex] = grouped[groupedIndex] || [];
      grouped[groupedIndex].push(child);
      return grouped;
    }, []);


  const isEmpty = groupedChildren.length === 0;

  return isEmpty ? false : (
    <div className={`graphs-wrapper ${isEmpty ? 'is-empty' : ''}`}>
      <div className="avoid-break">
        {props.label && <>
          <ReportTemplateHeading props={{}}>{props.label}</ReportTemplateHeading>
          <ReportTemplateSpacer size="s" />
        </>}
        {!!groupedChildren[0].length && (
          <ReportTemplateGraphsLine key={`graph-line-0`} columns={columnNumber}>{groupedChildren[0]}</ReportTemplateGraphsLine>
        )}
      </div>
      {groupedChildren.slice(1).map((line, index) => {
        return <ReportTemplateGraphsLine key={`graph-line-${index}`} columns={columnNumber}>{line}</ReportTemplateGraphsLine>
      }
      )}
    </div>
  );
};

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateGraphs({
  props,
  placeholders,
  children,
  ...otherProps
}) {

  const acceptedChildren = children.filter(child => child?.props?.props?.type === "measurement-curve");
  const refusedChildren = children.filter(child => !isNullOrUndefined(child?.props?.props?.type) && child?.props?.props?.type !== "measurement-curve");

  if (acceptedChildren.length === 0) {
    console.error("graphs accepts only measurement-curve as children")
    return false;
  }
  if (refusedChildren.length > 0) {
    console.error("graphs only accepts measurement-curve as children")
  }

  const requiredPlaceholders = acceptedChildren
    .map(child => measurementCurveRequiredPlaceholders(child.props.props, placeholders))
    .flat();

  return (
    <PlaceholderLoader
      Component={ReportTemplateGraphsBody}
      placeholders={placeholders}
      props={props}
      requiredPlaceholders={requiredPlaceholders}
      {...otherProps}
    >{acceptedChildren}</PlaceholderLoader>
  );
};


function ReportTemplateGraphsLine({ children, columns }) {
  return <div className="graphs-line">
    <ReportTemplateBlock props={{ layout: "columns" }}>
      {children}
      {Array.from("0".repeat(columns - children.length)).map((_, index) => <div key={`empty-${index}`} />)}
    </ReportTemplateBlock>
  </div>;
}

