import { useContext } from "react"
import { renderToString } from "react-dom/server"
import { withTranslation } from "react-i18next"
import { AppContext } from "../../context-providers/App"
import useVersion from "../../context-providers/LatestVersion"
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup"
import Icon from "../../atoms/Icon/Icon"
import "./Disclaimer.css"

/**
 *
 * blockingSituation can be one of the following :
 *  - soniopedia_user_on_sonio_url
 *  - sonio_user_on_soniopedia_url
 *
 */
function UserBlockingMessage({ t: __, blockingSituation }) {
  const appContext = useContext(AppContext)
  const { soniopediaBaseUrl } = useVersion()

  return (
    <div className="disclaimer-container">
      <div className="disclaimer-set-language">
        <ButtonGroup
          value={appContext?.preferences?.lang ?? ""}
          options={[
            { value: "en", label: "EN" },
            { value: "fr", label: "FR" },
            { value: "de", label: "DE" },
            { value: "ptbr", label: "PTBR" },
          ]}
          onChange={appContext.changeLanguage}
        />
      </div>
      <div className="disclaimer">
        <div
          dangerouslySetInnerHTML={{
            __html: __("user_blocking_message.soniopedia.text", {
              soniopediaBaseUrl,
              iconAttention: renderToString(<Icon name="attention" />),
            }),
          }}></div>
        <div
          className="small"
          dangerouslySetInnerHTML={{ __html: __("user_blocking_message.soniopedia.smalltext") }}></div>
      </div>
    </div>
  )
}

export default withTranslation()(UserBlockingMessage)
