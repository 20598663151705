import { useState, forwardRef } from 'react';
import Icon from '../Icon/Icon';
import "./InputPassword.css";

const InputPassword = forwardRef(({...props}, forwardedRef) => {
  const [isShown, setShown] = useState(false)
  const overrideProps = {type: isShown ? "text" : "password"}

  const newProps = {...props, ...overrideProps}
  return (
    <div className="input-password-wrapper">
      <input {...newProps} ref={forwardedRef} />
      <div className="input-password-icon" cursor="pointer" onClick={() => setShown(!isShown)}>
        {props.value != "" ? (!isShown ? <Icon name="eye" /> : <Icon name="eye-off" />) : <Icon name="empty" />}
      </div>
    </div>
  )
})

export default InputPassword;