import { withTranslation } from "react-i18next";
import './PatientApplicationSettings.css';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import { useEffect, useState } from "react";
import Icon from "../../atoms/Icon/Icon";
import { useApp } from "../../context-providers/App";
import { Link } from 'react-router-dom';
import useIsBasicAccount from "../../hooks/useIsBasicAccount";
import UserApi from "../../services/user";

const Fields = {
    SHOW_SITE_LOGO: "show_site_logo",
    SHOW_SITE_NAME: "show_site_name",
    SHOW_FETUS: "show_fetus",
    SHOW_NAMES_ON_VIEWS: "show_names_on_views",
    SHOW_DESCRIPTION_OF_VIEWS: "show_description_of_views"
};
const Errors = {
    SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE: "SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE",
    DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE: "DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE",
    SITE_LOGO_NOT_SET: "SITE_LOGO_NOT_SET"
};

function allowSiteOrLogoCheckboxClick(field, patientAppConfig, newValue) {
    switch (field) {
        case Fields.SHOW_SITE_LOGO:
            if (patientAppConfig[Fields.SHOW_SITE_NAME] == false && !newValue) {
                return false
            }
            return true;
        case Fields.SHOW_SITE_NAME:
            if (patientAppConfig[Fields.SHOW_SITE_LOGO] == false && !newValue) {
                return false
            }
            return true;
    }
    return true;
}

function allowLogoCheckboxClick(field, site, newValue) {
    const doesSiteHaveALogo = !!site.logo_base64;
    if (field === Fields.SHOW_SITE_LOGO && newValue && !doesSiteHaveALogo) {
        return false;
    }
    return true
}

function allowDescriptionCheckboxClick(field, patientAppConfig, newValue) {
    if (field !== Fields.SHOW_DESCRIPTION_OF_VIEWS) {
        return true;
    }

    if (!newValue) {
        return true;
    }

    return patientAppConfig[Fields.SHOW_NAMES_ON_VIEWS];
}

const ConfigRow = ({
    t: __,
    title,
    field,
    site,
    patientAppConfig,
    setPatientAppConfig,
    displayError,
    clearError
}) => {
    return <div className="row">
        <div className="w4">
            {title}
        </div>
        <div className="w8">
            <Checkbox key={field}
                checked={patientAppConfig[field]}
                onChange={(newChecked) => {
                    if (!allowSiteOrLogoCheckboxClick(field, patientAppConfig, newChecked)) {
                        displayError(Errors.SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE);
                        return;
                    }

                    if (!allowDescriptionCheckboxClick(field, patientAppConfig, newChecked)) {
                        displayError(Errors.DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE);
                        return;
                    }

                    if (!allowLogoCheckboxClick(field, site, newChecked)) {
                        displayError(Errors.SITE_LOGO_NOT_SET);
                        return;
                    }

                    UserApi.setPatientAppConfigValue({field, value: newChecked })
                        .then(res => {
                            if (res.status !== 200) {
                                return;
                            }

                            if (newChecked && (field === Fields.SHOW_DESCRIPTION_OF_VIEWS || field === Fields.SHOW_NAMES_ON_VIEWS)) {
                                clearError(Errors.DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE);
                            }
                            if (newChecked && (field === Fields.SHOW_SITE_LOGO || field === Fields.SHOW_SITE_NAME)) {
                                clearError(Errors.SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE);
                            }
                            setPatientAppConfig({ ...patientAppConfig, ...res.data });
                        });
                }}
            />
        </div>
    </div>
}

const PatientApplicationSettings = ({ t: __ }) => {
    const { site } = useApp();
    const isBasicAccount = useIsBasicAccount();

    const [patientAppConfig, setPatientAppConfig] = useState({
        [Fields.SHOW_SITE_LOGO]: true,
        [Fields.SHOW_SITE_NAME]: true,
        [Fields.SHOW_FETUS]: true,
        [Fields.SHOW_NAMES_ON_VIEWS]: true,
        [Fields.SHOW_DESCRIPTION_OF_VIEWS]: true
    });
    const [errors, setErrors] = useState({
        [Errors.SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE]: false,
        [Errors.DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE]: false,
        [Errors.SITE_LOGO_NOT_SET]: false
    });

    const displayError = (error) => {
        setErrors(prevErrors => ({ ...prevErrors, [error]: true }));
    };

    const clearError = (error) => {
        setErrors(prevErrors => ({ ...prevErrors, [error]: false }));
    };

    useEffect(() => {
        UserApi.getPatientAppConfig()
            .then(res => {
                setPatientAppConfig(res.data);
            })
    }, []);

    // Injects a link into a translation that looks like this: "some text [word that should be a link] something else"
    // Results: "some text <Link to="...">word that should be a link</Link> something else"
    const noLogoSetTranslationToJSX = () => {
        const firstPartOfTranslation = __("preferences.patientApplication.error.siteLogoNotSet.firstPart");
        const settingsLink = __("preferences.patientApplication.error.siteLogoNotSet.settingsLink");
        const remainderOfTranslation = __("preferences.patientApplication.error.siteLogoNotSet.remainder");
        return <p className="config-error"> <Icon name="attention" />
            {firstPartOfTranslation} <Link className="inline-link" to="/printing-sharing-settings#logo-section">{settingsLink}</Link> {remainderOfTranslation}
        </p>;
    }

    let fetusConfig = null;
    if (!isBasicAccount) {
        fetusConfig = <div>
            <h2>{__("preferences.patientApplication.displayedAnnotationsAndDescriptionsOnViews")}</h2>

            <ConfigRow
                t={__}
                field={"show_names_on_views"}
                title={__("preferences.patientApplication.namesOfTheViews")}
                site={site}
                patientAppConfig={patientAppConfig}
                setPatientAppConfig={setPatientAppConfig}
                displayError={displayError}
                clearError={clearError} />

            <hr />
            <ConfigRow
                t={__}
                field={"show_description_of_views"}
                title={__("preferences.patientApplication.descriptionOnViews")}
                site={site}
                patientAppConfig={patientAppConfig}
                setPatientAppConfig={setPatientAppConfig}
                displayError={displayError}
                clearError={clearError} />

            {errors[Errors.DESCRIPTION_CANNOT_BE_ENABLED_WITHOUT_TITLE]
                ? <p className="config-error"> <Icon name="attention" /> {__("preferences.patientApplication.error.descriptionRequiresNames")}</p>
                : null
            }

            <hr />
            <ConfigRow
                t={__}
                field={"show_fetus"}
                title={__("preferences.patientApplication.showFetus")}
                site={site}
                patientAppConfig={patientAppConfig}
                setPatientAppConfig={setPatientAppConfig}
                displayError={displayError}
                clearError={clearError} />
        </div>;
    }

    return <div className="patient-application-container">
        <h1>{__('preferences.patientApplication')}</h1>

        <h2>{__("preferences.patientApplication.displayedSiteInformationOnTheLogInPage")}</h2>

        <ConfigRow
            t={__}
            field={"show_site_logo"}
            title={__("preferences.patientApplication.siteLogo")}
            site={site}
            patientAppConfig={patientAppConfig}
            setPatientAppConfig={setPatientAppConfig}
            displayError={displayError}
            clearError={clearError} />

        {errors[Errors.SITE_LOGO_NOT_SET]
            ? <div>{noLogoSetTranslationToJSX()}</div>
            : null
        }
        <hr />
        <ConfigRow
            t={__}
            field={"show_site_name"}
            title={__("preferences.patientApplication.siteName")}
            site={site}
            patientAppConfig={patientAppConfig}
            setPatientAppConfig={setPatientAppConfig}
            displayError={displayError}
            clearError={clearError} />

        {errors[Errors.SITE_OR_LOGO_NEEDS_TO_BE_VISIBLE]
            ? <p className="config-error"> <Icon name="attention" /> {__("preferences.patientApplication.error.siteLogoOrSiteNameNeedToBeOn")}</p>
            : null
        }

        {fetusConfig}
    </div>;
};

export default withTranslation()(PatientApplicationSettings);