import Button from "../../../atoms/Button/Button";

const FavoriteButton = ({ isFavorite, onClick = () => { } }) => {
  return (
    <Button
      icon={isFavorite ? "star" : "star-empty"}
      label=""
      size="input"
      variant={isFavorite ? '' : 'outline'}
      onClick={() => onClick()}
    />
  );
};

export default FavoriteButton;
