import {useContext, useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import Button from "../../atoms/Button/Button";
import PreselectSlideGrid from "./PreselectSlideGrid";
import axios from "axios";
import ResourceApi from "../../services/resource";
import {
  isList,
  isEmptyList,
  lastPersonalisedProtoSetting,
  extractProtoSetting
} from "../../services/user-preselect-config";
import { AppContext } from "../../context-providers/App";

const idsToSlides = (ids, allSlides) => {
  const slidesIndex = new Map(allSlides.map(slide => [slide.id, slide]));
  return ids.map(id => slidesIndex.get(id));
}

const PrintingPreselectOption = ({t: __, preset, fullConfig, userConfig, allSlides = [], onSave}) => {
  const appContext = useContext(AppContext);
  const presetValue = extractProtoSetting(fullConfig, preset.id) || false;
  const [slides, setSlides] = useState([]);
  const [protocolSlides, setProtocolSlides] = useState([])
  const [showEdit, setShowEdit] = useState(false)
  const [availableOptions, setAvailableOptions] = useState([]);
  const isGuidelineEnabled = appContext?.preferences?.default_category === "CNEOF_2022";

  useEffect(async () => {
    const slides = await (async () => {
      if (!preset.id) return false;

      const exam_template = userConfig?.examination_template;
      if (!exam_template) return false;

      const template = exam_template.find(x => x.examination_preset_id === preset.id)
      if (!template) return false;

      const examination_template_id = template?.examination_template_id;
      if (!examination_template_id) return false;

      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();
      const { data: { data: examinationTemplate } } = await ResourceApi.getExaminationTemplate(examination_template_id, source);
      const protocolViewIds = examinationTemplate.configuration.instance_views.map(v => v.instance_view_id);

      return allSlides.filter(slide => protocolViewIds.includes(slide.id))
    })() || allSlides;
    setProtocolSlides(slides)
  }, [preset.id, allSlides]);

  useEffect(() => {
    if (!protocolSlides || isEmptyList(protocolSlides)) {
      setAvailableOptions([
        {label: __("sharingPreferences.preselect.option.all"), value: "all"},
        {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      ])
    } else {
      setAvailableOptions([
        {label: __("sharingPreferences.preselect.option.personalized"), value: []},
        isGuidelineEnabled && {label: __("sharingPreferences.preselect.option.guideline"), value: "guideline"},
        {label: __("sharingPreferences.preselect.option.all"), value: "all"},
        {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      ].filter(x => x))
    }

  }, [protocolSlides]);

  const valueToOption = (value) => {
    if (value === "guideline" && !isGuidelineEnabled) return "none";
    if (isList(value)) return [];
    return value || "none";
  }

  const handleOptionChange = value => isList(value) ? handlePresetEdit(value) : onSave(value);
  const handlePresetEdit = (selectedSlideIds) => {
    if (isEmptyList(selectedSlideIds)) {
      const lastPersonalisedIds = lastPersonalisedProtoSetting(fullConfig, preset.id)
      const lastPersonalisedSlides = lastPersonalisedIds && idsToSlides(lastPersonalisedIds, allSlides)
      const defaultSlides = lastPersonalisedSlides || protocolSlides
      setSlides(defaultSlides)
    } else {
      setSlides(idsToSlides(selectedSlideIds, allSlides))
    }
    setShowEdit(true)
  };
  const handlePresetUpdate = (value) => {
    onSave(value)
    setShowEdit(false)
  };

  const handleCancel = () => {
    setShowEdit(false)
  }

  return <>
    <SelectInput
      value={valueToOption(presetValue)}
      onChange={handleOptionChange}
      options={availableOptions}
    />
    {isList(presetValue) && <>
      <div className="personalised-edit-button">
        <Button icon="edit" size="small" onClick={() => handlePresetEdit(presetValue)} />
      </div>
    </>}
    { showEdit && (<PreselectSlideGrid
      slides={slides}
      protocolSlides={protocolSlides}
      trimester={preset.trimester}
      onCancel={handleCancel}
      onUpdate={handlePresetUpdate}
    />)}
  </>
}

export default withTranslation()(PrintingPreselectOption);
