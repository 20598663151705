import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import NumericInput from "./NumericInput";
import "./NumericInputOptional.css";

/**
 * <NumericInputOptional
 *  className
 *  defaultValue
 *  label
 *  min
 *  max
 *  step
 *  prefix
 *  suffix
 *  value
 *  textualValue
 *  onChange
 *  onDisable
 * />
 */

const NumericInputOptional = ({ className, defaultValue, tmpValue = false, label, min, max, step = 1, prefix, suffix, value, onChange, onDisable }) => {

  const [open, setOpen] = useState();
  const [inputHeight, setInputHeight] = useState(0);

  useEffect( () => {
    setOpen(value !== null);
    setInputHeight(value !== null ? '2.4em' : '0');
  }, [value]);

  const onChangeHandler = (value) => {
    if(onChange) onChange(value)
  }

  const toggleOpen = (e) => {
    setOpen(value !== null);
    
    // set inactive value when the element is closing
    if (open) {
      setInputHeight(0);
      if(onDisable) onDisable()
      else if(onChange) onChange(null)
    } else {
      const container = e.currentTarget.parentNode;
      const input = container.querySelector('.input');
      setInputHeight(input.scrollHeight + 'px');
      if(onChange) onChange(defaultValue)
    }
  }

  return (
    <div
      className={
        'numeric-input-optional ' +
        (className || '') +
        ' ' +
        (open ? 'open' : 'closed')
      }
    >
      <label onClick={toggleOpen}>{label}</label>
      <div className={'input ' + (open ? 'visible' : 'hidden')} style={{height: (open ? inputHeight : 0)}}>
        <NumericInput
          min={min}
          max={max}
          step={step}
          value={value}
          tmpValue={tmpValue}
          prefix={prefix}
          suffix={suffix}
          onChange={onChangeHandler}
        />
      </div>
    </div>
  )

};

export default NumericInputOptional;

NumericInputOptional.propTypes = {
  className: PropTypes.string,
  step: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
};
