export const measurementLabel = (measurement, labels, currentLanguage) => {
  return measurement != null ? 
    (labels?.measurement?.[measurement]?.label?.[currentLanguage] ?? measurement) 
    : measurement;
}

export const bodyStructureLabel = (bs, reportData, currentLanguage) =>{
  return bs != null ? 
    (labels?.body_structure?.[bs]?.label?.[currentLanguage] ?? bs)
    : bs;
}

export const lateralityLabel = (laterality, reportData, currentLanguage) =>{
  return laterality != null ? 
    (labels?.laterality?.[laterality]?.label?.[currentLanguage] ?? laterality) 
    : laterality;
}

