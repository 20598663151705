import { useContext } from 'react';
import Icon from '../../atoms/Icon/Icon';
import { useExamination } from '../../context-providers/Examination';
import { AppContext } from "../../context-providers/App";

export default function Examination({examination, openChapter, setOpenChapter}) {
  const appContext = useContext(AppContext)
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const examinationContext = useExamination();
  let previousViewId = false;

  return(
    <div className={`debug-section ${openChapter === "Examination" ? "open" : "closed"}`}>
      <h2 onClick={() => setOpenChapter(chapter => chapter === "Examination" ? false : "Examination")}>Examination <Icon name="down" /></h2>
      {openChapter === "Examination" && (
        <div className="debug-subsection">
          {!examination
            ? (<div>No open exams</div>)
            : (
              <>
                <h3>Association Dictionary</h3>
                <table>
                  <tbody>
                    {appContext.viewsDictionary.sort((a, b) => a.instance_view_id - b.instance_view_id ).map(term => {
                      const viewId = term.instance_view_id;
                      const label = previousViewId === viewId ? '' : examinationContext.instanceViews?.[viewId]?.label[currentLanguage];
                      previousViewId = viewId;
                      return <>
                        {label && <tr className="spacer" key={`${viewId}-spacer`}><td colSpan="2"/></tr>}
                        <tr key={`${viewId}-${term.text_on_img}`}>
                          {label ? <th>{label}</th> : <td></td>}
                          <td>{term.text_on_img}</td>
                        </tr>
                      </>
                    })}
                  </tbody>
                </table>
              </>
            )
          }
        </div>     
      )}
    </div>
  )
}
