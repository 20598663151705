import { formatYYYYMMDDDate } from "../../../../utils";
import { placeholderIdFromProps } from "../../utils";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateValueBody({ props, originalPlaceholder, fetusNumberPlaceholder, multiSelectValue = false, fetus = 1, appPreferences }) {
  const preferedDateFormat = appPreferences?.date_format;

  let placeholder;
  if (props.data?.startsWith("measurement.")) {
    fetus = (fetusNumberPlaceholder?.value || [])[Number(props.fetus ?? fetus ?? 1)] ?? -1;
  } else {
    fetus = Number(props.fetus ?? fetus ?? 1);
  }
  if (Array.isArray(originalPlaceholder) || props.data?.startsWith("measurement.")) {
    placeholder = originalPlaceholder[fetus];
  } else if (Array.isArray(originalPlaceholder.value)) {
    placeholder = { ...originalPlaceholder, value: originalPlaceholder.value[fetus] };
  } else {
    placeholder = originalPlaceholder;
  }

  const value = placeholder?.value;
  const { decimals } = (props ?? {});

  const convertDates = label => isNaN(label) && `${label}`?.match(/^\d{4}.\d{2}.\d{2}$/) ? formatYYYYMMDDDate(label, preferedDateFormat) : label;

  const getDisplayedValue = () => {
    let dispValue = value;

    if (multiSelectValue) {
      dispValue = placeholder?.value?.[multiSelectValue];
    }
    if (props.attribute) {
      return multiSelectValue
        ? dispValue?.[props.attribute]
        : placeholder?.[props.attribute];
    }

    return dispValue;
  };

  let output = getDisplayedValue();
  if (Array.isArray(output)) output = output[fetus];

  if (decimals && !isNaN(output)) output = Number(output).toFixed(decimals);

  output = convertDates(output);

  return output || false;
};

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateValue({
  props,
  placeholders,
  ...otherProps
}) {
  const fieldId = placeholderIdFromProps(props);

  return (
    <PlaceholderLoader
      Component={ReportTemplateValueBody}
      placeholders={placeholders}
      requiredPlaceholders={[fieldId, "fetus.number"]}
      fetusNumberPlaceholder={placeholders["fetus.number"]}
      originalPlaceholder={placeholders[fieldId]}
      props={props}
      {...otherProps}
    />
  );
};
