import { isValidElement, cloneElement } from "react";

export default function ReportTemplateElse({ defaultKey = '', children, fetus = 1 }) {

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child) && i !== 1) {
      return cloneElement(child, { ...child.props, fetus, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
    }
    return child;
  }) : children;


  return childrenWithFetusNumber;
}
