import { isValidElement, cloneElement } from "react";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateFetusBody({ defaultKey = '', numberOfFetuses, children }) {
  const childrenWithFetusNumber = [...Array(numberOfFetuses).keys()].map((_, fetus) => {
    return (Array.isArray(children) ? children : [children]).map((child, i) => {
      if (isValidElement(child)) {
        return cloneElement(child, { key: `${defaultKey}_${i}_fetus_${fetus}`, fetus: fetus + 1, defaultKey: `${defaultKey}_${i}_fetus_${fetus}` });
      }
      return child;
    });
  }).flat();

  return childrenWithFetusNumber;
}


/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateFetus({
  props,
  placeholders,
  ...otherProps
}) {
  return (
    <PlaceholderLoader
      Component={ReportTemplateFetusBody}
      placeholders={placeholders}
      requiredPlaceholders={["patient.nb_fetuses"]}
      numberOfFetuses={placeholders["patient.nb_fetuses"]?.value || 1}
      props={props}
      {...otherProps}
    />
  );
}
