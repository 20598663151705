import React, { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import { ExaminationContext } from "../../../context-providers/Examination";
import { DxContext } from "../../../context-providers/Dx";
import useAuth from "../../../context-providers/Auth";
import SignSearchBar from "../../../components/SignSearchBar";
import AnomalyLine from "../../../components-dx/AnomalyLine";
import SignLine from "../../../components-dx/SignLine";
import SyndromeLine from "../../../components-dx/SyndromeLine";
import RiskFactorLine from "../../../components-dx/RiskFactorLine";
import "./SearchBar.css";


const ExaminationViewSearchBar = ({ t: __ }) => {
  const examinationContext = useContext(ExaminationContext)
  const dxContext = useContext(DxContext)
  const { isFeatureFlagEnabled } = useAuth()
  const { examId } = useParams();
  const history = useHistory();
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const [searchKey, setSearchKey] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchNotification, setSearchNotification] = useState("");

  useEffect(() => {
    setSearchKey("");
    setSearchResults([]);
    dxContext.setRecommendation(recommendation => ({...recommendation, malformations: [], syndromes: []}));
    dxContext.loadRecommendations();
  }, [examinationContext.examination.id])

  useEffect(() => {
    const timeout = setTimeout(() => setSearchNotification(""), 3000);
    return () => clearTimeout(timeout)
  }, [searchNotification])

  return (
    <div className="examination-sign-search-bar">
      {isFeatureFlagEnabled("sonio.dx_v2") && (
        <>
        <SignSearchBar
          searchKey={searchKey}
          excludeIds={[]}
          notification={searchNotification}
          label={__("signSearchBar.searchForAnomalies")}
          onChange={signs => setSearchResults(signs)}
          onChangeSearchKey={searchKey => {
            setSearchKey(searchKey);
            if(!searchKey) setSearchResults([]);
          }}
        />
        {!!searchResults.length && (
          <ul>
            {searchResults.map((m) => (
              m.type === "malformation"
              ? <AnomalyLine
                key={m.id}
                anomaly={m}
                searchKey={searchKey}
                cta={__("dx.summary.searchResults.malformation")}
                onClick={(anomaly, status) => {
                  dxContext.updateMalformation(anomaly, status);
                  setSearchResults([]);
                  setSearchKey('');
                  setSearchNotification(__("dx.summary.searchResults.malformation.added").replace("{malformation}", anomaly.canonical_name[currentLanguage]));
                  return history.push(`/exam/${examId}/dx`);
                }}
                />
              : m.type === "sign"
              ? <SignLine
                  key={m.id}
                  sign={m}
                  searchKey={searchKey}
                  cta={__("dx.summary.searchResults.sign")}
                  onClick={(sign) => {
                    dxContext.addSemiologyItem(sign, "sign");
                    setSearchResults([]);
                    setSearchKey('');
                    setSearchNotification(__("dx.summary.searchResults.sign.added").replace("{sign}", sign.canonical_name[currentLanguage]));
                    return history.push(`/exam/${examId}/dx`);
                  }}
                />
              : m.type === "medicalhistory" || m.type === "teratogen"
              ? <RiskFactorLine
                  key={"riskfactor" + m.id}
                  riskFactor={m}
                  searchKey={searchKey}
                  cta={__("dx.summary.searchResults.riskFactor." + m.type)}
                  onClick={(risk, optionValue = null) => {
                    if (risk.type === "medicalhistory") {
                      let raw_value = null;
                      if(optionValue) {
                        const option = Object.values(examinationContext.medicalHistoryItems).find(item => item.id === risk.id)?.options.find(option => option.value === optionValue);
                        raw_value = !option ? optionValue : (option.lower_limit + option.upper_limit) / 2;
                      }
                      examinationContext.addRiskFactorById(risk.id, raw_value, optionValue, optionValue);
                    }
                    else if (risk.type === "teratogen") examinationContext.addMedicationById(risk.id, risk.risk_factor_ids);
                    setSearchResults([]);
                    setSearchKey('');
                    setSearchNotification(__("dx.summary.searchResults.riskFactor." + risk.type + ".added").replace("{risk}", risk.canonical_name[currentLanguage]));
                    return history.push(`/exam/${examId}/dx`);
                  }}
                  /> : m.type === "syndrome"
                  ? <SyndromeLine
                    key={"syndrome" + m.id}
                    syndrome={m}
                    searchKey={searchKey}
                    cta={__("dx.summary.searchResults.syndrome")}
                    isHidden={dxContext.isLoadingRecommendation}
                    onClick={(syndrome) => {
                      dxContext.setUserOverrides(userOverrides => userOverrides.filter(u => u.type !== "syndrome" && u.id !== syndrome.id).concat({
                        type: "syndrome",
                        id: syndrome.id,
                        action: "add",
                      }));
                      setSearchResults([]);
                      setSearchKey('');
                      setSearchNotification(__("dx.summary.searchResults.syndrome.added").replace("{syndrome}", syndrome.canonical_name[currentLanguage]));
                      return true;
                    }}
                  /> : false
            ))}
          </ul>
        )}
        </>
      )}
    </div>
  )
}

export default withTranslation()(ExaminationViewSearchBar);
