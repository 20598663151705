import { useState, useEffect, useContext } from "react"
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from "../../atoms/Button/Button"
import ResourceApi from "../../services/resource"
import { formatName } from '../../services/examination';
import "./WorklistView.css"
import { ExaminationContext } from '../../context-providers/Examination';
import {Snack, initialSnackState, snackEvent} from "../../atoms/Snack"

const compareDate = (date1, date2) => {
    const diff = (date1.getTime() - date2.getTime())
    if(diff < 0)
        return -1
    if(diff === 0)
        return 0
    if(diff > 0)
        return 1
}

const dateString = (date) => {
    const month = `${(date.getMonth() + 1)}`.padStart(2, "0")
    const day = `${(date.getDate())}`.padStart(2, "0")
    return `${date.getFullYear()}-${month}-${day}`
}

const groupByDay = (data, toDate) => {
    return data.reduce((acc, datum) => {
        acc[dateString(toDate(datum))] = (acc[dateString(toDate(datum))] || []).concat(datum)
        return acc
    }, {})
}

const Appointment = ({appointment, t: __}) => {
    const [loading, setLoading] = useState(false)
    const examinationContext = useContext(ExaminationContext);
    const history = useHistory()

    const goToExam = (examination_id) => {
        if(loading)
            return
        setLoading(true)
        history.push(`/exam/${examination_id}`);
    }

    const createExam = () => {
        if(loading)
            return
        setLoading(true)
        examinationContext.createExamination(appointment.patient_id, null, "T2", null)
            .then((exam) => {
                return ResourceApi.updateAppointment(appointment.id, {examination_id: exam.id})
                    .then(() => {
                        return history.push(`/exam/${exam.id}`);
                    })
            })
            .finally(() => setLoading(false))
    }

    return (
        <div>
            <div className="row wl-entry" style={{justifyContent: "space-around"}}>
                <div className="w6">
                    {appointment.scheduled_start_date ? <div style={{ marginTop: "0.5rem" }}>At: {new Date(appointment.scheduled_start_date).toLocaleTimeString()}</div> : null }
                    <div style={{ marginTop: "0.5rem" }}>Station: {appointment.station_ae_title}</div>
                </div>
                <div className="w6">
                    <div style={{ marginTop: "0.5rem" }}>Patient Name: {formatName(appointment.patient.name).fullName}</div>
                    <div style={{ marginTop: "0.5rem" }}>Practitionner: {formatName(appointment.performing_physician_name).fullName}</div>
                </div>
                <div className="w6">
                    <div style={{display: "flex", justifyContent: "space-around", gap: "1rem"}}>
                    {
                        appointment.examination_id ?
                        <Button label="Go to examination" disabled={loading} icon="right-big" onClick={() => goToExam(appointment.examination_id)}/> :
                        <Button label="Create examination" disabled={loading} icon="stars" onClick={() => createExam(appointment.examination_id)}/>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

const snackMessage = (state) => {
    switch(state) {
        case "already_updated":
            return "The worklist has already been fetched recently. Please Wait some time."
        default:
            return "An error happend while fetching the worklist."
    }
}

const WorklistView = ({t: __}) => {
    const [appointments, setAppointments] = useState([]);
    const [loading, setLoading] = useState(false)
    const [lastUpdateAt, setLastUpdateAt] = useState(null)
    const [snack, setSnack] = useState(initialSnackState({}))


    useEffect(() => {
      downloadAppointments()
      const apptChecker = setInterval(() => downloadAppointments(), 30000);

        return () => { clearInterval(apptChecker); }
    }, [])

    const downloadAppointments = () => {
        setLoading(true)
        return ResourceApi.appointment()
            .then(({data: {data}}) => setAppointments(groupByDay(data, (datum) => datum.scheduled_start_date ? new Date(datum.scheduled_start_date) : new Date())))
            .finally(() => setLoading(false))
    }

  const refreshAppointments = () => {
    setLoading(true)
    ResourceApi.refreshAppointmentFromWorklist().then(({data}) => {
        if (data.result == "already_updated") {
            setLastUpdateAt(new Date(data.completed_at))
            snackEvent("show", setSnack, {type: "warning", assigns: {state: "already_updated"}, hideAfter: 5000})
            return
        }
        if (data.result == "updated")
            return downloadAppointments()
    }).catch((resp) => {
        console.error("resp", resp)
        snackEvent("show", setSnack, {type: "error", assigns: {state: "error_fetching"}, hideAfter: 5000})
    }).finally(() => setLoading(false))
  }

    return <div className="wl-container">
        <Snack snack={snack} setSnack={setSnack}>
            {snackMessage(snack.assigns.state)}
        </Snack>
        <h2> Appointments:</h2>
        <div className="row vertical-center">
            <Button label={loading ? "Refreshing..." : "Refresh"} icon={""} onClick={refreshAppointments} disabled={loading}/>
            <div>
                {lastUpdateAt ? `Last updated from your worklist at ${lastUpdateAt.toLocaleTimeString()}` : ""}
            </div>
        </div>
        {
            Object.entries(appointments).sort(([key1], [key2]) => -key1.localeCompare(key2)).map(([day, appointments]) => {
                return (
                    <div key={day}>
                        <h3>Day: {new Date(day).toDateString()}</h3>
                        {
                            appointments.sort((a1, a2) => compareDate(new Date(a1.scheduled_start_date), new Date(a2.scheduled_start_date))).map((appointment) => <Appointment key={appointment.accession_number+"/"+appointment.patient_id} appointment={appointment} t={__}/>)
                        }
                    </div>
                )
            })
        }
    </div>
}

export default withTranslation()(WorklistView);
