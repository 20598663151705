import PropTypes from "prop-types"
import { useContext, useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import Button from "../../atoms/Button/Button"
import Icon from "../../atoms/Icon/Icon"
import DraftExamCard from "../../components/DraftExamCard/DraftExamCard"
import ExamPresetButtons from "../../components/ExamPresetButtons/ExamPresetButtons"
import { AppContext } from "../../context-providers/App"
import { ExaminationContext } from "../../context-providers/Examination"
import { getRiskFactors, getSyndromeName, getTeratogenicRisks } from "../../services/examination"
import LookupApi from "../../services/lookup"
import "./DashboardView.css"
import TextInput from "../../atoms/TextInput/TextInput"

const DashboardView = ({ t: __, deleteExam, allExams = [], medicalHistoryItems = {} }) => {
  const appContext = useContext(AppContext)
  const examinationContext = useContext(ExaminationContext)
  const [medications, setMedications] = useState([])
  const [examsNoPatientLength, setExamsNoPatientLength] = useState(6)
  const [examsWithPatientLength, setExamsWithPatientLength] = useState(6)
  const [exams, setExams] = useState([])
  const [searchKey, setSearchKey] = useState("")

  useEffect(() => {
    setExams(
      allExams.map((exam) => {
        let searchTerms = [exam.patient ? exam.patient.name : ""]
        if (exam.recommendation?.syndromes) {
          searchTerms = searchTerms.concat(
            exam.recommendation.syndromes.map((s) => {
              s.name = getSyndromeName(examinationContext.getSyndromeById(s.id), [])
              return s.name
            })
          )
        }
        exam.searchTextHelper = searchTerms.join("|").toLowerCase()
        return exam
      })
    )
  }, [allExams])

  useEffect(() => {
    for (const exam of exams.slice(0, examsWithPatientLength + examsNoPatientLength)) {
      if (exam.medical_history && exam.medical_history["teratogenicrisks.medications"]) {
        const medicationIds = exam.medical_history["teratogenicrisks.medications"]?.value
        if (!Array.isArray(medicationIds) || !medicationIds.length) return false
        const missingMedications = medicationIds.reduce(
          (ids, id) => (!medications.find((m) => m.id === id) ? [...ids, id] : ids),
          []
        )
        if (!missingMedications.length) return false
        // TODO: refactor: we already load medications inside the examination context, maybe this part can be replaced by that one
        LookupApi.getMedicationsByIds(missingMedications).then((results) =>
          setMedications([...medications, ...results.data])
        )
      }
    }
  }, [examsNoPatientLength, examsWithPatientLength])

  return (
    <div className="dashboard-container">
      <div className="start-new-exam">
        <div className="dashboard-new-exam-button">
          <div className="icon-wrapper">
            <Icon name="ultrasound" />
          </div>
          <div className="label" dangerouslySetInnerHTML={{ __html: __("dashboard.startNewExaminationDx") }}></div>
          <ExamPresetButtons action="new-exam" variant="outline" label="" />
        </div>
      </div>
      <div className="row">
        <div className="w12">
          <TextInput value={searchKey} onChange={(value) => setSearchKey(value)} icon="search" fullwidth="true" />
        </div>
      </div>
      {!!exams.length && (
        <div className="row">
          <div className="w12">
            <h1>{__("patients.examsWithPatient")}</h1>
            <ul className="dashboard-drafts">
              {exams
                .filter((e) => e.patient_id > 0 && e.searchTextHelper.includes(searchKey.toLowerCase()))
                .slice(0, examsWithPatientLength)
                .map((exam) => {
                  const riskFactors = exam.medical_history
                    ? getRiskFactors(exam.medical_history, medicalHistoryItems)
                    : []
                  const teratogenicRisks = exam.medical_history
                    ? getTeratogenicRisks(exam.medical_history, medications)
                    : []

                  return (
                    <li key={exam.id}>
                      <DraftExamCard
                        exam={exam}
                        riskFactors={riskFactors}
                        teratogenicRisks={teratogenicRisks}
                        deleteDraftExam={deleteExam}
                        dateFormat={appContext.preferences.date_format}
                        fullHeight={true}
                        searchText={searchKey}
                      />
                    </li>
                  )
                })}
            </ul>
            {exams.filter((e) => e.patient_id > 0).length > examsWithPatientLength && (
              <div className="dashboard-load-more">
                <Button
                  label={__("patients.loadMore")}
                  variant="outline"
                  onClick={() => setExamsWithPatientLength((count) => count + 6)}
                />
              </div>
            )}
          </div>
          <div className="w12">
            <h1>{__("patients.anonymousPatients")}</h1>
            <ul className="dashboard-drafts">
              {exams
                .filter((e) => !e.patient_id && e.searchTextHelper.includes(searchKey.toLowerCase()))
                .slice(0, examsNoPatientLength)
                .map((exam) => {
                  const riskFactors = exam.medical_history
                    ? getRiskFactors(exam.medical_history, medicalHistoryItems)
                    : []
                  const teratogenicRisks = exam.medical_history
                    ? getTeratogenicRisks(exam.medical_history, medications)
                    : []

                  return (
                    <li key={exam.id}>
                      <DraftExamCard
                        exam={exam}
                        riskFactors={riskFactors}
                        teratogenicRisks={teratogenicRisks}
                        deleteDraftExam={deleteExam}
                        dateFormat={appContext.preferences.date_format}
                        fullHeight={true}
                        searchText={searchKey}
                      />
                    </li>
                  )
                })}
            </ul>
            {exams.filter((e) => !e.patient_id).length > examsNoPatientLength && (
              <div className="dashboard-load-more">
                <Button
                  label={__("patients.loadMore")}
                  variant="outline"
                  onClick={() => setExamsNoPatientLength((count) => count + 6)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default withTranslation()(DashboardView)

DashboardView.propTypes = {
  t: PropTypes.any,
  createExam: PropTypes.func,
  exams: PropTypes.array,
  medicalHistoryItems: PropTypes.object,
}
