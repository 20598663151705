import React, { createContext, useState } from 'react';

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])

  // duration 0 = do not auto-hide
  const showNotification = (text, duration = 0, key = '') => {
    const actualKey = key || Date.now();
    setNotifications((notifications) => [...notifications, {
      text,
      duration,
      key: actualKey,
      timeout: !!duration ? setTimeout(() => removeNotification(actualKey), duration) : false
    }]);
  }

  const updateNotification = (text, duration = null, key) => {
    const index = notifications.findIndex(notification => notification.key === key);
    if (index < 0) {
      showNotification(text, duration, key);
      return false;
    }

    if (duration !== null) {
      clearTimeout(notifications[index]?.timeout);
    }

    let newNotifications = [...notifications];
    newNotifications[index] = {
      text,
      duration: duration ?? notifications[index].duration,
      key,
      timeout: duration !== null ? setTimeout(() => removeNotification(key), duration) : notifications[index].timeout
    }
    setNotifications(newNotifications);
  }

  const removeNotification = (key) => {
    clearTimeout(notifications.find(notification => notification.key === key)?.timeout);
    setNotifications((notifications) => notifications.filter((notification) => notification.key !== key));
  }

  const notificationExists = (key) => notifications.some(notification => notification.key === key);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        showNotification,
        updateNotification,
        removeNotification,
        notificationExists,
      }}
    >
      {children}
    </NotificationContext.Provider >
  )
};