import { useContext } from "react";
import SectionsNavBar from "../SectionsNavBar/SectionsNavBar";
import { withTranslation } from "react-i18next";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";

const ExamNavBar = ({ t: __, medicalHistory = {}, selected = "", instances = [], anomalies = [], videos = [], refs = {}, disableOptions = false, disableActions = false, onOptionClick = (panel) => {}, onChangeSection = (section) => {} }) => {
  const { isFeatureFlagEnabled } = useAuth();
  const examinationContext = useContext(ExaminationContext);

  const currentTrimester = examinationContext.examination.trimester;
  const unverifiedMedias = isFeatureFlagEnabled("sonio.detect") ? instances.filter(media => media.dicom_media_type === "image" && media.modality !== "SR" && !media?.verified) : [];

  const getSections = () => {
    let sections = [];

    sections.push({
      id: "images",
      icon: "image",
      title: __("examination.sidebar.images"),
      counter: anomalies.length,
      target: refs.imageChapterRef?.current
    });

/*     if (isFeatureFlagEnabled("sonio.detect") || unverifiedMedias.length > 0) {
      sections.push({
        id: "matching",
        icon: "matching-score",
        title: __("examination.sidebar.matching"),
        counter: currentTrimester !== "ND" && unverifiedMedias.length,
        disabled: currentTrimester === "ND" || !unverifiedMedias.length,
        target: refs.matchingChapterRef?.current
      });
    }
 */
    sections.push({
      id: "videos",
      icon: "video",
      title: __("examination.sidebar.videos"),
      disabled: !videos.length,
      target: refs.videoChapterRef?.current
    });

    return sections;
  }

  return (
    <SectionsNavBar
      onOptionClickCurrentOption={onChangeSection}
      options={getSections()}
      disableOptions={disableOptions}
      disableActions={disableActions}
      actions={[{
          icon: "paste",
          label: __("examination.sidebar.phenotype"),
          selected: selected === "phenotype",
          counter: medicalHistory.filter(item => item.is_risky).length,
          onClick: () => onOptionClick("phenotype"),
      },
      ]}
      actionsAfter={[{
        icon: "stats",
        label: __("examination.sidebar.stats"),
        selected: selected === "stats",
        onClick: () => onOptionClick("stats"),
      }]}
    />
  );
};

export default withTranslation()(ExamNavBar);
