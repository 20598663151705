import { useContext, useEffect, useRef, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';

/* Atoms */
import Button from '../../atoms/Button/Button';
import DateInput from '../../atoms/DateInput/DateInput';
import GaInput from '../../atoms/GaInput/GaInput';
import Icon from '../../atoms/Icon/Icon';
import NumericInput from '../../atoms/NumericInput/NumericInput';

/* Components */
import MedicalHistoryInput from '../../components/MedicalHistoryInput/MedicalHistoryInput';
import FetusSexHandler from '../../components/MedicalHistoryInput/FetusSexHandler';
import PatientName from "../../components/PatientName/PatientName";
import SectionsNavBar from '../../components/SectionsNavBar/SectionsNavBar';
import FetusesTable from '../../components/FetusesTable/FetusesTable';

/* Contexts */
import { WindowContext } from '../../context-providers/Window';
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from '../../context-providers/Examination';
import useAuth from '../../context-providers/Auth';

/* Services */
import { computeBMI, computeWeight, getNiceGestionalAge } from '../../services/examination';

/* CSS */
import "./ExaminationMedicalHistoryView.css";


const ExaminationMedicalHistoryView = ({ t: __, medicalHistoryItems }) => {
  const { isFeatureFlagEnabled, isFeatureFlagsLoaded } = useAuth();
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const windowContext = useContext(WindowContext);
  const appContext = useContext(AppContext);
  const preferedDateFormat = appContext.preferences.date_format;
  const examinationContext = useContext(ExaminationContext);
  const { examId } = useParams();
  const history = useHistory();

  const episode = examinationContext.episode || {};
  const examination = examinationContext.examination || {};
  const gestationalAge = episode?.lmp_date ? getNiceGestionalAge(__, new Date(episode.lmp_date), examination?.examination_date) : '';

  const sectionDashboard = useRef(null);
  const sectionTeratogenicRisks = useRef(null);
  const sectionGeneticHistory = useRef(null);
  const sectionWayOfLife = useRef(null);
  const sectionMedicalBackground = useRef(null);
  const sectionVirology = useRef(null);
  const sectionSerology = useRef(null);
  const sectionObstetricHistory = useRef(null);

  const [chapters, setChapters] = useState([]);

  useEffect(() => {
    const newChapters = [
      {
        icon: 'sonio',
        title: __('medicalhistory.initialInterview'),
        description: __('medicalhistory.initialmedicalhistory.description'),
        target: sectionDashboard.current,
        alert: printRiskFactorsCounter('medicalexam') ? 'bell' : '',
      },
      {
        icon: 'pills',
        title: __('medicalhistory.teratogenicRisks'),
        description: __('medicalhistory.teratogenicRisks.description'),
        target: sectionTeratogenicRisks.current,
        alert: printRiskFactorsCounter('teratogenicrisks') ? 'bell' : '',
      },
      {
        icon: 'dna',
        title: __('medicalhistory.geneticHistory'),
        description: __('medicalhistory.geneticHistory.description'),
        target: sectionGeneticHistory.current,
        alert: printRiskFactorsCounter('genetichistory') ? 'bell' : '',
      },
      {
        icon: 'wine',
        title: __('medicalhistory.wayOfLife'),
        description: __('medicalhistory.wayOfLife.description'),
        target: sectionWayOfLife.current,
        alert: printRiskFactorsCounter('wayoflife') ? 'bell' : '',
      },
      {
        icon: 'stethoscope',
        title: __('medicalhistory.medicalBackground'),
        description: __('medicalhistory.medicalBackground.description'),
        target: sectionMedicalBackground.current,
        alert: printRiskFactorsCounter('medicalbackground') ? 'bell' : '',
      },
      {
        icon: 'virus',
        title: __('medicalhistory.virology'),
        description: __('medicalhistory.virology.description'),
        target: sectionVirology.current,
        alert: printRiskFactorsCounter('infections') ? 'bell' : '',
      },
      {
        icon: 'serology',
        title: __('medicalhistory.serology'),
        description: __('medicalhistory.serology.description'),
        target: sectionSerology.current,
        alert: printRiskFactorsCounter('serology') ? 'bell' : '',
      },
      {
        icon: 'feeding-bottle',
        title: __('medicalhistory.obstetricHistory'),
        description: __('medicalhistory.obstetricHistory.description'),
        target: sectionObstetricHistory.current,
        alert: printRiskFactorsCounter('obstetrichistory') ? 'bell' : '',
      },
    ];
    setChapters(newChapters);
  }, [JSON.stringify(examinationContext.examination.medical_history)]);

  useEffect(() => {
    /* always scroll to top */
    document.querySelector('.examination-medical-history-chapters')?.scrollTo({ top: 0 });
  }, [isFeatureFlagsLoaded]);

  /**
   * print the right input field conforming to given options
   */
  let fieldOrder = 0; // this is used to store the order used by the front to display the fields, in order to repropose them in the same order everywhere
  const printField = (field, customCallbacks = {}) => {
    if (!field.type) return false;
    field.order = ++fieldOrder;
    return <MedicalHistoryInput field={field} customCallbacks={customCallbacks} />;
  };

  const getMedicalHistoryValue = (text_id) => {
    return (!examination.medical_history?.[text_id])
      ? { value: null, raw_value: null }
      : { value: examination.medical_history[text_id].value, raw_value: examination.medical_history[text_id].raw_value };
  };

  /**
   * print the counter when there are one or more risk factors for the given category
   * (prevent fetus items to be listed as risk factors)
   */
  const printRiskFactorsCounter = (category) => {
    if (!examination.medical_history) return false;
    const risks = Object.values(examination.medical_history).filter((risk) => risk.text_id?.indexOf("medicalexam.fetus") !== 0 && risk.text_id?.indexOf(category) === 0 && ((category === "teratogenicrisks" && !!risk.risk_factors?.length) || risk.is_risky));
    return risks.length !== 0;
  };


  const onBlurEpisodeField = (field) => (newValue) => {
    if (!newValue) {
      examinationContext.updateEpisode({ [field]: "" });
      return;
    }

    const dateFields = ["conception_date", "lmp_date", "estimated_delivery_date", "embryo_transfer_date", "prev_ultrasound_exam_date"];
    if (dateFields.includes(field)) newValue = new Date(newValue).toISOString().slice(0, 10);
    examinationContext.updateEpisode({ [field]: newValue });
  };

  const onBlurEdd = (eddString) => examinationContext.updateEpisode({ estimated_delivery_date: eddString || "" });

  const onChangeHeight = (field, height) => {
    const weight = examination.medical_history?.["medicalexam.mother.weight"]?.raw_value ?? '';
    if (!!height && !!weight) {
      let bmi = computeBMI(weight, height);
      if (!!bmi) bmi = bmi.toFixed(2);
      examinationContext.updateMedicalHistory([{ field: medicalHistoryItems["medicalexam.mother.height"], raw_value: height }, { field: medicalHistoryItems["medicalexam.mother.bmi"], raw_value: bmi }]);
    } else {
      examinationContext.updateMedicalHistory([{ field: medicalHistoryItems["medicalexam.mother.height"], raw_value: height }]);
    }
  };

  const onChangeWeight = (field, weight) => {
    const height = examination.medical_history?.["medicalexam.mother.height"]?.raw_value ?? '';
    if (!!height && !!weight) {
      let bmi = computeBMI(weight, height);
      bmi = !!bmi ? bmi.toFixed(2) : '';
      examinationContext.updateMedicalHistory([{ field: medicalHistoryItems["medicalexam.mother.weight"], raw_value: weight }, { field: medicalHistoryItems["medicalexam.mother.bmi"], raw_value: bmi }]);
    } else {
      examinationContext.updateMedicalHistory([{ field: medicalHistoryItems["medicalexam.mother.weight"], raw_value: weight }]);
    }
  };

  const onChangeBmi = (field, bmi) => {
    const height = examination.medical_history?.["medicalexam.mother.height"]?.raw_value ?? '';
    if (!!height && !!bmi) {
      let weight = computeWeight(height, bmi);
      weight = !!weight ? weight.toFixed() : '';
      examinationContext.updateMedicalHistory([{ field, raw_value: bmi }, { field: medicalHistoryItems["medicalexam.mother.weight"], raw_value: weight }]);
    } else {
      examinationContext.updateMedicalHistory([{ field, raw_value: bmi }]);
    }
  };

  const backToDashboard = () => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (isFeatureFlagEnabled("soniopedia")) {
      history.push("/");
    } else {
      /* Rely on the redirection in router.ex */
      window.location.href = "/dashboard";
    }
  };

  const backToExamination = (url = false) => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (url === false) url = `/exam/${examination?.id ? examination.id : examId}`;

    if (url.match(new RegExp(`^\/exam\/${Number(examId)}$`)) && isFeatureFlagEnabled("soniopedia")) url += "/dx"
    else url += "#anamnesis"

    history.push(url);
  };

  /* Wait for feature flag to be loaded before deciding what to display */
  if(!isFeatureFlagsLoaded)
    return null

  return (
    <div className="examination-medical-history-container">
      <SectionsNavBar
        options={chapters}
      />

      <div className="examination-medical-history-chapters">
        {/* initial interview */}
        <div className="chapter" ref={sectionDashboard}>
          <h2>{__('medicalhistory.initialInterview')}</h2>
          <div className="examination-medical-history-patient-container">
            <PatientName patient={examinationContext.patient} episode={examinationContext.episode} examinationDate={examinationContext.examination.examination_date} />
          </div>
          <div className="row justify-center">
              {isFeatureFlagEnabled("sonio.dx_v2")
                ? <div className="w8 text-align-center">
                    <FetusSexHandler>
                      {printField({ ...medicalHistoryItems["medicalexam.fetus.sex"] })}
                    </FetusSexHandler>
                  </div>
                : <>
                  <div className="w2 text-align-center">
                    <FetusSexHandler
                      showFetusSexButton={false}
                    />
                  </div>
                  <div className="w6 text-align-center">
                    <FetusesTable
                      episode={examinationContext.episode}
                      examination={examinationContext.examination}
                      fetusSexVisibility={examinationContext.fetusSexVisibility}
                      updateExaminationFetus={examinationContext.updateExaminationFetus}
                      createEpisodeFetus={examinationContext.createEpisodeFetus}
                      updateEpisodeFetus={examinationContext.updateEpisodeFetus}
                      deleteEpisodeFetus={examinationContext.deleteEpisodeFetus}
                    />
                  </div>
                </>
              }
          </div>
          <div className="row">
            <div className="w6">
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.mother.height"].label[currentLanguage]}</div>
                <div className="w7">{printField({ ...medicalHistoryItems["medicalexam.mother.height"], label: '' }, { onChange: onChangeHeight })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.mother.weight"].label[currentLanguage]}</div>
                <div className="w7">{printField({ ...medicalHistoryItems["medicalexam.mother.weight"], label: '' }, { onChange: onChangeWeight })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.mother.bmi"].label[currentLanguage]}</div>
                <div className="w7">{printField({ ...medicalHistoryItems["medicalexam.mother.bmi"], label: '' }, { onChange: onChangeBmi })}</div>
              </div>
            </div>
            <div className="w6 border">
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.fetus.conception_date"].label[currentLanguage]}</div>
                <div className="w7 ph-no-capture">
                  <DateInput
                    value={episode?.conception_date || ""}
                    localeOrFormat={preferedDateFormat}
                    onBlur={onBlurEpisodeField("conception_date")}
                  />
                </div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.fetus.lmp"].label[currentLanguage]}</div>
                <div className="w7 ph-no-capture">
                  <DateInput
                    value={episode?.lmp_date || ""}
                    localeOrFormat={preferedDateFormat}
                    onBlur={onBlurEpisodeField("lmp_date")}
                  />
                </div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.fetus.edd"]?.label[currentLanguage]}</div>
                <div className="w7 ph-no-capture">
                  <DateInput
                    value={episode?.estimated_delivery_date || ""}
                    localeOrFormat={preferedDateFormat}
                    onBlur={onBlurEpisodeField("estimated_delivery_date")}
                  />
                </div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.fetus.embryo_transfer_date"]?.label[currentLanguage]}</div>
                <div className="w7 ph-no-capture">
                  <DateInput
                    value={episode?.embryo_transfer_date || ""}
                    localeOrFormat={preferedDateFormat}
                    onBlur={onBlurEpisodeField("embryo_transfer_date")}
                  />
                </div>
              </div>

              <div className="row vertical-center">
                <div className="w5 text-align-right">{medicalHistoryItems["medicalexam.fetus.embryo_transfer_day"]?.label[currentLanguage]}</div>
                <div className="w7 ph-no-capture">
                  <NumericInput
                    value={episode?.embryo_transfer_day || ""}
                    onChange={onBlurEpisodeField("embryo_transfer_day")}
                  />
                </div>
              </div>

              <div className="row vertical-center">
                <div className="w5 text-align-right"> {medicalHistoryItems["medicalexam.fetus.prev_ultrasound_ga"]?.label[currentLanguage]} </div>
                <div className="w7 ph-no-capture">
                  <GaInput
                    value={episode?.prev_ultrasound_ga || ""}
                    onChange={onBlurEpisodeField("prev_ultrasound_ga")}
                  />
                </div>
              </div>
              <div className="row vertical-center">
                <div className="w5 text-align-right"> {medicalHistoryItems["medicalexam.fetus.prev_ultrasound_date"]?.label[currentLanguage]} </div>
                <div className="w5 ph-no-capture">
                  <DateInput
                    value={episode?.prev_ultrasound_exam_date || ""}
                    onBlur={onBlurEpisodeField("prev_ultrasound_exam_date")}
                    localeOrFormat={preferedDateFormat}
                  />
                </div>
              </div>

            </div>
          </div>
        </div>

        {/*** teratogenic risks ***/}
        <div className="chapter" ref={sectionTeratogenicRisks}>
          <h2>
            {__('medicalhistory.teratogenicRisks')}
            <img src="/images/crat-logo.svg" className="crat" alt="CRAT" />
          </h2>
          <div className="row vertical-center">
            <div className="w12 text-align-center">{printField(medicalHistoryItems["teratogenicrisks.medications"])}</div>
          </div>
        </div>

        {/*** genetic history ***/}
        <div className="chapter z-index-1000" ref={sectionGeneticHistory}>
          <h2>{__('medicalhistory.geneticHistory')}</h2>
          <div className="row vertical-center">
            <div className="w4 text-align-center">{printField(medicalHistoryItems["genetichistory.cgh"])}</div>
            <div className="w4 text-align-center">{printField(medicalHistoryItems["genetichistory.karyotype"])}</div>
            <div className="w4 text-align-center">{printField(medicalHistoryItems["genetichistory.dpni"])}</div>
          </div>
          {(getMedicalHistoryValue("genetichistory.cgh").value === "yes_and_positive"
            || getMedicalHistoryValue("genetichistory.karyotype").value === "yes_and_positive"
            || getMedicalHistoryValue("genetichistory.dpni").value === "yes_and_positive")
            && (
              <div className="row vertical-center">
                <div className="w3 o1 text-align-right">
                  {getMedicalHistoryValue("genetichistory.cgh").value === "yes_and_positive" ? __("medicalhistory.cgh") :
                    getMedicalHistoryValue("genetichistory.karyotype").value === "yes_and_positive" ? __("medicalhistory.karyotype") : __("medicalhistory.dpni")
                  }{" "}
                  {medicalHistoryItems["genetichistory.geneticist_result"].label[currentLanguage]}
                </div>
                <div className="w6 text-align-center">{printField({ ...medicalHistoryItems["genetichistory.geneticist_result"], label: '' })}</div>
              </div>
            )}
          <div className="row vertical-center">
            <div className="w4 o4 text-align-center">{printField(medicalHistoryItems["genetichistory.consanguinity"])}</div>
            {examinationContext.examination.medical_history?.["genetichistory.consanguinity"]?.value === "yes" && (
              <div className="examination-medical-history-warning">
                <Icon name="warning" />
                {" " + __("medicalhistory.consanguinityWarning")}
              </div>
            )}
          </div>
        </div>

        {/*** way of life ***/}
        <div className="chapter" ref={sectionWayOfLife}>
          <h2>{__('medicalhistory.wayOfLife')}</h2>
          <div className="row">
            <div className="w12">
              <div className="row vertical-center">
                <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.alcohol"].label[currentLanguage]}</div>
                <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.alcohol"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.tobacco"].label[currentLanguage]}</div>
                <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.tobacco"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.cannabis"].label[currentLanguage]}</div>
                <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.cannabis"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.cocaine"].label[currentLanguage]}</div>
                <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.cocaine"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.environment"].label[currentLanguage]}</div>
                <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.environment"], label: '' })}</div>
              </div>

              {/* {examination.medical_history?.["wayoflife.heroine"] && (
                <div className="row vertical-center">
                  <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.heroine"].label[currentLanguage]}</div>
                  <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.heroine"], label: '' })}</div>
                </div>
              )} */}

              {examination.medical_history?.["wayoflife.carbonmonoxide"] && (
                <div className="row vertical-center">
                  <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.carbonmonoxide"].label[currentLanguage]}</div>
                  <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.carbonmonoxide"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["wayoflife.ecstasy"] && (
                <div className="row vertical-center">
                  <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.ecstasy"].label[currentLanguage]}</div>
                  <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.ecstasy"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["wayoflife.ionisingradiation"] && (
                <div className="row vertical-center">
                  <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.ionisingradiation"].label[currentLanguage]}</div>
                  <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.ionisingradiation"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["wayoflife.leadpoisoning"] && (
                <div className="row vertical-center">
                  <div className="w4 text-align-right">{medicalHistoryItems["wayoflife.leadpoisoning"].label[currentLanguage]}</div>
                  <div className="w8">{printField({ ...medicalHistoryItems["wayoflife.leadpoisoning"], label: '' })}</div>
                </div>
              )}

            </div>
          </div>
        </div>

        {/*** medical background ***/}
        <div className="chapter" ref={sectionMedicalBackground}>
          <h2>{__('medicalhistory.medicalBackground')}</h2>
          <div className="row">
            <div className="w12">
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.diabetes"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.diabetes"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.thyroid"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.thyroid"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.hypertension"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.hypertension"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.phenylketonuria"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.phenylketonuria"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.risk_preeclampsia"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.risk_preeclampsia"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.rai"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.rai"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.lupus"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.lupus"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.autoimmune"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.autoimmune"], label: '' })}</div>
              </div>

              {examination.medical_history?.["medicalbackground.alloimmunisation"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.alloimmunisation"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.alloimmunisation"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.thalassemia"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.thalassemia"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.thalassemia"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.sicklecell"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.sicklecell"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.sicklecell"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.cervicalsurgery"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.cervicalsurgery"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.cervicalsurgery"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.uterusmalformation"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.uterusmalformation"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.uterusmalformation"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.gravidiccholestasis"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.gravidiccholestasis"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.gravidiccholestasis"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.hyperprolactinemia"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.hyperprolactinemia"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.hyperprolactinemia"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.antiphospholipid"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.antiphospholipid"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.antiphospholipid"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.coagulationpathology"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.coagulationpathology"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.coagulationpathology"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.hyperthyroidism"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.hyperthyroidism"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.hyperthyroidism"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.earlycataract"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.earlycataract"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.earlycataract"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.muscleweakness"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.muscleweakness"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.muscleweakness"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.hrdisturbances"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.hrdisturbances"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.hrdisturbances"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.functionalsigns"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.functionalsigns"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.functionalsigns"], label: '' })}</div>
                </div>
              )}

              {examination.medical_history?.["medicalbackground.thromboembolism"] && (
                <div className="row vertical-center">
                  <div className="w6 text-align-right">{medicalHistoryItems["medicalbackground.thromboembolism"].label[currentLanguage]}</div>
                  <div className="w6">{printField({ ...medicalHistoryItems["medicalbackground.thromboembolism"], label: '' })}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*** virology ***/}
        <div className="chapter" ref={sectionVirology}>
          <h2>{__('medicalhistory.virology')}</h2>
          <div className="row">
            <div className="w12">
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.chlamydia"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.cytomegalovirus"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.gonorrhea"])}</div>
              </div>
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.hepatitis_a"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.hepatitis_b"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.hepatitis_c"])}</div>
              </div>
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.herpes"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.leishmaniasis"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.lymeborreliosis"])}</div>
              </div>
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.malaria"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.parvovirus"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.rubella"])}</div>
              </div>
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.syphilis"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.streptococcus"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.toxoplasmosis"])}</div>
              </div>
              <div className="row">
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.varicella"])}</div>
                <div className="w4 text-align-center">{printField(medicalHistoryItems["infections.zika"])}</div>
              </div>
            </div>
          </div>
        </div>

        {/* serology */}
        <div className="chapter" ref={sectionSerology}>
          <h2>{__('medicalhistory.serology')}</h2>
          <div className="row">
            <div className="w12">
              <div className="row">
                <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.pappa"])}</div>
                <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.hcgb"])}</div>
                <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.afp"])}</div>
                <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.flt1_pigf"])}</div>
              </div>
              <div className="row">
                <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.riskT21"])}</div>
                {/* <div className="w3 text-align-center">{printField(medicalHistoryItems["serology.risk_rciu"])}</div> */}
                {/*<div className="w3 text-align-center">{printField(medicalHistoryItems["serology.estriol"])}</div>*/}
              </div>
            </div>
          </div>
        </div>

        {/*** obstetric history ***/}
        <div className="chapter" ref={sectionObstetricHistory}>
          <h2>{__('medicalhistory.obstetricHistory')}</h2>
          <div className="row">
            <div className="w12">
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["obstetrichistory.cesarean"]?.label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["obstetrichistory.cesarean"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["obstetrichistory.pretermdelivery"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["obstetrichistory.pretermdelivery"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["obstetrichistory.latemiscarriage"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["obstetrichistory.latemiscarriage"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["obstetrichistory.recurrentmiscarriage"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["obstetrichistory.recurrentmiscarriage"], label: '' })}</div>
              </div>
              <div className="row vertical-center">
                <div className="w6 text-align-right">{medicalHistoryItems["obstetrichistory.bleedingchildbirth"].label[currentLanguage]}</div>
                <div className="w6">{printField({ ...medicalHistoryItems["obstetrichistory.bleedingchildbirth"], label: '' })}</div>
              </div>
            </div>
          </div>
        </div>

      </div>

      {!windowContext.isDetached && (
        <div className="examination-medical-history-cta-bar">
          {appContext.referer.match(new RegExp(`^/exam/${Number(examId)}/dx$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : appContext.referer.match(new RegExp(`^/exam/${Number(examId)}$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : examination.status === "draft" ? (
            <>
              <Button label={__("examination.backToTheDashboard")} icon="home" onClick={() => backToDashboard()} />
              <Button label={__("examination.startTheExamination")} variant="outline" iconAfter="right-big" onClick={() => backToExamination()} />
            </>
          ) : (
            <Button label={examination.status !== "draft" ? __("examination.backToExamination") : __("examination.startTheExamination")} iconAfter="right-big" onClick={() => backToExamination()} />
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(ExaminationMedicalHistoryView);
