import { useCallback, useEffect, useState, useMemo } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import config from "../../config";
import ArrangeOnTop from "../../atoms/ArrangeOnTop/ArrangeOnTop";
import "./DetectionScore.css";

const DetectionScore = ({ t: __, score, source = "", view = false, viewName = "", onDismiss = () => { }, alternativeSlides, changeAssociation = () => { }, showIcon = true, showBaloon = true, variant = "", instancePrediction = {}, isDetectable = false, confirmedMatching = false, dictionaryTerms = [], examCompleted }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const [openBaloon, setOpenBaloon] = useState(false);
  const scoreIsLow = (score || 0) <= config.evaluationScoreThreshold && isDetectable;

  const ocrWasUsed = instancePrediction?.detected_by === "annotations"; 
  const ocrCorrected = instancePrediction?.correction_on_annotation;
  const ocrMatched = dictionaryTerms.some(d => instancePrediction?.annots_in_dic_used_for_matching?.includes(d) || instancePrediction?.annotation_detected === d);

  const getOcrIcon = (used, corrected) => {
    if (!used) {
      return "empty";
    }
    if (corrected) {
      return "ocr-approx";
    }
    return "ocr";
  }

  const onCloseHandler = useCallback(() => {
    setOpenBaloon(false);
  }, [setOpenBaloon]);

  useEffect(() => {
    if (openBaloon) {
      window.addEventListener('click', onCloseHandler, false);
    } else {
      window.removeEventListener('click', onCloseHandler, false);
    }
  }, [openBaloon]);

  useEffect(() => {
    return () => window.removeEventListener('click', onCloseHandler, true);
  }, []);

  return (
    (
      <span className={`detection-score-container variant-${variant}`}>
        {showIcon && (
          <span className="detection-score" onClick={() => setOpenBaloon(true)}>
            <>
            {source === "user" && <span className="ok"><Icon name="manual" /></span>}
            {!confirmedMatching && ocrWasUsed && <span className={ocrMatched ? 'ok' : 'ko'}><Icon name={getOcrIcon(ocrWasUsed, ocrCorrected)} /></span>}
            {!confirmedMatching && isDetectable && <span className={scoreIsLow ? 'ko' : 'ok'}><Icon name="ai-detection" /></span>}
            </>
          </span>
        )}
        {showBaloon && openBaloon && (
          <ArrangeOnTop variant="baloon">
            <div className="detection-score_baloon">
              <p>{
                source === "user"
                ? __(`examination.detectionScore.manual`, { score: Math.floor(score * 100), threshold: Math.floor(config.evaluationScoreThreshold * 100), view: viewName })
                : (isDetectable && __(`examination.detectionScore.${scoreIsLow ? 'low' : 'high'}`, { score: Math.floor(score * 100), threshold: Math.floor(config.evaluationScoreThreshold * 100), view: viewName }))
              }</p>
              {!confirmedMatching && ocrWasUsed && !!dictionaryTerms.length && (
                <>
                {!ocrMatched && (
                  <p>
                    {__("examination.detectionDictionary.notMatched")}
                  </p>
                )}
                <p>
                  {__("examination.detectionDictionary.labelDetected")}
                  <span className="label-detected">{instancePrediction?.annotation_detected || instancePrediction?.annots_read.join(", ")}</span>
                </p>
                {(__("examination.detectionDictionary.listOfLabels"))}
                <ul className="detection-score-dictionary">
                  {dictionaryTerms.map(d => (
                    <li key={d} className={instancePrediction?.annotation_detected?.includes(d) ? 'selected' : ''}>
                      {d}
                      {instancePrediction?.annots_in_dic_used_for_matching?.includes(d) && ocrCorrected && <span className="close-matching-label">{__("examination.detectionDictionary.closeMatching")}</span>}
                    </li>
                  ))}
                </ul>
                </>
              )}
              <div>
                {!examCompleted && !confirmedMatching && scoreIsLow && (
                  <Button
                    label={__("examination.detectionScore.dismiss")}
                    variant="outline"
                    onClick={async (e) => {
                      e.stopPropagation();
                      await onDismiss();
                      onCloseHandler();
                    }}
                  />
                )}
                <Button
                  label={__("examination.detectionScore.ok")}
                  onClick={onCloseHandler}
                />
              </div>
              {!confirmedMatching && scoreIsLow && !!alternativeSlides.length && (
                <div className="alternatives">
                  <h3 className="section-title">{__("examination.detectionScore.alternatives")}</h3>
                  {
                    alternativeSlides.map(slide => (
                      !!slide
                      && slide?.label?.[currentLanguage] !== viewName
                      && (
                        <Button
                          key={slide.id}
                          label={slide?.label?.[currentLanguage]}
                          variant="link"
                          iconAfter="right"
                          onClick={async () => {
                            if (!examCompleted) {
                              await changeAssociation(slide);
                            }
                            onCloseHandler();
                          }}
                        />
                      )))}
                </div>
              )}
            </div>
          </ArrangeOnTop>
        )}
      </span>
    )
  );
};

export default withTranslation()(DetectionScore);
