import React, { useContext, useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from '../../../atoms/Button/Button';
import Icon from '../../../atoms/Icon/Icon';
import SectionsNavBar from '../../../components/SectionsNavBar/SectionsNavBar';
import { AppContext } from '../../../context-providers/App';
import useAuth from "../../../context-providers/Auth";
import { ExaminationContext } from '../../../context-providers/Examination';
import { formatName, getRiskFactors, getTeratogenicRisks } from "../../../services/examination";
import LookupApi from "../../../services/lookup";
import ResourceApi from '../../../services/resource';
import ExaminationReviewOverviewDx from './chapters/OverviewDx';
import ExaminationReviewResults from './chapters/Results';
import './ExaminationReviewDxView.css';
import ExaminationReviewOverviewDxPrint from './print/OverviewDx';
import ExaminationReviewResultsPrint from './print/Results';

const ExaminationReviewDxView = ({ t: __, patient, episode, examination, instances, itemsAtRisk, syndromes, syndromeDetails, syndromeZero, allMedications, slides, biometries }) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { user, isFeatureFlagEnabled } = useAuth();
  const isDxSyndromeSummaryEnabled = isFeatureFlagEnabled('sonio.dx_syndrome_summary')

  const [showEmailReportModal, setShowEmailReportModal] = useState(false);
  const [examinationMetadata, setExaminationMetadata] = useState([]);
  const [medications, setMedications] = useState([]);
  const [medicalHistoryItems, setMedicalHistoryItems] = useState(null);
  const [zones, setZones] = useState([]);
  const [canBeDownloaded, setCanBeDownloaded] = useState(false);
  const [printingPDF, setPrintingPDF] = useState(false);
  const malformations = examination?.malformations || [];
  examination.type = (examination.type || (!!malformations.length ? "diagnostic" : "screening"));

  /** risk factors */
  const riskFactors = examination?.medical_history ? getRiskFactors(examination.medical_history, medicalHistoryItems) : [];
  const teratogenicRisks = examination?.medical_history ? getTeratogenicRisks(examination.medical_history, medications) : [];

  /** sections management */
  const sectionDashboard = useRef(null);
  const sectionRiskFactors = useRef(null);
  const sectionDiagnostic = useRef(null);

  const showDiagnosticSection = examination.type === "diagnostic" && !!examination.recommendation?.syndromes?.length;

  useEffect(() => {
    LookupApi.getMedicalHistoryItem().then(resp => {
      const mh = {};
      resp.data.data.forEach(item => {
        mh[item.text_id] = item;
      });
      setMedicalHistoryItems(mh);
    });

    LookupApi.getExamZone().then(resp => {
      setZones(resp.data.data.slice(0, 5));
    });
  }, [])

  useEffect(() => {
    if (examination?.medical_history?.["teratogenicrisks.medications"]) {
      const value = examination.medical_history["teratogenicrisks.medications"].value;
      const missingMedications = value.reduce((ids, id) => !medications.find(m => m.id === id) ? [...ids, id] : ids, []);
      if (!!missingMedications.length) {
        LookupApi.getMedicationsByIds(missingMedications).then(results => setMedications([...medications, ...results.data]));
      }
    }
  }, [examination]);

  useEffect(() => {
    if (examination?.id) {
      ResourceApi.getExaminationMetadata(examination.id).then(resp => {
        setExaminationMetadata(resp.data.data);
      });
    }
  }, [examination.id]);

  useEffect(() => {
    if (!!syndromeDetails.length) {
      setCanBeDownloaded(true);
    }
  }, [syndromeDetails])

  const renderReportHtml = () => renderToString(
    <>
      <div className="page">
        <ExaminationReviewOverviewDxPrint
          {...{ patient, episode, examination, examinationMetadata, zones, instances, itemsAtRisk, slides, biometries, riskFactors, teratogenicRisks, syndromes, appContext, examinationContext, isDxSyndromeSummaryEnabled }}
        />
      </div>
      <div className="page">
        <ExaminationReviewResultsPrint
          syndromes={syndromes}
          syndromeDetails={[...syndromeDetails, syndromeZero]}
          malformations={malformations}
          appContext={appContext}
          examinationContext={examinationContext}
          isDxSyndromeSummaryEnabled={isDxSyndromeSummaryEnabled}
        />
      </div>
    </>
  );

  /**
   * Download PDF report
   */
  const downloadReportAsPDF = async () => {
    setPrintingPDF(true);

    const HTML = renderReportHtml()

    ResourceApi.getPdfFromHtml(examination.id, HTML).then(response => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const date = new Date().toJSON().slice(0, 16).replace(/[T|:]/g, "-");
      const patientName = patient?.name ? formatName(patient.name).fullName : __("patients.anonymous");
      const fileName = `Sonio-${date}-${patientName}.pdf`;
      const a = document.createElement('a');
      let blob = new Blob([response.data])
      blob = blob.slice(0, blob.size, "application/pdf");
      a.href = window.URL.createObjectURL(blob);
      if (!isMobile) a.setAttribute('download', fileName);
      a.click();
      a.remove();
      setPrintingPDF(false);
    });
  }

  /**
   * Email PDF report
   */
  const emailPdfReport = () => {
    const HTML = renderReportHtml()

    ResourceApi.emailPdfReport(examination.id, HTML, [user.contact_email]).then(response => {
      setShowEmailReportModal(false);
    });
  }


  /**
   * Side menu elements
   */
  let chapters = [];

  chapters.push({ icon: 'sonio', title: __('examinationReview.dx.sections.dashboard.title'), description: __('examinationReview.dx.sections.dashboard.description'), target: sectionDashboard.current });
  chapters.push({ icon: 'bell', title: __('examinationReview.dx.sections.overview.title'), description: __('examinationReview.dx.sections.overview.description'), target: sectionRiskFactors.current });

  if (showDiagnosticSection)
    chapters.push({ icon: 'position', title: __('examinationReview.dx.sections.results.title'), description: __('examinationReview.dx.sections.results.description'), target: sectionDiagnostic.current });

  return (
    <div className="examination-review-container">
      {showEmailReportModal && (
        <div className="sharing-code-container">
          <div className="modal-background" onClick={() => { setShowEmailReportModal(false); }}><Icon name="close" /></div>
          <div className="sharing-code-modal">
            <div className="sharing-form">
              <span className="sharing-email">
                {__("examinationReview.report.recipientEmail")}
                <span className="recipient">{user.contact_email}</span>
              </span>
              <span className="sharing-cta">
                <Button icon="mail" label={__("examinationReview.sendByEmail.send")} onClick={emailPdfReport} />
              </span>
              <div className="warning">{__("examinationReview.sendByEmail.warning")}</div>
            </div>
          </div>
        </div>
      )}
      <SectionsNavBar
        options={chapters}
      />
      <div className="examination-review">
        <section className="examination-review-chapter dashboard" ref={sectionDashboard}>
          <div className="examination-review-dashboard-messages">
            <h1>{__('examinationReview.examinationFinished')}</h1>
            <div className="examination-review-gotomedias">
              <div className="cta">
                <Button
                  variant={showDiagnosticSection ? "" : "outline"}
                  icon="inbox"
                  label={printingPDF ? __('examinationReview.generatingPdf') : __('examinationReview.download')}
                  onClick={() => downloadReportAsPDF()}
                  disabled={printingPDF || !canBeDownloaded}
                />
                <Button
                  variant="outline"
                  icon="mail"
                  label={__('examinationReview.sendByEmail')}
                  onClick={() => setShowEmailReportModal(true)}
                />
                <div>
                  <div className="examination-review-gotohome"
                    onClick={() => history.push('/')}>
                    <Icon name="tab" />
                    {__('examinationReview.goToDashboard')}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="examination-review-gotodetails"
            onClick={() => sectionDashboard.current.parentNode.scrollTo({ top: sectionRiskFactors.current.offsetTop, behavior: 'smooth' })}
          >
            <Icon name="down" />
            {__('examinationReview.readTheReport')}
          </div>
        </section>

        {/** CHAPTER risk factors */}
        <section className="examination-review-chapter" ref={sectionRiskFactors}>
          <ExaminationReviewOverviewDx
            {...{ patient, episode, examination, examinationMetadata, zones, instances, itemsAtRisk, slides, biometries, riskFactors, teratogenicRisks, syndromes, appContext, examinationContext }}
          />
        </section>

        {/** CHAPTER results */}
        {showDiagnosticSection && (
          <section className="examination-review-chapter results" ref={sectionDiagnostic}>
            <ExaminationReviewResults
              syndromes={syndromes}
              syndromeDetails={[...syndromeDetails, syndromeZero]}
              allMedications={allMedications}
              malformations={malformations}
              appContext={appContext}
              examinationContext={examinationContext}
            />
          </section>
        )}
      </div>
    </div>
  )
}

export default withTranslation()(ExaminationReviewDxView)
