import { forwardRef } from "react";
import Icon from "../Icon/Icon";
import { useRef, useEffect } from "react";
import "./TextArea.css";


const TextArea = forwardRef(({ label = "", icon = "", value = "", fullwidth = false, onChange = () => { }, error = false, ...props }, forwardedRef) => {

  const fallbackRef = useRef(null);
  const ref = forwardedRef || fallbackRef;

  const updateHeight = (target) => {
    // Adapt height of textarea to content
    if (target === null) return;
    target.style.height = "0px";
    const scrollHeight = target.scrollHeight;
    target.style.height = scrollHeight + 10 + "px";
  };

  const onChangeHandler = (e) => {
    onChange(e.currentTarget.value);
    updateHeight(e.currentTarget);
  };
  useEffect(() => {
    updateHeight(ref.current);
  }, []);

  const iconClass = icon !== "" ? 'has-icon' : '';
  const widthClass = fullwidth !== false ? 'full-width' : '';
  const errorClass = error !== false ? 'error' : '';

  return (
    <div className={`textarea ${iconClass} ${widthClass} ${errorClass}`}>
      {label !== "" && (<label>{label}</label>)}
      <span className="textarea-field">
        {icon !== "" && (<Icon name={icon} />)}
        <textarea
          value={value || ''}
          {...props}
          onChange={onChangeHandler}
          ref={ref}
        />
      </span>
    </div>
  );
});

export default TextArea;
