import { SHAPES_AND_COLOURS } from "../../../../components/BiometryGraph/Constants";
import PlaceholderLoader from "../../PlaceholderLoader";

function ReportTemplateMeasurementCurveLegendBody({ t: __, placeholders}) {
  const names = placeholders["fetus.name"]?.value?.slice(1) || [];

  if (names.length < 2) return false;

  return (
    <div className="measurement-curve-legend" >
      {names.map((fetusName, idx) => {

        const { path, colour } = SHAPES_AND_COLOURS[idx] || SHAPES_AND_COLOURS[0];
        return (
          <div className="measurement-curve-legend-item" key={idx}>
            <div>{__("report.fetusName", { label: fetusName })}</div>
            <div><CurveShape path={path} colour={colour} /></div>
          </div>
        );
      })}
    </div>
  );
}

const CurveShape = ({ path, colour }) => {
  return (<svg viewBox="-2 -2 4 4">
    <g className="measurements" >
      <g className="point" >
        <path d={path} style={{ fill: colour }} />
      </g>
    </g>
  </svg>);
};

/* This is just a squelton to ensure placeholders are loaded */
export default function ReportTemplateMeasurementCurveLegend({
  props,
  placeholders,
  ...otherProps
}) {
  // No need to make them uniq as PlaceholderLoader will take care of it
  const requiredPlaceholders = [
    "fetus.name",
  ];

  return (
    <PlaceholderLoader
      Component={ReportTemplateMeasurementCurveLegendBody}
      placeholders={placeholders}
      requiredPlaceholders={requiredPlaceholders}
      props={props}
      {...otherProps}
    />
  );
};
