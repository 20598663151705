import { useEffect, useRef, useState, isValidElement } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import '../InlineEditing.css';

export default function InlineTextarea({ value, autofocus = false, active = true, printable = true, onStartEditing = () => { }, onChange = () => { } }) {

  const [editing, setEditing] = useState(false);
  const valueField = useRef(false);

  useEffect(() => {
    if (editing) {
      onStartEditing();
    }
  }, [editing]);

  useEffect(() => {
    if (autofocus) {
      valueField.current.focus();
    }
  }, [valueField, autofocus]);

  const cleanUpContent = (content, beforeSaving = false) => {
    let string = "";
    if (Array.isArray(content)) string = content.map(c => {
      return (isValidElement(c))
        ? renderToStaticMarkup(c)
        : c;
    }).join("");
    else if (isValidElement(content)) string = renderToStaticMarkup(content.textContent);
    else string = renderToStaticMarkup(content);

    // strip html
    const div = document.createElement('DIV');
    div.innerHTML = string.replace("</div>", "</div>\n");
    for (const icon of div.querySelectorAll('span.icon')) {
      let char = "";
      if (icon.className.includes("right")) char = " > ";
      if (icon.className.includes("left")) char = " < ";

      if (!!char) {
        icon.parentNode.insertBefore(document.createTextNode(char), icon);
      }
    }
    div.innerHTML = div.innerHTML.replace("\r\n", "\n");
    div.innerHTML = div.innerHTML.replace("\r", "\n");
    const stringNoHtml = div.textContent;
    return stringNoHtml.trim().replace(/^\s+|\t*|\s+$/g, '').replace(/ +/g, " ");
  }

  const cleanUpAsYouType = (e) => {
    if (e.code === "Enter") {
      e.preventDefault();

      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();
      const textNode = document.createTextNode("\n");
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);

      // Get the next sibling, skipping any empty text nodes
      let nextSibling = textNode.nextSibling;
      let count = 0 // Prevent infinite loops which happen for some reason
      while (nextSibling && nextSibling.nodeType === Node.TEXT_NODE && nextSibling.data === "" && count++ < 10000) {
        nextSibling = nextSibling.nextSibling;
      }

      // If there's no meaningful content after the \n, force another \n to ensure new line is created
      if (!nextSibling && !range.startContainer.wholeText.endsWith("\n\n")) {
        const doubledTextNode = document.createTextNode("\n");
        range.insertNode(doubledTextNode);
        range.selectNodeContents(doubledTextNode);
        range.collapse(false);
      }
    }
  };

  const displayedValue = cleanUpContent(value || "");


  const onChangeHandler = () => {
    const value = valueField.current?.textContent;
    setTimeout(setEditing(false), 200);
    onChange(cleanUpContent(value, true));
  };

  const pasteAsPlainText = (event) => {
    event.preventDefault();
    const text = cleanUpContent(event.clipboardData.getData("text/plain"));

    const range = document.getSelection().getRangeAt(0);
    range.deleteContents();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);
    range.selectNodeContents(textNode);
    range.collapse(false);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }

  return (
    <div className={`inline-editing inline-textarea ${active ? 'is-active' : 'not-active'} ${printable ? 'is-printable' : 'not-printable'} ${editing ? 'is-editing' : ''}`}>
      <div className="inline-textarea-value inline-editing-value">
        <div ref={valueField} contentEditable={active ? "true" : "false"} onKeyDown={cleanUpAsYouType} onFocus={() => setEditing(true)} onBlur={onChangeHandler} onPaste={pasteAsPlainText} suppressContentEditableWarning={true}>
          {displayedValue}
        </div>
      </div>
    </div>
  );
}
