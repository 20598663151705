import React from 'react'
import { formatName, getNiceGestionalAge} from '../../../services/examination';
import { withTranslation } from "react-i18next";
import { formatDate, formatDateTime } from '../../../utils';
import Fetus from '../../../components/Fetus/Fetus';
import Icon from '../../../atoms/Icon/Icon';

const ExaminationReviewOverviewDxPrint = ({ t: __, patient, episode, examination, examinationMetadata, zones, riskFactors, teratogenicRisks, instances, slides, biometries, appContext, examinationContext }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  return (
    <>
    <div className="row">
      <div className="w12">
        <h1 className="document-title"><Icon name="sonio" /> {__("examinationReview.title")}</h1>
      </div>
    </div>
    <div className="row">
      <div className="w5">
        <div className="column">
          <h2>{__('examinationReview.patient')}</h2>
          <div className="entry">
            <label>{__("examinationReview.patient.name")}</label>
            <span>{patient?.name ? formatName(patient?.name).fullName : __("patients.anonymous")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.patient.dob")}</label>
            <span>{patient?.dob ? formatDate(patient?.dob, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.episode.conceptionDate")}</label>
            <span>{episode?.conception_date ? formatDate(episode?.conception_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.episode.lmpDate")}</label>
            <span>{episode?.lmp_date ? formatDate(episode?.lmp_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
          </div>
          {episode?.lmp_date && (
            <div className="entry">
              <label>{__("examinationReview.episode.gestationalAge")}</label>
              <span>{getNiceGestionalAge(__, new Date(episode?.lmp_date), examination?.examination_date)}</span>
            </div>
          )}
          {examinationContext.fetusSexVisibility === 'visible' && (
            <div className="entry">
              <label>{examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"]?.label[currentLanguage]}</label>
              <span>{examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"]?.options?.find(option => option.value === examination.medical_history?.["medicalexam.fetus.sex"]?.value)?.label[currentLanguage] || __("examinationReview.notProvided")}</span>
            </div>
          )}

          <h2>{__('examinationReview.zones')}</h2>
          <div className="examination-review-overview-fetus">
            <Fetus
              zones={zones?.map(zone => ({id: zone.id, validated: examination.zones?.find(z => z.id === zone.id)?.status === "validated", unusual: examinationContext.examination?.malformations?.some(m => examinationContext.getMalformationById(m.id)?.exam_zone_id === zone.id && m.status === "yes")}))}
            />
          </div>

          <h2>{__('examinationReview.riskFactors')}</h2>
          <div className="examination-review-risk-factors">
            {(!riskFactors.length && !teratogenicRisks.length) ? (
              <div className="examination-review-nostudies">
                {__("patient_details.noRiskFactorsAvailable")}
              </div>
            ) : (
              <>
              {!!riskFactors.length && (
                riskFactors.map((risk) => (
                  <div key={risk.risk_factor_id} className="entry">
                    <label>{risk.label}</label>
                    <span>{!!risk.raw_value && !risk.tmp_value && (<span className="raw-value">{risk.raw_value}</span>)} {risk.value}</span>
                  </div>
                ))
              )}
              {!!teratogenicRisks.length && (
                teratogenicRisks.map(risk => (
                  <div key={risk.risk_factor_id} className="entry teratogenic-risks">
                    <label>{__('examinationReview.medication')}</label>
                    <span className="teratogenic-risk">{risk.label}</span>
                  </div>
                ))
              )}
              </>
            )}
          </div>
          {!!biometries.filter(metric => metric.status==="unusual").length && (
            <>
            <h2>{__('examinationReview.unusualBiometries')}</h2>
            <div className="examination-review-biometries">
              <ul>
                {biometries.filter(metric => metric.status==="unusual").map(metric => <li key={metric.id} className={`biometry ${metric.status}`}>
                  <span className="biometry-label">{metric.label[currentLanguage]}</span>
                  <span className="biometry-value">{metric.value}</span>
                </li>)}
              </ul>
            </div>
            </>
          )}
        </div>
      </div>

      <div className="w7">
        <div className="padding">
          <h2>{__('examinationReview.examination')}</h2>
          <div className="entry">
            <label>{__("examinationReview.trimester")}</label>
            <span>{examination.trimester} {__("examinationReview." + examination.type)}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.examinationDate")}</label>
            <span>{formatDateTime(examination.updated_at, appContext.preferences.date_format)}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.name")}</label>
            <span>{examinationMetadata.practitioner?.name || __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.site")}</label>
            <span>{examinationMetadata.site?.name || __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.rpps")}</label>
            <span>{examinationMetadata.practitioner?.rpps || __("examinationReview.notProvided")}</span>
          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default withTranslation()(ExaminationReviewOverviewDxPrint);
