import React from "react";
import Icon from "../Icon/Icon";
import "./ButtonAdd.css";

const ButtonAdd = ({
  label = "",
  icon = "add",
  align = "center",
  isActive = false,
  onClick = () => {}
}) => {
  return (
    <div className={`button-add-container align-${align} ${isActive ? 'active' : ''}`} onClick={onClick}>
      {!!label && align !== "left" && (<label>{align === "right" ? label : false}</label>)}
      <span className="icon"><Icon name={icon} /></span>
      {!!label && align !== "right" && (<label>{align !== "right" ? label : false}</label>)}
    </div>
  );
};

export default ButtonAdd;
