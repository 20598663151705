import React from "react";
import useAuth from "../../context-providers/Auth";
import PreferencesViewRoutine from "./PreferencesView";
import PreferencesViewDetect from "../../components-dt/Preferences/PreferencesView";

const Preferences = () => {
  const { isFeatureFlagEnabled } = useAuth();
  return (isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine"))
    ? <PreferencesViewDetect />
    : <PreferencesViewRoutine />;
};

export default Preferences;
