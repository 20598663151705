// FETUS A = lozenge light-blue #97CDF7
// FETUS B =  circle blue #207CC2
// FETUS C = triangle dark-blue #D5EDFF
// FETUS D = square grey #7599B6
// FETUS E = lozenge blue #207CC2
// FETUS F = circle light-blue #97CDF7
// FETUS G = triangle grey #7599B6
// FETUS H = square dark-blue #D5EDFF

const LOZENGE = "M-2 0 L 0 2 L 2 0 L 0 -2Z";
const CIRCLE = "M 0 -2 a 2 2 0 1 1 0 4 a 2 2 0 1 1 0 -4";
const TRIANGLE = "M 0 -2 L -1.732 1 L 1.732 1 L 0 -2 Z";
const SQUARE = "M -1.5 -1.5 L -1.5 1.5 L 1.5 1.5 L 1.5 -1.5 Z";

const LIGHT_BLUE = "#97CDF7EA";
const BLUE = "#207CC2EA";
const DARK_BLUE = "#D5EDFFEA";
const GREY = "#7599B6EA";

export const SHAPES_AND_COLOURS = [
    {
        // Fetus A
        path: LOZENGE,
        colour: LIGHT_BLUE
    },
    {
        // B
        path: CIRCLE,
        colour: BLUE
    },
    {
        // C
        path: TRIANGLE,
        colour: DARK_BLUE
    },
    {
        // D
        path: SQUARE,
        colour: GREY
    },
    {
        // E
        path: LOZENGE,
        colour: BLUE
    },
    {
        // F
        path: CIRCLE,
        colour: LIGHT_BLUE
    },
    {
        // G
        path: TRIANGLE,
        colour: GREY
    },
    {
        // H
        path: SQUARE,
        colour: DARK_BLUE
    },
];
