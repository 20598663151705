import React, { useContext } from "react";
import { withTranslation } from "react-i18next";
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from '../../context-providers/Examination';
import { convertCmToInch, convertInchToCm, convertKgToLbs, convertLbsToKg } from '../../utils';
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup";
import DateInput from "../../atoms/DateInput/DateInput";
import NumericInput from "../../atoms/NumericInput/NumericInput";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import TextInput from "../../atoms/TextInput/TextInput";
import MedicationsSearchBar from "../MedicationsSearchBar/MedicationsSearchBar";
import SyndromeSearchBar from "../SyndromeSearchBar/SyndromeSearchBar";
import "./MedicalHistoryInput.css";
import Icon from "../../atoms/Icon/Icon";

/*
 * Print a medical history field using the right component
 * 
 * <MedicalHistoryTextualInput
 *   field={}
 *   customCallbacks={onBlur: () => {}, onFocus: () => {}, onChange: () => {}}
 *   disableOptionalFields={boolean} // use normal input fields instead of optional ones, eg: NumericInput instead of NumericInputOptional
 * />
 */

const MedicalHistoryTextualInput = ({ t:__, field, customCallbacks = {}}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const preferedDateFormat = appContext.preferences.date_format;
  const preferedUnit = appContext.preferences.units;

  /**
   * Get the value of the given risk factor text id
   * @param {string} text_id 
   * @returns 
   */
  const getMedicalHistoryValue = ( text_id ) => {
    return (!examinationContext.examination.medical_history || !examinationContext.examination.medical_history[text_id])
      ? {value: null, raw_value: null}
      : {value: examinationContext.examination.medical_history[text_id].value, raw_value: examinationContext.examination.medical_history[text_id].raw_value}
  }

  /**
   * Print the given field using the right component
   * @param {*} field 
   * @param {*} customCallbacks 
   * @returns 
   */
  const printField = (field, customCallbacks = {}) => {
    if(!field.type) return false;

    const onBlur = customCallbacks.onBlur ? customCallbacks.onBlur : () => {};
    const onChange = customCallbacks.onChange ? customCallbacks.onChange : examinationContext.updateMedicalHistory;
    const onDisable = customCallbacks.onDisable ? customCallbacks.onDisable : examinationContext.removeFromMedicalHistory;
    const onDismiss = customCallbacks.onDismiss ? customCallbacks.onDismiss : (field) => examinationContext.dismissLiveQuestion(field.options?.reduce((risk_factor_ids, option) => option.risk_factor_id ? [...risk_factor_ids, option.risk_factor_id] : risk_factor_ids, []) || []);

    return {
      text: () => {
        return <TextInput
          value={getMedicalHistoryValue(field.text_id).raw_value}
          label={field.label[currentLanguage]}
          onChange={(value) => onChange(field, value)}
        />
      },
      number: () => {
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <ButtonGroup
            label={field.label[currentLanguage]}
            options={field.options.map(option => ({label: option.label[currentLanguage], value: option.value }))}
            value=""
            onChange={(value) => {
              const option = field.options.find(option => option.value === value);
              const numericValue = (option.upper_limit - option.lower_limit) / 2 + option.lower_limit;
              return onChange(field, numericValue, value, value);
            }}
          />
        </>
      },
      denominator: () => {
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <NumericInput
            label={field.label[currentLanguage]}
            min={field.options_metadata.lower_limit || 0}
            max={field.options_metadata.upper_limit || false}
            step={field.options_metadata.step || 1}
            prefix="1/"
            suffix=""
            value={getMedicalHistoryValue(field.text_id).raw_value}
            defaultValue={field.options_metadata.default_value || false}
            options={field.options}
            onChange={(value) => onChange(field, value)}
          />
        </>
      },
      size: () => {
        const value = getMedicalHistoryValue(field.text_id).raw_value
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <NumericInput
            label={field.label[currentLanguage]}
            min={(preferedUnit ==="imperial"
            ? convertCmToInch(field.options_metadata.lower_limit).toFixed(0)
            : field.options_metadata.lower_limit) || 0}
            max={
              (preferedUnit ==="imperial"
            ? convertCmToInch(field.options_metadata.upper_limit ).toFixed(0)
            : field.options_metadata.upper_limit ) || false}
            step={field.options_metadata.step || 1}
            prefix=""
            suffix={' ' + (preferedUnit ==="imperial"
            ? __('medicalhistory.units.inches')
            : __('medicalhistory.units.cm'))}
            value={
              !value
              ? ''
              : preferedUnit ==="imperial"
                ? convertCmToInch(value).toFixed(0)
                : Math.ceil(value * 10) / 10}
            defaultValue={field.options_metadata.default_value || false}
            options={field.options}
            onChange={(value) =>
              onChange(field, preferedUnit ==="imperial"
              ? convertInchToCm(value)
              : value)
            }
          />
        </>
        },
      weight: () => {
        const value = getMedicalHistoryValue(field.text_id).raw_value
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <NumericInput
            label={field.label[currentLanguage]}
            min={(preferedUnit ==="imperial"
            ? convertKgToLbs(field.options_metadata.lower_limit)
            : field.options_metadata.lower_limit) || 0}
            max={(preferedUnit ==="imperial"
            ? convertKgToLbs(field.options_metadata.upper_limit)
            : field.options_metadata.upper_limit) || false}
            step={field.options_metadata.step || 1}
            prefix=""
            suffix={' ' + (preferedUnit ==="imperial"
            ? __('medicalhistory.units.lbs')
            : __('medicalhistory.units.kg'))}
            value={
              !value
              ? ''
              : preferedUnit ==="imperial"
                ? convertKgToLbs(value).toFixed(0)
                : Math.ceil(value * 10) / 10}
            defaultValue={field.options_metadata.default_value || false}
            options={field.options}
            onChange={(value) =>
              onChange(field, preferedUnit ==="imperial"
              ? convertLbsToKg(value)
              : value)
            }
          />
        </>
        },
      buttongroup: () => {
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <ButtonGroup
            label={field.label[currentLanguage]}
            value={getMedicalHistoryValue(field.text_id).value || field.options_metadata.default_value}
            options={field.options.filter(option => !!option.value && option.value !== "unknown").map(option => { return {value: option.value, label:option.label[currentLanguage] } })}
            onChange={(value) => onChange(field,false,value)}
          />
        </>;
      },
      select: () => {
        return <>
          <div className="medical-history-input-dismiss">
            <Icon name="close" onClick={() => onDismiss(field)} />
          </div>
          <SelectInput
            label={field.label[currentLanguage]}
            options={field.options.map(option => { return {value: option.value, label:option.label[currentLanguage] } })}
            value={getMedicalHistoryValue(field.text_id).value || field.options_metadata.default_value}
            onChange={(value) => onChange(field,false,value)}
          />
        </>
        },
      date: () => {
        return <>
          <DateInput
            value={getMedicalHistoryValue(field.text_id).raw_value}
            localeOrFormat={preferedDateFormat}
            onChange={(value) => onChange(field, value)}
            onBlur={(value) => onBlur(field, value)}
          />
        </>
        },
      medication: () => {
        return <>
          <MedicationsSearchBar
            value={getMedicalHistoryValue(field.text_id).value}
            onChange={(raw_value, value) => onChange(field, raw_value, value)}
          />
        </>
        },
      syndrome: () => {
        return <>
          <SyndromeSearchBar
            label={field.label[currentLanguage]}
            value={getMedicalHistoryValue(field.text_id).value}
            SYNDROMES={examinationContext.SYNDROMES}
            onChange={(raw_value, value) => onChange(field, raw_value, value)}
          />
        </>
      }
    }[field.type]();
  }

  return <div className="medical-history-input">{printField(field, customCallbacks)}</div>;
};

export default withTranslation()(MedicalHistoryTextualInput);
