import ReportTemplateIdentifierBiometry_v1_0 from './IdentifierBiometry/v1.0.js';
import ReportTemplateIdentifierBiometry_v1_1 from './IdentifierBiometry/v1.1.js';

export default function ReportTemplateIdentifierBiometry(props) {
  const {apiVersion} = props;
  if (apiVersion === '1.0') {
    return ReportTemplateIdentifierBiometry_v1_0(props);
  } else if (apiVersion === '1.1') {
    return ReportTemplateIdentifierBiometry_v1_1(props);
  }
  return null;
};
