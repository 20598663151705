import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import ButtonAdd from "../../atoms/ButtonAdd/ButtonAdd";
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import Icon from "../../atoms/Icon/Icon";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import TextInput from "../../atoms/TextInput/TextInput";
import SlideBrowser from "../../components/Examination/SlideBrowser/SlideBrowser";
import Popup from "../../components/Popup/Popup";
import Slide from "../../components/Slide/Slide";
import config, { presets } from "../../config";
import { AppContext } from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import LookupApi from "../../services/lookup";
import ResourceApi from "../../services/resource";
import { isNullOrUndefined, formatDateTimeWithTZ } from "../../utils";
import "./TemplateManagerView.css";

const TemplateManagerView = ({ t: __ }) => {
  const { config: userConfig, updateUserConfig } = useAuth();
  const appContext = useContext(AppContext);
  const [presetIdString, setPresetIdString] = useState(null);
  const [trimester, setTrimester] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templateName, setTemplateName] = useState('');
  const [allSlides, setAllSlides] = useState([]);
  const [slideListOpen, setSlideListOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [slides, setSlides] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [selectedTrimester, setSelectedTrimester] = useState("T1");
  const [selectedCategory, setSelectedCategory] = useState("OB");
  const [imagingExpected, setImagingExpected] = useState(true);
  const [nameInput, setNameInput] = useState("");
  const [selectedSlide, setSelectedSlide] = useState(false);
  const [createTemplatePopupOpen, setCreateTemplatePopupOpen] = useState(false);
  const [createProtocolPopupOpen, setCreateProtocolPopupOpen] = useState(false);
  const [createPresetPopupOpen, setCreatePresetPopupOpen] = useState(false);
  const [importTemplatePopupOpen, setImportTemplatePopupOpen] = useState(false);
  const [importSelectedTemplate, setImportSelectedTemplate] = useState(false);
  const preferedExaminationTemplates = appContext.examinationTemplatesConfiguration;

  useEffect(() => {
    const defaultPresetId = appContext.allPresets?.[0]?.id
    if (!isNullOrUndefined(defaultPresetId)) {
      setPresetIdString(defaultPresetId.toString())
      setTrimester(appContext.allPresets.find(preset => preset.id === defaultPresetId).trimester)
    }
  }, [JSON.stringify(appContext.allPresets), isNullOrUndefined])

  const zoomLevel = Number(window.localStorage.getItem('defaultZoomLevel')) || config.examinationLive.numberOfColumns;

  const templateOptions = useMemo(() => {
    const createNew = [
      {
        label: __("manageTemplate.new"),
        value: 0,
      },
      {
        value: 0,
        type: "separator",
      },
      {
        value: 0,
        label: __("manageTemplate.chooseExitingTemplate"),
        type: "heading",
      },
    ];
    const templateOptions = templates.filter(template => template.trimester === trimester).map(template => ({ label: `${template.name} [${formatDateTimeWithTZ(template.inserted_at, appContext.preferences.date_format)}]`, value: template.id }));
    return [...createNew, ...templateOptions];
  }, [templates, trimester]);

  const isEditingExistingTemplate = !!createTemplatePopupOpen && preferedExaminationTemplates?.some(template => template.trimester === trimester);

  const getAllSlides = useCallback(async () => {
    const { data: { data: slides } } = await LookupApi.listImagePlane()
    setAllSlides(slides.filter(slide => ['picture', 'other'].includes(slide.type)))
  }, [LookupApi.listImagePlane])

  const getAllTemplates = useCallback(async () => {
    const { data: { data: templates } } = await ResourceApi.listExaminationTemplate()
    setTemplates(templates)
  }, [ResourceApi.listExaminationTemplate])

  const addViewToTemplate = useCallback((slide, options = {}) => {
    const insertAfter = options.insertAfter ?? slides.length;
    const newSlides = [...slides.slice(0, insertAfter), slide, ...slides.slice(insertAfter)];
    setSlides(newSlides);
    setSelectedSlide(insertAfter);
  }, [slides])

  const changeTechno = useCallback((value, index) => {
    const newSlides = [...slides]
    newSlides[index] = { ...newSlides[index], techno: value }
    setSlides(newSlides);
  }, [slides])

  const deleteViewFromTemplate = useCallback((index) => {
    const newSlides = [...slides]
    newSlides.splice(index, 1)
    setSlides(newSlides);
  }, [slides])

  const addNewPresetTemplateAssociation = useCallback(async () => {
    let template_id = selectedTemplate || templateOptions[0]?.value;
    
    if (!template_id) {
      setSlides([]);
      setCreateTemplatePopupOpen({ template_id });
    } else {
      const templateName = templates.find(t => t.id === template_id)?.name;
      const postPayload = {
        trimester: appContext.allPresets.find(preset => preset.id === parseInt(presetIdString)).trimester,
        type: "screening",
        name: templateName,
        configuration: {
          instance_views: getEnrichedInstanceViews(getSlides(template_id)),
        }
      };
      const { data: { data: { id: tmp_template_id } } } = await ResourceApi.createExaminationTemplate(postPayload);
      template_id = tmp_template_id;
      
      changePresetTemplateAssociation(parseInt(presetIdString), template_id);
      setSelectedTemplate(false);
      await getAllTemplates();
    }
  }, [presetIdString, selectedTemplate]);

  const addNewPreset = useCallback(async () => {
    const { data: { data: newPreset } } = await ResourceApi.createExaminationPreset({ name: nameInput, trimester: selectedTrimester, category: selectedCategory, imaging_expected: imagingExpected })
    await appContext.getUserConfig()
    if (newPreset?.id) setPresetIdString(newPreset.id.toString());
    await getAllTemplates();
  }, [selectedCategory, imagingExpected, selectedTrimester, nameInput, ResourceApi, appContext]);

  const changePresetTemplateAssociation = useCallback((presetId, template_id) => {
    const trimester = appContext.allPresets.find(preset => preset.id === presetId)?.trimester
    const newPreferedExaminationTemplates = [...preferedExaminationTemplates];

    const preferedExaminationTemplateIndex = preferedExaminationTemplates
      ?.findIndex(template =>
        template.examination_type === "screening"
        && template.examination_preset_id === presetId)

    preferedExaminationTemplateIndex === -1
      ? newPreferedExaminationTemplates.push({
        examination_template_id: template_id,
        examination_type: "screening",
        trimester: trimester,
        examination_preset_id: presetId
      })
      : newPreferedExaminationTemplates[preferedExaminationTemplateIndex] =
      {
        examination_template_id: template_id,
        examination_type: "screening",
        trimester: trimester,
        examination_preset_id: presetId
      };

    appContext.changeExaminationTemplates(newPreferedExaminationTemplates);
  }, [preferedExaminationTemplates, appContext])

  const getEnrichedInstanceViews = (slides) => {
    const instance_views = slides.reduce((instance_views, slide) => {
      const new_instance_views = [...instance_views];

      if (new_instance_views[new_instance_views.length - 1]?.instance_view_id === slide.id && new_instance_views[new_instance_views.length - 1]?.techno === slide.techno) {
        new_instance_views[new_instance_views.length - 1] = { ...new_instance_views[new_instance_views.length - 1], number_of_instances: new_instance_views[new_instance_views.length - 1].number_of_instances + 1 }
      }
      else {
        new_instance_views.push({ instance_view_id: slide.id, number_of_instances: 1, techno: slide.techno })
      }
      return new_instance_views;
    },
      []);

    const enriched_instance_views = [...instance_views, { instance_view_id: 39, number_of_instances: null, techno: "us" }];
    return enriched_instance_views;
  };

  const onCreateClick = useCallback(async () => {
    setLoading(true);
    let template_id = createTemplatePopupOpen.template_id;
    const newTemplateName = templateName;

    const enriched_instance_views = getEnrichedInstanceViews(slides);

    const postPayload = {
      trimester: appContext.allPresets.find(preset => preset.id === parseInt(presetIdString)).trimester,
      type: "screening",
      name: newTemplateName,
      configuration: {
        instance_views: enriched_instance_views
      }
    };

    const { data: { data: { id: tmp_template_id } } } = await ResourceApi.createExaminationTemplate(postPayload);
    template_id = tmp_template_id;

    if (template_id) changePresetTemplateAssociation(parseInt(presetIdString), template_id);
    setCreateProtocolPopupOpen(false);
    setCreateTemplatePopupOpen(false);

    await getAllTemplates();
    setLoading(false);
  }, [slides, presetIdString, templateName, appContext.allPresets])

  const onPresetUseToggle = useCallback(async (checked) => {
    setLoading(true);
    const newPresetsConfig = checked ? [...(appContext.displayedPresets || []), parseInt(presetIdString)] : (appContext.displayedPresets?.filter(el => el !== parseInt(presetIdString)) || []);
    updateUserConfig({ id: userConfig.id, examination_preset: newPresetsConfig });
    setLoading(false);
  }, [appContext, presetIdString, updateUserConfig, userConfig])

  const getSlides = (template_id) => {
    const instances = templates.find(t => t.id === template_id)?.configuration.instance_views || [];
    let slides = [];
    for (const instance of instances) {
      slides.push(Array(instance.number_of_instances).fill(instance));
    }
    slides = slides.flat(2);
    return slides.map(slide => {
      const s = allSlides.find(s => s.id === slide.instance_view_id);
      return !s ? false : { ...s, ...slide };
    }).filter(slide => !!slide)
  };

  useEffect(() => {
    getAllTemplates()
  }, [getAllTemplates])

  useEffect(() => {
    getAllSlides()
  }, [getAllSlides])

  useEffect(() => {
    setSlides(() => {
      const instances = templates.find(t => t.id === createTemplatePopupOpen.template_id)?.configuration.instance_views || [];
      let slides = [];
      for (const instance of instances) {
        slides.push(Array(instance.number_of_instances).fill(instance));
      }
      slides = slides.flat(2);
      return slides.map(slide => {
        const s = allSlides.find(s => s.id === slide.instance_view_id);
        return !s ? false : { ...s, ...slide };
      }).filter(slide => !!slide)
    });
    setTemplateName(templates.find(t => t.id === createTemplatePopupOpen.template_id)?.name);
    setImportSelectedTemplate(false);
  }, [createTemplatePopupOpen.template_id]);

  const realOptions = useMemo(() => {
    return appContext.allPresets
      ?.sort((presetA, presetB) => presetA.id - presetB.id)
      .map(preset => (
        preset.id === presets.NDid
          ? { icon: "ultrasound", value: preset.id.toString() }
          : { label: preset.name, value: preset.id.toString() }
      ));
  }, [appContext.allPresets]);

  const currentPresetIsDisplayed = () => {
    return appContext.displayedPresets?.includes(parseInt(presetIdString));
  };

  const getCheckboxLabel = () => {
    return (
      <>
        {__("manageTemplate.preset")}&nbsp;
        {
          currentPresetIsDisplayed()
            ? <span className="mode-info-checked">{__("manageTemplate.preset.used")}</span>
            : __("manageTemplate.preset.unused")
        }
        <div className="mode-info">
          {__('manageTemplate.preset.modeInfo')}
        </div>
      </>
    );
  }

  return (
    <div className={`manage-template-container ${!!createTemplatePopupOpen ? 'show-create' : ''}`}>
      <div className="manage-template-templates">
        <div className="row vertical-center">
          <div>
            <h1>
              {__("manageTemplate.myRoutines")}
            </h1>
          </div>
          <div>
            <ButtonGroup
              value={presetIdString}
              options={realOptions}
              size="compact"
              onChange={(value) => {
                setPresetIdString(value)
                setTrimester(appContext.allPresets.find(preset => preset.id === parseInt(value)).trimester)
              }}
            />
          </div>
          <ButtonAdd
            align="left"
            label={__("manageTemplate.preset.add")}
            onClick={(e) => {
              e.stopPropagation();
              setCreatePresetPopupOpen(true);
            }}
          />
        </div>
        <div className="row">
          <div className="live-checkbox">
            <Checkbox
              label={getCheckboxLabel()}
              checked={currentPresetIsDisplayed()}
              onChange={onPresetUseToggle}
            />
          </div>
        </div>
        <div className="row">
          <div className="w12">
            {!!preferedExaminationTemplates?.length && (
              <ul className="manage-template-positions">
                <li className="header">
                  <span>{__("manageTemplate.templateName")}</span>
                  <span></span>
                </li>
                {preferedExaminationTemplates
                  .filter(template => template.examination_preset_id === parseInt(presetIdString))
                  .map(template => (
                    <li key={`template_${template.examination_template_id}`} onClick={() => {
                      setCreateTemplatePopupOpen({ template_id: template.examination_template_id });
                      setSelectedSlide(false);
                    }} style={{display:"flex", justifyContent: "space-between"}}>
                      <span>{templates.find(t => t.id === template.examination_template_id)?.name}</span>
                      <span>
                        <Button
                          icon="edit"
                          size="small"
                          label={__("manageTemplate.edit")}
                        />
                      </span>
                    </li>
                  ))}
              </ul>
            )}

            {!!preferedExaminationTemplates?.length && 
              preferedExaminationTemplates.filter(template => template.examination_preset_id === parseInt(presetIdString)).length === 0 &&
              <div className="manage-template-add-button">
                <ButtonAdd
                  label={__("manageTemplate.addTheProtocol")}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCreateProtocolPopupOpen(true);
                  }}
                />
              </div>
            }
          </div>
        </div>
      </div>

      <div className="manage-template-create">
        <div className="manage-template-create-header">
          <div className="manage-template-create-title">
            <h1><span className="trimester">{trimester}</span></h1>
            <div className="manage-template-create-title-import">
              <Button
                variant="outline"
                label={__("manageTemplate.import")}
                size="small"
                icon="switch"
                onClick={() => setImportTemplatePopupOpen(true)}
              />
            </div>
            <div className="manage-template-create-title-input">
              <label>{__("manageTemplate.namePlaceholder")}</label>
              <TextInput
                value={templateName}
                onChange={(value) => {
                  setTemplateName(value);
                }}
                fullwidth={true}
              />
            </div>
          </div>
        </div>

        {<div className="manage-template-slide-grid-container">
          <div className="manage-template-slide-grid" style={{ "--number-of-columns": zoomLevel }}>
            {slides.map((slide, index) => (
              <div
                key={`${slide.id}_${index}`}
                className={`manage-template-slide-grid-cell ${selectedSlide === index ? 'selected' : ''}`}
                onClick={() => setSelectedSlide(prev => prev !== index ? index : false)}
              >
                <div className="manage-template-examination-live-slide">
                  <Slide
                    slide={slide}
                    trimester={trimester}
                    zoomLevel={zoomLevel}
                  />
                </div>
                {selectedSlide === index && (
                  <>
                    <div className="manage-template-examination-live-delete" onClick={(e) => e.stopPropagation()}>
                      <Icon name="close" onClick={() => deleteViewFromTemplate(index)} />
                    </div>
                    <div className="manage-template-examination-live-options" onClick={(e) => e.stopPropagation()}>
                      <ButtonGroup
                        value={slide.techno}
                        variant="outline"
                        options={[
                          { label: __("examination-plane.techno.us"), value: "us" },
                          { label: __("examination-plane.techno.doppler"), value: "doppler" },
                          { label: __("examination-plane.techno.threeD"), value: "threeD" }
                        ]}
                        size="compact"
                        onChange={(value) => {
                          changeTechno(value, index);
                          setSelectedSlide(false);
                        }}
                      />
                    </div>
                  </>
                )}
                <div
                  className="manage-template-slide-grid-cell-add"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSlideListOpen({ insertAfter: index });
                  }}
                >
                  <ButtonAdd
                    align="left"
                  />
                </div>
              </div>
            ))}
            <div className="manage-template-slide-grid-cell add-new">
              <div className="manage-template-examination-live-slide" onClick={() => setSlideListOpen(true)}>
                <ButtonAdd
                  label={__("manageTemplate.addPlane")}
                  align="left"
                />
              </div>
            </div>
          </div>
        </div>
        }
        <div className="manage-template-create-footer">
          <div className="manage-template-grid-warning">
            {isEditingExistingTemplate && (
              <><Icon name="warning" /> {__("manageTemplate.editingWarning")}</>
            )}
          </div>
          <div className="manage-template-create-cta">
            <Button label={__("manageTemplate.cancel")} variant="outline" onClick={() => setCreateTemplatePopupOpen(false)} /> &nbsp;
            <Button disabled={loading} label={isEditingExistingTemplate ? __("manageTemplate.save") : __("manageTemplate.create")} onClick={onCreateClick} />
          </div>
        </div>
      </div>

      {createProtocolPopupOpen && (
        <Popup
          cta={<div className="manage-template-create-position">
            <h2>{__("manageTemplate.addTheProtocol")}</h2>
            <div>
              <SelectInput
                options={templateOptions}
                label={__("manageTemplate.template")}
                value={selectedTemplate || templateOptions[0]?.value}
                onChange={(value) => setSelectedTemplate(value)}
              />
            </div>
            <div>
              <Button
                label={__("manageTemplate.cancel")}
                variant="outline"
                onClick={() => setCreateProtocolPopupOpen(false)}
              />
              <Button
                label={__("manageTemplate.addTheProtocol")}
                onClick={() => {
                  addNewPresetTemplateAssociation();
                  setCreateProtocolPopupOpen(false);
                }}
              />
            </div>
          </div>}
        />
      )}

      {createPresetPopupOpen && (
        <Popup
          cta={<div className="manage-template-create-position">
            <h2>{__("manageTemplate.preset.addNew")}</h2>
            <div>
              <TextInput
                value={nameInput}
                label={__("manageTemplate.preset.name")}
                onChange={setNameInput}
              />
              <SelectInput
                options={[
                  {
                    label: "T1",
                    value: "T1",
                  },
                  {
                    label: "T2",
                    value: "T2",
                  },
                  {
                    label: "T3",
                    value: "T3",
                  },
                  {
                    icon: "ultrasound",
                    value: "ND",
                  },
                ]}
                label={__("manageTemplate.preset.trimester")}
                value={selectedTrimester}
                onChange={(value) => setSelectedTrimester(value)}
              />
              <SelectInput
                options={[
                  {
                    label: "OB",
                    value: "OB",
                  },
                  {
                    label: "GYN",
                    value: "GYN",
                  },
                  {
                    label: "Other",
                    value: "other",
                  }
                ]}
                label={__("manageTemplate.preset.category")}
                value={selectedCategory}
                onChange={(value) => setSelectedCategory(value)}
              />
              <div>

                <Checkbox
                  label={__("manageTemplate.preset.imagingExpected")}
                  checked={!imagingExpected}
                  onChange={(checked) => setImagingExpected(!checked)}
                />
              </div>
            </div>
            <div>
              <Button
                label={__("manageTemplate.cancel")}
                variant="outline"
                onClick={() => setCreatePresetPopupOpen(false)}
              />
              <Button
                label={__("manageTemplate.preset.addThePreset")}
                onClick={() => {
                  addNewPreset()
                  setCreatePresetPopupOpen(false);
                }}
              />
            </div>
          </div>}
        />
      )}

      {importTemplatePopupOpen && (
        <Popup
          cta={<div className="manage-template-create-position">
            <h2>{__("manageTemplate.import")}</h2>
            <div>
              <SelectInput
                options={templates.filter(template => template.trimester === trimester).map(template => ({ label: `${template.name} [${formatDateTimeWithTZ(template.inserted_at, appContext.preferences.date_format)}]`, value: template.id }))}
                label={__("manageTemplate.template")}
                value={importSelectedTemplate || createTemplatePopupOpen.template_id || templates.filter(template => template.trimester === trimester)[0]?.id}
                onChange={(value) => setImportSelectedTemplate(value)}
              />
            </div>
            <div>
              <Button
                label={__("manageTemplate.cancel")}
                variant="outline"
                onClick={() => setImportTemplatePopupOpen(false)}
              />
              <Button
                label={__("manageTemplate.setTemplate")}
                onClick={() => {
                  setCreateTemplatePopupOpen(value => ({ ...value, template_id: importSelectedTemplate }));
                  setImportTemplatePopupOpen(false);
                }}
              />
            </div>
          </div>}
        />
      )}

      {!!slideListOpen && (
        <SlideBrowser
          slides={allSlides}
          trimester={trimester}
          title={__("manageTemplate.selectSlide")}
          onChange={(slide) => {
            addViewToTemplate({ ...slide, techno: slide.default_techno }, { insertAfter: slideListOpen.insertAfter });
            setSlideListOpen(false);
          }}
          onClose={() => setSlideListOpen(false)}
        />
      )}
    </div >
  )
};

export default withTranslation()(TemplateManagerView);
