import React, { useCallback, useContext } from "react";
import { withTranslation } from "react-i18next";
import Slide from "../../Slide/Slide";
import { AppContext } from "../../../context-providers/App";
import { ExaminationContext } from "../../../context-providers/Examination";
import { isNullOrUndefined } from "../../../utils";
import "./SmartMatch.css";
import Icon from "../../../atoms/Icon/Icon";

const SmartMatch = ({
  t: __,
  alternativeSlides,
  trimester,
  changeAssociation,
  media,
  setShowSlideBrowser
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);

  const changeAssociationHandler = useCallback((slide) => {
    changeAssociation(slide)
  }, []);

  return trimester !== 'ND' && <div className="smartmatch-container">
    <h2>{__("examination-plane.smartMatching.questionAssociation")}</h2>
    <div className="smartmatch-alternatives">
      {alternativeSlides.map(slide => {
          const categoryLabel = appContext.getDefaultCategoryLabelForSlide(slide, trimester, __)
          return !isNullOrUndefined(slide) && (<div key={slide.idx_in_template} className="smartmatch-slide-container" onClick={() => changeAssociationHandler(slide)}>
          <div className="smartmatch-slide-name">
            {slide.label?.[currentLanguage]}
            {!!categoryLabel && (<span className="smartmatch-slide-category">{categoryLabel}</span>)}
          </div>
          <Slide
            version={2}
            trimester={trimester}
            slide={slide}
            type={slide.type}
            rotation={slide.transformation?.find(transformationDef => transformationDef.fetus_position_id === examinationContext.examination.fetus_position_id)}
          />
        </div>)
      })}
    </div>
    <div className="smartmatch-alternatives-more-slides" onClick={() => setShowSlideBrowser({ slide: false, media, callback: () => changeAssociation(false) })}>
      <Icon name="more" />
    </div>
  </div>
}

export default withTranslation()(SmartMatch);