import { useContext, useEffect, useMemo, useState } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../atoms/Icon/Icon";
import { AppContext } from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";
import { getInstanceThumbnailUri } from "../../utils";
import "./Slide.css";
import SlideDrawing from "./SlideDrawing/SlideDrawing";
import SlideStarButton from "./SlideStarButton";


const LiveSlide = ({ t: __, trimester, sex, slide, type = null, media, atRisk = false, zoomLevel = 3, setManualUserInteraction, shareButtonsOverride = null }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const appContext = useContext(AppContext)
  const examinationContext = useContext(ExaminationContext)
  const { isFeatureFlagEnabled } = useAuth();
  const isVideo = media?.dicom_media_type === "video";

  const [categoryLabel, setCategoryLabel] = useState('');
  useEffect(() => {
    setCategoryLabel(appContext.getDefaultCategoryLabelForSlide(slide, trimester, __))
  }, [slide])

  if (!type && !!slide?.type) type = slide?.type;
  const isExtractedFromVideo = !!media?.dicom_origin_id;
  const isVerified = media?.verified;
  const isOptional = !!slide?.optional;
  const isOther = type === "other";
  const toRetake = media?.to_retake;
  const isDoppler = !isOther && !!slide?.techno && slide.techno === "doppler";

  const instancePrediction = (media
    ?.predictions
    ?.sort((a, b) => b.id - a.id)
    .find(prediction => prediction.type === 'view' && prediction.status === 'done')?.data || [])
    ?.[0];

  const external_to_routine_detected = instancePrediction
    ?.external_to_routine_detected?.[0]

  const annotation_detected = instancePrediction
    ?.annotation_detected

  const qcAmount = useMemo(() => {
    const mandatoryQC = examinationContext.getQualityCriteriaByInstanceViewId(slide, media, trimester, "mandatory");
    const unusualChecklistItems = (examinationContext.examination.checklist_items?.filter(checklist_item => checklist_item.instance_view_id.includes(slide?.id)) || []);
    return mandatoryQC.filter(qc => qc.is_valid === false).length + unusualChecklistItems.length;
  }, [examinationContext.getQualityCriteriaByInstanceViewId, JSON.stringify(examinationContext.examination.checklist_items), slide?.id, media, trimester]);

  const buttons = shareButtonsOverride || <SlideStarButton
        media={media}
        onClickCallback={() => setManualUserInteraction(true)}
    />

  return (
    <div className={`slide-live-container trimester-${trimester} ${isOther ? "is-other" : ""} ${!!media ? "has-media" : ""} ${!!isVideo || !!isVerified ? "verified" : "not-verified"} ${isOptional ? "optional" : "mandatory"} ${!!toRetake ? "to-retake" : ""} ${isFeatureFlagEnabled("sonio.risk") && !!atRisk ? "at-risk" : ""} zoom-level-${zoomLevel}`} >
      <div className="slide-live-protect-click" />
      {!!media && (
        <div className="slide-live-thumbnail slide-live-media">
          <img
            src={getInstanceThumbnailUri(media.id)}
            alt="media thumbnail"
            data-media-id={media.id}
          />
        </div>
      )}
      {!!slide && (
        <div className="slide-live-thumbnail slide-live-slide">
          <SlideDrawing
            version="2"
            trimester={trimester}
            slide={slide}
            type={slide.type}
            sex={sex}
            rotation={slide?.transformation?.find(transformationDef => transformationDef?.fetus_position_id === examinationContext.examination.fetus_position_id)}
          />
        </div>
      )}
      {toRetake && (
        <div className="slide-live-icon">
          <Icon name="reload" />
        </div>
      )}
      {!toRetake && isVideo && (
        <div className="slide-live-icon">
          <Icon name="play-circled" />
        </div>
      )}
      {!toRetake && !isVideo && isExtractedFromVideo && (
        <div className="slide-live-icon">
          <Icon name="video" />
        </div>
      )}
      {isDoppler && (
        <div className="slide-techno-icon">
          <Icon name="doppler" />
        </div>
      )}
      <div className="slide-header">
        <div className="slide-title">
          {type !== "other" && (
            <>
              {!!slide && <div className="slide-label">
                {!!slide.techno && slide.techno !== "us" && (
                  <div className="slide-techno">
                    {__("examination-plane.techno." + slide.techno)}
                  </div>
                )}
                {annotation_detected || slide.label?.[currentLanguage]}
                {slide?.key_count > 1 && (
                  <span className="slide-counter">
                    {slide.idx_in_view + 1}/{slide.view_count}
                  </span>
                )}
              </div>}
              {!!categoryLabel && <div className="slide-category">{categoryLabel}</div>}
            </>
          )}
          {type === "other" && (
            <>
              {!!slide && <div className="slide-label">
                {annotation_detected}
              </div>}
            </>
          )}

        </div>
        {isFeatureFlagEnabled("sonio.share") &&
          <div className="slide-icons"> {buttons} </div>
        }
      </div>
      {!isOther && !!qcAmount && (
        <span className="slide-qc-evaluation">{qcAmount}</span>
      )}
    </div>
  )
}

export default withTranslation()(LiveSlide);