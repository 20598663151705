import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "../../atoms/Button/Button";
import ButtonGroup from "../../atoms/ButtonGroup/ButtonGroup";
import CountryFlag from "../../atoms/CountryFlag";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import { userConfigEnabled } from "../../authorization-utils";
import { CertificationIdErrorMessage, CertificationIdInput, MultipleCertificationIdInput, certificationIdTypeName } from '../../components/CertificationId';
import IsNotPractitionerWarn from '../../components/IsNotPractitionerWarn';
import config from "../../config";
import { AppContext } from "../../context-providers/App";
import { ZoomLevelContext } from "../../context-providers/ZoomLevel";
import useAuth from "../../context-providers/Auth";
import LookupApi from "../../services/lookup";
import UserApi from "../../services/user";
import "./PreferencesView.css";

function upCaseFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function OneCertificationIdType({ user, isPractitioner, certificationIdTypes, t: __ }) {
  const [certificationId, setCertificationId] = useState(user.rpps)
  const [certificationIdType, setCertificationIdType] = useState(user.certification_id_type_id)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const submitRPPS = async () => {
    setLoading(true);
    setErrors({});
    try {
      await UserApi.update(user.id, { rpps: certificationId, certification_id_type_id: certificationIdType });
    } catch (error) {
      if (error.response.status == 400) {
        setErrors(error.response.data.errors)
      }
      //setError(true);
    } finally {
      setLoading(false);
    }
  }

  const certificationIdProps = { certificationId, setCertificationId, certificationIdTypes, certificationIdType, setCertificationIdType, certificationIdError: errors.rpps }

  return (
    <div className="row">
      <div className={"w4 " + (isPractitioner ? "preferences-align-content" : "")}>{upCaseFirstLetter(certificationIdTypeName({ ...certificationIdProps, t: __ }))}</div>
      <div className="w8">
        {
          isPractitioner ?
            <div>
              <div className="preferences-space-around">
                <div>
                  <CertificationIdInput {...certificationIdProps} />
                </div>
                <Button
                  label={!loading ? __('common.submit') : __('common.loading')}
                  disabled={loading}
                  onClick={submitRPPS} />

              </div>
              <CertificationIdErrorMessage {...certificationIdProps} />
            </div>
            :
            <IsNotPractitionerWarn state={isPractitioner} disabled={true} t={__} />
        }
      </div>
    </div>
  )
}

function MultipleCertificationIdTypes({ user, isPractitioner, certificationIdTypes, t: __ }) {
  const [certificationIdType, setCertificationIdType] = useState(user.certification_id_type_id)
  const [certificationId, setCertificationId] = useState(user.rpps)
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const submitRPPS = async () => {
    setLoading(true);
    setErrors({});
    try {
      await UserApi.update(user.id, { rpps: certificationId, certification_id_type_id: certificationIdType });
    } catch (error) {
      if (error.response.status == 400) {
        setErrors(error.response.data.errors)
      }
    } finally {
      setLoading(false);
    }
  }

  const certificationIdProps = { certificationId, setCertificationId, certificationIdTypes, certificationIdType, setCertificationIdType, certificationIdError: errors.rpps }

  return (
    <div className="row">
      <div className={"w4 " + (isPractitioner ? "preferences-align-content" : "")}>{upCaseFirstLetter(certificationIdTypeName({ ...certificationIdProps, t: __ }))}</div>
      <div className="w8">
        {
          isPractitioner ?
            <div>
              <div className="preferences-space-around">
                <div className="prefrences-cert-id-wrapper">
                  <MultipleCertificationIdInput {...certificationIdProps} />
                </div>
                <Button
                  label={!loading ? __('common.submit') : __('common.loading')}
                  disabled={loading}
                  onClick={submitRPPS} />

              </div>
              <CertificationIdErrorMessage {...certificationIdProps} />
            </div>
            :
            <IsNotPractitionerWarn state={isPractitioner} disabled={true} t={__} />
        }
      </div>
    </div>
  )
}

function NoCertificationIdTypes({ user, isPractitioner, t: __ }) {
  return (
    <div className="row">
      <div className="w4">{ }</div>
      <div className="w8">
        {
          isPractitioner ?
            <div>{/* There is no Identification number for these other country*/}</div>
            :
            <IsNotPractitionerWarn state={isPractitioner} disabled={true} t={__} />
        }
      </div>
    </div>
  )
}
function CertificationIdRow(props) {
  switch (props.certificationIdTypes.length) {
    case 1:
      return <OneCertificationIdType {...props} />
    case 0:
      return <NoCertificationIdTypes {...props} />
    default:
      return <MultipleCertificationIdTypes {...props} />
  }
}


const PreferencesView = ({ t: __ }) => {
  const { isFeatureFlagEnabled, user, isPractitioner } = useAuth();
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { zoomLevel, updateZoomLevel } = useContext(ZoomLevelContext);
  const [pageLoading, setPageLoading] = useState(true)
  const [certificationIdTypes, setCertificationIdTypes] = useState([])

  const country_id = user.country_id
  useEffect(() => {
    const fetchApis = async () => {
      const certificationIdTypes = (await LookupApi.getCertificationIdTypes(country_id)).data.data
      setCertificationIdTypes(certificationIdTypes)
      return
    }

    fetchApis()
      .catch(() => setCertificationIdTypes([]))
      .finally(() => setPageLoading(false))
  }, [country_id])

  const goTo = (url) => {
    history.push(url);
    appContext.activateGoBackMenuButton(true, () => () => {
      history.goBack();
      appContext.activateGoBackMenuButton(false);
    })
  }

  if (pageLoading) return null

  return (
    <div className="preferences-container">
      <div className="row">
        <div className="w12"><h1>
          {__("preferences.title")}
        </h1>
        </div>
        <div className="w4 preferences-align-content">{__('preferences.darkMode')}</div>
        <div className="w8">
          <ButtonGroup
            value={appContext?.preferences?.theme ?? ''}
            options={[
              { value: 'light', label: __('preferences.disabled') },
              { value: 'dark', label: __('preferences.enabled') },
            ]}
            onChange={appContext.changeTheme}
          />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="w4 preferences-align-content">{__('preferences.language')}</div>
        <div className="w8">
          <ButtonGroup
            value={appContext?.preferences?.lang ?? ''}
            options={[
              { value: 'en', label: "EN" },
              { value: 'fr', label: "FR" },
              { value: 'de', label: "DE" },
              { value: 'ptbr', label: "PTBR" },
            ]}
            onChange={appContext.changeLanguage}
          />
        </div>
      </div>
      {
        user.country_id != "other" ?
          <div className="row">
            <div className="w4 preferences-align-content">{__('register.country')}</div>
            <div className="w8">
              <div className="preferences-align-content">
                <CountryFlag country={user.country_id} />
              </div>
            </div>
          </div>
          : null
      }
      <CertificationIdRow user={user} isPractitioner={isPractitioner} certificationIdTypes={certificationIdTypes} t={__} />
      <hr />
      {!isFeatureFlagEnabled('soniopedia') && (<div className="row">
        <div className="w4 preferences-align-content">{__('preferences.defaultCategory')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 'CNEOF',
                label: 'CNEOF 2016',
              },
              {
                value: 'AIUM',
                label: 'AIUM',
              },
              {
                value: 'ISUOG',
                label: 'ISUOG',
              },
              {
                value: 'CNEOF_2022',
                label: 'CNEOF 2022',
              },
            ]}
            value={appContext?.preferences?.default_category}
            onChange={appContext.changeDefaultCategory}
          />
        </div>
      </div>)}
      <div className="row">
        <div className="w4 preferences-align-content">{__('preferences.dateFormat')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 'dd/mm/yyyy',
                label: 'dd/mm/yyyy',
              },
              {
                value: 'mm/dd/yyyy',
                label: 'mm/dd/yyyy',
              },
            ]}
            value={appContext?.preferences?.date_format}
            onChange={appContext.changeDateFormat}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4 preferences-align-content">{__('preferences.timeFormat')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: "HH:MM:SS",
                label: "HH:MM:SS",
              },
              {
                value: "HH:MM",
                label: "HH:MM",
              },
              {
                value: "HH:MM:SS XM",
                label: "HH:MM:SS XM",
              },
              {
                value: "HH:MM XM",
                label: "HH:MM XM",
              },
            ]}
            value={appContext?.preferences?.time_format}
            onChange={appContext.changeTimeFormat}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4 preferences-align-content">{__('preferences.units')}</div>
        <div className="w8">
          <SelectInput
            options={[
              {
                value: 'metric',
                label: __('preferences.cm-kg'),
              },
              {
                value: 'imperial',
                label: __('preferences.inch-lbs'),
              },
            ]}
            value={appContext?.preferences?.units}
            onChange={appContext.changeUnits}
          />
        </div>
      </div>
      <hr />
      {isFeatureFlagEnabled("sonio.dx_v2") && !isFeatureFlagEnabled('soniopedia') && (
        <div className="row">
          <div className="w4 preferences-align-content">{__('preferences.defaultExaminationType')}</div>
          <div className="w8">
            <SelectInput
              options={[
                {
                  value: "screening",
                  label: __('preferences.defaultExaminationType.screening'),
                },
                {
                  value: "diagnostic",
                  label: __('preferences.defaultExaminationType.diagnostic'),
                },
              ]}
              value={appContext.preferences.default_exam_type}
              onChange={appContext.changeDefaultExamType}
            />
          </div>
        </div>
      )}
      {!isFeatureFlagEnabled('soniopedia') && 
        <>
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.defaultZoomLevel')}</div>
            <div className="w8">
              <SelectInput
                options={[
                  {
                    value: 1,
                    label: __('preferences.defaultZoomLevel.1'),
                  },
                  {
                    value: 2,
                    label: __('preferences.defaultZoomLevel.2'),
                  },
                  {
                    value: 3,
                    label: __('preferences.defaultZoomLevel.3'),
                  },
                  {
                    value: 5,
                    label: __('preferences.defaultZoomLevel.5'),
                  },
                  {
                    value: 8,
                    label: __('preferences.defaultZoomLevel.8'),
                  },
                ]}
                value={zoomLevel}
                onChange={updateZoomLevel}
              />
            </div>
          </div>
          <hr />
        </>
      }
      {!isFeatureFlagEnabled('soniopedia') && userConfigEnabled(isFeatureFlagEnabled) &&
        <>
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.live-config')}</div>
            <div className="w8">
              <Button
                label={__('preferences.customize')}
                icon="settings"
                onClick={() => goTo('/configuration')}
              />
            </div>
          </div>
          <hr />
        </>
      }
      {isFeatureFlagEnabled("sonio.share") &&
        <>
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.sharing-preferences')}</div>
            <div className="w8">
              <Button
                label={__('preferences.customize')}
                icon="settings"
                onClick={() => goTo('/sharing-preferences')}
              />
            </div>
          </div>
          <hr />
        </>
      }
      {!isFeatureFlagEnabled('soniopedia') && userConfigEnabled(isFeatureFlagEnabled) &&
        <>
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.manage-template')}</div>
            <div className="w8">
              <Button
                label={__('preferences.customize')}
                icon="settings"
                onClick={() => goTo('/manage-templates')}
              />
            </div>
          </div>
          <hr />
        </>
      }
      <div className="row">
        <div className="w4 preferences-align-content">{__('preferences.checkForUpdates')}</div>
        <div className="w8">
          <Button
            label={__('preferences.reload')}
            icon="reload"
            onClick={() => window.location = '/'}
          />
        </div>
      </div>
      {!isFeatureFlagEnabled('soniopedia') && userConfigEnabled(isFeatureFlagEnabled) &&
        <>
          <hr />
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.printingSharing')}</div>
            <div className="w8">
              <Button
                label={__('preferences.customize')}
                icon="settings"
                onClick={() => goTo('/printing-sharing-settings')}
              />
            </div>
          </div>
        </>
      }
      {!isFeatureFlagEnabled('soniopedia') && isFeatureFlagEnabled("sonio.share") &&
        <>
          <hr />
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.patientApplication')}</div>
            <div className="w8">
              <Button
                label={__('preferences.customize')}
                icon="settings"
                onClick={() => goTo('/patient-application-settings')}
              />
            </div>
          </div>
        </>
      }
      {!(user?.country?.config?.fetus_sex_visibility === "hidden") &&
        <>
          <hr />
          <div className="row">
            <div className="w4 preferences-align-content">{__('preferences.fetusSexVisibility')}</div>
            <div className="w8">
              <SelectInput
                options={[
                  {
                    value: 'visible',
                    label: __("preferences.fetusSexVisibility.visible"),
                  },
                  {
                    value: 'masked',
                    label: __("preferences.fetusSexVisibility.masked"),
                  },
                  {
                    value: 'hidden',
                    label: __("preferences.fetusSexVisibility.hidden"),
                  },
                ]}
                value={appContext.fetusSexVisibility || "hidden"}
                onChange={value => appContext.changeFetusSexDisplay(value)}
              />
            </div>
          </div>
        </>
      }
      {/* Temporarily disabled because the uplink configuration page is not used for now
        <hr />
        <div className="row">
          <div className="w4 preferences-align-content">{__('preferences.uplinkConfiguration')}</div>
          <div className="w8">
            <Button
              label={__('preferences.customize')}
              icon="settings"
              onClick={() => history.push('/uplink-configuration')}
            />
          </div>
        </div> */}
      {!isFeatureFlagEnabled('soniopedia') &&
        <>
          <hr />
          <div className="row">
            <div className="w12 anonymized-images-note">
              {__('preferences.anonymizedImages')}
            </div>
          </div>
        </>
      }
    </div>
  )
};

export default withTranslation()(PreferencesView);

PreferencesView.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
