import React, {useContext} from "react";
import PropTypes from "prop-types";
import Loader from "../atoms/Loader/Loader";
import { AppContext } from "../context-providers/App";
import "./WindowLayout.css";


/* used for detaching windows in multi-screen / multi-window mode */

const WindowLayout = ({ children }) => {
  const appContext = useContext(AppContext);

  return (
    <>
      <Loader isLoading={appContext.isLoading} />
      <div className="detached-page-container">
        {children}
      </div>
    </>
  );
};

WindowLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WindowLayout;
