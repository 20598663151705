import { useState } from 'react';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';

const readWarningsFromLocalStorage = () => {
    return localStorage.getItem("i18nDebugWarnings") === "true";
}

const saveWarningsToLocalStorage = (value) => {
    localStorage.setItem("i18nDebugWarnings", value);
}

const I18nDebugger = ({openChapter, setOpenChapter}) => {
    const [warnings, setWarnings] = useState(readWarningsFromLocalStorage());

    const updateWarnings = () => {
        const value = !warnings;
        setWarnings(value);
        saveWarningsToLocalStorage(value);
    }
    return <div className={`debug-section ${openChapter === "i18n-debugger" ? "open" : "closed"}`}>
        <h2 onClick={() => setOpenChapter(chapter => chapter === "i18n-debugger" ? false : "i18n-debugger")}>Translations debugger<Icon name="down" /></h2>

        {openChapter === "i18n-debugger" && (<>
            <Button variant="contained" label={warnings?"Disable warnings":"Enable warnings"} onClick={updateWarnings} />
        </>)}
    </div>
}

export default I18nDebugger;
