import { useEffect, useState } from 'react';
import TextInput from '../../../atoms/TextInput/TextInput';
import NumericInput from '../../../atoms/NumericInput/NumericInput';
import Button from '../../../atoms/Button/Button';
import SelectInput from '../../../atoms/SelectInput/SelectInput';
import { convertValueToSelectedUnit } from '../../../utils';

export default function EditBiometry({ id, fetus, data, props, reportDataOptions, close }) {
  const [currentLabel, setCurrentLabel] = useState(data?.label);
  const [currentValue, setCurrentValue] = useState(data?.value);
  const [currentComment, setCurrentComment] = useState(data?.comment);
  const [currentVisible, setCurrentVisible] = useState(data?.visible ?? true);
  const [currentVisibleGraph, setCurrentVisibleGraph] = useState(props?.visible === "true" || data?.visible_graph);
  const [currentCurveSlug, setCurrentCurveSlug] = useState(data?.curveSlug);
  const [currentDerivation, setCurrentDerivation] = useState(data?.derivation);
  const [availableDerivations, setAvailableDerivations] = useState([]);
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const onClose = () => {
    let updates = {};

    if (fetus) updates.fetus = fetus;
    if (data?.label !== currentLabel) updates.label = currentLabel;
    if (data?.value !== currentValue && currentDerivation === "edit") updates.value = currentValue;
    if (data?.derivation !== currentDerivation) updates.derivation = currentDerivation;
    if (data?.comment !== currentComment) updates.comment = currentComment;
    if (!data.editingGraph && data?.visible !== currentVisible) updates.visible = currentVisible;
    if (data.editingGraph && data?.visible_graph !== currentVisibleGraph) updates.visible_graph = currentVisibleGraph;
    if (data?.curve_slug !== currentCurveSlug) updates.curve_slug = currentCurveSlug;

    close(`measurement.${id}`, updates);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") onClose();
    else if (e.key === "Escape") close(); // TODO: not working
  };

  const availableSlugs = (data?.availableCurveSlugs ?? []).map(slug => {

    const label = slug.slice(slug.lastIndexOf(".") + 1).toUpperCase();

    return {
      label,
      value: slug,
    };
  });

  const decimals = data.decimals || 2;
  const displayUnit = data?.userUnits || data?.units

  const makeDerivationLabel = (derivation, value) => {
    const label = reportDataOptions?.derivation_labels[derivation]?.label?.[currentLanguage];
    const displayValue = convertValueToSelectedUnit(Number(value), data?.defaultUnit, displayUnit).toFixed(Number(data?.decimals));
    return `${label} - ${displayValue}`;
  };

  const onChangeMeasurementValue = (value) => {
    value = convertValueToSelectedUnit(value, displayUnit, data?.defaultUnit);
    setCurrentValue(Number(value));
    const existingDerivation = data.availableDerivations.find(d => d.value == value);
    setCurrentDerivation(existingDerivation?.derivation ?? "edit");
  };

  const onChangeDerivation = (derivation) => {
    setCurrentDerivation(derivation);
    const selectedDerivation = data.availableDerivations.find(d => d.derivation === derivation);
    setCurrentValue(selectedDerivation?.value || currentValue);
  };

  useEffect(() => {
    let derivations = Object.values(data.availableDerivations).map(({ derivation, value }) => {
      const label = makeDerivationLabel(derivation, value);
      return {
        label, value: derivation
      };
    });
    if (currentDerivation === "edit") {
      const edit = derivations.find(d => d.value === "edit");
      if (edit)
        edit.label = makeDerivationLabel("edit", currentValue);
      else
        derivations.push({ label: makeDerivationLabel("edit", currentValue), value: "edit" });
    }
    setAvailableDerivations(derivations);

  }, [currentValue, currentDerivation]);

  const displayValue = convertValueToSelectedUnit(currentValue, data.defaultUnit, displayUnit).toFixed(decimals);

  return (
    <>
      <div className="modal-background" onClick={onClose} />
      <div className="exam-report-edit-field biometry" onClick={(e) => e.stopPropagation()}>
        <div className="value">
          <span className="measurement-label">
            <TextInput value={currentLabel} fullwidth={true} onChange={value => setCurrentLabel(value)} />
          </span>
          <span className="measurement-value">
            <NumericInput value={displayValue} step={10 ** (-decimals)} onChange={value => onChangeMeasurementValue(value)} />
          </span>
          {availableSlugs.length > 0 && <span className="curve-slug">
            <SelectInput value={currentCurveSlug} options={[{label: "", value: ""}, ...availableSlugs]} onChange={value => setCurrentCurveSlug(value)} />
          </span>}
          {availableDerivations.length > 0 && <span className="derivation-dropdown">
            <SelectInput value={currentDerivation} options={availableDerivations} onChange={value => onChangeDerivation(value)} />
          </span>}
          <span className="visibility">
            <Button icon={((!data.editingGraph && currentVisible) || (data.editingGraph && currentVisibleGraph)) ? "eye" : "eye-off"} label="" size="small" variant="link" onClick={() => data.editingGraph ? setCurrentVisibleGraph(c => !c) : setCurrentVisible(c => !c)} />
          </span>
        </div>
        <div className="comment">
          <TextInput value={currentComment} onChange={value => setCurrentComment(value)} variant="underline" fullwidth={true} icon="comment" onKeyPress={onKeyPress} />
        </div>
      </div>
    </>
  );
}
