import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom'
import { withTranslation } from "react-i18next";
import Button from "../../atoms/Button/Button";
import useAuth from "../../context-providers/Auth";
import { useQuery } from "../../utils";
import "./LoginView.css";
import InputPassword from '../../atoms/InputPassword';
import Icon from "../../atoms/Icon/Icon";
import UserApi from "../../services/user";

const LoginView = ({t: __ }) => {
  let query = useQuery();

  const { login, loading, loginErrorStatus } = useAuth();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authorizationBackends, setAuthorizationBackends] = useState([]);

  const usernameField = useRef(null);
  const passwordField = useRef(null);

  const afterLoginRedirectTo = query.get("next") ? decodeURIComponent(query.get("next")) : "/";

  useEffect(() => {
    UserApi.supportedAuthorizationBackend().then(resp => setAuthorizationBackends(resp.data.data));
  }, []);

  const clickLoginButtonHandler = (e) => {
    if(e?.preventDefault)
      e.preventDefault();
    login(email, password, afterLoginRedirectTo);
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      if (usernameField.current?.value && passwordField.current?.value) {
        clickLoginButtonHandler(event);
      } else {
        event.preventDefault();
        if (!usernameField.current?.value) usernameField.current?.focus();
        else if (!passwordField.current?.value) passwordField.current?.focus();
      }
    }
  };

  const SsoLogin = () =>{
    if (authorizationBackends.includes("oidc_google") || authorizationBackends.includes("oidc_microsoft")) {
      return (
        <div className="login-sso">
          {authorizationBackends.includes("oidc_google") && <a className="login-sso-backend" href="/auth/sso/google">
            <img src="/images/google-sso-logo.svg" alt="Login with Google" />
            <span>{__("login.ssoGoogle")}</span>
          </a>}
          {authorizationBackends.includes("oidc_microsoft") && <a className="login-sso-backend" href="/auth/sso/microsoft">
            <img src="/images/microsoft-sso-logo.svg" alt="Login with Microsoft" />
            <span>{__("login.ssoMicrosoft")}</span>
          </a>}
        </div>
      );
    }
    return false;
  }

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo-container">
          <Icon name="sonio" /> <b>sonio</b>
        </div>
        <div className="login-options">
          {authorizationBackends.includes("password") && <form className="login-form">
            <label className={`login-form-item ${!email ? 'empty' : 'not-empty'}`}>
              <span>{__("login.email")}</span>
              <span>
                <input
                  ref={usernameField}
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                  onFocus={(event) => event.target.parentNode.parentNode.classList.add('focus')}
                  onBlur={(event) => event.target.parentNode.parentNode.classList.remove('focus')}
                  onKeyDown={handleEnter}
                />
              </span>
            </label>

            <label className={`login-form-item ${!password ? 'empty' : 'not-empty'}`}>
              <span>{__("login.password")}</span>
              <span className="ph-no-capture">
                <InputPassword
                  autoComplete="current-password"
                  onChange={(event) => setPassword(event.target.value)}
                  onFocus={(event) => event.target.parentNode.parentNode.classList.add('focus')}
                  onBlur={(event) => event.target.parentNode.parentNode.classList.remove('focus')}
                  onKeyDown={handleEnter}
                  ref={passwordField}
                />
              </span>
            </label>

            {loginErrorStatus === 401 && <div className="error">{__('login.wrongCredentials')}</div>}
            {loginErrorStatus === 500 && <div className="error">{__('fallback.message')}</div>}

            <div className="login-form-submit">
            <div className="login-form-forgot-pwd">
              <Link to="/forgot-password" className="go-to-forgotten-password">
                {__("login.forgottenPassword")}
              </Link>
            </div>
              <Button
                onClick={clickLoginButtonHandler}
                label={loading ? __("login.logging") : __("login.login")}
                disabled={loading}
              />
            </div>
          </form>}
          <SsoLogin />
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(LoginView);

LoginView.propTypes = {
  t: PropTypes.any,
  i18n: PropTypes.any,
};
