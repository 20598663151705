import { isNullOrUndefined } from "../../utils";
import { convertValueToSelectedUnit } from "../../unitConverter";
import { MeasurementDefaultUnits } from "../../config";

/*
 * @type {Props} - XML props of the condition block statement
 */

/*
 * @desc - Try to rerender the XML tag from received props of the component
 * @param {Props} props - XML props of the component
 * @return {string} - XML tag string
 * @example
 * // returns "<content data="examination.status">"
 * propsToTag({type: "content", data: "examination.status"})
 */
export const propsToTag = (props) => {
  const { type, ...rest } = props;
  const attributes = Object.entries(rest)
    .filter(([_, value]) => typeof value === "string")
    .map(([key, value]) => `${key}="${value}"`)
    .join(" ");

  const attributesString = attributes ? ` ${attributes}` : "";
  return `<${props.type}${attributesString}>`
}

const getDataAttributeSlugs = (dataAttribute, custom) => {
  if (typeof dataAttribute !== "string") {
    console.error("Attribute is not a string", dataAttribute)
    return [];
  }
  return dataAttribute.includes("|")
    ? dataAttribute.split("|").map(v => (custom ? "custom." : "") + v.replace(/\&/g, "").trim())
    : dataAttribute.split("&").map(v => (custom ? "custom." : "") + v.replace(/\|/g, "").trim());
}

const getDataAttributeOperator = (dataAttribute) => {
  if(typeof dataAttribute !== "string") {
    console.error("Attribute is not a string", dataAttribute)
    return "AND";
  }
  return dataAttribute.includes("|") ? "OR" : "AND";
}

/*
 * @desc - Extracts placeholders IDs from the props passed to a XML component
 * @param {Props} props - XML props of the component
 * @return {string} - placeholder ID applying to this component (extracted from the data attribute)
 * @example 
 * // returns "examination.status"
 * placeholderIdFromProps({data: "examination.status"})
 *
 * // returns "custom.examination.status"
 * placeholderIdFromProps({data: "examination.status", custom: "true"})
 */
export const placeholderIdFromProps = (props) => {
  return `${props.custom === "true" ? 'custom.' : ''}${props.data}`;
}

/* 
 * @desc - Extracts placeholders IDs required for the evaluation of the condition block statement
 *         This helps us define the condition block placeholder requirement squeleton
 *
 * @param {Props} props - XML props of the condition block statement
 * @return {Array} - Array of placeholders IDs 
 *
 * @example
 * // returns ["examination.status"]
 */
export const conditionRequiredPlaceholders = (props) => {
  const { data: dataAttribute, custom = false } = props;
  return getDataAttributeSlugs(dataAttribute, custom);
}

/*
 * @param {Props} props - XML props of the condition block statement
 * @param {Number} fetus - Fetus index
 * @param {Object} fetchedPlaceholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the condition is met, false otherwise
 *
 * @example
 * // TODO write an example (also we can use in test)
 */
export const checkCondition = (props, fetus = 0, fetchedPlaceholders) => {
  let { data: dataAttribute, attribute, custom = false, includes = "", is = "", to = "", than = "", low = "", high = "" } = props;
  if (!than && to) than = to; // to is an alias of than
  if (includes) is = "includes";
  if (!is) is = "not-empty";

  let placeholders = [];
  const operator = getDataAttributeOperator(dataAttribute);
  const dataList = conditionRequiredPlaceholders(props);
  const includesList = getDataAttributeSlugs(includes || "");

  let isTrue = false;
  let isTrueAccordingToOperator = operator === "AND";

  for (const placeholderData of dataList) {
    let placeholder = fetchedPlaceholders[placeholderData];

    if (!Array.isArray(placeholder)) {
      if (!placeholder.hasOwnProperty("value")) placeholder.value = null;
      if (!placeholder.hasOwnProperty("normal")) placeholder.normal = null;
      if (!placeholder.hasOwnProperty("viewed")) placeholder.viewed = null;
    }

    if (fetus && placeholder[0] && !placeholder[fetus]) fetus = 0; // temporary workaround for patient measurements
    const fetusPlaceholder = !Array.isArray(placeholder)
      ? (!Array.isArray(placeholder.value) ? placeholder : { ...placeholder, value: placeholder.value[fetus] })
      : (fetus ? placeholder[fetus] : placeholder.find(elm => elm));
    placeholder.valueToCheck = (attribute ? (fetusPlaceholder?.[attribute] || false) : fetusPlaceholder?.value);
    if (!!placeholder.value && typeof placeholder.value !== "object") {
      placeholder.valueToCheck = placeholder.valueToCheck ? `${placeholder.valueToCheck}` : false; // to string only if set -> important for matching empty/not-empty statuses
    }

    placeholders.push(placeholder);
  }

  for (const placeholder of placeholders) {
    const valueToCheck = placeholder.valueToCheck;
    const normal = placeholder.normal;
    const viewed = placeholder.viewed;

    switch (is) {
      case "empty":
      case "":
        isTrue = !valueToCheck;
        break;

      case "not-empty":
      case "not_empty":
        isTrue = !!valueToCheck;
        break;

      case "equal":
      case "=":
        if (valueToCheck !== null && than !== null) {
          isTrue = `${valueToCheck}`.toLowerCase() === `${than}`.toLowerCase();
        }
        break;

      case "not-equal":
      case "not_equal":
      case "!=":
        if (valueToCheck !== null && than !== null) {
          isTrue = `${valueToCheck}`.toLowerCase() !== `${than}`.toLowerCase();
        }
        break;

      case "greater":
      case ">":
        isTrue = (Number(valueToCheck) > Number(than));
        break;

      case "greater_or_equal":
      case "greater-or-equal":
      case "greater or equal":
      case ">=":
        isTrue = (Number(valueToCheck) >= Number(than));
        break;

      case "less":
      case "lower":
      case "<":
        isTrue = (Number(valueToCheck) < Number(than));
        break;

      case "less_or_equal":
      case "lower_or_equal":
      case "less-or-equal":
      case "lower-or-equal":
      case "less or equal":
      case "lower or equal":
      case "<=":
        isTrue = (Number(valueToCheck) <= Number(than));
        break;

      case "between":
      case "><":
        if (low !== null && high !== null) {
          isTrue = (Number(valueToCheck) <= Number(high) && Number(valueToCheck) >= Number(low));
        }
        break;

      case "not-between":
      case "not_between":
      case "<>":
        if (low !== null && high !== null) {
          isTrue = (Number(valueToCheck) > Number(high) || Number(valueToCheck) < Number(low));
        }
        break;

      case "normal":
        isTrue = !!normal;
        break;

      case "not_normal":
      case "not-normal":
        isTrue = (normal !== null && !normal);
        break;

      case "viewed":
        isTrue = !!viewed;
        break;

      case "not_viewed":
      case "not-viewed":
        isTrue = (viewed !== null && !viewed);
        break;

      case "includes":
        // TODO: check includesList by operator, at now everything is always considered as OR
        isTrue = (!!valueToCheck && typeof valueToCheck === 'object') ? includesList.some(v => !!valueToCheck[v]) : valueToCheck?.includes(includes);
        break;
    }

    if (operator === "AND") {
      isTrueAccordingToOperator = (isTrueAccordingToOperator && isTrue) ? true : false;
    } else if (operator === "OR" && isTrue) {
      isTrueAccordingToOperator = true;
    }
  }

  return isTrueAccordingToOperator;
};

/*
 * @desc - Return the placeholder ID from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @return {string} - Placeholder ID
 *
 * @example
 * // returns "measurement.bpd"
 * measurementCurvePlaceholderId({data: "bpd"})
 */
export const measurementCurvePlaceholderId = (props) => {
  // TODO This is super dumb we should always use placehodlers as they come
  // We will need to fix once we can avoid caring about backwards compatibility
  if (!props.data)
    throw new Error(`measurement-curve must always come with a data attribute.\nIn ${propsToTag(props)}`)
  return `measurement.${placeholderIdFromProps(props)}`;
}

/*
 * @desc - Return the placeholder from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {Object} - Placeholder object
 *
 * TODO @example
 */
export const measurementCurvePlaceholder = (props, placeholders) => {
  const placeholderId = measurementCurvePlaceholderId(props);
  const fetus = Number(props.fetus || 1);
  const originalPlaceholder = placeholders[placeholderId];
  return Array.isArray(originalPlaceholder) ? originalPlaceholder[fetus] : originalPlaceholder;
}

/*
 * @desc - Return the curve slug from props of measurement-curve component.
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {string} - Curve slug
 *
 * TODO @example
 */
export const measurementCruveSlug = (props, placeholders) => {
  // Priority - 1: Report edit, 2: xml prop, 3: Hadlock, 4: first available
  const placeholder = measurementCurvePlaceholder(props, placeholders);
  return placeholder?.curve_slug ??
    (props.curve ? `${props.data}.${props.curve}` : null) ??
    placeholder?.availableCurveSlugs.find(slug => slug.includes("hadlock")) ??
    placeholder?.availableCurveSlugs[0];
}

export const measurementCurve = (props, placeholders) => {
  const slug = measurementCruveSlug(props, placeholders);
  return placeholders[`curve.${slug}`]?.value;
}

/*
 * @desc - Check if the measurement graph is visible
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the measurement graph is visible, false otherwise
 *
 * TODO @example
 */
export const isMeasurementCurveVisible = (props, placeholders) => {
  const placeholder = measurementCurvePlaceholder(props, placeholders);
  if (!placeholder) return false;
  const propsVisible = !isNullOrUndefined(props.visible) ? props.visible !== "false" : null;
  return placeholder.visible_graph ?? propsVisible ?? false;
}

export const measurementCurveData = (props, placeholders, reportDataOptions) => {
  const fieldId = measurementCurvePlaceholderId(props);
  const originalPlaceholder = placeholders[fieldId];
  const placeholder = measurementCurvePlaceholder(props, placeholders);
  const curve = measurementCurve(props, placeholders);
  const fetus = Number(props.fetus || 1);

  const measurementId = props.data?.split(".")[0].split("/")[0]
  const storedUnit = MeasurementDefaultUnits[reportDataOptions?.labels?.measurement?.[measurementId]?.type];
  const displayUnit = props.units || (isNullOrUndefined(placeholder?.units) || placeholder?.units === "null" ? "" : placeholder.units);

  const yAxis = placeholder;
  const xAxisId = curve?.horizontal_axis_id;
  const xAxis = { id: xAxisId };
  const isAtRisk = placeholder?.sonio_percentile > 97 || placeholder?.sonio_percentile < 3;

  // Retrieve current and previous measurements for all fetuses
  const allMeasurements = (fetus === 0 ? [originalPlaceholder[0]] : originalPlaceholder.slice(1))
    .map(({ value, xvalue, previousMeasurements }, currentFetus) => {
      return (
        [{ examId: "current", value, xvalue }, ...(previousMeasurements || [])]
          .sort((a, b) => a.xvalue - b.xvalue) // Sort by GA
          .reduce((acc, elem) => {
            let xvalue;

            if (xAxisId === "ga")
              xvalue = elem.xvalue;
            else {
              const xAxisPlaceholder = placeholders?.[`measurement.${xAxisId}`];
              if (xAxisPlaceholder)
                xvalue = elem?.examId === "current" ?
                  xAxisPlaceholder?.[currentFetus + 1]?.value :
                  xAxisPlaceholder?.[currentFetus + 1]?.previousMeasurements?.find(m => m?.examId == elem?.examId)?.value;

            }
            if (xvalue) acc.push({ value: convertValueToSelectedUnit(elem.value, storedUnit, displayUnit), xvalue });
            return acc;
          }, [])
      );
    });

  return { placeholder, fetus, allMeasurements, xAxis, yAxis, isAtRisk, storedUnit, displayUnit };
}


/*
 * @desc - Check if the measurement graph is empty
 * @param {Props} props - XML props of the component
 * @param {Object} placeholders - Placeholders fetched from the report relative data
 * @return {Boolean} - True if the measurement graph is empty, false otherwise
 */
export const isMeasurementCurveEmpty = (props, placeholders) => {
  const placeholder = measurementCurvePlaceholder(props, placeholders);
  const curve = measurementCurve(props, placeholders);
  const { allMeasurements } = measurementCurveData(props, placeholders);

  /* If no measurement data is available, the curve is considered empty */
  if (!allMeasurements?.length || !allMeasurements[0]?.length) return true;

  return !placeholder || placeholder?.value === false || !curve
}

export const measurementCurveRequiredPlaceholders = (props) => {
  const fieldId = measurementCurvePlaceholderId(props);
  const curveSlug = measurementCruveSlug(props, placeholders);
  const curve = measurementCurve(props, placeholders);

  const xAxisId = curve?.horizontal_axis_id;
  const xAxisRequiredField = xAxisId ? [`measurement.${xAxisId}`] : [];
  return [...xAxisRequiredField, fieldId, "fetus.number", `curve.${curveSlug}`];
}
