import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Icon from "../Icon/Icon";
import "./NumericInput.css";

/**
 * <NumericInput
 *  min
 *  max
 *  step
 *  prefix
 *  suffix
 *  value
 *  defaultValue
 *  tempValue = a textual value to display until the numeric value is inserted
 *  onChange
 * />
 */

let timer = null;
let bounceTimer = null;

const NumericInput = ({ min, max, step = 1, label = "", prefix, suffix, value, tmpValue, defaultValue, onChange, onBlur, showPlusMinus = true }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  const [addIsPressed, setAddIsPressed] = useState(false);
  const [subtractIsPressed, setSubtractIsPressed] = useState(false);
  const inputField = useRef(null);

  useEffect(() => setInternalValue(value), [value]);

  const removePrefix = (value) => {
    if (prefix) value = value.replace(prefix, '');
    return value;
  };

  const addPrefix = (value) => {
    value = value ? value.toString() : '';

    if (prefix) value = prefix + value;
    return value;
  };

  const removeSuffix = (value) => {
    if (suffix) value = value.replace(suffix, '');
    return value;
  };

  const addSuffix = (value) => {
    value = value ? value.toString() : '';

    if (suffix) value += suffix;
    return value;
  };

  /* trasform text to numeric input on focus */
  const onFocusHandler = (event) => {
    setIsFocused(true);

    const input = event.currentTarget;
    input.value = removePrefix(input.value);
    input.value = removeSuffix(input.value);
    input.type = 'number';
    inputField.current.select();
  };

  /* trasform back numeric to text input on blur, and add suffix */
  const onBlurHandler = (event) => {
    setIsFocused(false);

    const input = event.currentTarget;

    let value = input.value;

    value = removePrefix(value);
    value = removeSuffix(value);

    if (value) {
      value = value.replace(',', '.');
      value = Number(value);

      if (max !== false && value > max) value = max;

      if (min !== false && value < min) value = min;

      const ratio = 1 / step;
      value = Math.round((value * ratio) / (step * ratio)) / ratio;
    }

    input.type = 'text';
    input.value = addPrefix(addSuffix(value));

    if (onChange) onChange(value, '');
    if (onBlur) onBlur(value, '');
  };

  const onChangeHandler = (event) => {
    let value = event.currentTarget.value;
    onChangeValue(value, '');
  };

  const onChangeValue = useCallback((value, withBounce = true) => {
    value = value.replace(',', '.');
    value = value.replace(/[^0-9.]/g, '');
    setInternalValue(value);

    /* we are always applying a small bounce even when withBounce is false, to make the UI updates smoother in case of multiple clicks */
    if (bounceTimer) clearTimeout(bounceTimer);
    bounceTimer = setTimeout(() => {
      if (onChange) onChange(value, '');
    }, withBounce ? 1000 : 200);
  }, [onChange]);

  const getValidValue = useCallback(() => {
    if (!isNaN(internalValue) && internalValue) return internalValue;
    if (!internalValue && defaultValue) return defaultValue;
    if (min && max) return (max - min) / 2;
    if (min) return min;
    if (max) return max;
    return 0;
  }, [internalValue, defaultValue, min, max]);

  const onShortPressAdd = useCallback((withBounce = true) => {
    const ratio = 1 / step;
    let value = getValidValue();

    value = (parseFloat(value) * ratio + step * ratio) / ratio;

    if (max && value > max) value = parseFloat(max);

    value = value.toFixed(countDecimals(step));

    if (onChange) onChangeValue(value, withBounce);
  }, [onChangeValue, getValidValue, step, max]);

  const onShortPressSubtract = useCallback((withBounce = true) => {
    const ratio = 1 / step;
    let value = getValidValue();
    value = (parseFloat(value) * ratio - step * ratio) / ratio;

    if (min && value < min) value = parseFloat(min);

    value = value.toFixed(countDecimals(step));

    if (onChange) onChangeValue(value, withBounce);
  }, [onChangeValue, getValidValue, step, min]);

  const countDecimals = (value) => {
    if (Math.floor(value) !== value) return value.toString().split('.')[1].length || 0;
    return 0;
  };

  const onLongPressSubtract = useCallback((event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onShortPressSubtract(false);
    timer = setTimeout(() => setSubtractIsPressed(true), 200);
    document.body.addEventListener('mouseup', onMouseUp);
  }, [onShortPressSubtract]);

  const onLongPressAdd = useCallback((event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    onShortPressAdd(false);
    timer = setTimeout(() => setAddIsPressed(true), 200);
    document.body.addEventListener('mouseup', onMouseUp);
  }, [onShortPressAdd]);

  const onMouseUp = () => {
    document.body.removeEventListener('mouseup', onMouseUp);
    if (timer) clearTimeout(timer);
    setSubtractIsPressed(false);
    setAddIsPressed(false);
  };

  useEffect(() => {
    if (addIsPressed) setTimeout(onShortPressAdd, 200);
    if (subtractIsPressed) setTimeout(onShortPressSubtract, 200);
  }, [addIsPressed, subtractIsPressed, onShortPressAdd, onShortPressSubtract]);

  const displayedValue = isFocused ? internalValue : addPrefix(addSuffix(internalValue));

  return (
    <div className={`numeric-input`}>
      {!!label && (
        <label>{label}</label>
      )}
      {showPlusMinus &&
        <span className="subtract-wrapper not-printable" onMouseDown={onLongPressSubtract}>
          <Icon name="subtract" />
        </span>
      }
      <input
        ref={inputField}
        type="text"
        value={!!tmpValue ? tmpValue : displayedValue}
        onChange={onChangeHandler}
        onFocus={onFocusHandler}
        onBlur={onBlurHandler}
      />
      {showPlusMinus &&
        <span className="add-wrapper not-printable" onMouseDown={onLongPressAdd}>
          <Icon name="add" />
        </span>
      }
    </div>
  );
};

export default NumericInput;

NumericInput.propTypes = {
  step: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  onChange: PropTypes.func,
};
