import { useApp } from "../context-providers/App";

const ND_ID = 4;
function useIsBasicAccount() {
    const { displayedPresets, allPresets } = useApp();
    if (displayedPresets.length > 1) {
        return false;
    }
    const displayedPresetId = displayedPresets[0];
    return allPresets.filter(preset => preset.id === displayedPresetId)[0].id === ND_ID;
}

export default useIsBasicAccount;