import React, { useState, useEffect} from 'react'
import { withTranslation } from "react-i18next";
import { Link, useParams } from 'react-router-dom';
import "./ResetPassword.css"
import userApi from '../../services/user'
import Button from '../../atoms/Button/Button';
import InputPassword from '../../atoms/InputPassword';
import PasswordChecker from '../../components/PasswordChecker';
import { useHistory } from 'react-router-dom';
import UserApi from '../../services/user';


const ResetPassword = ({ t: __ }) => {
  const { token } = useParams();
  const history = useHistory();

  const [newPassword, doSetNewPassword] = useState("");
  const [confirmPassword, doSetConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [identity, setIdentity] = useState({});
  const [loadingIdentity, setLoadingIdentity] = useState(true);

  useEffect(() => {
    UserApi.validatePasswordDetail(token)
      .then(({data: {data}}) => setIdentity(data))
      .catch(() => setIdentity(null))
      .finally(() => setLoadingIdentity(false))
  }, [token])

  if (loadingIdentity)
    return null
  if (identity == null)
    return (
      <div className="reset-password-page">
        <div className="sonio-header-logo"><img src="/logo-full.svg" alt="Sonio"/></div>
        <div>{__("login.resetPasswordRequestUnauthorized")}</div>
      </div>
    )

  const splitted_name = (identity.title|| "").split(" ")

  const little_identity = {
    email: identity.email,
    email_part1: (identity.email || "").split("@")[0],
    rpps: identity.rpps,
    first_name: splitted_name[0],
    last_name: splitted_name[splitted_name.length - 1],
  }

  const setNewPassword = (newPassword) => {
    setErrors({...errors, password: null})
    doSetNewPassword(newPassword)
  }

  const setConfirmPassword = (newPassword) => {
    setErrors({...errors, confirm_password: null})
    doSetConfirmPassword(newPassword)
  }

  const resetPassword = async (e) => {
    e.preventDefault();

    setLoading(true);
    setErrors({});
    try {
      await userApi.resetPassword(token, newPassword, confirmPassword);
      history.push('/reset-password/success')
    } catch ({ response: { data: {errors}} }){
      setErrors(errors || {});
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="reset-password-page">
      <div className="sonio-header-logo"><img src="/logo-full.svg" alt="Sonio"/></div>
      <form className="reset-password-form" onSubmit={resetPassword}>
        <div className="register-user-form-title">{__('reset_password.title')}</div>
        <div className="register-user-form-section-title">{__("login.resetPasswordPrompt")}</div>
        <PasswordChecker password={newPassword} opts={little_identity} t={__}/>
        <div className="register-user-field-wrapper">
          <div><label htmlFor="password">{__('login.password')}</label></div>
          <InputPassword disabled={loading} className={errors.password ? "register-user-input-error" : ""} id="password" autoComplete="new-password" placeholder={__("register.password.placeholder")} style={{width: "100%"}} onChange={({target: {value: value}}) => setNewPassword(value)} value={newPassword}/>
          {errors.password && <div className="register-user-field-error">{__(errors.password)}</div>}
        </div>
        <div className="register-user-field-wrapper">
          <div><label htmlFor="confirm-password">{__('login.confirmPassword')}</label></div>
          <InputPassword disabled={loading} className={errors.confirm_password ? "register-user-input-error" : ""} id="confirm-password" type="password" autoComplete="new-password" style={{width: "100%"}} onChange={({target: {value: value}}) => setConfirmPassword(value)} value={confirmPassword}/>
          {errors.confirm_password && <div className="register-user-field-error">{__(errors.confirm_password)}</div>}
        </div>
        <Button
          disabled={loading}
          label={loading ? __("common.loading") : __("common.submit")}
        />
      </form>
    </div>
  )
}
export default withTranslation()(ResetPassword);
