export const isList = (value) => Array.isArray(value);
export const isEmptyList = (array) => isList(array) && array.length === 0;


export const extractProtoSetting = (userPreselectConfig, presetId) => {
  const printPreselect = userPreselectConfig?.printing_preset;
  return printPreselect && printPreselect[presetId+'']
}
export const changedFieldsOfProtoSetting = (userPreselectConfig, presetId, newValue) => {
  const fieldsToUpdate = {};
  const printPreselect = userPreselectConfig?.printing_preset || {};
  const oldValue = extractProtoSetting(userPreselectConfig, presetId)

  // delete saved if saving list
  if (isList(newValue) && !isList(oldValue)) {
    const printPreselectSaved = userPreselectConfig?.printing_preset_saved || {};
    delete printPreselectSaved[presetId+'']
    fieldsToUpdate["printing_preset_saved"] = printPreselectSaved
  }
  // updated if old was a list
  if (isList(oldValue) && !isList(newValue)) {
    const printPreselectSaved = userPreselectConfig?.printing_preset_saved || {};
    printPreselectSaved[presetId+''] = oldValue
    fieldsToUpdate["printing_preset_saved"] = printPreselectSaved
  }

  if (newValue === false) {
    delete printPreselect[presetId+''];
  } else {
    printPreselect[presetId+''] = newValue;
  }
  fieldsToUpdate["printing_preset"] = printPreselect;

  return fieldsToUpdate;
}

export const lastPersonalisedProtoSetting = (userPreselectConfig, presetId) => {
  const savedPrintingPreset = userPreselectConfig?.printing_preset_saved;
  return savedPrintingPreset && savedPrintingPreset[presetId+'']
}
