import Icon from "../../atoms/Icon/Icon";
import "./Popup.css";

/**
 * <Popup
 *  message={html string}
 *  icon={icon slug}
 *  cta={jsx with buttons etc}
 *  onBackgroundClick={function}
 * >
 */
const Popup = ({ message = false, icon = false, cta = false, onBackgroundClick = () => { } }) => {
  return (
    <div className="popup-container">
      <div className="popup">
        {!!icon && (
          <Icon name={icon} />
        )}
        {!!message && (
          <span className="popup-message" dangerouslySetInnerHTML={{ __html: message }}></span>
        )}
        {!!cta && (
          <div className="cta">{cta}</div>
        )}
      </div>
      <div className="modal-background" onClick={onBackgroundClick} />
    </div>
  );
};

export default Popup;
