if(Object.groupBy === undefined) {
  Object.defineProperty(Object.prototype, "groupBy", {
    value: (arr, callback) => {
      return arr.reduce((acc = {}, ...args) => {
        const key = callback(...args);
        acc[key] ??= []
        acc[key].push(args[0]);
        return acc;
      }, {})
    }
  })
}
