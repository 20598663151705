import React from 'react';
import { withTranslation } from "react-i18next";
import Popup from '../components/Popup/Popup';
import Button from '../atoms/Button/Button';
import './ManualUserSwitchDialog.css'

const Options = ({ users, onUserSelect, onIgnoreSelect, ignoreMessage }) => (
    <div className="manual-user-switch-dialog">
      {users.map(({ id, title }) => <Button key={id} onClick={() => onUserSelect(id)} label={title} />)}
      <Button variant='outline' onClick={() => onIgnoreSelect()} label={ignoreMessage} />
    </div>
)

const ManualUserSwitchDialog = ({ t: __, users, onUserSelect, onIgnoreSelect, currentPhysicianName }) => {

  return (
    <Popup 
      message={__('examination.selectCurrentUser', {physicianName: currentPhysicianName})}
      cta={
        <Options 
          users={users} 
          onUserSelect={onUserSelect} 
          onIgnoreSelect={onIgnoreSelect}
          ignoreMessage={__('examination.ignoreExam')}
        />
      }
    />
)};

export default withTranslation()(ManualUserSwitchDialog);


