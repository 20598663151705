/**
* Using the browser is great for experimenting 👍.
* However, due to all of the strings Faker uses to generate fake data, Faker is a large package.
* It's > 5 MiB minified. TODO: use faker only in backend
*/
import { faker } from "@faker-js/faker";
import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";
import { createFullName } from "../../services/examination";
import ResourceApi from "../../services/resource";
import "./SimulatorDisclaimerView.css";
import SimulatorView from "./SimulatorView";
import "./assets/fontello.css";
import srMetadata from "./srMetadata";

const SimulatorDisclaimerView = ({ t: __ }) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const [performingPhysicianName, setPerformingPhysicianName] = useState("")
  const [seriesInstanceUID, setSeriesInstanceUID] = useState("")
  const [studyInstanceUID, setStudyInstanceUID] = useState("")
  const [patientName, setPatientName] = useState("")
  const [accessionNumber, setAccessionNumber] = useState("")
  const [started, setStarted] = useState(false)

  const startExam = useCallback(() => {
    goFullScreen();
    // This is a work around as we do only have one language nopt fitting in faker locales
    // We may want to have a mapping function
    // console.log(faker.locales)
    faker.setLocale(currentLanguage === "ptbr" ? "pt_BR" : currentLanguage);
    const patientName = createFullName(faker.name.lastName('female'),
      faker.name.firstName('female'),
      faker.name.middleName('female'),
      faker.name.prefix('female'))

    setPerformingPhysicianName(faker.name.findName())
    setSeriesInstanceUID(faker.datatype.uuid())
    setStudyInstanceUID(faker.datatype.uuid())
    setPatientName(patientName)
    setAccessionNumber(faker.datatype.uuid())
    setStarted(true)
  }, [])

  const endExam = useCallback(async () => {
    const SOPInstanceUID = faker.datatype.uuid();
    await ResourceApi.ingestDicomWithImage({
      "instanceUuid": SOPInstanceUID,
      "metadata": {
        ...srMetadata,
        "DeviceSerialNumber": "1234567",
        "SOPInstanceUID": SOPInstanceUID,
        "Modality": "SR",
        "PerformingPhysicianName": performingPhysicianName,
        "OperatorsName": "",
        "NumberOfFrames": "1",
        "SeriesInstanceUID": seriesInstanceUID,
        "StudyInstanceUID": studyInstanceUID,
        "PatientName": patientName,
        "PatientBirthDate": "19950805",
        "PatientSex": "F",
        "AccessionNumber": accessionNumber,
        "PatientID": "",
        "instanceUuid": SOPInstanceUID
      },
      "thumbnailBase64": "/lol",
      "metaHeader": ""
    })
    setPerformingPhysicianName("")
    setSeriesInstanceUID("")
    setStudyInstanceUID("")
    setPatientName("")
    setAccessionNumber("")
    setStarted(false)
  }, [
    faker,
    ResourceApi.ingestDicomWithImage,
    performingPhysicianName,
    seriesInstanceUID,
    studyInstanceUID,
    patientName,
    accessionNumber
  ]);

  const goFullScreen = () => {
    // Full screen can only be used in a user request handler as a onClick
    // that's why we use it here and not in the actual video component
    document.body.requestFullscreen();
  }

  return started ?
    <SimulatorView
      performingPhysicianName={performingPhysicianName}
      seriesInstanceUID={seriesInstanceUID}
      studyInstanceUID={studyInstanceUID}
      patientName={patientName}
      accessionNumber={accessionNumber}
      endExam={endExam}
    />
    : (
      <div className="simulator-disclaimer-page-container">
        {__("simulator.disclaimer")}
        <div>
          <button onClick={startExam}>{__("simulator.disclaimer.start")}</button>
        </div>
      </div>
    );
};

export default withTranslation()(SimulatorDisclaimerView);

