import { createContext, useContext } from "react";
import { ExaminationContext } from "./Examination";

import XMLTemplateContextProvider_v1_0 from "./XMLTemplate/v1.0";
import XMLTemplateContextProvider_v1_1 from "./XMLTemplate/v1.1";
import XMLTemplateContextProvider_v2_0 from "./XMLTemplate/v2.0";

import { isNullOrUndefined } from '../utils';

const defaultContext = {
  getPlaceholderWithProps: () => { },
  placeholders: {},
  loaded: false,
  BIContext: {},
  reportDataOptions: {},
};

export const XMLTemplateContext = createContext(defaultContext);

export const XMLTemplateContextProvider = (({ children }) => {
  // TODO get from the reportData
  const { examination } = useContext(ExaminationContext);

  if (isNullOrUndefined(examination.report_version)) {
    return <XMLTemplateContext.Provider value={defaultContext}>
      {children}
    </XMLTemplateContext.Provider>
  }
  if (examination.report_version === "1.0.0") {
    return <XMLTemplateContextProvider_v1_0 XMLTemplateContext={XMLTemplateContext}>
      {children}
    </XMLTemplateContextProvider_v1_0>
  }

  if (examination.report_version === "1.1.0") {
    return <XMLTemplateContextProvider_v1_1 XMLTemplateContext={XMLTemplateContext}>
      {children}
    </XMLTemplateContextProvider_v1_1>
  }

  return <XMLTemplateContextProvider_v2_0 XMLTemplateContext={XMLTemplateContext}>
    {children}
  </XMLTemplateContextProvider_v2_0>
});

export const useXMLTemplate = () => useContext(XMLTemplateContext);
