const getRecentsStorageKey = (collection, userId) => {
  return `recent_${userId || '*'}_${collection}`;
}

const getRecents = (collection, userId, limit = 3, filter = (a) => a.item) => {
  const localStorageKey = getRecentsStorageKey(collection, userId);
  let recents = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
  if (!Array.isArray(recents)) recents = [];

  return recents
  .filter(filter)
  .sort((a, b) => b.last - a.last)
  .slice(0, limit);
}

const getMostUsed = (collection, userId, limit = 3, filter = (a) => a.item) => {
  const localStorageKey = getRecentsStorageKey(collection, userId);
  let recents = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
  if (!Array.isArray(recents)) recents = [];

  /* TODO remove it just after the go live for an improvement */
  
  return recents
  .filter(filter)
  .sort((a, b) => b.clicks - a.clicks)
  .slice(0, limit);
}

const getFromMostUsed = (id, collection, userId) => {
  const localStorageKey = getRecentsStorageKey(collection, userId);
  let recents = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
  if (!Array.isArray(recents)) recents = [];
  return recents.find(r => r.id === id);
}

const addToRecent = (collection, userId, itemId, item) => {
  const localStorageKey = getRecentsStorageKey(collection, userId);
  let recents = JSON.parse(window.localStorage.getItem(localStorageKey) || '[]');
  if (!Array.isArray(recents)) recents = [];

  let recentIndex = recents.findIndex(item => item.id === itemId);
  if (recentIndex === -1) recentIndex = recents.length;

  if (!recents[recentIndex]) {
    recents[recentIndex] = {
      item,
      id: itemId,
      clicks: 0,
      last: false,
    };
  }
  recents[recentIndex].clicks++;
  recents[recentIndex].last = Math.ceil(Date.now() / 1000);

  // remove everything not used in the last month
  const oneMonthAgo = Math.ceil(Date.now() / 1000) - 2592000;
  recents = recents.filter(item => item.last > oneMonthAgo);

  window.localStorage.setItem(localStorageKey, JSON.stringify(recents));
};

const Recents = {
    getRecents,
    getMostUsed,
    getFromMostUsed,
    addToRecent,
};

export default Recents;
