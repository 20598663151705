import parse from 'html-react-parser';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Icon from '../../atoms/Icon/Icon';
import { AppContext } from '../../context-providers/App';
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from '../../context-providers/Examination';
import ResourceApi from '../../services/resource';
import { getInstancePreviewUri, getInstanceThumbnailUri } from "../../utils";
import './PrintPreview.css';

const DEFAULT_NB_IMG_PER_PAGE = 8;

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

const PrintPreview = ({ t: __, close = null }) => {
  const history = useHistory();
  const examinationContext = useContext(ExaminationContext);
  const appContext = useContext(AppContext);
  const { site } = appContext;

  const { examination, instances: contextInstances } = examinationContext;
  const { config } = useAuth();
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const currentTrimester = examination.trimester;
  const malformations = examination?.malformations || [];
  examination.type = (examination.type || (!!malformations.length ? "diagnostic" : "screening"));
  const instances = contextInstances.filter(instance => instance.dicom_media_type === "image")

  const printingTemplate = useMemo(() => config?.printing_configuration?.find((printing_config) => printing_config.trimester === currentTrimester?.toLowerCase()), [currentTrimester, config]);
  const [printingPreferenceImgPerPage, setPrintingPreferenceImgPerPage] = useState(DEFAULT_NB_IMG_PER_PAGE);
  const [anonymizeImages, setAnonymizeImages] = useState(false);
  const [printLabels, setPrintLabels] = useState(true);
  const [mountNode, setMountNode] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [instancesToPrint, setInstancesToPrint] = useState([]);
  const [closeAction, setCloseAction] = useState(null);

  // The page will render a single QR whenever it's selected to exclusively do so or 
  // the user did not select a single instance image yet selected to include a QR
  const printOnlyQr = useMemo(() => {
    return examinationContext.exclusivelyQr || (instancesToPrint.length === 0 && examinationContext.includeQRCode)
  }, [examinationContext.exclusivelyQr, instancesToPrint, examinationContext.includeQRCode])
  /** medias */
  const printableFrame = useRef(null);

  useEffect(() => {
    const closeActionProvider = () => (!close) ? history.goBack : close
    appContext.activateGoBackMenuButton(true, closeActionProvider);
    setCloseAction(closeActionProvider)
    return () => appContext.activateGoBackMenuButton(false);
  }, [])

  useEffect(() => {
    if (examinationContext.examination.id) ResourceApi.printingList(examinationContext.examination.id).then(resp => setInstancesToPrint(resp.data.data));
  }, [examinationContext.examination.id, examinationContext.instances])

  useEffect(() => {
    if (printingTemplate?.number_of_images_per_page != null) {
      setPrintingPreferenceImgPerPage(printingTemplate?.number_of_images_per_page);
    }
  }, [printingTemplate?.number_of_images_per_page]);

  useEffect(() => {
    /** awaiting for DOM creation inside the iframe (Firefox only) */
    if (printableFrame.current) printableFrame.current?.addEventListener('load', () => setIframeLoaded(true));
  }, [JSON.stringify(instancesToPrint)]);

  useEffect(() => {
    if (!iframeLoaded && !printableFrame.current?.contentWindow) return false;
    if (printableFrame.current?.contentWindow?.document?.head.getElementsByClassName("main-style").length > 0) return false;

    const css = document.createElement("style");
    css.className = "main-style";
    const styleString = `
      * {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        vertical-align:baseline;
        background: transparent;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      *::-webkit-scrollbar {
        display: none;
      }
      @page {
        margin: 0;
      }
      html,body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      body {
        font-size: 13px;
        font-family: "Metropolis", sans-serif;
        text-align: center;
      }
      .print-preview-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
        position: relative;
        padding: 100px 0.4cm 50px 0.4cm;
        background: white;
        color: black;
        font-size: 13px;
      }
      .qr-only-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .common-message {
        font-size: 12px;
        text-align: left;
        margin-bottom: 20px;
      }
      .page-header {
        display: flex;
        width: 100%;
        left: 0;
        right: 0;
        background: white;
        top: 0;
        height: 100px;
        min-height: 100px;
        align-items: center;
        position: absolute;
        margin-bottom: 0.1cm;
        padding: 0.1cm 0.3cm;
      }
      .page-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding: 0.1cm 0.3cm;
      }
      .page-footer > .powered-by {
        font-size: 11px;
      }
      .page-header > img {
        position: absolute;
        width: 2.4cm;
        height: 2.4cm;
        background: white;
      }
      .page-footer > img {
        width: 0.82cm;
        height: 0.82cm;
        max-width: 0.82cm;
        max-height: 0.82cm;
        background: white;
        margin-left: 0.1cm;
      }
      .page-header > .site-name {
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
      .img-item {
        margin: 0;
        padding: 0;
      }
      .slides-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 100%;
        column-gap: 1%;
        row-gap: 3%;
        align-content: flex-start;
      }
      .qr-row {
        width: 100%;
      }
      .label-container {
        max-width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }
      .label-container > p {
        text-overflow: ellipsis;
        margin: 0;
        font-size: 12px;
        margin-bottom: 2px;
      }
      .img-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      img {
        border:0;
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
        aspect-ratio: 4 / 3;
        background: #000;
      }
      .img-container.anonymized {
        margin-top: 5%;
      }
      .img-container.anonymized img {
        top: -10%;
      }
      .single-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-column: span var(--number-of-columns);
        grid-row-start: span var(--number-of-rows);
      }
      .footer-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
      }
      .footer-qr-code > p, .top-qr-code > p {
        font-size: 11px;
        text-align: left;
      }
      `;
    const style = document.createTextNode(`
      ${styleString}
      @media print {
        html {
          box-sizing: border-box;
          font-size: 12px;
        }
        html, body {
          width: 100%;
          height: 100%;
        }
        *, *:before, *:after {
          box-sizing: inherit;
          margin: 0;
          padding: 0;
        }
        body, h1, h2, h3, h4, h5, h6, p, ol, ul {
          font-weight: normal;
        }
        ol, ul {
          list-style: none;
        }
        .print-preview-page {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          min-height: 100%;
          position: relative;
          padding: 100px 0.4cm 50px 0.4cm;
          background: white;
          color: black;
        }
        .page-header {
          height: 100px;
        }
        .page-footer {
          height: 40px;
        }
        .label-container {
          max-width: 100%;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          margin:0;
          margin-bottom: 2px;
        }
        .label-container > p {
          text-overflow: ellipsis;
          margin: 0;
          font-size: 12px;
        }
        .slides-content {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 1%;
          row-gap: 2%;
          align-content: flex-start;
          width: 100%;
        }
        .img-item {
          display: inline-block;
        }
        img {
          border:0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          position: relative;
          aspect-ratio: 4 / 3;
          background: #000;
        }
        .img-container {
          display: flex;    
          flex-direction: column;
          align-items: flex-start;
        }
        .img-container.anonymized {
          margin-top: 5%;
        }
        .img-container.anonymized img {
          top: -10%;
        }
        .footer-qr-code > p, .top-qr-code > p {
          font-size: 13px;
        }
        .common-message {
          font-size: 16px;
        }
      }
    `)
    css.appendChild(style);
    printableFrame.current?.contentWindow?.document?.head.appendChild(css);

    setMountNode(printableFrame.current?.contentWindow?.document?.body);

  }, [iframeLoaded, printableFrame.current?.contentWindow]);

  // Places the QR either at the top or bottom depend on whether it's the only rendered element
  const qrCodeContent = examinationContext.share?.share_link == null ?
    (<></>)
    : (
      <div className={printOnlyQr ? 'single-qr-code' : 'footer-qr-code'} key="qr-code">
        <QRCodeSVG
          value={`${examinationContext.share.share_link}&source=qr`}
          size={120}
          level="Q"
          includeMargin={true}
          imageSettings={{
            src: "/logo192.png",
            x: null,
            y: null,
            height: 22,
            width: 22,
          }} />
        <p>{__("examinationReview.qrCode.label")}</p>
      </div>
    );

  const frameComponent = useMemo(() => {

    const InstanceView = ({ instance }) => {
      let imgWidth = 48;
      let imgHeight = 22;

      if (printingPreferenceImgPerPage === 2) {
        imgWidth = 98;
        imgHeight = 46;
      }
      else if (printingPreferenceImgPerPage === 15) {
        imgWidth = 32;
        imgHeight = 16;
      }

      // We do not want to show the label for "other" type instances on the print 
      const view = examinationContext.instanceViews.find(view => view?.id === instance.instance_view_id && view?.type !== "other");

      return (
        <div className="img-item" style={{ width: imgWidth + '%', height: imgHeight + '%' }}>
          {printLabels && (
            <div className="label-container">
              <p>
                {view?.label[currentLanguage] || <>&nbsp;</>}
              </p>
            </div>
          )}
          <div className={`img-container ${anonymizeImages ? 'anonymized' : ''}`}>
            <img
              src={getInstancePreviewUri(instance.dicom_instance_id, true)}
              alt="instance"
            />
          </div>
        </div>
      )
    }
    const Header = () => {
      if (site?.logo_base64 == null || site?.logo_base64 == "") {
        return (
          <div className="page-header">
            <div className="site-name">{site?.name ?? ""}</div>
          </div>
        )
      }
      return (
        <div className="page-header">
          <img src={`data:image/png;base64, ${site.logo_base64}`} />
          <div className="site-name">{site?.name ?? ""}</div>
        </div>
      )
    }
    const Footer = () => {
      return (
        <div className="page-footer">
          <div className="powered-by">{__("branding.poweredBySonio")}</div>
          <img src="/logo192.png" />
        </div>
      )
    }
    const SiteMessage = () => {
      if (site?.brand_message == null || site?.brand_message == "") return null;
      return (
        <div className="common-message">
          {parse(site.brand_message)}
        </div>
      )
    }

    const FullSlide = ({ children }) => {
      return (
        <div className="print-preview-page">
          <Header />
          {children}
          <Footer />
        </div>
      )
    }

    const QrOnlyContent = () => {
      return (
        <FullSlide>
          <div className="qr-only-content">
            {examinationContext.exclusivelyQr && <SiteMessage />}
            <div>{qrCodeContent}</div>
          </div>
        </FullSlide>
      )
    }
    const MaybePrintQrSection = () => {
      if (examinationContext.includeQRCode === false) return null;
      return (<div className="qr-row">{qrCodeContent}</div>)
    }
    const MaybePrintQrPage = () => {
      if (examinationContext.includeQRCode === false) return null;
      return <QrOnlyContent />;
    }
    const LastSlideContent = ({ elements }) => {

      let qrThreshold = 7;
      if (printingPreferenceImgPerPage === 2) qrThreshold = 2;
      else if (printingPreferenceImgPerPage === 15) qrThreshold = 13;

      if (elements.length && elements.length < qrThreshold) {
        return (
          <FullSlide key="last-page">
            <div className="slides-content" >
              {elements.map(i => <InstanceView instance={i} key={i.id} />)}
              <MaybePrintQrSection />
            </div>
          </FullSlide>
        )
      }
      return (
        <>
          <FullSlide key="last-page">
            <div className="slides-content">
              {elements.map(i => <InstanceView instance={i} key={i.id} />)}
            </div>
          </FullSlide>
          <MaybePrintQrPage />
        </>
      )
    }
    const OnlySlidesContent = ({ elements }) => {
      return (
        <FullSlide>
          <div className="slides-content">
            {elements.map(i => <InstanceView instance={i} key={i.id} />)}
          </div>
        </FullSlide>
      )
    }
    const SlidesContent = () => {
      if (printingPreferenceImgPerPage == null) return null;
      const allItems = instancesToPrint ?? []
      // Chunk elements based on the amount of numbers per page selected
      // to separate pages
      const chunks = chunk(allItems, printingPreferenceImgPerPage)
      if (chunks.length > 1) {
        const lastChunk = chunks.slice(-1)[0];
        const firstChunks = chunks.slice(0, -1)
        return (
          <>
            {firstChunks.map((chunk, chunkIndex) => <OnlySlidesContent elements={chunk} key={`page-${chunkIndex}`} />)}
            <LastSlideContent elements={lastChunk} key={`page-last`} />
          </>
        )
      }
      else if (chunks.length === 0) return <LastSlideContent elements={[]} />
      return <LastSlideContent elements={chunks[0]} />
    }

    return printOnlyQr ? <QrOnlyContent /> : <SlidesContent />;

  }, [JSON.stringify(instancesToPrint), printingPreferenceImgPerPage, printLabels, anonymizeImages, iframeLoaded, printableFrame.current?.contentWindow?.document, examinationContext.share, examinationContext.instanceViews, examinationContext.includeQRCode, examinationContext.exclusivelyQr])

  const printIframe = (ref) => {
    const iframeWindow = ref.current.contentWindow || ref.current;
    iframeWindow.focus();
    iframeWindow.print();
    return false;
  };

  const onPrintClick = useCallback(async (mediaId) => {
    const isSelected = instancesToPrint.some(i => i?.dicom_instance_id === mediaId);
    await examinationContext.toggleInstanceSelectedForPrinting(mediaId, !isSelected);
  }, [instancesToPrint, examination.id]);

  const handleIncludeQRCode = async () => {
    if (examinationContext.includeQRCode === false && !examinationContext.share?.share_link) {
      // There is no share link - for this exam - so create one
      await examinationContext.shareExamination();
    }
    examinationContext.setExclusivelyQr(_ => false)
    examinationContext.setIncludeQRCode((s) => !s)
  }

  const QrOption = () => {
    return (
      <>
        <Checkbox
          checked={examinationContext.includeQRCode}
          label={__("examinationReview.includeQRCode")}
          onChange={handleIncludeQRCode}
        />
        {examinationContext.includeQRCode === true &&
          <Checkbox
            checked={examinationContext.exclusivelyQr}
            label={__("examinationReview.exclusivelyQR")}
            onChange={() => examinationContext.setExclusivelyQr(v => !v)}
          />
        }
      </>
    );
  }

  return (
    <>
      <div className="examination-review-print-preview-container">
        <div className="examination-review-print-preview-leftbar">
          <h2>{__("examinationReview.printingImageSelection")}</h2>
          <div className="examination-review-print-preview-images">
            {instances.sort((a, b) => a.idx_in_template - b.idx_in_template).map(instance => {
              const instanceToPrint = instancesToPrint.find(i => i?.dicom_instance_id === instance.id);
              const view = examinationContext.instanceViews.find(view => view?.id === instance.slideId)
              const categoryLabel = appContext.getDefaultCategoryLabelForSlide(view, currentTrimester, __)
              return <div className={`media ${instanceToPrint ? 'selected' : 'not-selected'}`} key={instance.id} onClick={() => onPrintClick(instance.id)}>
                <div className="slide-header">
                  <div className="slide-check"><Icon name={instanceToPrint ? 'done' : 'empty'} /></div>
                  <div className="slide-title">
                    <div className="slide-label">{view?.label[currentLanguage]}</div>
                    {!!categoryLabel && <div className="slide-category">{categoryLabel}</div>}
                  </div>
                </div>
                <img src={getInstanceThumbnailUri(instance.id)} alt="instance" />
              </div>;
            })}
          </div>
        </div>
        <div className="examination-review-print-preview-preview">
          <h2>{__("examinationReview.printingPreview")}</h2>
          <iframe
            className='examination-review-print-preview-iframe'
            ref={printableFrame}
            title="CNEOF medias">
            {mountNode && createPortal(frameComponent, mountNode)}
          </iframe>
        </div>

        <div className="examination-review-print-preview-rightbar">
          <h2>{__("examinationReview.printingSettings")}</h2>
          <div className="examination-review-print-preview-settings">
            <div className="templates">
              <ul>
                {[2, 8, 15].map(imgsPerPage => (
                  <li
                    key={imgsPerPage}
                    className={imgsPerPage === printingPreferenceImgPerPage ? "selected" : ""}
                    onClick={() => setPrintingPreferenceImgPerPage(imgsPerPage)}
                  >
                    {imgsPerPage}
                  </li>
                ))}
              </ul>
            </div>
            <div className="options">
              <Checkbox
                checked={anonymizeImages}
                label={__("examinationReview.anonymizeImages")}
                onChange={() => setAnonymizeImages(a => !a)}
              />
              <Checkbox
                checked={printLabels}
                label={__("examinationReview.printLabels")}
                onChange={() => setPrintLabels(a => !a)}
              />
              <QrOption />
            </div>
          </div>
          <div className="examination-review-print-preview-cta">
            {examinationContext.includeQRCode && <Button
              label={__("examinationReview.print.editSharingList")}
              variant="contained"
              size="full-width"
              onClick={() => history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`, { source: "printing-list" })}
            />}
            <Button
              label={examinationContext.includeQRCode && examinationContext.share?.share_link ? __('examinationReview.printAndShare') : __('examinationReview.print')}
              icon={examinationContext.includeQRCode && examinationContext.share?.share_link ? 'share' : 'print'}
              size="full-width"
              onClick={() => printIframe(printableFrame)}
            />
          </div>
        </div>

      </div>
      <div className="modal-background" onClick={closeAction} />
    </>
  )
}

export default withTranslation()(PrintPreview)
