import React from "react";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import "./CertificationId.css";

function downCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

function lang() {
    return i18n.language
}

function certificationIdErrorTranslator(certification_id_type, __) {
    switch(certification_id_type) {
        case "rpps":
            return __("register.error.onlyXNumber", {count: 11})
        case "lanr":
            return __("register.error.onlyXNumber", {count: 9})
        default:
            return __("register.error.onlyContainsNumber")
    }
}

function certificationIdFormats(certificationIdType) {
    switch(certificationIdType) {
        case "rpps":
            return {
                placeholder: "00000000000",
                format: /^\d{0,11}$/
            }
        case "lanr":
            return {
                placeholder: "000000000",
                format: /^\d{0,9}$/
            }
        case "imr":
            return {
                placeholder: "",
                format: /^\d*$/
            }
        case "ardms":
            return {
                placeholder: "",
                format: /^\d*$/
            }
        case "arrt":
            return {
                placeholder: "",
                format: /^\d*$/
            }
        default:
            return {
                placeholder: "",
                format: /^.*$/
            }
    }
}

function certificationIdPlaceHolder(certificationIdType) {
    return certificationIdFormats(certificationIdType).placeholder
}

function CertificationIdTypeSelector({certificationIdType, setCertificationIdType, certificationIdTypes}) {
    const className = (localCertIdType) =>  localCertIdType == certificationIdType ? "cert-id-type selected" : "cert-id-type"

    return (
        <div className="cert-id-type-wrapper">
            {
                certificationIdTypes.map(({id}) =>
                    <div className={className(id)} onClick={() => setCertificationIdType(id)} key={id}>
                        {id.toUpperCase()}
                    </div>
                )
            }
        </div>
    )
}

export const certificationIdTypeName = ({certificationIdTypes, certificationIdType, t: __}) => { /* We can not put the with translation as we want to return a string. That is sad */
    return downCaseFirstLetter(certificationIdTypes.find(({id}) => id == certificationIdType)?.name[lang(__)] || __("register.DefaultCertificationIdName"))
}

export const CertificationIdInput = withTranslation()(({certificationId, setCertificationId, certificationIdType, certificationIdTypes, certificationIdError, t: __}) => {
    const doSetCertificationId = (newCertificationId) => {
        const format = certificationIdFormats(certificationIdType).format
        if(format.test(newCertificationId))
            return setCertificationId(newCertificationId)
        return
    }
    return (
        <input className={certificationIdError ? "input-error" : ""}
            type="text"
            autoComplete="off"
            placeholder={certificationIdPlaceHolder(certificationIdType)}
            style={{width: "100%"}}
            onChange={(e) => doSetCertificationId(e.target.value)} value={certificationId}/>
    )
})

export const CertificationIdErrorMessage = withTranslation()(({certificationIdError, certificationIdType, certificationIdTypes, t: __}) => {
    const certification_id_error = certificationIdErrorTranslator(certificationIdType, __)
    if (!certificationIdError) {
        return null
    }
    return <div className="error-message">{__(certificationIdError, {certification_type_name: certificationIdTypeName({certificationIdType, certificationIdTypes, t: __}), certification_id_error})}</div>
})

export const MultipleCertificationIdInput = withTranslation()((props) => {
    return (
        <div className="cert-id-input-flex-wrapper">
            <CertificationIdTypeSelector {...props}/>
            <CertificationIdInput {...props} />
        </div>
    )
})

