import { useMemo } from 'react'
import { withTranslation } from "react-i18next";
import { formatName, getNiceGestionalAge } from '../../../services/examination';
import { formatDate, formatDateTime } from '../../../utils';
import { positionIdToRotation } from '../../../config';
import Fetus from '../../../components/Fetus/Fetus';
import Icon from '../../../atoms/Icon/Icon';

const ExaminationReviewOverview = ({
  t: __,
  examinationType,
  currentTrimester,
  patient,
  episode,
  examination,
  examinationMetadata,
  zones,
  medicalHistory,
  anomalies,
  checklistItems,
  appContext,
  examinationContext
}) => {
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();

  const fetusRotation = useMemo(() => {
    if (!appContext.fetusPositions?.length || !currentTrimester) return {rotation: 0, flip: false};
    return !examinationContext.examination.fetus_position_id
      ? positionIdToRotation[1]
      : positionIdToRotation[examinationContext.examination.fetus_position_id];
  }, []);

  const checklistItemsToShow = useMemo(() => checklistItems?.filter(item => (anomalies.includes(item.id) || item.category[examinationType]?.[currentTrimester]?.includes(appContext.preferences.default_category)) && item.type === "item"), [JSON.stringify(checklistItems)]);
  const biometricsToShow = useMemo(() => checklistItems?.filter(item => (anomalies.includes(item.id) || item.category[examinationType]?.[currentTrimester]?.includes(appContext.preferences.default_category)) && item.type === "metric"), [JSON.stringify(checklistItems)]);

  return (
    <>
    <div className="row">
      <div className="w6">
        <div className="card">
          <div className="entry">
            <label>{__("examinationReview.trimester")}</label>
            <span>{examination.trimester}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.examinationDate")}</label>
            <span>{formatDateTime(examination.updated_at, appContext.preferences.date_format)}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.name")}</label>
            <span>{examinationMetadata.practitioner?.name || __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.site")}</label>
            <span>{examinationMetadata.site?.name || __("examinationReview.notProvided")}</span>
          </div>
          <div className="entry">
            <label>{__("examinationReview.practitioner.rpps")}</label>
            <span>{examinationMetadata.practitioner?.rpps || __("examinationReview.notProvided")}</span>
          </div>
        </div>
      </div>
      <div className="w6">
        <div className="card">
          <div className="entry">
            <label>{__("examinationReview.patient.name")}</label>
            <span className="ph-no-capture">{patient?.name ? formatName(patient?.name).fullName : __("patients.anonymous")}</span>
          </div>
          {!!episode?.dob && (
            <div className="entry">
              <label>{__("examinationReview.patient.dob")}</label>
              <span className="ph-no-capture">{patient?.dob ? formatDate(patient?.dob, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
            </div>
          )}
          {!!episode?.conception_date && (
            <div className="entry">
              <label>{__("examinationReview.episode.conceptionDate")}</label>
              <span className="ph-no-capture">{episode?.conception_date ? formatDate(episode?.conception_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
            </div>
          )}
          {!!episode?.lmp_date && (
            <>
            <div className="entry">
              <label>{__("examinationReview.episode.lmpDate")}</label>
              <span className="ph-no-capture">{episode?.lmp_date ? formatDate(episode?.lmp_date, appContext.preferences.date_format) : __("examinationReview.notProvided")}</span>
            </div>
            <div className="entry">
              <label>{__("examinationReview.episode.gestationalAge")}</label>
              <span className="ph-no-capture">{getNiceGestionalAge(__, new Date(episode?.lmp_date), examination?.examination_date)}</span>
            </div>
            </>
          )}
          {(!!examination.medical_history?.["medicalexam.fetus.sex"] && examinationContext.fetusSexVisibility === 'visible') && (
            <div className="entry">
              <label>{examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"]?.label[currentLanguage]}</label>
              <span>{examinationContext.medicalHistoryItems?.["medicalexam.fetus.sex"]?.options?.find(option => option.value === examination.medical_history?.["medicalexam.fetus.sex"]?.value)?.label[currentLanguage] || __("examinationReview.notProvided")}</span>
            </div>
          )}
        </div>
      </div>
    </div>
    <div className="row">
      <div className="w4 cards">
        {!!examinationContext.examination.fetus_position_id && (
          <>
          <div className="card">
            <h2>{__('examinationReview.fetusPosition')}</h2>
            <div className="examination-review-overview-fetus">
              <Fetus
                zones={zones?.map(zone => ({id: zone.id, validated: true}))}
                rotation={fetusRotation.rotation}
                flip={fetusRotation.flip}
              />
              {appContext.fetusPositions.find(position => position.id === examinationContext.examination.fetus_position_id)?.label[currentLanguage].split("–").map(position => <span className="position-label">{position}</span>)}
            </div>
          </div>
          </>
        )}
        <div className="card">
          <h2>{__('examinationReview.medicalHistory')}</h2>
          <div className="examination-review-risk-factors">
            {(!medicalHistory.length) ? (
              <div className="examination-review-nostudies">
                {__("patient_details.noRiskFactorsAvailable")}
              </div>
            ) : (
              <>
              {!!medicalHistory.length && (
                medicalHistory.map((risk) => (
                  <div key={risk.risk_factor_id} className={`entry ${!!risk.is_risky ? 'is-risky' : ''}`}>
                    <label>{risk.label}</label>
                    <span>{!!risk.raw_value && !risk.tmp_value && !risk.value.includes(risk.raw_value) && (<span className="raw-value">{risk.raw_value}</span>)} {risk.value}</span>
                  </div>
                ))
              )}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="w8 cards">
        <div className="card">
          <h2>{__("examinationReview.checklistItems")}</h2>
          {!!checklistItemsToShow?.length ? (
            <ul className="examination-review-checklist-items">
              {checklistItemsToShow.map(item => (
                <li key={item.id} className={anomalies.includes(item.id) ? 'unusual' : 'usual'}>
                  <span>{item.label[currentLanguage]}</span>
                  <span>{!anomalies.includes(item.id) ? <Icon name="done" /> : false}</span>
                  <span>{anomalies.includes(item.id) ? <Icon name="close" /> : false}</span>
                </li>
              ))}
            </ul>
          ) : (
            <div className="examination-review-checklist-empty">
              {__("examinationReview.noChecklistItemsForThisTrimester")}
            </div>
          )}

          {!!biometricsToShow?.length && (
            <>
            <h2>{__("examinationReview.biometries")}</h2>
            <ul className="examination-review-checklist-items">
              {biometricsToShow.map(item => (
                <li key={item.id} className={anomalies.includes(item.id) ? 'unusual' : 'usual'}>
                  <span>{item.label[currentLanguage]}</span>
                  <span>{!anomalies.includes(item.id) ? <Icon name="done" /> : false}</span>
                  <span>{anomalies.includes(item.id) ? <Icon name="close" /> : false}</span>
                </li>
              ))}
            </ul>
            </>
          )}
        </div>

        {/*!!itemsAtRisk.length && (
            <h2>{__('examinationReview.itemsAtRisk')}</h2>
            <div>
              {!itemsAtRisk.filter(item => item.unusual && item.type !== "metric").length && (
                <div className="examination-review-nostudies">
                  {__("patient_details.noRiskItemsAvailable")}
                </div>
              )}
              {itemsAtRisk.filter(item => item.unusual && item.type !== "metric").map(item => (
                <div key={item.id} className="examination-review-signs-item unusual">
                  <span className="examination-review-signs-name">
                    {item.label[currentLanguage]}
                  </span>
                </div>
              ))}
            </div>
              )*/}
      </div>
    </div>
    </>
  );
}

export default withTranslation()(ExaminationReviewOverview);
