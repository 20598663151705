import ResourceApi from "./resource";
import changeStatusPreferenceGeneralDoc from "../providers/examSharing/actions/changeStatusPreferenceGeneralDoc";
const updateAttachment = async (attachment, processErrorMessages) => {
    var formData = new FormData();
    formData.append("attachment[enabled_by_default]", !attachment.enabled_by_default);

    try {
      const { data } = await ResourceApi.updateAttachment(attachment.id, formData);
      changeStatusPreferenceGeneralDoc(data.data);
    } catch(e) {
      processErrorMessages(e.response, setUpdateError)
    }
}

export default updateAttachment;
