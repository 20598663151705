import {createContext, useState} from "react";
import PropTypes from "prop-types";
import config from "../config";

export const ZoomLevelContext = createContext({});

const LOCALSTORAGE_ZOOMLEVEL_CONGIF_PARAM = 'defaultZoomLevel';
const ALLOWED_ZOOM_LEVELS = [1, 2, 3, 5, 8];

export const ZoomLevelContextProvider = ({children}) => {
  const [zoomLevel, setZoomLevel] = useState(Number(window.localStorage.getItem(LOCALSTORAGE_ZOOMLEVEL_CONGIF_PARAM)) || config.examinationLive.numberOfColumns);

  const updateZoomLevel = (newLevel) => {
    window.localStorage.setItem(LOCALSTORAGE_ZOOMLEVEL_CONGIF_PARAM, newLevel);
    setZoomLevel(newLevel);
    return newLevel;
  };

  const zoomIn = () => {
    const currentIndex = ALLOWED_ZOOM_LEVELS.findIndex(level => level === zoomLevel);
    if (currentIndex > 0) updateZoomLevel(ALLOWED_ZOOM_LEVELS[currentIndex - 1]);
  }

  const zoomOut = () => {
    const currentIndex = ALLOWED_ZOOM_LEVELS.findIndex(level => level === zoomLevel);
    if (currentIndex >= 0 && currentIndex < ALLOWED_ZOOM_LEVELS.length - 1) updateZoomLevel(ALLOWED_ZOOM_LEVELS[currentIndex + 1]);
  }

  return (
    <ZoomLevelContext.Provider
      value={{
        zoomLevel,
        updateZoomLevel,
        zoomIn,
        zoomOut,
      }}
    >
      {children}
    </ZoomLevelContext.Provider>
  );

};
ZoomLevelContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
