import React, { useState } from 'react'
import { withTranslation } from "react-i18next";
import "./ForgotPassword.css"
import userApi from '../../services/user'
import Button from '../../atoms/Button/Button';
import { Link } from 'react-router-dom';
import Icon from '../../atoms/Icon/Icon';

const ForgotPassword = ({ t: __ }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);
  const [success, setSuccess] = useState(false);
  const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

  const requestPasswordReset = async () => {
      setLoading(true);
      setSuccess(null);
      setErrorStatus(null);
      try {
        await userApi.requestPasswordReset(email, currentLanguage);
        setSuccess(true);
      } catch ({ response: { status } }){
        setErrorStatus(status);
      } finally {
        setLoading(false);
      }
  } 

  return (
    <>
      <div className='go-back-to-login'><Link to="/login"><Icon name="left" /></Link></div>
      <div className="container" style={{ width: "100vw" }}>
        <div>{__("login.resetPasswordEmailPrompt")}</div>
        <input type="email" disabled={loading} onChange={({ target: { value }}) => setEmail(value)} />
        <Button 
          disabled={loading} 
          label={loading ? __("common.loading") : __("common.submit")} 
          onClick={requestPasswordReset} 
        />
        {errorStatus === 401 && <div className="forgot-pwd error">{__("login.validResetPasswordRequestAlreadyExists")}</div>}
        {errorStatus === 404 && <div className="forgot-pwd error">{__("login.emailNotFound")}</div>}
        {errorStatus === 500 && <div className="forgot-pwd error">{__("fallback.message")}</div>}
        {success && <div className="forgot-pwd success">{__("login.resetEmailSent", { email })}</div>}
      </div>
    </>
  )
}
export default withTranslation()(ForgotPassword);
